import dayjs from "dayjs";

export const TEMPLATES_TITLE = "Modelet";
export const DOCUMENTATION_TITLE = "Lloji Dokumentacionit";
export const CUSTOM_FIELDS_TITLE = "Fushat e Personalizuara";

export const keyByTitle = {
  [TEMPLATES_TITLE]: "templatesAvailable",
  [DOCUMENTATION_TITLE]: "documentationsAvailable",
  [CUSTOM_FIELDS_TITLE]: "customsFieldsAvailable",
};

export const docGridColumnDefs = ({ onDocClick }) => [
  {
    headerName: "Lloji i Dokumentacionit",
    field: "docName",
    resizable: true,
    sortable: true,
    cellRendererFramework: (data) => (
      <span onClick={() => onDocClick(data.value)} className="docName">
        {data?.value}
      </span>
    ),
  },
  {
    headerName: "Krijuar Në",
    field: "createdAt",
    resizable: true,
    sortable: true,
    cellRenderer: (data) => dayjs(data?.value).format("DD/MM/YYYY hh:mm a"),
  },
];

export const fieldsGridColumnDefs = [
  {
    headerName: "Fushat e Personalizuara",
    field: "label",
    resizable: true,
    sortable: true,
  },
  {
    headerName: "Modelet",
    field: "type",
    resizable: true,
    sortable: true,
  },
];
