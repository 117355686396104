import types from "../types/types";
const initialState = {
	deductions: undefined,
};

const deductionsReducer = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case types.SET_DEDUCTIONS:
			return {
				...state,
				deductions: payload,
			};
		default:
			return state;
	}
};
export default deductionsReducer;
