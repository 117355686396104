import { Button } from "antd";
import React from "react";
import { htmlParser } from "../../../../utils";
import MobileHtmlTabel from "../../../../utils/MobileComponents/MobileHtmlTabel";

function MobileMenyratPageses({ tableData, handleCLickOpen }) {
  return (
    <div className="base-mobile-with-header">
      <div className="header-div">
        {" "}
        <Button onClick={handleCLickOpen} className="btn-header">
          Shto një mënyrë pagese
        </Button>{" "}
      </div>
      <MobileHtmlTabel
        header={["Mënyra pagesës", "Përshkrimi", "Formula"]}
        tableData={tableData?.map?.(({ name, description, formula }) => ({
          statusHeaderKey: name,
          description: htmlParser(description),
          formula,
        }))}
        isHeader={true}
        isLink={false}
      />
    </div>
  );
}

export default MobileMenyratPageses;
