import { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import Chart from "react-google-charts";
import { Alert, message } from "antd";
import { HollowDotsSpinner } from "react-epic-spinners";
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Cell,
} from "recharts";

var tempColor = "#3A98B9";
function WageTotals() {
  const { clockings } = useSelector((state) => state.clockings);
  const { departments } = useSelector((state) => state.departments);
  const { eligibleEmployeesClockIn } = useSelector(
    (state) => state.employeesList
  );
  const dataSet = useMemo(() => {
    let temp = 0;
    if (!!eligibleEmployeesClockIn && !!clockings && !!departments) {
      const filteredClockings = clockings
        ?.filter(
          (c) =>
            !!c?.clockInDate &&
            !!c?.clockOutDate &&
            c?.approved === true &&
            moment(c?.clockInDate).startOf("month").valueOf() ===
              moment().startOf("month").valueOf()
        )
        ?.forEach((c) => {
          const dayEarned = c?.totalities?.totalPay || 0;
          temp += Number(dayEarned);
        });
    }
    return [{ name: "Pagat", Total: temp.toFixed(2) }];
  }, [eligibleEmployeesClockIn, clockings, departments]);

  return (
    <>
      {!!clockings && !!eligibleEmployeesClockIn ? (
        <div className="clock-in-dashboard-card">
          {1 === 1 ? (
            <div
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ResponsiveContainer width="100%" height="95%">
                <BarChart
                  data={dataSet}
                  margin={{
                    top: 40,
                    right: 40,
                    left: 10,
                    bottom: 5,
                  }}
                >
                  <XAxis
                    dataKey="name"
                    scale="point"
                    padding={{ left: 50, right: 50 }}
                  />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="Total" fill="#8884d8" barSize={80}>
                    {dataSet.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={"#1DA193"} />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: 60,
              }}
            >
              <Alert
                type="info"
                showIcon
                message="Nuk ka te dhena statistikore..."
              />
            </div>
          )}
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <HollowDotsSpinner color="#1DA193" size={24} />
        </div>
      )}
    </>
  );
}

export default WageTotals;
