import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Form,
  TimePicker,
  Row,
  Col,
  Input,
  Checkbox,
} from "antd";
import { OraBukur } from "../../../../assets/icons/index";
import {
  getDisabledAfterCustomHours,
  getDisabledBeforeCustomHours,
  getDisabledBeforeCustomMinutes,
  getDisabledAfterCustomMinutes,
} from "../../../dashboard/utils/disabledTimeFilters";
import { RichTextEditor } from "../../../commonComponents";
import moment from "moment-timezone";
function CreateShiftModal({
  setVisible,
  visible,
  onChange,
  addItems,
  form,
  data,
  setTableData,
  setUpdateData,
  updateItems,
  updateData,
  onNestedChange,
}) {
  // const { TextArea } = Input;
  const format = "HH:mm";
  const [isPaid, setIsPaid] = useState(null);
  // console.log(updateData);
  //   const [form] = Form.useForm();
  //fill up the form if the modal is opend for update
  useEffect(() => {
    // console.log({ updateData });
    if (updateData?.length !== null) {
      form.setFieldsValue({
        // shiftId: updateData?.shiftId,
        shiftName: updateData?.shiftName,
        shiftDescription: updateData?.shiftDescription,
        shiftStartTime: moment(updateData?.shiftStartTime),
        shiftEndTime: moment(updateData?.shiftEndTime),
        breakStartTime: moment(updateData?.shiftBreak?.breakStartTime),
        breakEndTime: moment(updateData?.shiftBreak?.breakEndTime),
        isBreakTimePaid: updateData?.shiftBreak?.isBreakTimePaid,
        // createdAt: moment(),
      });
    }
    setIsPaid(!!updateData?.shiftBreak?.isBreakTimePaid);
  }, [updateData]);

  const handleIsPaidChange = (e) => {
    setIsPaid(e.target.checked);
    // console.log(e.target.checked);
    onNestedChange("shiftBreak", "isBreakTimePaid", e.target.checked);
  };

  const onClose = () => {
    // console.log("sjkfksdjfhskdjfh");
    setIsPaid(false);
    setUpdateData(null);
    setVisible(false);
  };
  return (
    <div>
      <Modal
        title="Krijo një orar të ri"
        centered
        destroyOnClose={true}
        className="paisjet-orarit-modal"
        wrapClassName="paisjet-orarit"
        open={visible}
        onOk={() => {
          onClose();
        }}
        onCancel={() => {
          onClose();
        }}
        width={900}
        footer={[
          <Form.Item>
            <Button
              className="mbyll"
              key="close"
              onClick={() => {
                onClose();
              }}
            >
              Mbyll
            </Button>
            <Button
              form="shift-modal-form"
              type="primary"
              className="ruaj"
              htmlType="submit"
              key="save"
            >
              Ruaj Ndryshimet
            </Button>
          </Form.Item>,
        ]}
      >
        <Form
          name="shift-modal-form"
          onFinish={updateData ? updateItems : addItems}
          form={form}
        >
          <div className="paisje-orarit-content">
            <div className="logo-container">
              <OraBukur />
            </div>
            <div className="grid-container">
              <Row style={{ width: "100%", marginBottom: 30 }}>
                <Col span={12}>
                  <h3>Titulli i Orarit:</h3>
                  <Form.Item
                    name="shiftName"
                    rules={[
                      {
                        required: true,
                        message: "Ju lutem shkruani titullin e orarit !",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Shkruaj titullin "
                      style={{
                        width: "95%",
                        background: "#F5F5F7",
                        border: 0,
                        borderRadius: 5,
                      }}
                      // onChange={(e) => onChange("shiftName", e.target.value)}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <h3>Përshkrimi i orarit:</h3>
                  <Form.Item
                    name="shiftDescription"
                    rules={[
                      {
                        required: true,
                        message: "Ju lutem shkruani përshkrimin e orarit !",
                      },
                    ]}
                  >
                    <RichTextEditor
                      className={"paisjet-orarit-quill"}
                      // onChange={(e) => onChange("shiftDescription", e)}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row style={{ width: "100%" }}>
                <Col span={12}>
                  <h3>Orari i fillimit:</h3>
                  <Form.Item
                    name="shiftStartTime"
                    rules={[
                      {
                        required: true,
                        message: "Ju lutem selekto orën e fillimit !",
                      },
                    ]}
                  >
                    <TimePicker
                      style={{
                        width: "95%",
                        background: "#F5F5F7",
                        border: 0,
                        borderRadius: 5,
                      }}
                      className="konfigurimet-shift-picker"
                      placeholder="Selekto orën e fillimit"
                      format={format}
                      onChange={(val) => onChange("shiftStartTime", val)}
                      disabledHours={() =>
                        getDisabledAfterCustomHours(
                          !!updateData
                            ? updateData.shiftEndTime
                            : data.shiftEndTime
                        )
                      }
                      disabledMinutes={(value) =>
                        getDisabledAfterCustomMinutes(
                          !!updateData
                            ? updateData.shiftEndTime
                            : data.shiftEndTime,
                          value
                        )
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <h3>Orari i mbarimit:</h3>
                  <Form.Item
                    name="shiftEndTime"
                    rules={[
                      {
                        required: true,
                        message: "Ju lutem selekto orën e mbarimit !",
                      },
                    ]}
                  >
                    <TimePicker
                      style={{
                        width: "95%",
                        background: "#F5F5F7",
                        border: 0,
                        borderRadius: 5,
                      }}
                      className="konfigurimet-shift-picker"
                      placeholder="Selekto orën e mbarimit"
                      format={format}
                      onChange={(val) => onChange("shiftEndTime", val)}
                      disabledHours={() =>
                        getDisabledBeforeCustomHours(
                          !!updateData
                            ? updateData.shiftStartTime
                            : data.shiftStartTime
                        )
                      }
                      disabledMinutes={(value) =>
                        getDisabledBeforeCustomMinutes(
                          !!updateData
                            ? updateData.shiftStartTime
                            : data.shiftStartTime,
                          value
                        )
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row style={{ width: "100%" }}>
                <Col span={12}>
                  <h3>Fillimi i pushimit:</h3>
                  <Form.Item
                    name="breakStartTime"
                    rules={[
                      {
                        required: true,
                        message: "Ju lutem selekto fillimin e pushimit !",
                      },
                    ]}
                  >
                    <TimePicker
                      style={{
                        width: "95%",
                        background: "#F5F5F7",
                        border: 0,
                        borderRadius: 5,
                      }}
                      className="konfigurimet-shift-picker"
                      placeholder="Selekto orën e fillimit"
                      format={format}
                      onChange={(val) =>
                        onNestedChange("shiftBreak", "breakStartTime", val)
                      }
                      disabledHours={() =>
                        getDisabledAfterCustomHours(
                          !!updateData
                            ? updateData.shiftEndTime
                            : data.shiftEndTime
                        )
                      }
                      disabledMinutes={(value) =>
                        getDisabledAfterCustomMinutes(
                          !!updateData
                            ? updateData.shiftEndTime
                            : data.shiftEndTime,
                          value
                        )
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <h3>Mbarimi i pushimit:</h3>
                  <Form.Item
                    name="breakEndTime"
                    rules={[
                      {
                        required: true,
                        message: "Ju lutem selekto orën e mbarimit !",
                      },
                    ]}
                  >
                    <TimePicker
                      style={{
                        width: "95%",
                        background: "#F5F5F7",
                        border: 0,
                        borderRadius: 5,
                      }}
                      className="konfigurimet-shift-picker"
                      placeholder="Selekto orën e mbarimit"
                      format={format}
                      onChange={(val) =>
                        onNestedChange("shiftBreak", "breakEndTime", val)
                      }
                      disabledHours={() =>
                        getDisabledBeforeCustomHours(
                          !!updateData
                            ? updateData.breakStartTime
                            : data.breakStartTime
                        )
                      }
                      disabledMinutes={(value) =>
                        getDisabledBeforeCustomMinutes(
                          !!updateData
                            ? updateData.breakStartTime
                            : data.breakStartTime,
                          value
                        )
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row style={{ width: "100%" }}>
                <Col span={12}>
                  <Form.Item label="Pushim i paguar:" name="isBreakTimePaid">
                    <Checkbox
                      onChange={handleIsPaidChange}
                      defaultChecked={updateData?.shiftBreak?.isBreakTimePaid}
                    >
                      {!!isPaid ? "Paguar" : "I papaguar"}
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </div>
        </Form>
      </Modal>
    </div>
  );
}
export default CreateShiftModal;
