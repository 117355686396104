export const COEFFICENTS_WAGES = {
	NORMAL_HOURS: 1,
	LATE_HOURS: 1.2,
	EXTRA_HOURS: 1.5,
	EXTRA_HOURS2: 1.5,
};

export const COEFFICENTS_WAGES_TYPES = {
	normalHours: 1,
	lateHours: 1.2,
	extraHours: 1.5,
};

export const DAY_TYPES_TRANSLATIONS = {
	normalHours: "(normale)",
	lateHours: 1.2,
	extraHours: 1.5,
};

export const getCoefficentDayType = (dayT) => {
	if (COEFFICENTS_WAGES_TYPES.hasOwnProperty(dayT)) {
		return COEFFICENTS_WAGES_TYPES[dayT];
	} else {
		return 1;
	}
};

export const getDayTypeTranslation = (dayT) => {
	if (DAY_TYPES_TRANSLATIONS.hasOwnProperty(dayT)) {
		return DAY_TYPES_TRANSLATIONS[dayT];
	} else {
		return 1;
	}
};
