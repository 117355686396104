import { Modal, Button, Form, message, Input } from "antd";
import React, { useEffect } from "react";
import { API } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import { useProgramFields } from "../../../../hooks/useProgramFields";
import { RichTextEditor } from "../../../commonComponents";
import { useAuth } from "../../../../authentication/authHelpers";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { programFields as programFieldsReducer } from "../../../../store/actions";

const programFieldsId = {
  PAISJET_ORARIT: "32ca97d2-1eb1-43c3-9e80-a30a60acfc3b",
};
function PaisjetOraritPostModal({
  setVisible,
  visible,
  filterData,
  dataLista,
  shouldShowError,
  setShouldShowError,
  programFields,
  gridApi
}) {
  const [form] = Form.useForm();

  const { PAISJET_ORARIT } = programFieldsId;
  const { [PAISJET_ORARIT]: records } = useProgramFields();
  const { userAccess } = useAuth();

  const username = {
    userId: userAccess[0].userSub,
    employeeFirstName: userAccess[0].given_name,
    employeeLastname: userAccess[0].family_name,
  };

  const dispatch = useDispatch()


  //save the new data
  const handleSave = () => {

    if (!dataLista) {
      // const formData = { ...form.getFieldsValue(), id: uuidv4() };
      // form.resetFields();
      // API.put(
      //   "programFields",
      //   `/programFields/32ca97d2-1eb1-43c3-9e80-a30a60acfc3b`,
      //   {
      //     body: {
      //       fieldOptions: [...filterData?.fieldOptions, formData],
      //     },
      //   }
      // ).then(() => {
      //   message.success("Të dhënat u regjistruan me sukses !", 10);
      //   // setTableData([...tableData, formData]);
      //   setVisible(false);
      // });
      // let field = [...filterData?.fieldOptions, formData];
      // // setRequests((prev) => ({ ...prev, fieldOptions: field }));
    } else {
      let getData = { ...form.getFieldsValue(), id: dataLista.id };
      let logs = compareObjects(dataLista, getData)

      getData = { ...getData, logs: !!dataLista?.logs ? [...dataLista.logs, logs] : [] }

      API.put(
        "programFields",
        "/programFields/32ca97d2-1eb1-43c3-9e80-a30a60acfc3b",
        {
          body: {
            fieldOptions: [
              ...filterData?.fieldOptions.filter((el) => el.id !== dataLista.id),
              getData,
            ],
          },
        }
      ).then(() => {
        message.success("Të dhënat u regjistruan me sukses !", 10);
        form.resetFields();
        let field = [
          ...filterData?.fieldOptions.filter((el) => el.id !== dataLista.id),
          getData,
        ];

        let index = programFields.findIndex(({ fieldId }) => fieldId === "32ca97d2-1eb1-43c3-9e80-a30a60acfc3b");
        const newValues = [...programFields];
        if (index !== -1) {
          newValues[index] = { ...newValues[index], fieldOptions: field };
        }
        dispatch(programFieldsReducer(newValues))
        if (!!dataLista?.logs) {

          gridApi.applyTransaction(
            {
              update: [getData],
            }
          )
        } else {
          gridApi.applyTransaction(
            {
              add: [getData],
            }
          )
        }
        setVisible(false);
        setShouldShowError(false);
      });
    }
  };

  //function to compare 2 object and get the changes
  function compareObjects(previousObject, currentObject) {
    const keys1 = Object.keys(previousObject);
    const keys2 = Object.keys(currentObject);
    let changesArray = [];

    const commonKeys = keys1.filter((key) => keys2.includes(key));
    if (commonKeys.length === 0) {
    }
    commonKeys.forEach((key) => {
      if (typeof currentObject[key] === "object") {
        if (currentObject[key].hasOwnProperty("files")) {
          const differenceAddedFiles = _.differenceBy(
            currentObject[key].files,
            previousObject[key].files,
            "id"
          );
          const differenceRemovedFiles = _.differenceBy(
            previousObject[key].files,
            currentObject[key].files,
            "id"
          );
          if (differenceAddedFiles.length !== 0) {
            const name = differenceAddedFiles.map((obj) => obj.name).join(", ");
            let changedData = {
              id: uuidv4(),
              activity: "U shtuan",
              author: username,
              changeDate: Date.now(),
              field: key,
              previousData: " ",
              currentData: name,
            };
            changesArray.push(changedData);
          }
          if (differenceRemovedFiles.length !== 0) {
            const name = differenceRemovedFiles
              .map((obj) => obj.name)
              .join(", ");
            let changedData = {
              id: uuidv4(),
              activity: "U Hoqen",
              author: username,
              changeDate: Date.now(),
              field: key,
              previousData: " ",
              currentData: name,
            };
            changesArray.push(changedData);
          }
        }
      } else {
        if (previousObject[key] !== currentObject[key]) {
          let changedData = {
            id: uuidv4(),
            activity: "Ndryshuar",
            author: username,
            changeDate: Date.now(),
            field: key,
            previousData: previousObject[key],
            currentData: currentObject[key],
          };
          changesArray.push(changedData);
        }
      }
    });
    if (changesArray !== 0) {
      return changesArray;
    } else {
      return false;
    }
  }

  //fill in the form with data if is modal opend for edit
  useEffect(() => {
    if (!dataLista) return;
    form.setFieldsValue(dataLista);
  }, [dataLista]);

  return (
    <div>
      <Modal
        title="Pajisje e orarit"
        className="paisjet-per-orarin-modal"
        centered
        open={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        width={550}
        destroyOnClose
        footer={[
          <Button onClick={() => setVisible(false)} className="Mbyll-btn">
            Anuloje
          </Button>,
          <Button
            key="save"
            form="PaisejePerOrarin"
            htmlType="submit"
            className="ruaj-btn"
          >
            Ruaj ndryshimet
          </Button>,
        ]}
      >
        <Form name="PaisejePerOrarin" onFinish={handleSave} form={form}>
          <h3>Emri i pajisjes:</h3>
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: "Ju lutem shkruani emrin e pajisjes!",
              },
              {
                validator: (_, value) =>
                  shouldShowError &&
                    !!value &&
                    records.some(
                      ({ name }) => name.toLowerCase() === value.toLowerCase()
                    )
                    ? Promise.reject(
                      new Error("Ekziston nje paisje orari me kete emer!")
                    )
                    : Promise.resolve(),
              },
            ]}
          >
            <Input placeholder="Emri i pajisjes" />
          </Form.Item>
          <h3>Pershkrimi per pajisjen:</h3>
          <Form.Item
            name="description"
            rules={[
              {
                required: true,
                message: "Ju lutem përshkruani pajisjen!",
              },
            ]}
          >
            <RichTextEditor className={"modal-quill-edit"} />
            {/* <Input placeholder="Pershkrimi per pajisjen" /> */}
          </Form.Item>
          <h3>Ip adresa:</h3>
          <Form.Item
            name="ip"
            rules={[
              {
                required: true,
                message: "Ju lutem shkruaj adresën Ip!",
              },
            ]}
          >
            <Input placeholder="Ip adresa për paisjen" />
          </Form.Item>
          <h3>Shfletuesi:</h3>
          <Form.Item
            name="shfletuesi"
            rules={[
              {
                required: true,
                message: "Ju lutem shkruaj shfletuesin!",
              },
            ]}
          >
            <Input placeholder="Shfletuesi" />
          </Form.Item>
          <h3>Sistemi:</h3>
          <Form.Item
            name="sistemi"
            rules={[
              {
                required: true,
                message: "Ju lutem shkruaj sistemin!",
              },
            ]}
          >
            <Input placeholder="Sistemi" />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default PaisjetOraritPostModal;
