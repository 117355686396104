import { Button, Form, Input, Modal, Select } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { RichTextEditor } from "../../../commonComponents";
import "./EmailTemplateModal.scss";
import { ModalXIcon } from "../../../../assets/icons";
import { v4 as uuidv4 } from "uuid";
import { Editor } from "@tinymce/tinymce-react";

function EmailTemplateModal({
  isModalVisible,
  handleCancel,
  editData,
  setEditData,
  refreshTable,
  setChanges,
}) {
  const [form] = Form.useForm();
  const [optionsCategorySelected, setOptionsCategorySelected] = useState(null);

  //the submit modal
  const onFinish = (values) => {
    delete values?.options;

    if (Object.keys(editData).length !== 0) {
      Object.assign(values, {
        emailTemplateId: editData?.emailTemplateId || uuidv4(),
      });

      refreshTable(values, "update");
      setEditData({});
      handleCancel();
    } else {
      Object.assign(values, { emailTemplateId: uuidv4() });
      refreshTable(values, "add");
      setEditData({});
      handleCancel();
    }
    setChanges(true);
  };

  const handleNewCancel = () => {
    form.resetFields();
    setEditData({});
    handleCancel();
  };

  //Function to add the dynamic field on the rich text editor
  const handleSelectOption = (e) => {
    const test = form.getFieldValue("templateBodyValues");
    if (!!!test) {
      form.setFieldValue("templateBodyValues", e);
    } else {
      const result = test + `<p>${e}</p>`;
      form.setFieldValue("templateBodyValues", result);
    }
    form.setFieldValue("options", null);
  };

  //dynamic values
  const optionsValues = [
    {
      key: "addLeave",
      values: [
        {
          label: "Admin",
          value: "${admin}",
        },
        {
          label: "Start Date",
          value: "${startDate}",
        },
        {
          label: "End Date",
          value: "${endDate}",
        },
        {
          label: "User",
          value: "${user}",
        },
      ],
    },
  ];

  //when we are in edit
  useEffect(() => {
    form.setFieldsValue(editData);
    const dynamicValues = optionsValues?.find(
      (item) => item?.key === editData.templateCategory
    );
    setOptionsCategorySelected(dynamicValues?.values);
  }, [editData]);

  return (
    <Modal
      open={isModalVisible}
      onCancel={handleNewCancel}
      destroyOnClose={true}
      width={600}
      title={"Email Content"}
      className="emailTemplateModal"
      closeIcon={<ModalXIcon />}
      footer={[
        <Button className="decline" onClick={handleNewCancel}>
          Mbyllni faqen
        </Button>,
        <Button
          className="send-request"
          htmlType="submit"
          key="submit"
          form="emailTemplateForm"
        >
          Ruani Ndryshimet
        </Button>,
      ]}
    >
      <Form
        onFinish={onFinish}
        form={form}
        name="emailTemplateForm"
        style={{ height: "100%" }}
        layout="vertical"
      >
        <Form.Item
          name="templateName"
          label="Emri i template"
          rules={[
            {
              required: true,
              message: "Kjo fushë nuk mund të jetë bosh!",
            },
          ]}
        >
          <Input placeholder="Vendosni emrin e template..."></Input>
        </Form.Item>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Form.Item
            name="templateCategory"
            label="Vendodhja e Template"
            style={{ width: "49%" }}
            rules={[
              {
                required: true,
                message: "Kjo fushë nuk mund të jetë bosh!",
              },
            ]}
          >
            <Select
              placeholder="Zgjidhni ku do te përdoret ky template...."
              onSelect={(e) => {
                const dynamicValues = optionsValues?.find(
                  (item) => item?.key === e
                );
                setOptionsCategorySelected(dynamicValues?.values);
              }}
              options={[
                {
                  label: "Shtimin e lejeve",
                  value: "addLeave",
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            style={{ width: "49%" }}
            name="options"
            label="Zgjidhni opsionet "
          >
            <Select
              notFoundContent={"Zgjidhni vendodhjen e template"}
              placeholder="Zgjidhni emrat dinamik..."
              onSelect={handleSelectOption}
              options={optionsCategorySelected}
            />
          </Form.Item>
        </div>

        <Form.Item
          name="templateBodyValues"
          className="textAreaFormItem"
          rules={[
            {
              required: true,
              message: "Kjo fushë nuk mund të jetë bosh!",
            },
          ]}
        >
          <RichTextEditor className={"lajmerime-quill"} />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default EmailTemplateModal;
