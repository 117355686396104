import CardComonentFitimet from "../../../../Konfigurimet/PagatSettings/FitmetSetings/CardComonentFitimet";

const defaultData = {

  x: 0,
  y: 0,
  h: 1,
  w: 1,
  minW: 1,
  minH: 1,
  static: false

}

const proccessMap = (dynamicMap) => {
  let a = 0;
  let b = 0;

  return dynamicMap.map((el, index) => {
    if (a === 5) {
      a = 0;
      b++;
    }
    const result = {
      i: `${index}`,
      data: {
        name: el.earningName,
        emri: el.earningName,
        src: CardComonentFitimet,
        parent: false,
        // data: cardData[0],
        data: el,
        hidden: false,
      }, ...defaultData,
      x: a,
      y: b
    }
    a++
    return result
  });
};



export const getSettingsFitimetSaved = (dynamicMap) => {
  let test = proccessMap(dynamicMap);
  const ls = JSON.parse(localStorage.getItem("draggableSetingsFitimet"));
  if (ls !== null) {
    let temporary = [];
    ls.forEach((el, idx) => {
      let found = test.findIndex((l, x) => l.i === el.i);
      if (found !== -1) {
        temporary.push({
          ...test[found],
          data: {
            ...test[found].data,
            hidden: el.data.hidden
          },
          x: ls[found].x,
          y: ls[found].y,
          h: ls[found].h,
          w: ls[found].w,
          minW: ls[found].minW,
          minH: ls[found].minH,
        });
      }
    });
    return temporary;
  } else if (ls === null) {
    localStorage.setItem("draggableSetingsFitimet", JSON.stringify(proccessMap(dynamicMap)));
    return proccessMap(dynamicMap);
  }
};
