import { Button, Modal } from "antd";
import moment from "moment-timezone";
import React from "react";
import { PushimeIcon } from "../../../../assets/icons";
import { DayOffIcon, HolidayIcon } from "../../assets";

function EmployeLeaveRequesDetail({ setVisibleModal, visible, userRequests }) {
  console.log(userRequests);

  const iconStyle = {
    marginTop: 2,
    marginLeft: 3,
    width: 13,
    height: 14,
  };

  const getIcon = (reqType, style) => {
    switch (reqType) {
      // case "Leje lindje":
      //   return <MaternityLeaveIcon style={style} />;
      case "Pushime":
        return <HolidayIcon width={18} height={18} style={style} />;
      case "Dite Personale":
        return <DayOffIcon style={style} />;
      default:
        return;
    }
  };

  const getColor = (reqStatus) => {
    switch (reqStatus) {
      case "Aprovuar":
        return "#1DA193";
      case "Refuzuar":
        return "#EA3943";
      case "Pending":
        return "#FCC94A";
    }
  };

  return (
    <div>
      <Modal
        className="add-new-request"
        title="Detajet për lejen"
        open={visible}
        // width={500}
        onOk={() => setVisibleModal(false)}
        onCancel={() => setVisibleModal(false)}
        footer={[
          <Button
            className="decline"
            style={{ margin: 0 }}
            onClick={() => setVisibleModal(false)}>
            Mbyll
          </Button>,
        ]}>
        <div
          style={{
            backgroundColor: getColor(userRequests.requestStatus),
            width: 150,
            padding: 10,
            borderRadius: 5,
            color: "#fff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "16px",
          }}>
          Statusi : {userRequests.requestStatus}
        </div>
        <div
          className="CONTENT"
          style={{
            display: "flex",
            alignItems: "center",
            padding: 15,
            // justifyContent: "space-between",
          }}>
          <div
            className="icon-container"
            style={{
              backgroundColor: "#1D3445",
              borderRadius: 2,
              height: 20,
              width: 20,
            }}>
            {getIcon(userRequests?.requestType, iconStyle)}
          </div>
          <div
            className="request-title"
            style={{
              color: "white",
              display: "flex",
              justifyContent: "center",
            }}>
            Kërkesa për&nbsp;
            <span style={{ textTransform: "lowercase" }}>
              {userRequests?.requestType}
            </span>
            &nbsp; është krijuar më{" "}
            {moment(userRequests?.createdAt).format("DD/MM/YYYY")}
          </div>
          <div
            className="start-date"
            style={{ marginLeft: 90, marginRight: 30 }}>
            Fillimi
            <p>{moment(userRequests?.requestPeriod[0]).format("DD/MM/YYYY")}</p>
          </div>
          <div className="end-date">
            Mbarimi
            <p>
              {moment(userRequests?.requestPeriod.slice(-1)[0]).format(
                "DD/MM/YYYY"
              )}
            </p>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default EmployeLeaveRequesDetail;
