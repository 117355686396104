import types from "../types/types";

const employees = (employees, type) => {
	return (dispatch) => {
		dispatch({
			type: types[type],
			payload: employees,
		});
	};
};

export const addEmployees = (employeeObj) => {
	return (dispatch) => {
		dispatch({
			type: types.ADD_EMPLOYEE,
			payload: employeeObj,
		});
	};
};

export const editEmployee = (employeeObj) => {
	return (dispatch) => {
		dispatch({
			type: types.EDIT_EMPLOYEE,
			payload: employeeObj,
		});
	};
};
export default employees;
