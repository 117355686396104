import "./GeneralViewModal.scss";
import { useEffect, useMemo, useRef, useState } from "react";
import { Badge, Button, Modal, Alert, Col, Row, Tooltip } from "antd";
import moment from "moment-timezone";
import { getOnlineEmployees } from "../../../utils/getOnlineEmployees";

/**
 *
 * @param {Array} tableDefs
 *  [
 *      { header: '', key: '', badge: Boolean }
 *  ]
 */
const GeneralViewModal = ({
  title,
  tableDefs,
  visible,
  dataList,
  setVisible,
  subDataList,
  pagination,
}) => {
  const dateFormat = "DD/MM/YYYY";
  const tableContentRef = useRef();
  const [paginationRange, setPaginationRange] = useState([]);
  const [paginationPage, setPaginationPage] = useState(1);

  //region ONLINE EMPL
  const newSubDataList = useMemo(() => {
    if (!subDataList && !dataList) return [];
    else if (
      dataList?.findIndex((el) => el?.hasOwnProperty("lastLoggedIn")) !== -1
    ) {
      return getOnlineEmployees(dataList, subDataList);
    }
    return subDataList;
  }, [subDataList, dataList]);

  //region PAGINATION SLICER
  const dataToMap = useMemo(() => {
    return newSubDataList?.slice(
      paginationRange?.[0] || 0,
      paginationRange?.[1] || subDataList?.length + 1
    );
  }, [newSubDataList, paginationRange]);

  //region >paginate()
  const paginateData = (action) => {
    if (action === "next") {
      setPaginationRange(([prev, next]) =>
        next === subDataList?.length
          ? [prev, subDataList?.length]
          : next + pagination > subDataList?.length
          ? [prev + pagination, subDataList?.length]
          : [prev + pagination, next + pagination]
      );
      setPaginationPage((prev) =>
        prev + 1 <= Math.ceil(subDataList?.length / pagination)
          ? prev + 1
          : prev
      );
    }
    if (action === "prev") {
      setPaginationRange(([prev, next]) =>
        next === subDataList?.length
          ? [prev - pagination, prev]
          : prev < pagination
          ? [0, pagination]
          : [prev - pagination, next - pagination]
      );
      setPaginationPage((prev) => (prev - 1 > 0 ? prev - 1 : prev));
    }
    tableContentRef.current.scrollIntoView({ top: 0, behavior: "smooth" });
  };

  const resetValues = () => {
    setVisible(false);
    setPaginationRange([]);
    setPaginationPage(1);
  };

  useEffect(() => {
    if (!!pagination) {
      if (!!subDataList?.length && !paginationRange?.length) {
        setPaginationRange([0, pagination]);
      }
    }
  }, [subDataList, visible]);

  //region RETURN
  return (
    <Modal
      title={title}
      className="generalViewModal"
      centered
      open={visible}
      onOk={resetValues}
      onCancel={resetValues}
      width={(tableDefs?.length * 200 || 1250) + 200}
      footer={[
        <Button onClick={resetValues} className="closeModal">
          Mbyll
        </Button>,
      ]}
    >
      {newSubDataList?.length > 0 ? (
        <div className="container">
          <div className="tableContainer">
            <div className="tableHeaders">
              {tableDefs?.map(({ header }) => (
                <span>{header}</span>
              ))}
            </div>
            <div className={"tableContent"}>
              {dataToMap?.map((el, index) => (
                <Row
                  ref={paginationRange?.[0] === index ? tableContentRef : null}
                >
                  {tableDefs?.map(({ key, badge, color }) => (
                    <span className="item" style={{ color }}>
                      {!!badge ? (
                        el?.isActive ? (
                          <Badge status="success" />
                        ) : (
                          <Badge status="error" />
                        )
                      ) : null}
                      {key === "fullName"
                        ? `${el?.employeeFirstName} ${el?.employeeLastName}`
                        : !!el?.[key]
                        ? Array.isArray(el?.[key])
                          ? el?.[key]?.join(", ")
                          : moment(el?.[key]).isValid()
                          ? moment(el?.[key]).format(dateFormat)
                          : el?.[key]
                        : ""}
                    </span>
                  ))}
                </Row>
              ))}
            </div>
          </div>
          {!!pagination && subDataList?.length > pagination ? (
            <div className="paginationFooter">
              <div>
                <strong>{paginationRange[0] + 1}</strong> -{" "}
                <strong>{paginationRange[1]}</strong> nga{" "}
                <strong>{subDataList?.length}</strong>
              </div>
              <div>
                <span
                  className={`pagingButton ${
                    paginationRange[0] === 0 ? "disabled" : ""
                  }`}
                  onClick={() => paginateData("prev")}
                >
                  {"<"}
                </span>
                <span>
                  Faqja <strong>{paginationPage}</strong> nga{" "}
                  <strong>{Math.ceil(subDataList?.length / pagination)}</strong>
                </span>
                <span
                  className={`pagingButton ${
                    paginationRange[1] === subDataList?.length ? "disabled" : ""
                  }`}
                  onClick={() => paginateData("next")}
                >
                  {">"}
                </span>
              </div>
            </div>
          ) : null}
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Alert message={"Nuk ka të dhëna."} type="info" showIcon />
        </div>
      )}
    </Modal>
  );
};

export default GeneralViewModal;
