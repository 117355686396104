import React, { useState } from "react";
import { API } from "aws-amplify";
import { Drawer, Button, Select, Form, Input, Checkbox, message } from "antd";
import { CaretDownOutlined } from "@ant-design/icons";
import { useZbritjetContext } from "./ZbritjetContext";

function ZbritjetModal({ setVisible, visible, socketHandler }) {
	const { Option } = Select;
	// get the data with context
	const { tableData, setTableData } = useZbritjetContext();

	// objects in konfigration kards
	const [saveData, setSavedData] = useState({
		// ...tableData,
		deductionName: "",
		deductionRule: "",
		deductionAmount: "",
	});

	// clear form inputs after submit
	const [form] = Form.useForm();

	// set the state value of the form
	const handleChange = (objField, e) => {
		let test = objField;
		setSavedData((prev) => ({ ...prev, [test]: e }));
	};

	// save the form
	const handleSave = async () => {
		await API.post("deductions", "/deductions", { body: { ...saveData } }).then((r) => {
			/**
			 * * @EneaXharau - Added socket emitter
			 * * .send() takes -> { request: String, body: any} -> stringified
			 */
			// if (socketHandler.readyState === socketHandler.OPEN) socketHandler.send(JSON.stringify({ request: "loss_added", body: "A loss was added" }));
			setTableData((prev) => [...prev, r]);
			message.success("Të dhënat  u regjistruan me sukses !", 10);
			window.localStorage.removeItem("draggableSetingsZbritjet");
		});
		form.resetFields();
		setVisible(false);
	};

	return (
		<div className="krijo-modal">
			<Drawer
				title="Po krijon një kategori të re zbritje"
				className="krijo-modal"
				open={visible}
				placement="right"
				onClose={() => setVisible(false)}
				width={500}
				footer={null}
			>
				<Form name="modal-zbritjet" onFinish={handleSave} form={form}>
					<div className="krijo-modal-content">
						<div className="input-krijo" style={{ width: "100%" }}>
							<h3>Titulli:</h3>
							<Form.Item
								name="deductionName"
								rules={[
									{
										required: true,
										message: "Ju lutem shkruaj Titullin !",
									},
								]}
							>
								<Input
									onChange={(e) => handleChange("deductionName", e.target.value)}
									autoComplete="off"
									placeholder="Shkruaj Titullin"
									style={{ width: 222 }}
								/>
							</Form.Item>
						</div>
						{/* <div className="dropdown-krijo" style={{ width: "100%" }}>
              <h3 style={{ width: "100%" }}>Lloji i zbritjes:</h3>
              <Form.Item
                name="deductionType"
                rules={[
                  {
                    required: true,
                    message: "Selekte nje opsion !",
                  },
                ]}
              >
                <Select
                  placeholder="Selekto"
                  onChange={(val) => handleChange("deductionType", val)}
                  style={{ width: 222 }}
                  bordered={false}
                  dropdownClassName="krijo-dropdown"
                  suffixIcon={<CaretDownOutlined />}
                >
                  <Option value="Software Developer">Software Developer</Option>
                  <Option value="React Developer">React Developer</Option>
                  <Option value="Node Developer">Node Developer</Option>
                </Select>
              </Form.Item>
            </div>
            <div className="dropdown-krijo" style={{ width: "100%" }}>
              <h3 style={{ width: "100%" }}>Rregulli i llogaritjes:</h3>
              <Form.Item
                name="deductionFormula"
                rules={[
                  {
                    required: true,
                    message: "Selekte nje opsion !",
                  },
                ]}
              >
                <Select
                  placeholder="Selekto"
                  onChange={(val) => handleChange("deductionFormula", val)}
                  style={{ width: 222 }}
                  bordered={false}
                  dropdownClassName="krijo-dropdown"
                  suffixIcon={<CaretDownOutlined />}
                >
                  <Option value="Software Developer">Software Developer</Option>
                  <Option value="React Developer">React Developer</Option>
                  <Option value="Node Developer">Node Developer</Option>
                </Select>
              </Form.Item>
            </div>
            <div className="input-krijo" style={{ width: "100%" }}>
              <h3>Raporti:</h3>
              <Form.Item
                name="deductionRate"
                rules={[
                  {
                    required: true,
                    message: "Ju lutem shkruaj Raportin !",
                  },
                ]}
              >
                <Input
                  type="number"
                  onChange={(e) =>
                    handleChange("deductionRate", e.target.value)
                  }
                  autoComplete="off"
                  placeholder="0.00"
                  style={{ width: 222 }}
                />
              </Form.Item>
            </div> */}
						<div className="input-krijo" style={{ width: "100%" }}>
							<h3>Rregulli:</h3>
							<Form.Item
								name="deductionRule"
								rules={[
									{
										required: true,
										message: "Ju lutem shkruaj faktorin e raportit !",
									},
								]}
							>
								<Input
									type="number"
									onChange={(e) => handleChange("deductionRule", e.target.value)}
									autoComplete="off"
									placeholder="0.00"
									style={{ width: 222 }}
								/>
							</Form.Item>
						</div>
						<div className="input-krijo" style={{ width: "100%" }}>
							<h3>Vlera:</h3>
							<Form.Item
								name="deductionAmount"
								rules={[
									{
										required: true,
										message: "Ju lutem shkruaj Vlerën !",
									},
								]}
							>
								<Input
									type="number"
									onChange={(e) => handleChange("deductionAmount", e.target.value)}
									autoComplete="off"
									placeholder="0.00"
									style={{ width: 222 }}
								/>
							</Form.Item>
						</div>
						<Form.Item
							name="excludeFromTotalHours"
							valuePropName="checked"
						// rules={[
						//     {
						//         validator: async (_, checked) => {
						//             if (!checked) {
						//                 return Promise.reject(
						//                     new Error("Ju lutem Zgjidh Opsionin !"),
						//                 );
						//             }
						//         }
						//     },
						// ]}
						>
							<Checkbox
								onChange={(e) => handleChange("excludeFromTotalHours", e.target.checked)}
								className="krijo-checkbox"
								style={{ width: "100%" }}
							>
								<h3 style={{ width: 222 }}>Përjashtoje në shfaqjen e orëve totale</h3>
							</Checkbox>
						</Form.Item>
						<div className="modal-footer">
							<Form.Item>
								<Button className="anullo-modal-btn" onClick={() => setVisible(false)}>
									Anulo
								</Button>
								<Button htmlType="submit" className="ruaj-modal-btn">
									Ruaj ndryshimet
								</Button>
							</Form.Item>
						</div>
					</div>
				</Form>
			</Drawer>
		</div>
	);
}
export default ZbritjetModal;
