import React, { useState } from "react";
import { Drawer, Button } from "antd";
import Notifications from "../../dashboard/components/notifications/Notifications";
import { ModalXIcon } from "../../../assets/icons";
function NotificationsModal({ notifsActive, setNotifsActive }) {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setNotifsActive(false);
  };

  const handleCancel = () => {
    setNotifsActive(false);
  };
  return (
    <>
      <Drawer
        title="Njoftimet"
        className="notification-drawer"
        width={900}
        height={100}
        // closable={false}
        onClose={handleCancel}
        open={notifsActive}
        closeIcon={<ModalXIcon />}
      >
        {/* <Drawer
          title="Two-level Drawer"
          width={320}
          closable={false}
          // onClose={this.onChildrenDrawerClose}
          visible={true}
        >
          This is two-level drawer
        </Drawer> */}
        <Notifications handleCancel={handleCancel} />
      </Drawer>
    </>
  );
}

export default NotificationsModal;
