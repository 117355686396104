import React, { useEffect, useState } from "react";
import { Modal, Button, message, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";
import "./LeaveModal.scss";
import { API } from "aws-amplify";
import { htmlParser } from "../../../../../utils";
import {
  notificationAdminToSecifikUser,
  notificationToAllAdminAndSecifikUser,
} from "../../../../../utils/sendNotification";
import { useAuth } from "../../../../../authentication/authHelpers";
import { requestDataWithParams } from "../../../../../helpers/API/RequestFactory";
import { employeeRequests } from "../../../../../store/actions";
import { DeleteIcon, ModalXIcon } from "../../../../../assets/icons";
import GlobalPopupKonfirm from "../../../../commonComponents/GlobalPopupKonfirm";
import { getAllUsers } from "../../../../punonjesit/EmployeeView/Payment/EmployeeShifts/utils/apiHelpers";
import { driveApi } from "../../../../DriveRequest";
// * @EneaXharau - Websocket handler and listener
// const socketHandler = new WebSocket(getSocketUrl("/dashboard/sockets"));
// ----------------------

const LeaveModal = ({
  isModalVisible,
  setIsModalVisible,
  content,
  getEmployeRequest,
  getIcon,
}) => {
  const { usersRedux } = useSelector((state) => state.usersList);
  // * @EneaXharau - Set the users in state to make sure component has users when it renders
  const [users, setUsers] = useState([]);
  const { employees } = useSelector((state) => state.employeesList);
  const [userData, setUserData] = useState([]);
  const [currentFile, setCurrentFile] = useState();
  const userDataAuth = useAuth();
  const { accessToken } = useSelector((state) => state.accessToken);
  const driveRequest = driveApi({ accessToken });
  const [spinning, setSpinning] = useState(true);
  //Merr te dhenat e userit qe ka krijuar lejen e zgjdhur
  const getUserData = () => {
    setUserData(employees?.find((r) => r?.userSub === content?.userSub));
  };

  const dispatch = useDispatch();
  // style for the holiday icon
  const iconStyle = {
    marginTop: 2,
    marginLeft: 3,
    width: 13,
    height: 14,
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const refreshRequests = async () => {
    return await requestDataWithParams(
      "employeeRequests",
      moment().subtract(2, "years").valueOf()
    ).then((res) => dispatch(employeeRequests(res || [])));
  };
  //Fshin kerkerat per leje
  const deleteRequest = async () => {
    await API.del(
      "employeeRequests",
      `/employeeRequests/${content.requestId}`
    ).then(() => {
      /**
       * * @EneaXharau - Added socket emitter
       * * .send() takes -> { request: String, body: any} -> stringified
       */
      // if (socketHandler.readyState === socketHandler.OPEN) {
      // 	socketHandler.send(
      // 		JSON.stringify({
      // 			request: "user_leave_request",
      // 			body:
      // 				(
      // 					userDataAuth.userAccess[0].given_name +
      // 					" " +
      // 					userDataAuth.userAccess[0].family_name
      // 				).toString() + " iu fshi kerkesa.",
      // 		})
      // 	);
      // }
      setTimeout(() => {
        getEmployeRequest();
        refreshRequests();
        message.success("Kërkesa u fshi me sukses !", 10);
      }, 1500);
      setIsModalVisible(false);
      // * @EneaXharau - Added if condition to send only when there are users to send to
      if (!!users && users.allUsers.Items.length > 0) {
        notificationToAllAdminAndSecifikUser(
          {
            notificationTitle: "Fshirje leje",
            createdAt: moment().format("DD/MM/YYYY"),
            notificationPriority: false,
            notificationCategory: "Critical",
            notificationBody: `${userDataAuth?.userAccess[0]?.given_name}  ${userDataAuth?.userAccess[0]?.family_name} fshiu lejen për ${content.given_name} ${content.family_name}`,
            isAnnouncement: false,
            notificationUrl: "/kryefaqja",
            notificationFirstName: userDataAuth?.userAccess[0]?.given_name,
            notificationLastName: userDataAuth?.userAccess[0]?.family_name,
          },
          users?.allUsers?.Items?.filter(
            (el) =>
              el?.userSub === content?.userSub ||
              el?.userRole === "Admin" ||
              el?.userRole === "Super Admin"
          ),
          users?.allUsers?.Items?.find((el) => el?.userSub === content.userSub)
        );
      }
    });
  };
  //Shton kerkesat per leje
  const addItems = async () => {
    if (!!content?.requestId) {
      await API.put(
        "employeeRequests",
        `/employeeRequests/${content.requestId}`,
        {
          body: { requestStatus: "Refuzuar" },
        }
      ).then(() => {
        /**
         * * @EneaXharau - Added socket emitter
         * * .send() takes -> { request: String, body: any} -> stringified
         */
        // if (socketHandler.readyState === socketHandler.OPEN) {
        // 	socketHandler.send(
        // 		JSON.stringify({
        // 			request: "user_leave_request",
        // 			body:
        // 				(
        // 					userDataAuth.userAccess[0].given_name +
        // 					" " +
        // 					userDataAuth.userAccess[0].family_name
        // 				).toString() + " iu refuzua kerkesa.",
        // 		})
        // 	);
        // }

        setTimeout(() => {
          getEmployeRequest();
          refreshRequests();
          message.success("Kërkesa për leje është refuzuar !", 10);
        }, 1500);
        setIsModalVisible(false);
        // * @EneaXharau - Added if condition to send only when there are users to send to
        if (!!users && users.allUsers.Items.length > 0) {
          notificationToAllAdminAndSecifikUser(
            {
              notificationTitle: "Refuzim leje",
              createdAt: moment().format("DD/MM/YYYY"),
              notificationPriority: false,
              notificationCategory: "Critical",
              notificationBody: `${userDataAuth?.userAccess[0]?.given_name}  ${userDataAuth?.userAccess[0]?.family_name} refuzoi lejen për ${content.given_name} ${content.family_name}`,
              isAnnouncement: false,
              notificationUrl: "/kryefaqja",
              notificationFirstName: userDataAuth?.userAccess[0]?.given_name,
              notificationLastName: userDataAuth?.userAccess[0]?.family_name,
            },
            users?.allUsers?.Items?.filter(
              (el) =>
                el?.userSub === content?.userSub ||
                el?.userRole === "Admin" ||
                el?.userRole === "Super Admin"
            ),
            users?.allUsers?.Items?.find(
              (el) => el?.userSub === content.userSub
            )
          );
        }
      });
    } else {
      message.error("Ndodhi nje gabim");
    }
  };
  //Kthen lejen ne gjendjen e me parshme, pra Pending
  const revertChanges = async () => {
    if (!!content?.requestId) {
      await API.put(
        "employeeRequests",
        `/employeeRequests/${content.requestId}`,
        {
          body: { requestStatus: "Pending" },
        }
      ).then(() => {
        /**
         * * @EneaXharau - Added socket emitter
         * * .send() takes -> { request: String, body: any} -> stringified
         */
        // if (socketHandler.readyState === socketHandler.OPEN) {
        // 	socketHandler.send(
        // 		JSON.stringify({
        // 			request: "user_leave_request",
        // 			body:
        // 				(
        // 					userDataAuth.userAccess[0].given_name +
        // 					" " +
        // 					userDataAuth.userAccess[0].family_name
        // 				).toString() + " iu pezullua leja.",
        // 		})
        // 	);
        // }

        setTimeout(() => {
          getEmployeRequest();
          refreshRequests();
          message.success(
            "Kërkesa për leje u kthye ne gjendjen fillestare !",
            10
          );
        }, 1500);
        setIsModalVisible(false);
        // * @EneaXharau - Added if condition to send only when there are users to send to
        if (!!users && users.allUsers.Items.length > 0) {
          notificationToAllAdminAndSecifikUser(
            {
              notificationTitle: "Kthim ne gjendjen fillestare",
              createdAt: moment().format("DD/MM/YYYY"),
              notificationPriority: false,
              notificationBody: `${userDataAuth?.userAccess[0]?.given_name}  ${userDataAuth?.userAccess[0]?.family_name} ktheu ne gjendjen fillestare lejen për ${content.given_name} ${content.family_name}`,
              isAnnouncement: false,
              notificationUrl: "/kryefaqja",
              notificationFirstName: userDataAuth?.userAccess[0]?.given_name,
              notificationLastName: userDataAuth?.userAccess[0]?.family_name,
            },
            users?.allUsers?.Items?.filter(
              (el) =>
                el?.userSub === content?.userSub ||
                el?.userRole === "Admin" ||
                el?.userRole === "Super Admin"
            ),
            users?.allUsers?.Items?.find(
              (el) => el?.userSub === content?.userSub
            )
          );
        }
      });
    } else {
      message.error("Ndodhi nje gabim");
    }
  };
  //Funksioni aprovon lejen
  const addItemsPrano = async () => {
    await API.put(
      "employeeRequests",
      `/employeeRequests/${content.requestId}`,
      {
        body: { requestStatus: "Aprovuar" },
      }
    ).then(() => {
      /**
       * * @EneaXharau - Added socket emitter
       * * .send() takes -> { request: String, body: any} -> stringified
       */
      // if (socketHandler.readyState === socketHandler.OPEN) {
      // 	socketHandler.send(
      // 		JSON.stringify({
      // 			request: "user_leave_request",
      // 			body:
      // 				(
      // 					userDataAuth.userAccess[0].given_name +
      // 					" " +
      // 					userDataAuth.userAccess[0].family_name
      // 				).toString() + " iu shtua kerkesa.",
      // 		})
      // 	);
      // }
      setTimeout(() => {
        getEmployeRequest();
        refreshRequests();
        message.success("Kërkesa për leje është aprovuar !", 10);
      }, 1500);
      setIsModalVisible(false);
      // * @EneaXharau - Added if condition to send only when there are users to send to
      if (!!users && users.allUsers.Items.length > 0) {
        notificationToAllAdminAndSecifikUser(
          {
            notificationTitle: "Aprovim Leje",
            createdAt: moment().format("DD/MM/YYYY"),
            notificationPriority: false,
            notificationBody: `${userDataAuth?.userAccess[0]?.given_name}  ${userDataAuth?.userAccess[0]?.family_name} aprovoi lejen për ${content.given_name} ${content.family_name}`,
            isAnnouncement: false,
            notificationUrl: "/kryefaqja",
            notificationCategory: "Critical",
            notificationFirstName: userDataAuth?.userAccess[0]?.given_name,
            notificationLastName: userDataAuth?.userAccess[0]?.family_name,
          },
          users?.allUsers?.Items?.filter(
            (el) =>
              el?.userSub === content?.userSub ||
              el?.userRole === "Admin" ||
              el?.userRole === "Super Admin"
          ),
          users?.allUsers?.Items?.find((el) => el?.userSub === content.userSub)
        );
      }
    });
  };

  useEffect(() => {
    if (!!employees) {
      getUserData();
    }
  }, [employees]);

  useEffect(() => {
    getAllUsers().then((res) => {
      setUsers(res);
    });
  }, [usersRedux]);

  useEffect(() => {
    if (!!userData?.googleDriveFileId) {
      driveRequest.getImageSrc(userData?.googleDriveFileId).then((image) => {
        setCurrentFile(image?.src);
        setSpinning(false);
      });
    } else {
      setTimeout(() => {
        setSpinning(false);
      }, 1000);
    }
  }, [userData?.googleDriveFileId]);

  return (
    <>
      <Modal
        className="add-leave-request-modal"
        title={`Kerkesa per leje nga ${content?.given_name} ${content?.family_name}`}
        open={isModalVisible}
        width={"auto"}
        centered
        onOk={handleOk}
        destroyOnClose={true}
        onCancel={handleCancel}
        closeIcon={<ModalXIcon />}
        footer={[
          <div style={{ display: "flex", width: "100%", gap: "20px" }}>
            <Button
              onClick={addItems}
              className="refuse"
              disabled={content?.requestStatus === "Refuzuar" ? true : false}
            >
              {content?.requestStatus === "Refuzuar" ? "E refuzuar" : "Refuzo"}
            </Button>
            <Button
              onClick={revertChanges}
              className="pending"
              disabled={content?.requestStatus === "Pending" ? true : false}
            >
              {content?.requestStatus === "Pending"
                ? "Ne pritje"
                : "Kthe ne pritje"}
            </Button>
            <Button
              onClick={addItemsPrano}
              className="accept"
              disabled={content?.requestStatus === "Aprovuar" ? true : false}
            >
              {content?.requestStatus === "Aprovuar" ? "E pranuar" : "Prano"}
            </Button>
            <GlobalPopupKonfirm
              Button={
                <Button className="delete-request">
                  <p className="del-btn-text">Fshi</p>
                  <DeleteIcon
                    className="del-icon"
                    style={{ marginTop: 0, width: 14, height: 18 }}
                  />
                </Button>
              }
              title={"Jeni i sigurt?"}
              onConfirm={() => deleteRequest()}
              okText={"Po"}
              cancelText={"Jo"}
            />
          </div>,
        ]}
        wrapClassName="request"
      >
        <div className="req-container">
          <div>
            <Spin spinning={spinning}>
              {!!currentFile && (
                <img
                  className="user-img"
                  src={currentFile}
                  alt="Flex"
                  style={{ borderRadius: 5 }}
                />
              )}
              {!spinning && !currentFile && <div>No photo uploaded</div>}
            </Spin>
            <div>
              <p style={{ font: "normal normal 600 16px/22px Open Sans" }}>
                {content?.given_name + " " + content?.family_name}
              </p>
              <p>{userData?.employeePosition}</p>
              <p>
                {!!userData?.employeePhoneNumber
                  ? "+" + userData?.employeePhoneNumber
                  : ""}
              </p>
            </div>
          </div>
          <div>
            <div className="start-date">
              Fillimi
              <p>{moment(content?.requestPeriod[0]).format("DD/MM/YYYY")}</p>
            </div>
            <div className="end-date">
              Mbarimi
              <p>
                {moment(content?.requestPeriod.slice(-1)[0]).format(
                  "DD/MM/YYYY"
                )}
              </p>
            </div>
          </div>
        </div>
        <div className="title-container">
          <div
            className="icon-container"
            style={{
              backgroundColor: "#1D3445",
              borderRadius: 2,
              height: 20,
              width: 20,
              position: "relative",
            }}
          >
            {getIcon(content?.requestType, iconStyle)}
          </div>
          <div
            className="request-title"
            style={{
              color: "white",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              position: "relative",
            }}
          >
            Kërkesa për{" "}
            <span style={{ textTransform: "lowercase", margin: "0 5px 0 5px" }}>
              {content?.requestType}
            </span>{" "}
            është krijuar më {moment(content?.createdAt).format("DD/MM/YYYY")}
          </div>
          <div
            className="icon-container"
            style={{
              backgroundColor: "#1D3445",
              borderRadius: 2,
              height: 20,
              width: 20,
              position: "relative",
            }}
          >
            {getIcon(content?.requestType, iconStyle)}
          </div>
        </div>
        <div
          className="request-description"
          // style={{ height: "auto", width: "auto", margin: 25 }}
        >
          {htmlParser(content?.requestDescription)}
        </div>
      </Modal>
    </>
  );
};
export default LeaveModal;
