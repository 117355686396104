import { Divider, Form, List, Modal } from "antd";
import { ModalXIcon } from "../../../../../assets/icons";
import DrivePermissionForm from "../../../../DrivePermissionList/DrivePermissionForm/DrivePermissionForm";
import { useState, useMemo } from "react";
import { Delete } from "../../../../Documentation/View/assets";
import { useSelector } from "react-redux";
import "./PermissionsModal.scss";
import { Tick } from "../../../assets";
import MondayButton from "../../../../commonComponents/MondayButton/MondayButton";

const PermissionsModal = ({
  onSave,
  docName,
  visible,
  setVisible,
  setEditedCategory,
  currentPermissions = [],
}) => {
  const [form] = Form.useForm();
  const { employees: employeesList } = useSelector(
    (state) => state.employeesList
  );
  const [permissionsList, setPermissionsList] = useState(currentPermissions);

  const filteredEmployees = useMemo(() => {
    const permissionEmails = new Set(
      permissionsList?.map((el) => el?.permissionEmail)
    );
    return employeesList.filter(
      (employee) =>
        !!employee?.employeeEmailWork &&
        !permissionEmails.has(employee?.employeeEmailWork)
    );
  }, [employeesList]);

  const [employees, setEmployees] = useState(filteredEmployees || []);

  const handleRow = () => {
    form.validateFields().then((res) => {
      setPermissionsList((prev) => [...prev, res]);
      setEmployees((prev) =>
        prev?.filter((el) => el?.employeeEmailWork !== res?.permissionEmail)
      );
      form.resetFields();
    });
  };
  const handleRemove = (email) => {
    const removedEmployee = employeesList?.find(
      (el) => el?.employeeEmailWork === email
    );
    setEmployees((prev) => [removedEmployee, ...prev]);
    setPermissionsList((prev) =>
      prev?.filter((el) => el?.permissionEmail !== email)
    );
  };
  const onCancel = () => {
    form.resetFields();
    setVisible(false);
  };
  const handleSubmit = () => {
    if (setEditedCategory && typeof setEditedCategory === "function") {
      setEditedCategory(
        (prev) => ({
          ...prev,
          documentationsAvailable: prev.documentationsAvailable?.map((el) =>
            el.docName === docName
              ? {
                  ...el,
                  docPermissions: permissionsList,
                }
              : el
          ),
        }),
        () => {
          onCancel();
          onSave();
        }
      );
    } else onSave(permissionsList);
  };

  return (
    <Modal
      {...{
        visible,
        className: "permissionModal",
        destroyOnClose: true,
        centered: true,
        maskClosable: true,
        title: "Ndrysho aksesin në Google Drive",
        onCancel,
        footer: null,
      }}
      closeIcon={<ModalXIcon />}
    >
      <DrivePermissionForm
        {...{
          form,
          handleRow,
          employees,
          permissions: permissionsList,
        }}
      />
      {!!permissionsList?.length && (
        <div className="permissionsList">
          <div className="permissionsHeader">
            <span>Email</span>
            <span>Roli</span>
          </div>
          {permissionsList?.map((el) => (
            <div style={{ display: "flex", width: "100%" }}>
              <div className="permission">
                <div className="content">
                  <span className="email">{el?.permissionEmail}</span>
                  <span className="role">{el?.permissionRole}</span>
                </div>
                <Divider type="horizontal" className="divider" />
              </div>
              <span
                className="deletePermissionIcon"
                onClick={() => handleRemove(el?.permissionEmail)}
              >
                <Delete width={24} height={24} fill="#fff" />
              </span>
            </div>
          ))}
        </div>
      )}
      <MondayButton
        onClick={handleSubmit}
        Icon={
          <div>
            <Tick className="submitIcon" />
          </div>
        }
        className="saveButton"
      >
        Ruaj Ndryshimet
      </MondayButton>
    </Modal>
  );
};

export default PermissionsModal;
