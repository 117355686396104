import types from "../types/types";

const earnings = (earnings) => {
	return (dispatch) => {
		dispatch({
			type: types.SET_EARNINGS,
			payload: earnings,
		});
	};
};
export default earnings;
