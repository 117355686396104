import { message } from "antd";
import moment from "moment-timezone";
import { getTranslation } from "../../generate/translations";
export const bankForwardPay = (data, filter, columns, manualColumns) => {
	let result = {
		columnsDef: [],
		allColumns: [],
		rowData: [],
		selectedColumns: [],
		allColumns: [],
		totalities: {
			bruto: 0,
			neto: 0,
			individual: 0,
			employeer: 0,
			bank: 0,
			total: 0,
		},
	};
	if (!!data && !!filter) {
		const filtered = preFilter(data, filter).map((paysub, idx) => {
			result.totalities.bruto += paysub?.bruto?.totalBruto || 0;
			result.totalities.neto += paysub?.taxes?.netoPay?.netoPay || 0;
			result.totalities.individual += paysub?.taxes?.netoPay?.totalIns || 0;
			result.totalities.employeer += paysub?.taxes?.netoPay?.totalEmployeeerIns || 0;
			result.totalities.bank += paysub?.taxes?.netoPay?.netoPay || 0;
			result.totalities.total +=
				paysub?.bruto?.totalBruto + paysub?.taxes?.netoPay?.totalEmployeeerIns || 0;
			let temp = idx + 1;
			temp = temp.toString();
			return {
				...paysub,
				...paysub?.employeeInfo,
				idNumber: temp,
				timeframe: moment(paysub.startPeriod).format("MMMM YYYY"),
				totalBruto: currencyformatter.format(paysub?.bruto?.totalBruto || 0).replace("ALL ", ""),

				totalNeto: currencyformatter
					.format(paysub?.taxes?.netoPay?.netoPay || 0)
					.replace("ALL ", ""),
				totalIns: currencyformatter
					.format(paysub?.taxes?.netoPay?.totalIns || 0)
					.replace("ALL ", ""),
				totalEmployeeerIns: currencyformatter
					.format(paysub?.taxes?.netoPay?.totalEmployeeerIns || 0)
					.replace("ALL ", ""),
				bankTotal: currencyformatter
					.format(paysub?.taxes?.netoPay?.netoPay || 0)
					.replace("ALL ", ""),
				total: currencyformatter.format(
					paysub?.bruto?.totalBruto + paysub?.taxes?.netoPay?.totalEmployeeerIns || 0
				),
			};
		});
		result.selectedColumns = manualColumns || columns.columnDefs;
		result.allColumns = columns.allAppliedColumnDefs;
		if (!!manualColumns && Array.isArray(manualColumns)) {
			manualColumns.forEach((e) => {
				result.columnsDef.push(getTranslation(e));
			});
			filtered?.forEach((emplRecord) => {
				let toAdd = [];
				manualColumns.forEach((c) => toAdd.push(emplRecord[c]));
				result.rowData.push(toAdd);
			});
			// const indx = result.columnsDef.findIndex((e) => e === "Totali");
			// if (indx !== -1 && result.rowData.length > 0) {
			// 	const last = new Array(result.columnsDef.length - 1).fill("fd");
			// 	last[indx] = currencyformatter.format(result.totalities.total);
			// 	result.rowData.push(last);
			// }
		} else {
			columns?.columnDefs.forEach((e) => {
				result.columnsDef.push(getTranslation(e));
			});
			filtered?.forEach((emplRecord) => {
				let toAdd = [];
				columns?.columnDefs?.forEach((c) => toAdd.push(emplRecord[c]));
				result.rowData.push(toAdd);
			});
			// const indx = result.columnsDef.findIndex((e) => e === "Totali");
			// if (indx !== -1 && result.rowData.length > 0) {
			// 	const last = new Array(result.columnsDef.length - 1).fill("");
			// 	last[indx] = currencyformatter.format(result.totalities.total);
			// 	result.rowData.push(last);
			// }
			// Adding Totalities!
		}
	}
	return result;
};

const preFilter = (data, filter) => {
	return data
		.filter(
			(el) =>
				moment(el.startPeriod).isSameOrAfter(filter?.timeInterval[0]) &&
				moment(el.endPeriod).isSameOrBefore(filter?.timeInterval[1])
		)
		.sort((a, b) => a.startPeriod - b.startPeriod);
};

const prepareMultis = (savedArr) => {
	const tempTable = {
		columnsDef: [
			"",
			"EMRI",
			"MBIEMRI",
			"MUAJI",
			"DEPARTAMENTI",
			"BRUTO",
			"NETO",
			"INDIVID ",
			"PUNEDHENES",
			"BANKA",
			"TOTAL",
		],
		rowData: [],
		totalities: {
			bruto: 0,
			neto: 0,
			individual: 0,
			employeer: 0,
			bank: 0,
			total: 0,
		},
	};
	if (savedArr.length > 0) {
		savedArr.map((paysub, idx) => {
			tempTable.totalities.bruto += paysub?.bruto?.totalBruto || 0;
			tempTable.totalities.neto += paysub?.taxes?.netoPay?.netoPay || 0;
			tempTable.totalities.individual += paysub?.taxes?.netoPay?.totalIns || 0;
			tempTable.totalities.employeer += paysub?.taxes?.netoPay?.totalEmployeeerIns || 0;
			tempTable.totalities.bank += paysub?.taxes?.netoPay?.netoPay || 0;
			let temp = idx + 1;
			tempTable.rowData.push([
				temp.toString(),
				paysub?.employeeInfo?.employeeFirstName,
				paysub?.employeeInfo?.employeeLastName,
				moment(paysub?.startPeriod).format("MMMM YYYY"),

				paysub?.employeeInfo?.employeeDepartmentName,
				currencyformatter.format(paysub?.bruto?.totalBruto || 0).replace("ALL", ""),
				currencyformatter.format(paysub?.taxes?.netoPay?.netoPay || 0).replace("ALL", ""),
				currencyformatter.format(paysub?.taxes?.netoPay?.totalIns || 0).replace("ALL", ""),
				currencyformatter
					.format(paysub?.taxes?.netoPay?.totalEmployeeerIns || 0)
					.replace("ALL", ""),
				currencyformatter.format(paysub?.taxes?.netoPay?.netoPay || 0).replace("ALL", ""),
				currencyformatter.format(
					paysub?.bruto?.totalBruto + paysub?.taxes?.netoPay?.totalEmployeeerIns || 0
				),
			]);
		});
		tempTable.totalities.total += tempTable.totalities.bruto + tempTable.totalities.employeer;

		tempTable.rowData.push([
			"TOTALI",
			"",
			"",
			"",
			"",
			currencyformatter.format(tempTable.totalities.bruto).replace("ALL", ""),
			currencyformatter.format(tempTable.totalities.neto).replace("ALL", ""),
			currencyformatter.format(tempTable.totalities.individual).replace("ALL", ""),
			currencyformatter.format(tempTable.totalities.employeer).replace("ALL", ""),
			currencyformatter.format(tempTable.totalities.bank).replace("ALL", ""),
			currencyformatter.format(tempTable.totalities.total),
		]);
	}
	return tempTable;
};

const currencyformatter = new Intl.NumberFormat("en-US", {
	style: "currency",
	currency: "ALL",
	minimumFractionDigits: 2,
	// These options are needed to round to whole numbers if needed.
	//minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
	//maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});
