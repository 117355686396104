import React from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "./ag-grid-component.scss";

function AgGridComponent({
  onGridReady,
  columnDefs,
  paginationPageSize,
  onCellValueChanged,
  rowData,
  onRowValueChanged,
  onSelectionChanged,
  rowSelection,
  suppressRowClickSelection,
  overlayNoRowsTemplate,
  footer,
  onPaginationChanged,
  overlayLoadingTemplate,
  getRowId,
  onFirstDataRendered,
  gridApi,
  customDefaultColDef = {},
  dataTestId = "ag-grid-component",
}) {
  const defaultColDef = {
    sortable: true,
    flex: 1,
    filter: true,
    resizable: true,
    // editable: { edit },
  };

  return (
    <>
      <div className="ag-grid-component" data-testid={dataTestId}>
        <AgGridReact
          gridApi={gridApi}
          rowData={rowData}
          rowSelection={rowSelection}
          editType={"fullRow"}
          onCellValueChanged={onCellValueChanged}
          onRowValueChanged={onRowValueChanged}
          columnDefs={columnDefs}
          defaultColDef={Object.assign(defaultColDef, customDefaultColDef)}
          onGridReady={onGridReady}
          pagination={true}
          // singleClickEdit={true}
          paginationPageSize={paginationPageSize}
          groupSelectsChildren={true}
          enableRangeSelection={true}
          rowMultiSelectWithClick={true}
          onSelectionChanged={onSelectionChanged}
          enterMovesDown={true}
          enterMovesDownAfterEdit={true}
          suppressRowClickSelection={suppressRowClickSelection}
          overlayNoRowsTemplate={overlayNoRowsTemplate}
          onPaginationChanged={onPaginationChanged}
          overlayLoadingTemplate={overlayLoadingTemplate}
          onFirstDataRendered={onFirstDataRendered}
          getRowNodeId={getRowId}
          animateRows={true}
        />
      </div>
      {footer}
    </>
  );
}

export default AgGridComponent;
