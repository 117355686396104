import moment from "moment-timezone";
export const getMonthsOfYear = () => {
	let months = [];
	let startInstance = moment().startOf("year");
	let len = moment().diff(moment(startInstance), "months", false);
	[...Array(len + 1)].map((x, idx) =>
		months.push({
			nr: idx + 1,
			muaji: moment(startInstance).add(`${idx}`, "months").valueOf(),
			neto: "",
			bruto: "",
			taxes: "",
			totalCost: "",
		})
	);
	// console.log("start of year", startInstance.format("DD/MM/YYYY HH:MM"));
	// console.log("Months", months);
	return months;
};

export const getNextMonthsOfYear = (type, id) => {
	let months = [];
	let indicator = type === "next" ? id : 0;
	let startInstance =
		type === "next"
			? moment().startOf("year").add(5, "days")
			: moment().startOf("year").subtract(id, "months");
	let len = moment().diff(moment(startInstance), "months", false);
	[...Array(len + 1 + indicator)].map((x, idx) =>
		months.push({
			nr: idx + 1,
			muaji: moment(startInstance).add(`${idx}`, "months").valueOf(),
			neto: "",
			bruto: "",
			taxes: "",
			totalCost: "",
		})
	);
	// console.log("start of year", startInstance.format("DD/MM/YYYY HH:MM"));
	// console.log("Months", months);
	return months;
};

// export const netoFromBruto = (brutoAmount) => {
// 	if (brutoAmount > 0) {

// 	}

// }

// export const brutoFromNeto = () => {

// }
