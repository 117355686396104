import { groupBy } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import "./Wages.scss";
function WagesHeader({ onFilter, statuses }) {
  const [statusOccur, setOccurence] = useState({
    "Ne pritje": { value: 0, color: "#FCC94A" },
    "I pallogaritur": { value: 0, color: "#EA3943" },
    Finalizuar: { value: 0, color: "#1DA193" },
    Draft: { value: 0, color: "#fcba03" },
  });
  // const { programFields } = useSelector((state) => state.programFields);
  // const statusTypes = useMemo(() => {
  // 	if (!!programFields) {
  // 		const temp = programFields?.find((el) => el?.fieldName === "wageStatus");
  // 		return !!temp ? temp?.fieldOptions || [] : [];
  // 	} else return [];
  // }, [programFields]);

  useEffect(() => {
    if (!!statuses) {
      let groupedStatuses = groupBy(statuses, "wageStatus");
      Object.keys(statusOccur).map((k) => {
        let tempOccur = statusOccur;
        let tempKey = tempOccur[k];
        tempKey.value = groupedStatuses[k]?.length || 0;
        tempOccur[k] = tempKey;
        setOccurence(tempOccur);
      });
    }
  }, [statuses]);

  return (
    <div className="wages-header">
      <div className="header-wrapper">
        {Object.entries(statusOccur).map(([key, value]) => (
          <FilterCard
            value={value?.value}
            name={key}
            background={value?.color}
            onFilter={onFilter}
          />
        ))}
      </div>
    </div>
  );
}

export default WagesHeader;

const FilterCard = ({ value, name, background, onFilter }) => {
  return (
    <div
      className="wage-header__card"
      style={{ background: background }}
      onClick={() => onFilter(name)}>
      {" "}
      <span className="statusValue">{value}</span>{" "}
      <span className="statusName"> {name} </span>
    </div>
  );
};
