/**
 * The first scale filtering of Notifications, which categorizes the notifications according to their type.
 * @param {String} nType notif type "All", "Hidden", "Relevant", "Mentioned"
 * @param {Array} notifications an Array of Notif Objects coming from Context API
 * @returns {Array} of filtered Notifications for further processing
 */
export const particulateNotifCategory = (nType, notifications) => {
  // console.log(notifications);
  let categ = [];
  try {
    switch (nType) {
      case "All":
        return notifications.filter((n) => n.notificationMarkedHidden === false);
      case "Hidden":
        return (categ = notifications.filter((n) => n.notificationMarkedHidden === true));
      case "Relevant":
        return (categ = notifications.filter(
          (n) => n.notificationType === "Relevant" && n.notificationMarkedHidden === !true
        ));
      case "Mentioned":
        return (categ = notifications.filter(
          (n) => n.notificationType === "Mentioned" && n.notificationMarkedHidden === !true
        ));
      default:
        return notifications;
    }
  } catch (err) {
    console.error("There was an error with filtering Categories for Notifications", err);
    return categ;
  }
};
/**
 * A function used for further processing after the first filter has been applied.
 * @param {Number} activeFilter ID which filters the Notifications in Second Scale
 * @param {Array} particulates an Array of Notif Objects, which are filtered in First Scale
 * @returns {Array} of Object for Notifications after second degree Filtering.
 */
export const activeFiltering = (activeFilter, particulates) => {
  let active = [];
  try {
    if (activeFilter !== null)
      switch (activeFilter) {
        case 101:
          return (active = particulates.filter((n) => n.notificationCategory === "Important"));
        case 102:
          return (active = particulates.filter((n) => n.notificationCategory === "Critical"));
        case 103:
          return (active = particulates.filter((n) => n.notificationCategory === "Info"));
        case 104:
          return (active = particulates.filter((n) => n.notificationMarkedRed === false)); // Unread Notifications
      }
    else {
      return (active = particulates);
    }
  } catch (err) {
    console.log("There was an error processing further filtering of the notifications", err);
    return active;
  }
};
/**
 * A function which returns the number of each notifications in first degree filtering
 * @param {Array} notifications array of Objects for getting the count of each Notif
 * @returns {Object} structured object with each key belonging to the number of notifs
 */
export const getNotifsCount = (notifications) => {
  try {
    const All = notifications.filter((n) => n.notificationMarkedHidden === !true).length;
    const Hidden = notifications.filter((n) => n.notificationMarkedHidden === true).length;
    const Relevant = notifications.filter(
      (n) => n.notificationType === "Relevant" && n.notificationMarkedHidden === !true
    ).length;
    const Mentioned = notifications.filter(
      (n) => n.notificationType === "Mentioned" && n.notificationMarkedHidden === !true
    ).length;
    return { ...{ All, Hidden, Relevant, Mentioned } };
  } catch (err) {
    console.error("There was an error with retrieving notification count", err);
    return { All: 0, Hidden: 0, Relevant: 0, Mentioned: 0 };
  }
};
