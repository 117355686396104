import { compareIncluding } from "../../../Documentation/utils/compareIncluding";

export const inputTypes = {
  Amount: "input",
  Text: "input",
  "Services Dropdown": "select",
  Dropdown: "select",
  "Text Area": "textarea",
  Checkbox: "checkbox",
  "Radio Button": "radio",
  "Date Picker": "datepicker",
  "Time Picker": "timepicker",
  "Location AutoComplete": "placesautocomplete",
};

export const typesWithOptions = ["radio", "select"];
const typesWithRegEx = ["input", "textarea"];

export const inputFields = ({ onTypeSelect, selectedType, form }) => ({
  inputs: [
    {
      label: "Etiketa",
      formItemName: "customFieldsLabel",
      placeholder: "Emëroje këtu...",
      required: true,
    },
    {
      label: "Lloji i të dhënave",
      formItemName: "customFieldsType",
      placeholder: "Zgjidh një këtu...",
      type: "select",
      onSelect: onTypeSelect,
      options: Object.keys(inputTypes),
      required: true,
    },
    typesWithRegEx.includes(selectedType) && {
      label: "RegEx",
      formItemName: "customFieldsRegEx",
      placeholder: "Emëroje këtu...",
    },
  ].filter(Boolean),

  checkboxes: [
    {
      label: "E detyrueshme",
      formItemName: "customFieldRequired",
      primaryStyle: { backgroundColor: "#f00" },
      secondaryStyle: { backgroundColor: "#fff" },
    },
    selectedType === "input" &&
      !compareIncluding(form.getFieldValue("customFieldsType"), "Amount") && {
        label: "Vetëm Numra",
        formItemName: "customFieldType",
        primaryStyle: { backgroundColor: "#f00" },
        secondaryStyle: { backgroundColor: "#fff" },
      },
    selectedType === "select" && {
      label: "Përzgjedhja e Shumëfishtë",
      formItemName: "customFieldMultiple",
    },
  ].filter(Boolean),
});
