import { Divider } from "antd";
import { DocConfigIcon } from "../../assets";
import {
  DocumentationsPanel,
  SquircleBackButton,
  ViewLogoHeader,
} from "../../components";
import { useDocumentationContext } from "../../context";
import {
  VIEW_SUBTITLE,
  VIEW_TITLE,
  views,
} from "../../documentationConfigurationData";
import { formatCategoryName } from "../utils/formatCategoryName";
import "./CategoryView.scss";

const CategoryView = () => {
  const { selectedCategoryName, setSelectedCategoryName, setActiveView } =
    useDocumentationContext();

  const onBack = () => {
    setActiveView(views.CATEGORIES_VIEW);
    setSelectedCategoryName("");
  };

  return (
    <div className="documentationCategoryContainer">
      <div className="header">
        <SquircleBackButton className="backButton" onClick={onBack} />
        <ViewLogoHeader
          icon={DocConfigIcon}
          title={VIEW_TITLE}
          subtitle={VIEW_SUBTITLE}
        />
        <Divider type="vertical" className="headerDivider" />
        <div className="categoryTitle">
          {formatCategoryName(selectedCategoryName)}
        </div>
      </div>
      <DocumentationsPanel />
    </div>
  );
};

export default CategoryView;
