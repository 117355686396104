import types from "../types/types";
const initialState = {
	employees: undefined,
};

const employeesReducer = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case types.SET_EMPLOYEES:
			return {
				...state,
				employees: payload,
			};
		case types.GET_ACTIVE_EMPLOYEES:
			return {
				...state,
				employees: payload,
				activeEmployees: payload.filter((el) => el?.employeeStatus === "Aktiv") || [],
				eligibleEmployeesClockIn:
					payload?.filter(
						(el) => el?.employeePayrollType !== "Page Fikse" && el?.employeeStatus === "Aktiv"
					) || [],
				flexEligibleEmployees:
					payload?.filter(
						(el) => (el) =>
							el?.employeePayrollType !== "Page Fikse" &&
							el?.employeeStatus === "Aktiv" &&
							el?.employeeTeam === "Flex Tirana"
					) || [],
				// activeEmployees: payload.filter((el) => el?.employeeStatus === "Aktiv") || [],
			};
		case types.ADD_EMPLOYEE: {
			return { ...state, employees: addNewEmployeeWS(state.employees, payload) };
		}

		case types.EDIT_EMPLOYEE: {
			return { ...state, employees: editEmployee(state.employees, payload) };
		}
		default:
			return state;
	}
};

const addNewEmployeeWS = (array, employeeObj) => {
	const temp = new Set(array);
	temp.add(employeeObj);
	return Array.from(temp);
};

const editEmployee = (array, employeeObj) => {
	const temp = array.map((item) => {
		if (item.employeeId === employeeObj.employeeId) {
			return employeeObj;
		} else {
			return item;
		}
	});

	return Array.from(new Set(temp));
};

export default employeesReducer;
