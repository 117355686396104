import { API } from "aws-amplify";
import React, { useEffect, useMemo, useState } from "react";
import { Form, message, Skeleton } from "antd";
import { Auth } from "aws-amplify";
import SiguriaPerdoruesitModal from "../../../../Konfigurimet/SiguriaSettings/SiguriaPerdoruesit/SiguriaPerdoruesitModal";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import "./User.scss";
import LoadedUser from "./LoadedUser";
import { NoUser, WarningRed } from "../../../../../assets/icons";
import MergeEmployeeUser from "./MergeEmployeeUser";
function User({ employee }) {
  const { users } = useSelector((state) => state.usersList);
  const [form] = Form.useForm();
  const [user, setUser] = useState();
  const [renderUser, setRenderUser] = useState(null);
  const [visible, setVisible] = useState(false);
  const [mergeVisib, setMergeVisib] = useState(false);
  const [uSub, setUsub] = useState(false);
  const [konfig, setKonfig] = useState({
    email: "",
    verifyCode: "",
  });
  const [data, setData] = useState({
    given_name: "",
    family_name: "",
    gender: "",
    email: "",
    department: "",
    userRole: "",
    departmentRole: "",
    userSub: "",
    phone_number: "",
    forceReset: true,
    // createdAt: moment(),
  });
  const navigate = useNavigate();
  const handleNewUser = () => {
    setVisible(true);
  };
  const addItems = async (addIndex) => {
    await API.put("employees", `/employees/${employee.employeeId}`, {
      body: {
        userSub: uSub,
        accountStatus: "created",
      },
    }).then((r) => {
      setRenderUser(null);
      navigate(`/punonjesit/specifikat/${employee?.employeeId}`);
    });
    form.resetFields();
    setVisible(false);
  };
  const formSubmit = async (res) => {
    // console.log(res);
    await Auth.signUp({
      username: form.getFieldValue("email"),
      password: form.getFieldValue("password"),
      attributes: {
        phone_number:
          "+" +
          form
            .getFieldValue("phone_number")
            .replace("(", "")
            .replace(")", "")
            .replace(" ", "")
            .replace("-", ""),
        family_name: form.getFieldValue("family_name"),
        given_name: form.getFieldValue("given_name"),
        gender: form.getFieldValue("gender"),
        "custom:department": form.getFieldValue("department"),
        "custom:userRole": form.getFieldValue("userRole"),
        "custom:role": form.getFieldValue("departmentRole"),
      },
    })
      .then((res) => {
        console.log("Rezultati Sign Up", res);
        setUsub(res?.userSub);
        setData((prev) => ({
          ...prev,
          // sub: res.userSub,
          given_name: form.getFieldValue("given_name"),
          family_name: form.getFieldValue("family_name"),
          gender: form.getFieldValue("gender"),
          email: form.getFieldValue("email"),
          department: form.getFieldValue("department"),
          userRole: form.getFieldValue("userRole"),
          departmentRole: form.getFieldValue("departmentRole"),
          phone_number: form.getFieldValue("phone_number"),
        }));
        setUser(res);
      })
      .catch((err) =>
        message.error({ content: err.toString().split(":")[1], key: "signUp" })
      );
  };
  const userConfig = () => {
    Auth.confirmSignUp(
      form.getFieldValue("email"),
      form.getFieldValue("verifyCode")
    ).then((res) => {
      if (res === "SUCCESS") {
        addItems();
        message
          .success("Krijimi i nje useri u bë me sukses")
          .then((r) => onClose());
      }
    });
  };
  const onChange = (objField, e) => {
    const value = objField;
    setData((prev) => ({ ...prev, [value]: e }));
  };
  const HandleChange = (objField, e) => {
    const value = objField;
    setKonfig((prev) => ({ ...prev, [value]: e }));
  };
  const onClose = () => {
    // form.resetFields();
    setVisible(false);
    // setUser(null);
  };
  useEffect(() => {
    let toReturn = null;
    if (!!employee?.userSub) {
      toReturn = users?.allUsers?.Items?.find(
        (el) => el.userSub === employee?.userSub
      );
    }
    setRenderUser(toReturn);
  }, [users?.allUsers?.Items, employee]);
  useEffect(() => {
    if (!!Object.keys(employee)?.length) {
      form.setFieldsValue({
        given_name: employee?.employeeFirstName,
        family_name: employee?.employeeLastName,
        gender: employee?.employeeGender,
        email: employee?.employeeEmailWork,
        department: employee?.employeeDepartmentName,
        phone_number: employee?.employeePhoneNumber,
        userRole: "",
        departmentRole: employee?.employeeRoleName,
      });
    }
  }, [employee]);
  return (
    <div className="loaded-user-merge-card">
      {renderUser !== null ? (
        <LoadedUser renderUser={renderUser} />
      ) : (
        <div className="no-user-merge">
          {
            employee?.accountStatus !== "created" &&
            employee?.accountStatus !== "registered" ? (
              <>
                {" "}
                <span>
                  <WarningRed />
                </span>
                <span className="no-user-merge__account">Nuk ka llogari!</span>
                <span className="no-user-merge__employee">
                  Punonjësi i zgjedhur nuk ka një llogari për të aksesuar
                  sistemin.
                </span>
                <button className="add-user" onClick={handleNewUser}>
                  Krijo Përdorues
                </button>{" "}
                <button
                  className="add-user"
                  style={{ width: "auto" }}
                  onClick={() => setMergeVisib(true)}
                >
                  Lidh Userin me Punonjësin
                </button>
              </>
            ) : employee?.accountStatus === "created" ? (
              <>
                <span>
                  <NoUser />
                </span>
                <span className="no-user-merge__account">
                  Llogaria është krijuar me sukses!
                </span>
                <span className="no-user-merge__employee">
                  Pritet që përdoruesi të logohet në sistem për të aktivizuar
                  llogarinë.
                </span>
              </>
            ) : null
            // <button onClick={onClick}>CLICK</button>
          }
        </div>
      )}
      {!!visible ? (
        <SiguriaPerdoruesitModal
          formSubmit={formSubmit}
          userConfig={userConfig}
          user={user}
          onClose={onClose}
          form={form}
          onChange={onChange}
          HandleChange={HandleChange}
          visible={visible}
          setVisible={setVisible}
          setData={setData}
          from={"employee"}
        />
      ) : null}
      {!!mergeVisib ? (
        <MergeEmployeeUser
          visible={mergeVisib}
          setVisible={setMergeVisib}
          selected={employee}
        />
      ) : null}
    </div>
  );
}
export default User;
