import types from "../types/types";

const initialState = {
  accessToken: undefined,
};
const accessTokenReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: payload,
      };
    default:
      return state;
  }
};
export default accessTokenReducer;
