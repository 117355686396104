import ProfileCustomCard from "../../../../Konfigurimet/Profile/ProfileCustomCard";
import Profile from "../../../../Konfigurimet/Profile/Profile";

const proccessMap = (dynamicMap) => {
  const defaultData = {
    x: 0,
    y: 0,
    h: 1,
    w: 1,
    minW: 1,
    minH: 1,
    static: false,
  };

  let save = [
    {
      i: "1",
      data: {
        name: "ProfiliCard",
        emri: "Profili",
        src: Profile,
        parent: false,
        // data: cardData[0],
        data: dynamicMap,
        hidden: false,
      },
      ...defaultData,
      w: 3,
      h: 2,
      minH: 2,
      minW: 3,
      x: 0,
      y: 0,
    },
    {
      i: "2",
      data: {
        name: "KontaktCard",
        emri: "Kontakt",
        src: ProfileCustomCard,
        parent: false,
        // data: cardData[0],
        data: dynamicMap,
        hidden: false,
      },
      ...defaultData,
      minW: 6,
      w: 6,
      minH: 2,
      h: 2,
      x: 3,
      y: 0,
    },
    {
      i: "3",
      data: {
        name: "PerdoruesiCard",
        emri: "Roli",
        src: ProfileCustomCard,
        parent: false,
        // data: cardData[0],
        data: dynamicMap,
        hidden: false,
      },
      ...defaultData,
      minW: 7,
      w: 7,
      minH: 2,
      h: 2,
      x: 10,
      y: 0,
    },
  ];
  return save;
};

export const getProfiliSaved = (dynamicMap) => {
  let test = proccessMap(dynamicMap);
  const ls = JSON.parse(localStorage.getItem("draggableSettingsProfili"));
  if (ls !== null) {
    let temporary = [];
    ls.forEach((el, idx) => {
      let found = test.findIndex((l, x) => l.i === el.i);
      if (found !== -1) {
        temporary.push({
          // ...test[found], hidden: el.hidden, data: dynamicMap
          ...test[found],
          data: {
            ...test[found].data,
            hidden: el?.data?.hidden,
            blur: el?.data?.blur,
          },
          x: el.x,
          y: el.y,
          h: el.h,
          w: el.w,
          minW: el.minW,
          minH: el.minH,
        });
      }
    });
    return temporary;
  } else if (ls === null) {
    localStorage.setItem(
      "draggableSettingsProfili",
      JSON.stringify(proccessMap(dynamicMap))
    );
    return proccessMap(dynamicMap);
  }
};
