import React, { useEffect, useState, useRef, forwardRef } from "react";
import "./AddEmployeeModal/AddEmployeeModal.scss";
import "./punojsit scss/punonjesit.scss";
import {
  IconPunonjesit,
  IconKerko,
  IconNgargo,
  IconExcel,
  PunojsitAcitvIcon,
  PunojsitLarguarIcon,
  ShkarkuarIcon,
} from "../../assets/icons";
import { Delete } from "../Documentation/View/assets";
import { CaretDownFilled } from "@ant-design/icons";
import { Input, Button, Card, Select, Form, Tooltip, Switch } from "antd";
import AgGridComponent from "../AG-grid/AgGridComponent";
import { API } from "aws-amplify";
import moment from "moment-timezone";
import LoadableComp from "../commonComponents/LoadableComp/LoadableComp";
import PunojsitPrint from "./PunojsitPrint";
import { useReactToPrint } from "react-to-print";
import { useNavigate } from "react-router-dom";
import { GeneralInformationsContext } from "./AddEmployeeModal/context";
import FilterAgGrid from "./components/FilterAgGrid";
import { useSelector } from "react-redux";
import { useAuth } from "../../authentication/authHelpers";
import { useLocation } from "react-router";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import ExportToPdf from "../Konfigurimet/dynamic components/ExportToPdf";
import { uniqBy } from "lodash";
import GlobalPopupKonfirm from "../commonComponents/GlobalPopupKonfirm";
import { useDispatch } from "react-redux";
import { addEmployees, editEmployee } from "../../store/actions/";
import { getSocketUrl } from "../../utils/websocketConfig";
import { useMediaQuery } from "react-responsive";
import MobilePunojsitView from "./components/MobilePunojsitView";
import AddEmployeModal from "./components/AddEmployeModal";

// * @EneaXharau -  Websocket handler and listener
// const socketHandler = new WebSocket(getSocketUrl("/dashboard/sockets"));

const FILTER_KEYS = [
  { key: "employeeFullName", label: "Punonjesi", type: "string" },
  { key: "employeeDepartmentName", label: "Departamenti", type: "string" },
  { key: "employeeRoleName", label: "Roli", type: "string" },
];

const Punonjesit = forwardRef(() => {
  const dispatch = useDispatch();
  // columns displayed in the grid
  const columnDefs = [
    {
      headerName: "Nr.",
      // field: "employeeId",
      checkboxSelection: true,
      // headerCheckboxSelection: true,
      filter: "agTextColumnFilter",
      valueGetter: "node.rowIndex + 1",
      resizable: true,
      flex: 1.2,
    },
    {
      headerName: "Punonjësi",
      field: "employeeFullName",
      filter: "agTextColumnFilter",
      resizable: true,
      flex: 2,
      valueGetter: function (params) {
        return (
          params?.data?.employeeFirstName + " " + params?.data?.employeeLastName
        );
      },
      cellRendererFramework: function (params) {
        try {
          return (
            <div
              className="navigate-row"
              onClick={() => {
                navigate(`/punonjesit/specifikat/${params?.data?.employeeId}`);
              }}
            >
              {params?.data?.employeeFirstName +
                " " +
                params?.data?.employeeLastName}
            </div>
          );
        } catch (err) {
          if (params.value) {
            return params.value;
          } else {
            return null;
          }
        }
      },
    },
    {
      headerName: "Departamenti",
      field: "employeeDepartmentName",
      filter: "agTextColumnFilter",
      flex: 3,
      resizable: true,
    },
    {
      headerName: "Dega",
      field: "employeeTeam",
      filter: "agTextColumnFilter",
      flex: 3,
      resizable: true,
    },
    {
      headerName: "Roli",
      field: "employeeRoleName",
      filter: "agTextColumnFilter",
      flex: 2.5,
      resizable: true,
    },
    {
      headerName: "Email punës",
      field: "employeeEmailWork",
      filter: "agTextColumnFilter",
      flex: 2.5,
      resizable: true,
    },
    {
      headerName: "Data Punësimit",
      field: "employeeHireDate",
      filter: "agTextColumnFilter",
      resizable: true,
      flex: 2,
      cellRendererFramework: function (params) {
        if (params?.value !== undefined) {
          return moment(params?.value).format("DD/MM/YYYY");
        } else {
          return <div>Pa përcaktuar</div>;
        }
      },
    },
    {
      headerName: "Lloj punsimit",
      field: "employeeTypeOfEmployment",
      filter: "agTextColumnFilter",
      flex: 2.5,
      resizable: true,
      hide: true,
    },
    {
      headerName: "createdAt",
      field: "createdAt",
      flex: 2.5,
      sort: "desc",
      filterParams: { apply: true, newRowsAction: "keep" },
      resizable: true,
      hide: true,
    },
    {
      headerName: "Emri",
      field: "employeeFirstName",
      filter: "agTextColumnFilter",
      flex: 2.5,
      resizable: true,
      hide: true,
    },
    {
      headerName: "Mbiemri",
      field: "employeeLastName",
      filter: "agTextColumnFilter",
      flex: 2.5,
      resizable: true,
      hide: true,
    },

    { headerName: "Status", field: "employeeStatus", hide: true },
  ];
  const navigate = useNavigate();
  const location = useLocation();
  const userData = useAuth();
  const [form] = Form.useForm();
  const { Option } = Select;
  // get  programFields , employees , users from redoux
  const { programFields } = useSelector((state) => state.programFields);
  const { employees } = useSelector((state) => state.employeesList);
  // const { activeEmployees } = useSelector((state) => state.employeesList);
  const { users } = useSelector((state) => state.usersList);
  //* states to show employeeModal
  const [visible, setVisible] = useState(false);
  const [getName, setGetName] = useState();
  const [files, setFiles] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [tableData, setTableData] = useState(null);
  //selected value from department dropdown
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedBranch, setSelectedBranch] = useState(null);
  // store the searched value return string
  const [searchInput, setSearchInput] = useState(null);
  //store card statuses return array
  const [cardStatus, setCardStatus] = useState([]);
  // store the state from location return string
  const [locationState, setLocationState] = useState(location?.state);
  //* state for switching only to activ employees
  const [showOnlyActivEmployees, setShowOnlyActivEmployees] = useState(false);
  //* state that will show only the active employees when we open punonjesit for the first time
  const [activeEmployees, setActivEmployes] = useState([]);
  const [findEmployees, setFindEmployees] = useState({
    active: {},
    larguar: {},
    shkarkuar: {},
  });

  //* find status field in programfields tabel
  const getProgramfields = () => {
    const filterData = programFields?.find(
      (data) => data?.fieldId === "ea2e2537-0b6a-4292-aca8-62eafecbfad1"
    );
    let values = filterData?.fieldOptions.filter(
      (item) => item.name !== "Logs"
    );

    setCardStatus({ ...filterData, fieldOptions: values });
  };

  //* make the grid ready
  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  /*
   * * @EneaXharau - Data fetching from database
   * * for better communication in sockets
   */
  const getEmployees = () => {
    API.get("employees", "/employees").then((r) => {
      setTableData(
        r.map((el) => ({
          ...el,
          employeeFullName: `${el?.employeeFirstName} ${el?.employeeLastName}`,
        }))
      );
    });
  };
  const deleteItems = () => {
    const selectedNodes = gridApi.getSelectedNodes();
    let selectedEmployeeId = selectedNodes.map((node) => node.data.employeeId);
    let selectedData = selectedNodes.map((node) => node.data);
    gridApi.applyTransaction({
      remove: selectedData,
    });

    API.del("employees", `/employees/${selectedEmployeeId[0]}`);
  };

  let rowData = [];
  gridApi?.forEachNodeAfterFilter?.((node) => {
    rowData?.push(node?.data);
  });

  //! ag grid global search
  const onFilterTextChange = (e) => {
    gridApi.setQuickFilter(e.target.value);
    setSearchInput(e.target.value);
  };

  //! clear THE AG GRID  filter
  const clearFilters = () => {
    gridApi.setFilterModel(null);
    gridApi.onFilterChanged(null);
    gridApi.setQuickFilter(null);
    setSelectedDepartment(null);
    setSearchInput(null);
    setLocationState(null);
  };

  //* dropdown header select  filter
  const DepartamentFilter = (e) => {
    const departamentdFilter = gridApi.getFilterInstance(
      "employeeDepartmentName"
    );
    departamentdFilter.setModel({
      condition1: {
        type: "contains",
        filter: `${e}`,
      },
      operator: "OR",
      condition2: {
        type: "contains",
        filter: `${e}`,
      },
    });
    gridApi.onFilterChanged();
    setSelectedDepartment(e);
  };

  const BranchFilter = (e) => {
    if (e !== undefined) {
      const departamentdFilter = gridApi.getFilterInstance("employeeTeam");
      departamentdFilter.setModel({
        condition1: {
          type: "contains",
          filter: `${e}`,
        },
        operator: "OR",
        condition2: {
          type: "contains",
          filter: `${e}`,
        },
      });
      gridApi.onFilterChanged();
    } else {
      gridApi.destroyFilter("employeeTeam");
    }
    setSelectedBranch(e);
  };

  const DepartamentFiltersTATE = async (value) => {
    var emriFilterComponent = gridApi?.getFilterInstance(
      "employeeTypeOfEmployment"
    );
    await emriFilterComponent?.setModel({
      type: "contains",
      filter: value,
    });
    gridApi.onFilterChanged();
  };

  const departmentState = async (locState) => {
    var emriFilterComponent = gridApi?.getFilterInstance(
      "employeeDepartmentName"
    );
    await emriFilterComponent?.setModel({
      type: "contains",
      filter: locState,
    });
    gridApi.onFilterChanged();
  };

  //* print fille
  const componentRef = useRef("Perdoruesit-print");
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  //* check for duplicate employe department name , dropdown advanced filter
  const duplicateCheck = [];

  tableData &&
    tableData
      ?.map((data) => {
        if (duplicateCheck.includes(data.employeeDepartmentName)) return null;
        duplicateCheck.push(data.employeeDepartmentName);
        return data;
      })
      ?.filter((e) => e);

  // * @EneaXharau - Changed this from const -> useCallback for better performance
  const filterering = (typeName) => {
    return uniqBy(
      cardStatus?.fieldOptions?.map(({ name, color }) => ({
        name,
        color,
        length: tableData?.filter(
          ({ employeeStatus }) => employeeStatus === name
        ).length,
      })),
      "name"
    )
      ?.filter((x) => !!x?.name)
      ?.filter((e) => e?.name === typeName)[0];
  };

  const statusIcon = (status) => {
    switch (status) {
      case "Aktiv":
        return <PunojsitAcitvIcon width={90} height={90} />;
      case "Larguar":
        return <PunojsitLarguarIcon width={90} height={90} />;
      case "Shkarkuar":
        return <ShkarkuarIcon width={90} height={90} />;
      default:
        return <PunojsitAcitvIcon width={90} height={90} />;
    }
  };

  const emplStatusFilter = (status) => {
    var emriFilterComponent = gridApi.getFilterInstance("employeeStatus");
    emriFilterComponent.setModel({
      type: "contains",
      filter: `${status}`,
    });
    gridApi.onFilterChanged();
  };

  const onSwitch = () => {
    setShowOnlyActivEmployees(!showOnlyActivEmployees);
    return !showOnlyActivEmployees ? getEmployees() : emplStatusFilter("Aktiv");
  };

  const sortedEmployees = (data = []) =>
    data?.sort(
      (a, b) =>
        moment(a?.employeeHireDate).valueOf() -
        moment(b?.employeeHireDate).valueOf()
    );

  /**
   * * @EneaXharau - useEffect to listen to socket emits from backend
   * * and run a function with a setTimeout to cancel the delay of emitting
   * * according to its emit request.
   * * .onmessage is a variable that needs to be a function (arrow preferably)
   * * .onmessage takes a parameter from backend which is is a stringified object
   */
  // useEffect(() => {
  // 	socketHandler.onmessage = (msg) => {
  // 		const { request, body, id } = JSON.parse(msg.data);
  // 		switch (request) {
  // 			case "user_added_received":
  // 				{
  // 					if (
  // 						userData?.employeeId !== id &&
  // 						(userData?.userRole === "Admin" ||
  // 							userData?.userRole === "Super Admin" ||
  // 							userData?.userRole === "Supervisor")
  // 					)
  // 						dispatch(addEmployees(body));
  // 				}
  // 				break;
  // 			case "user_edit_received":
  // 				{
  // 					if (
  // 						userData?.employeeId !== id &&
  // 						(userData?.userRole === "Admin" ||
  // 							userData?.userRole === "Super Admin" ||
  // 							userData?.userRole === "Supervisor")
  // 					)
  // 						dispatch(editEmployee(body));
  // 				}
  // 				break;
  // 			default:
  // 				break;
  // 		}
  // 	};
  // 	return () => socketHandler.close();
  // }, []);

  // * @EneaXharau - useEffects moved before return, for esLint reasons
  useEffect(async () => {
    await API.get("employees", "/employees").then((r) => {
      setActivEmployes(r?.filter((e) => e?.employeeStatus === "Aktiv"));
    });
  }, []);

  useEffect(() => {
    switch (locationState) {
      case "Kohe te plote":
        return DepartamentFiltersTATE("Kohe te plote");
      case "Kohe te pjesshme":
        return DepartamentFiltersTATE("Kohe te pjesshme");
      default:
        return departmentState(locationState);
    }
  }, [locationState, gridApi]);

  // * @EneaXharau - Added employees dependency
  useEffect(() => {
    if (!!employees) {
      getEmployees();
    }
  }, [employees]);

  // * @EneaXharau - Added programFields dependency
  useEffect(() => {
    getProgramfields();
  }, [programFields]);

  useEffect(() => {
    setFindEmployees({
      ...findEmployees,
      active: filterering("Aktiv"),
      larguar: filterering("Larguar"),
      shkarkuar: filterering("Shkarkuar"),
    });
  }, [employees, tableData, cardStatus]);

  const isMobileView = useMediaQuery({
    query: "(min-width: 460px)",
  });

  return (
    <>
      <LoadableComp loading={!!tableData ? false : true}>
        <GeneralInformationsContext.Provider
          value={{ getName, setGetName, files, setFiles }}
        >
          <AddEmployeModal
            {...{
              setVisible,
              visible,
              tableData,
              setTableData,
              userData,
              setFiles,
              files,
              getName,
              setGetName,
              // socketHandler,
              socketHandler: () => {},
              users,
              form,
              setActivEmployes,
            }}
          />
        </GeneralInformationsContext.Provider>
        {isMobileView ? (
          <div className="punonjesit">
            <div className="punonjesit-cards">
              <Card
                className="card-puno-prog"
                onClick={() => emplStatusFilter(findEmployees?.active?.name)}
              >
                <div>
                  {statusIcon(findEmployees?.active?.name)}
                  <div
                    className="statusi-per-punojsit"
                    style={{ color: findEmployees?.active?.color }}
                  >
                    {findEmployees?.active?.length}
                    <br></br>
                    {findEmployees?.active?.name}
                  </div>
                </div>
              </Card>
              {showOnlyActivEmployees && (
                <>
                  <Card
                    className="card-puno-prog"
                    onClick={() =>
                      emplStatusFilter(findEmployees?.larguar?.name)
                    }
                  >
                    <div>
                      {statusIcon(findEmployees?.larguar?.name)}
                      <div
                        className="statusi-per-punojsit"
                        style={{ color: findEmployees?.larguar?.color }}
                      >
                        {findEmployees?.larguar?.length}
                        <br></br>
                        {findEmployees?.larguar?.name}
                      </div>
                    </div>
                  </Card>
                  <Card
                    className="card-puno-prog"
                    onClick={() =>
                      emplStatusFilter(findEmployees?.shkarkuar?.name)
                    }
                  >
                    <div>
                      {statusIcon(findEmployees?.shkarkuar?.name)}
                      <div
                        className="statusi-per-punojsit"
                        style={{ color: findEmployees?.shkarkuar?.color }}
                      >
                        {findEmployees?.shkarkuar?.length}
                        <br></br>
                        {findEmployees?.shkarkuar?.name}
                      </div>
                    </div>
                  </Card>
                </>
              )}
              <Card className="add-punojsi-card card-puno-prog">
                <IconPunonjesit
                  className="add-icon"
                  width={73}
                  height={73}
                  fill="#1d3445"
                />
                <p style={{ fontSize: 14, whiteSpace: "350px" }}>
                  Këtu ti mund të krijosh <br></br>një profil të ri punonjësi.
                </p>
                <Button onClick={() => setVisible(true)}>Shto Punonjës</Button>
              </Card>
            </div>
            <div className="ag-grid-punojsit">
              <div className="ag-grid-header">
                <div className="header-search">
                  <div className="icon-search-header">
                    <IconKerko width={15} height={15} />
                  </div>
                  <Input
                    placeholder="Kërko një punonjës"
                    onChange={onFilterTextChange}
                    value={searchInput}
                    style={{ width: 200 }}
                  />
                  <Button className="pastro-btn" onClick={clearFilters}>
                    Pastro
                  </Button>
                </div>
                <div className="punojsit-div">
                  <span className="icon-punojsit">
                    <IconPunonjesit width={15} />
                  </span>
                  <Select
                    className="punojsit-dropdown"
                    suffixIcon={<CaretDownFilled />}
                    placeholder="Të gjithë"
                    style={{ width: 220 }}
                    onChange={DepartamentFilter}
                    value={selectedDepartment}
                  >
                    {duplicateCheck?.map((Data) => (
                      <Option value={Data} key={Data}>
                        {Data}
                      </Option>
                    ))}
                  </Select>
                </div>{" "}
                <div className="punojsit-div">
                  <span className="icon-punojsit">
                    <IconPunonjesit width={15} />
                  </span>
                  <Select
                    className="punojsit-dropdown"
                    suffixIcon={<CaretDownFilled />}
                    placeholder="Filtro Branch"
                    style={{ width: 220 }}
                    onChange={BranchFilter}
                    value={selectedBranch}
                    allowClear
                  >
                    <Option value={"Flex Tirana"}>Flex Tirana</Option>
                    <Option value={"Flex Prishtina"}>Flex Prishtina</Option>
                  </Select>
                </div>{" "}
                <div className="header-icons">
                  <GlobalPopupKonfirm
                    title={"jeni të sigurt që do të fshini këtë njoftim?"}
                    Button={
                      <Tooltip
                        placement="top"
                        overlayClassName="global-icon-tooltip"
                        title={"Fshi një punonjës"}
                      >
                        <Delete width={20} height={20} fill="#323338" />
                      </Tooltip>
                    }
                    cancelText={"JO"}
                    okText={"PO"}
                    onConfirm={deleteItems}
                  />
                  <FilterAgGrid
                    gridApi={gridApi}
                    clearFilters={clearFilters}
                    tableData={tableData}
                    filterKeys={FILTER_KEYS}
                    filterActive={showOnlyActivEmployees}
                  />
                  <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button"
                    table="table-to-xls"
                    filename="Lista_e_Punonjesve"
                    sheet="punojsit"
                    buttonText={
                      <Tooltip
                        placement="top"
                        overlayClassName="global-icon-tooltip"
                        title={"Eksporto në eksel"}
                      >
                        <IconExcel />
                      </Tooltip>
                    }
                  />
                  <ExportToPdf
                    id={"#table-to-xls"}
                    tabelName={"Lista_e_Punonjesve"}
                  />
                  <Tooltip
                    placement="top"
                    overlayClassName="global-icon-tooltip"
                    title={"Printo tabelën"}
                  >
                    <IconNgargo onClick={handlePrint} />
                  </Tooltip>
                </div>
                <div className="switchCards">
                  <span className="span">
                    {showOnlyActivEmployees
                      ? "Shfaq vetëm punonjësit aktiv"
                      : "Shiko të	gjithë punonjësit"}
                  </span>
                  <Switch
                    onClick={onSwitch}
                    checked={!showOnlyActivEmployees}
                  />
                </div>
              </div>

              <div
                className="ag-theme-alpine"
                style={{ height: 580, width: "100%" }}
              >
                <AgGridComponent
                  rowData={
                    showOnlyActivEmployees
                      ? sortedEmployees(tableData)
                      : sortedEmployees(activeEmployees)
                  }
                  rowSelection="single"
                  onGridReady={onGridReady}
                  paginationPageSize={11}
                  columnDefs={columnDefs}
                  // onSelectionChanged={handleSelectionChanged}
                  serverSideStoreType={"full"}
                  enableCellChangeFlash={true}
                  rowModelType={"serverSide"}
                />
              </div>
            </div>
            <PunojsitPrint
              tableData={activeEmployees}
              componentRef={componentRef}
            />
          </div>
        ) : (
          <MobilePunojsitView
            setVisible={setVisible}
            activeEmployees={activeEmployees}
          />
        )}
      </LoadableComp>
    </>
  );
});

export default Punonjesit;
