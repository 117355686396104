import { Form } from "antd";
import { useState } from "react";
import { ColorPickerIcon } from "./assets";
import ColorPicker from "./ColorPicker";
import "./ColorPicker.scss";

const ColorPickerWrapper = ({ className = "", onChange, ...props }) => {
  const [visible, setVisible] = useState();

  return (
    <div {...{ className: `customColorPicker ${className}`.trim() }}>
      <div className="toogleButton" onClick={() => setVisible((prev) => !prev)}>
        <ColorPickerIcon />
      </div>

      <Form.Item {...props} style={!visible ? { position: "absolute" } : {}}>
        {!!visible && <ColorPicker {...{ setVisible, onChange }} />}
      </Form.Item>
    </div>
  );
};

export default ColorPickerWrapper;
