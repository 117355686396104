import { message } from "antd";
import { apiDelete, apiPost, apiPut } from "./api";
import {
  showErrorMsg,
  showLoadingMsg,
  showSuccessMsg,
} from "../../Documentation/utils/Messages";
import { apiRoutes } from "../../../apiRoutes";
import { actions } from "../actions";
import { replaceArrayElement, updateArrayElement } from "./updateArrayElement";

const updateDocConfig = async (
  categoryName,
  { userId, createdAt, categoryName: id, ...rest }
) => await apiPut(apiRoutes.docConfiguration, categoryName, rest);

export const createCategory = (categoryName) => async (dispatch) => {
  showLoadingMsg();
  return await apiPost(apiRoutes.docConfiguration, { categoryName })
    .then((createdCategory) => {
      showSuccessMsg();
      dispatch({
        type: actions.CREATE_CATEGORY,
        payload: createdCategory,
      });
    })
    .catch((e) => {
      console.error(e);
      showErrorMsg();
    });
};

export const deleteCategory = (categoryName) => async (dispatch) => {
  showLoadingMsg({ content: "Duke fshirë..." });
  return (
    !!categoryName &&
    (await apiDelete(apiRoutes.docConfiguration, categoryName)
      .then(() => {
        showSuccessMsg({
          content: "U fshi!",
        });
        dispatch({
          type: actions.DELETE_CATEGORY,
          payload: categoryName,
        });
      })
      .catch((e) => {
        showErrorMsg();
        console.error(e);
      }))
  );
};

export const createDocumentation =
  (selectedCategory, docName, defaultStatuses, docPermissions) =>
  async (dispatch) => {
    const { documentationsAvailable = [], categoryName } = selectedCategory;

    const updatedCategory = {
      ...selectedCategory,
      documentationsAvailable: [
        ...documentationsAvailable,
        {
          createdAt: Date.now(),
          docName,
          docObject: [
            {
              label: "Data e Kërkuar",
              formItemName: "requestedDate",
              type: "datepicker",
            },
          ],
          docStatuses: defaultStatuses,
          docPermissions,
        },
      ],
    };

    const duplication = documentationsAvailable.find(
      ({ docName: arrDocName = "" }) =>
        arrDocName.toLowerCase() === docName.toLowerCase()
    );

    if (!duplication) {
      showLoadingMsg();

      return (
        !!docName &&
        (await updateDocConfig(categoryName, updatedCategory)
          .then(() => {
            showSuccessMsg();
            dispatch({
              type: actions.UPDATE_CATEGORY,
              payload: updatedCategory,
            });
          })
          .catch((e) => {
            console.error(e);
            showErrorMsg();
          }))
      );
    } else message.warning("Ky dokumentacion ekziston tashmë!");
  };

export const deleteCustomField =
  (selectedCategory, label, type) => async (dispatch) => {
    const { customsFieldsAvailable = [], categoryName } = selectedCategory;

    const updatedCategory = {
      ...selectedCategory,
      customsFieldsAvailable: customsFieldsAvailable.filter(
        ({ label: arrLabel, type: arrType }) =>
          arrLabel !== label && arrType !== type
      ),
    };

    showLoadingMsg({ content: "Duke fshirë..." });

    return await updateDocConfig(categoryName, updatedCategory)
      .then(() => {
        showSuccessMsg({ content: "U fshi!" });
        dispatch({
          type: actions.UPDATE_CATEGORY,
          payload: updatedCategory,
        });
      })
      .catch((e) => {
        console.error(e);
        showErrorMsg();
      });
  };

export const deleteTemplate =
  (selectedCategory, template) => async (dispatch) => {
    const { categoryName, templatesAvailable = [] } = selectedCategory;

    const updatedCategory = {
      ...selectedCategory,
      templatesAvailable: templatesAvailable.filter(
        ({ templateName }) => templateName !== template
      ),
    };

    showLoadingMsg({ content: "Duke fshirë..." });

    return await updateDocConfig(categoryName, updatedCategory)
      .then(() => {
        showSuccessMsg({ content: "U fshi!" });
        dispatch({
          type: actions.UPDATE_CATEGORY,
          payload: updatedCategory,
        });
      })
      .catch((e) => {
        console.error(e);
        showErrorMsg();
      });
  };

export const deleteDocumentation =
  (selectedCategory, documentation) => async (dispatch) => {
    const {
      categoryName,
      documentationsAvailable = [],
      templatesAvailable = [],
    } = selectedCategory;

    const updatedCategory = {
      ...selectedCategory,
      documentationsAvailable: documentationsAvailable.filter(
        ({ docName }) => docName !== documentation
      ),
      templatesAvailable: templatesAvailable.map((template) => ({
        ...template,
        templateDocs: template?.templateDocs?.filter?.(
          (templateDoc) => templateDoc !== documentation
        ),
      })),
    };

    showLoadingMsg({ content: "Duke fshirë..." });

    return await updateDocConfig(categoryName, updatedCategory)
      .then(() => {
        showSuccessMsg({ content: "U fshi!" });
        dispatch({
          type: actions.UPDATE_CATEGORY,
          payload: updatedCategory,
        });
      })
      .catch((e) => {
        console.error(e);
        showErrorMsg();
      });
  };

export const createCustomField =
  (selectedCategory, field) => async (dispatch) => {
    const { categoryName, customsFieldsAvailable = [] } = selectedCategory;

    const updatedCategory = {
      ...selectedCategory,
      customsFieldsAvailable: [...customsFieldsAvailable, field],
    };

    const duplication = customsFieldsAvailable.find(
      ({ label, type }) => label === field.label && type === field?.type
    );

    if (!duplication) {
      showLoadingMsg();
      await updateDocConfig(categoryName, updatedCategory)
        .then(() => {
          showSuccessMsg();
          dispatch({
            type: actions.UPDATE_CATEGORY,
            payload: updatedCategory,
          });
        })
        .catch((e) => {
          console.error(e);
          showErrorMsg();
        });

      return Promise.resolve();
    } else {
      message.warning(
        "Ekziston një fushë ekzistuese që përdor këtë informacion!"
      );
      return Promise.reject();
    }
  };

export const updateCustomField =
  (selectedCategory, field) => async (dispatch) => {
    const { categoryName, customsFieldsAvailable = [] } = selectedCategory;

    const updatedCategory = {
      ...selectedCategory,
      customsFieldsAvailable: replaceArrayElement(
        customsFieldsAvailable,
        field,
        "formItemName"
      ),
    };

    showLoadingMsg();

    return await updateDocConfig(categoryName, updatedCategory)
      .then(() => {
        showSuccessMsg();
        dispatch({
          type: actions.UPDATE_CATEGORY,
          payload: updatedCategory,
        });
      })
      .catch((e) => {
        console.error(e);
        showErrorMsg();
      });
  };

export const createTemplate =
  (selectedCategory, template) => async (dispatch) => {
    const { categoryName, templatesAvailable = [] } = selectedCategory;

    const updatedCategory = {
      ...selectedCategory,
      templatesAvailable: [...templatesAvailable, template],
    };

    showLoadingMsg();
    return await updateDocConfig(categoryName, updatedCategory)
      .then(() => {
        showSuccessMsg();
        dispatch({
          type: actions.UPDATE_CATEGORY,
          payload: updatedCategory,
        });
      })
      .catch((e) => {
        console.error(e);
        showErrorMsg();
      });
  };

export const updateTemplate =
  (selectedCategory, template) => async (dispatch) => {
    const { categoryName, templatesAvailable = [] } = selectedCategory;

    const updatedCategory = {
      ...selectedCategory,
      templatesAvailable: updateArrayElement(
        templatesAvailable,
        template,
        "id"
      ),
    };

    showLoadingMsg();
    return await updateDocConfig(categoryName, updatedCategory)
      .then(() => {
        showSuccessMsg();
        dispatch({
          type: actions.UPDATE_CATEGORY,
          payload: updatedCategory,
        });
      })
      .catch((e) => {
        console.log(e);
        showErrorMsg();
      });
  };

export const updateCategory = (category) => async (dispatch) => {
  showLoadingMsg();

  return await updateDocConfig(category.categoryName, category)
    .then(() => {
      showSuccessMsg();
      dispatch({
        type: actions.UPDATE_CATEGORY,
        payload: category,
      });
    })
    .catch((e) => {
      console.error(e);
      showErrorMsg();
    });
};

export const updatePermissions = (category) => async (dispatch) => {
  showLoadingMsg();
};
