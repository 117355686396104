import { useContext, useState } from "react";
import DocumentationModal from "../../../DocumentationModal/DocumentationModal";
import DocumentationSelectorModal from "../../../DocumentationSelectorModal/DocumentationSelectorModal";
import MondayButton from "../../../../../../commonComponents/MondayButton/MondayButton";
import { PlusIcon } from "../../../../assets";
import "./CardHeader.scss";
import PunonjesitContext from "../../../../../../punonjesit/store/PunonjesitContext";

const CardHeader = ({ getMaxByDocumentation }) => {
  const { employee, categoryConfiguration } = useContext(PunonjesitContext);
  const [documentationModalVisible, setDocumentationModalVisible] =
    useState(false);
  const [docType, setDocType] = useState("");

  const {
    docObject,
    docStatuses,
    expirationDate: configExpirationDate,
  } = categoryConfiguration?.documentationsAvailable?.find(
    ({ docName }) => docName === docType
  ) || {};

  // const { folderId, docId = "" } = getMaxByDocumentation(docType);

  return (
    <>
      <div className="documentationListCardHeader">
        <span className="documentationListCardHeaderTitle">Dokumentacione</span>
        <div className="leftSection">
          <span className="employeeName">{employee?.employeeFirstName}</span>

          <MondayButton
            className="newButton"
            Icon={<PlusIcon />}
            {...{ onClick: () => setDocumentationModalVisible(true) }}
          >
            <span>Dokumentacion i Ri</span>
          </MondayButton>
        </div>
      </div>
      {!!docType && (
        <DocumentationModal
          {...{
            visible: !!docType,
            docType,
            setVisible: () => setDocType(""),
            configExpirationDate,
            docObject,
            docStatuses,
          }}
        />
      )}
      <DocumentationSelectorModal
        {...{
          visible: documentationModalVisible,
          setVisible: setDocumentationModalVisible,
          setDocType,
          categoryConfiguration,
        }}
      />
    </>
  );
};

export default CardHeader;
