import { add, multiply, subtract } from "lodash";

export const prepareDeductions = (deductionsArr) => {
	const tempTable = {
		columnsDef: [],
		rowData: [],
		totalities: {
			amount: 0,
			hours: 0,
			days: 0,
		},
	};

	if (Array.isArray(deductionsArr)) {
		if (deductionsArr.length > 0) {
			tempTable.columnsDef = ["ZBRITJET (-)", "SHUMA", "ORET", "DITET", "SHENIMI", "KOEFICENTI"];
			deductionsArr.map((ded) => {
				const { type, hours, days, amount, note, rate } = ded;
				tempTable.rowData.push([type, amount, hours, days, note, rate]);
			});
			tempTable?.rowData?.forEach((rd) => {
				tempTable.totalities.amount += multiply(rd[1], rd[5]);
				tempTable.totalities.hours += rd[2];
				tempTable.totalities.days += rd[3];
			});
			tempTable.rowData.push([
				"TOTALI",
				tempTable.totalities.amount,
				tempTable.totalities.hours,
				tempTable.totalities.days,
				"",
				"",
			]);
		}
	}

	return tempTable;
};

export const prepareAdditions = (additionsArr) => {
	const tempTable = {
		columnsDef: [],
		rowData: [],
		totalities: {
			amount: 0,
			hours: 0,
			days: 0,
		},
	};

	if (Array.isArray(additionsArr)) {
		if (additionsArr.length > 0) {
			tempTable.columnsDef = ["SHTIMET (+)", "SHUMA", "ORET", "DITET", "SHENIMI", "KOEFICENTI"];
			additionsArr.map((ded) => {
				const { type, hours, days, amount, note, rate } = ded;
				tempTable.rowData.push([type, amount, hours, days, note, rate]);
			});
			tempTable?.rowData?.forEach((rd) => {
				tempTable.totalities.amount += multiply(rd[1], rd[5]);
				tempTable.totalities.hours += rd[2];
				tempTable.totalities.days += rd[3];
			});
			tempTable.rowData.push([
				"TOTALI",
				tempTable.totalities.amount,
				tempTable.totalities.hours,
				tempTable.totalities.days,
				"",
				"",
			]);
		}
	}

	return tempTable;
};

export const getSummary = (deductionsArr, additiondsArr) => {
	const tempTable = {
		columnsDef: ["LLOJI", "SHUMA", "ORET", "DITET"],
		rowData: [],
		deductions: {},
		additions: {},
		deductionsArr: [],
		additionsArr: [],
	};

	const deductions = prepareDeductions(deductionsArr);
	const additionsArr = prepareAdditions(additiondsArr);
	tempTable.deductionsArr = deductionsArr;
	tempTable.additionsArr = additiondsArr;
	tempTable.deductions = deductions;
	tempTable.additions = additionsArr;
	if (additiondsArr.length > 0) {
		tempTable.rowData.push([
			"Fitimet (+)",
			additionsArr?.totalities?.amount,
			additionsArr?.totalities?.hours,
			additionsArr?.totalities?.days,
		]);
	}
	if (deductionsArr.length > 0) {
		tempTable.rowData.push([
			"Zbritjet (-)",
			deductions?.totalities?.amount,
			deductions?.totalities?.hours,
			deductions?.totalities?.days,
		]);
	}

	// tempTable.totalities.amount = additionsArr?.totalities?.amount - deductions?.totalities?.amount;
	// tempTable.totalities.hours = deductions?.totalities?.hours + additionsArr?.totalities?.hours;
	// tempTable.totalities.days = deductions?.totalities?.days + additionsArr?.totalities?.days;
	if (tempTable.rowData.length > 0)
		tempTable.rowData.push([
			"TOTAL",
			subtract(additionsArr?.totalities?.amount || 0, deductions?.totalities?.amount || 0),
			"",
			"",
		]);
	if (tempTable?.rowData.length > 0) return tempTable;
};
