import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import { Button, Input, Row, Col, Card, Tooltip } from "antd";
import { IconKerko } from "../../../../assets/icons";
import { ZbritjetContext } from "./ZbritjetContext";
import ZbritjetViewCard from "./ZbritjetViewCard";
import ZbritjetEditCard from "./ZbritjetEditCard";
import ZbritjetModal from "./ZbritjetModal";
import LoadableComp from "../../../commonComponents/LoadableComp/LoadableComp";
import "./zbritjet_pagat_setings.scss";
import "./ZbritjetContext";
import DragContainer from "../../../dashboard/AdminDashboard/Draggable/DragContainer";
import { getSocketUrl } from "../../../../utils/websocketConfig";
import { LogsIcon } from "../../../Documentation/View/assets";
import PagatLogs from "../PagatLogs/PagatLogs";

/**
 * * @EneaXharau - Websocket connection
 */
// const socketHandler = new WebSocket(getSocketUrl("/settings/wages/losses/sockets"));

function PagatZbritjetSetings() {
	const [visible, setVisible] = useState(false);
	const [tableData, setTableData] = useState(null);
	const [showViewEdit, setShowViewEditCard] = useState(false);
	const [toBeEdited, setToBeEdited] = useState(null);
	const [searchTerm, setSearchTerm] = useState([]);
	const [filterActive, setFilterActive] = useState(false);
	const [toggle, setToggle] = useState(false);
	const [logsVisible, setLogsVisible] = useState(false)

	//open the edit card view
	const handleShowEditView = (id) => {
		setShowViewEditCard(true);
		setToBeEdited(id);
	};

	//close the edit card view
	const handleHideEditView = (id) => {
		setShowViewEditCard(false);
		if (id === toBeEdited) {
			setToBeEdited(null);
		}
	};

	// getting earnings data form api
	const getEarnings = async () => {
		await API.get("deductions", "/deductions").then((r) => {
			setTableData(r);
			// console.log(r);
		});
	};

	const openModal = () => {
		setVisible(true);
		setToggle(false);
	};

	useEffect(() => {
		getEarnings();
	}, []);

	/**
	 * * @EneaXharau - useEffect to listen to socket emits from backend
	 * * and run a function with a setTimeout to cancel the delay of emitting
	 * * according to its emit request.
	 * * .onmessage is a variable that needs to be a function (arrow preferably)
	 * * .onmessage takes a parameter from backend which is is a stringified object
	 */
	// useEffect(() => {
	// 	socketHandler.onmessage = (msg) => {
	// 		const { request, body } = JSON.parse(msg.data);
	// 		switch (request) {
	// 			case "loss_added_received": {
	// 				getEarnings();
	// 				break;
	// 			}
	// 			case "loss_removed_received": {
	// 				getEarnings();
	// 				break;
	// 			}
	// 			case "loss_updated_received": {
	// 				getEarnings();
	// 				break;
	// 			}
	// 			default:
	// 				break;
	// 		}
	// 	};
	// 	return () => socketHandler.close();
	// }, []);

	// search card function
	const onSearchCard = (e) => {
		let substring = e.target.value;
		let filteredData = tableData.filter(
			(item) => item.deductionName.toLowerCase().includes(substring.toLowerCase())
			// item.earningName.toLowerCase().includes(substring.toLowerCase())
		);
		setSearchTerm(filteredData);
		substring !== "" ? setFilterActive(true) : setFilterActive(false);
	};

	return (
		<LoadableComp loading={!!tableData ? false : true}>
			<ZbritjetContext.Provider value={{ tableData, setTableData, setToBeEdited }}>
				<div className="pagat-fitimet-setings">
					<div className="pagat-fitimet-header">
						<div className="pagat-fitimet-search">
							<div className="search-icon">
								<IconKerko width={15} height={15} />
							</div>{" "}
							<Input
								placeholder="Kërko një rekord"
								onChange={onSearchCard}
								style={{ width: 212 }}
							/>
						</div>
						<Button className="krijo-btn" onClick={() => openModal()}>
							Krijo një të re
						</Button>
						<div className="header-icons">
							<Tooltip title={"Shfaq historikun"}>
								<LogsIcon fill="black" onClick={() => setLogsVisible(true)} />
							</Tooltip>
						</div>
						<div className="toogleDiv">
							<Button className="dnd-btn" onClick={() => setToggle(!toggle)}>
								Drag {"&"} Drop
							</Button>
						</div>
						<ZbritjetModal
							visible={visible}
							setVisible={setVisible}
							socketHandler={() => { }}
						// socketHandler={socketHandler}
						/>
					</div>
					{tableData?.length > 0 ? (
						<Row className="DYNAMIC" style={{ width: "100%" }}>
							{filterActive === false ? (
								toggle === true ? (
									<DragContainer view={"settingsZbritjet"} dynamicMap={tableData} />
								) : (
									tableData?.map((Data) => (
										<Col style={{ width: "20%", paddingRight: "20px" }} key={Data.deductionId}>
											<div
												className="card-fitimet"
												style={{ marginLeft: 20 }}
												key={Data.deductionId}
											>
												<Card
													key={Data.deductionId}
													width="100%"
													height="100%"
													title={Data.deductionName}
													bordered={false}
												// style={{ marginLeft: 10, marginRight: 10 }}
												>
													{toBeEdited === Data?.deductionId ? (
														<ZbritjetEditCard
															id={Data.deductionId}
															Data={Data}
															handleHideEditView={handleHideEditView}
															// socketHandler={socketHandler}
															socketHandler={() => { }}
														/>
													) : (
														<ZbritjetViewCard
															id={Data.deductionId}
															handleShowEditView={handleShowEditView}
															Data={Data}
														/>
													)}
												</Card>
											</div>
										</Col>
									))
								)
							) : (
								searchTerm?.map((Data) => (
									<Col
										className="search-col"
										style={{
											width: "20%",
											paddingLeft: "20px",
											paddingRight: "20px",
										}}
										key={Data.deductionId}
									>
										<div className="card-fitimet" key={Data.deductionId}>
											<Card
												key={Data.deductionId}
												width="100%"
												height="100%"
												title={Data.deductionName}
												bordered={false}
											>
												{toBeEdited === Data?.deductionId ? (
													<ZbritjetEditCard
														id={Data.deductionId}
														Data={Data}
														handleHideEditView={handleHideEditView}
													/>
												) : (
													<ZbritjetViewCard
														id={Data.deductionId}
														handleShowEditView={handleShowEditView}
														Data={Data}
													/>
												)}
											</Card>
										</div>
									</Col>
								))
							)}
						</Row>
					) : (
						<div className="ska-fitime-data">Nuk ka asnjë rekord për zbritjet e pagave</div>
					)}
				</div>
				{logsVisible && <PagatLogs data={tableData} isVisible={logsVisible} setIsVisible={setLogsVisible} logsName={"Zbritjet"} />}
			</ZbritjetContext.Provider>
		</LoadableComp>
	);
}

export default PagatZbritjetSetings;
