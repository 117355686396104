export const reportsConstants = {
	// Liste Prezencat
	approvals: {
		title: "Listë Prezencat",
		subTitle: "Info rreth turneve",
		note: "",
		columnDefs: [
			// "employeeFirstName",
			// "employeeLastName",
			"employee",
			"employeeDepartmentName",
			"clockDate",
			"clockInDate",
			"clockOutDate",
			// "employeeFullName",
			// "clockInPayment",
			// "clockInRate",
			"before19",
			"between19and22",
			"after22",
			"duration",
			"dayType",
			"breakTime",
			"breakTimeVal",
			"employeeTeam",
		],
		allAppliedColumnDefs: [
			"clockInDate",
			"clockOutDate",
			"clockDate",
			"employeeFirstName",
			"employeeLastName",
			"employeeDepartmentName",
			"approved",
			"clockInNote",
			"clockOutNote",
			"duration",
			"employeeFullName",
			"clockInPayment",
			"clockInRate",
			"before19",
			"between19and22",
			"after22",
			"employee",
			"dayType",
			"breakTime",
			"breakTimeVal",
			//
			"employeeId",
			"employeeGender",
			"employeeDayOfBirth",
			"employeeSupervisor",
			"employeeEmailWork",
			"employeeEmailPersonal",
			"employeePhoneWork",
			"employeePhonePersonal",
			"employeeAddress",
			"employeeCity",
			"employeeCountry",
			"employeeZipCode",
			"employeePosition",
			// "employeeStartDate",
			"employeeHireDate",
			"employeeEndDate",
			"employeeStatus",
			"employeeReasonforTermination",
			"employeeTypeOfEmployment",
			"employeeTypeOfContract",
			"employeeTypeOfContractDescription",
			"employeeTypeOfContractStartDate",
			"employeeTypeOfContractEndDate",
			"employeeTypeOfContractSalary",
			"employeeSalaryPerHour",
			"employeeMonthlyNetSalary",
			"employeeGrossMonthlySalary",
			"employeeTeam",
		],
	},
	supervisorApprovals: {
		title: "Listë Prezencat",
		subTitle: "Info rreth turneve",
		note: "",
		columnDefs: [
			// "employee",
			"employee",
			"employeeDepartmentName",
			"clockDate",
			"clockInDate",
			"clockOutDate",
			// "employeeFullName",
			// "clockInPayment",
			// "clockInRate",
			"before19",
			"between19and22",
			"after22",
			"duration",
			"dayType",
			"breakTime",
			// "employeeFullName",
			// "employeeDepartmentName",
			// "clockInPayment",
			// "clockInRate",
			// "duration",
			// "dayType",
			"employeeTeam",
		],
		allAppliedColumnDefs: [
			"clockInDate",
			"clockOutDate",
			"clockDate",
			"employeeFirstName",
			"employeeLastName",
			"employeeDepartmentName",
			"approved",
			"clockInNote",
			"clockOutNote",
			"duration",
			"employeeFullName",
			"employee",

			// "clockInPayment",
			// "clockInRate",
			//
			"before19",
			"between19and22",
			"after22",
			// "duration",
			"dayType",
			"employeeId",
			"employeeGender",
			"employeeDayOfBirth",
			"employeeSupervisor",
			"employeeEmailWork",
			"employeeEmailPersonal",
			"employeePhoneWork",
			"employeePhonePersonal",
			"employeeAddress",
			"employeeCity",
			"employeeCountry",
			"employeeZipCode",
			"employeePosition",
			"employeeStartDate",
			"employeeEndDate",
			"employeeStatus",
			"employeeReasonforTermination",
			"employeeTypeOfEmployment",
			"employeeTypeOfContract",
			// "employeeTypeOfContractDescription",
			"employeeTypeOfContractStartDate",
			"employeeTypeOfContractEndDate",
			"breakTime",
			// "employeeTypeOfContractSalary",
			// "employeeSalaryPerHour",
			// "employeeMonthlyNetSalary",
			// "employeeGrossMonthlySalary",
			"employeeTeam",
		],
	},
	clockingTypesReports: {
		title: "Listë Prezencat",
		subTitle: "Info rreth turneve",
		note: "",
		columnDefs: [
			// "employee",
			"employee",
			"employeeDepartmentName",
			"employeeTeam",
			"regularDays",
			"sickDays",
			"holidayRequestDays",
			"nationalHolidayDays",
			"nationalHolidayWorkedDays",
			"maternityDays",
			"missingDays",

			/////////////////////////

			/////////////////////////,

			// "employeeDepartmentName",
			// "clockDate",
			// "clockInDate",
			// "clockOutDate",
			// "employeeFullName",
			// "clockInPayment",
			// "clockInRate",
			// "before19",
			// "between19and22",
			// "after22",
			// "duration",
			// "dayType",
			// "breakTime",
			// "employeeFullName",
			// "employeeDepartmentName",
			// "clockInPayment",
			// "clockInRate",
			// "duration",
			// "dayType",
			// "employeeTeam",
		],
		allAppliedColumnDefs: [
			"regularDays",
			"sickDays",
			"holidayRequestDays",
			"nationalHolidayDays",
			"nationalHolidayWorkedDays",
			"maternityDays",
			"missingDays",
			"clockInDate",
			"clockOutDate",
			"clockDate",
			"employeeFirstName",
			"employeeLastName",
			"employeeDepartmentName",
			"approved",
			"clockInNote",
			"clockOutNote",
			"duration",
			"employeeFullName",
			"employee",

			// "clockInPayment",
			// "clockInRate",
			//
			// "before19",
			// "between19and22",
			// "after22",
			// "duration",
			"dayType",
			"employeeId",
			"employeeGender",
			"employeeDayOfBirth",
			"employeeSupervisor",
			"employeeEmailWork",
			"employeeEmailPersonal",
			"employeePhoneWork",
			"employeePhonePersonal",
			"employeeAddress",
			"employeeCity",
			"employeeCountry",
			"employeeZipCode",
			"employeePosition",
			"employeeStartDate",
			"employeeEndDate",
			"employeeStatus",
			"employeeReasonforTermination",
			"employeeTypeOfEmployment",
			"employeeTypeOfContract",
			// "employeeTypeOfContractDescription",
			"employeeTypeOfContractStartDate",
			"employeeTypeOfContractEndDate",
			"breakTime",
			// "employeeTypeOfContractSalary",
			// "employeeSalaryPerHour",
			// "employeeMonthlyNetSalary",
			// "employeeGrossMonthlySalary",
			"employeeTeam",
		],
	},

	// Employee Requests
	requests: {
		title: "Regjistri i kërkesave",
		subTitle: "Vjetore/personale",
		note: "",
		columnDefs: [
			"employeeFirstName",
			"employeeLastName",
			"requestType",
			"requestStart",
			"requestEnd",
			"employeeDepartmentName",
			"requestStatus",
			"employeeTeam",
		],
		allAppliedColumnDefs: [
			"requestType",
			"requestStart",
			"requestEnd",
			"createdAt",
			"requestStatus",
			"requestDescription",
			//
			"employeeId",
			"employeeFirstName",
			"employeeLastName",
			"employeeDepartmentName",
			"employeeGender",
			"employeeDayOfBirth",
			"employeeSupervisor",
			"employeeEmailWork",
			"employeeEmailPersonal",
			"employeePhoneWork",
			"employeePhonePersonal",
			"employeeAddress",
			"employeeCity",
			"employeeCountry",
			"employeeZipCode",
			"employeePosition",
			// "employeeStartDate",
			"employeeHireDate",
			"employeeEndDate",
			"employeeStatus",
			"employeeReasonforTermination",
			"employeeTypeOfEmployment",
			"employeeTypeOfContract",
			"employeeTypeOfContractDescription",
			"employeeTypeOfContractStartDate",
			"employeeTypeOfContractEndDate",
			"employeeTypeOfContractSalary",
			// "employeeSalaryPerHour",
			// "employeeMonthlyNetSalary",
			// "employeeGrossMonthlySalary",
			"employeeTeam",
		],
	},
	// Payments from table wages
	bankForwardPay: {
		title: "Listëpagesa për në bankë",
		subTitle: "Të dhëna financiare",
		note: "",
		columnDefs: [
			"idNumber",
			"employeeFirstName",
			"employeeLastName",
			"timeframe",
			"totalBruto",
			"totalNeto",
			"totalIns",
			"totalEmployeeerIns",
			"bankTotal",
			"total",
			"employeeTeam",
		],
		allAppliedColumnDefs: [
			"idNumber",
			"employeeFirstName",
			"employeeLastName",
			"timeframe",
			"totalBruto",
			"totalNeto",
			"totalIns",
			"totalEmployeeerIns",
			"bankTotal",
			"total",
			"employeeTeam",
		],
	},

	// Additions and Deductions
	prepayments: {
		title: "Orë shtesë/Banka e Orëve/Kompensime",
		subTitle: "Të dhëna financiare",
		note: "",
		columnDefs: [
			"employeeFirstName",
			"employeeLastName",
			"additions",
			"deductions",
			"timeframe",
			"employeeDepartmentName",
			"total",
			"employeeTeam",
		],
		allAppliedColumnDefs: [
			"additions",
			"deductions",
			"timeframe",
			"total",
			//
			"employeeId",
			"employeeFirstName",
			"employeeLastName",
			"employeeDepartmentName",
			"employeeGender",
			"employeeDayOfBirth",
			"employeeSupervisor",
			"employeeEmailWork",
			"employeeEmailPersonal",
			"employeePhoneWork",
			"employeePhonePersonal",
			"employeeAddress",
			"employeeCity",
			"employeeCountry",
			"employeeZipCode",
			"employeePosition",
			"employeeStartDate",
			"employeeEndDate",
			"employeeStatus",
			"employeeReasonforTermination",
			"employeeTypeOfEmployment",
			"employeeTypeOfContract",
			"employeeTypeOfContractDescription",
			"employeeTypeOfContractStartDate",
			"employeeTypeOfContractEndDate",
			"employeeTypeOfContractSalary",
			"employeeSalaryPerHour",
			"employeeMonthlyNetSalary",
			"employeeGrossMonthlySalary",
			"employeeTeam",
		],
	},

	// Detailed Taxes
	detailedTaxes: {
		title: "Listëpagesa e Kontributeve dhe TAP",
		subTitle: "Të dhëna financiare",
		note: "",
		columnDefs: [
			"idNumber",
			"employeeFirstName",
			"employeeLastName",
			"employeeDepartmentName",
			"totalBruto",
			"totalNeto",
			"healthIns",
			"socialIns",
			"progressiveTax",
			"totalIns",
			"healthInsEmployeer",
			"insuranceEmployeer",
			"total",
			"employeeTeam",
		],
		allAppliedColumnDefs: [
			"idNumber",
			"employeeFirstName",
			"employeeLastName",
			"employeeDepartmentName",
			"totalBruto",
			"totalNeto",
			"healthIns",
			"socialIns",
			"progressiveTax",
			"totalIns",
			"healthInsEmployeer",
			"insuranceEmployeer",
			"total",
			"employeeGender",
			"employeeDayOfBirth",
			"employeeSupervisor",
			"employeeEmailWork",
			"employeeEmailPersonal",
			"employeePhoneWork",
			"employeePhonePersonal",
			"employeeAddress",
			"employeeCity",
			"employeeCountry",
			"employeeZipCode",
			"employeeDepartmentName",
			"employeePosition",
			"employeeStartDate",
			"employeeEndDate",
			"employeeStatus",
			"employeeReasonforTermination",
			"employeeTypeOfEmployment",
			"employeeTypeOfContract",
			"employeeTypeOfContractDescription",
			"employeeTypeOfContractStartDate",
			"employeeTypeOfContractEndDate",
			"employeeTypeOfContractSalary",
			"employeeSalaryPerHour",
			"employeeMonthlyNetSalary",
			"employeeGrossMonthlySalary",
			"employeeTeam",
		],
	},

	employeesList: {
		title: "Lista e punonjësve",
		subTitle: "Të dhëna kompanie",
		columnDefs: [
			"employeeFirstName",
			"employeeLastName",
			"employeeAddress",
			"employeeDepartmentName",
			"employeeEmailWork",
			"employeeTeam",

			// "employeePersonalEmail",
			// "employeePhoneNumber",
		],
		allAppliedColumnDefs: [
			"employeeId",
			"employeeFirstName",
			"employeeLastName",
			"employeeGender",
			"employeeDayOfBirth",
			"employeeSupervisor",
			"employeeEmailWork",
			// "employeeEmailPersonal",
			"employeePersonalEmail",
			"employeePhoneNumber",
			"employeePhoneWork",
			"employeePhonePersonal",
			"employeeAddress",
			"employeeCity",
			"employeeCountry",
			"employeeZipCode",
			"employeeDepartmentName",
			"employeePosition",
			// "employeeStartDate",
			"employeeHireDate",
			"employeeEndDate",
			"employeeStatus",
			"employeeReasonforTermination",
			"employeeTypeOfEmployment",
			"employeeTypeOfContract",
			"employeeTypeOfContractDescription",
			"employeeTypeOfContractStartDate",
			"employeeTypeOfContractEndDate",
			"employeeTypeOfContractSalary",
			"employeeSalaryPerHour",
			"employeeMonthlyNetSalary",
			"employeeGrossMonthlySalary",
			"employeeTeam",
		],
		note: "",
	},

	// Trendi i punesimeve dhe largimeve!
	suspensions: {
		title: "Trendi i punësimeve dhe largimeve",
		subTitle: "Të dhëna kompanie",
		columnDefs: [
			"employeeFirstName",
			"employeeLastName",
			"employeeAddress",
			"employeeDepartmentName",
			"employeeEmailWork",
			"employeeStatus",
			"employeeLatestStatusUpdate",
			"employeeTeam",
		],
		allAppliedColumnDefs: [
			"employeeFirstName",
			"employeeLastName",
			"employeeAddress",
			"employeeDepartmentName",
			"employeeEmailWork",
			"employeeStatus",
			"employeeLatestStatusUpdate",
			"employeeTeam",
		],
	},
};
