import { automateClockInReminder } from "./automateClockInReminder";
import { automateRequestsToApprovals } from "./requestsToApprovals";

export const automateFactory = (type, user, info) => {
	switch (type) {
		case "requestsToApprovals":
			return automateRequestsToApprovals(user, info);

		case "clockInReminder":
			return automateClockInReminder(user);

		default:
			return null;
	}
};
