import "./ViewLogoHeader.scss";

const ViewLogoHeader = ({ icon, title, subtitle, className = "" }) => {
  return (
    <div className={`docConfigLogoHeader ${className}`.trim()}>
      <img src={icon} className="logo" alt="" />
      <div className="labelContainer">
        <span className="title">{title}</span>
        <span className="subtitle">{subtitle}</span>
      </div>
    </div>
  );
};

export default ViewLogoHeader;
