import { useMemo, useState } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import { DashboardLayoutCard } from "./DashboardLayoutCard/DashboardLayoutCard";

import "./DashboardLayout.scss";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

const LayoutBody = WidthProvider(Responsive);

export default function DashbordLayout({
  layout,
  handleBlur,
  handleDelete,
  change,
  editedLayout,
  view,
}) {
  const [breakpoints] = useState({
    lg: 1600,
    md: 1320,
    sm: 900,
    xs: 480,
    xxs: 0,
  });
  const [currentBreakpoint, setCurrentBreakpoint] = useState(calcBreakpoint());

  //create the cards that will be displayed, get the layout as a prop
  const cards = useMemo(() => {
    return layout?.map(({ static: s, i, ...rest }) => {
      return (
        <DashboardLayoutCard
          handleBlur={handleBlur}
          handleDelete={handleDelete}
          className={s ? "static" : ""}
          key={i}
          cardKey={i}
          title={rest.data.name}
          {...rest}
          change={change}
        />
      );
    });
  }, [layout, change]);

  //calculate the number of colums of the grid depending on the screen
  function calcBreakpoint() {
    let result = "lg";
    let width = window.innerWidth;
    let br = Object.keys(breakpoints).sort(
      (a, b) => breakpoints[a] - breakpoints[b]
    );

    for (const b of br) {
      if (breakpoints[b] >= width) {
        result = b;
        break;
      }
    }
    return result;
  }

  //depending on the grid, we will have different colums and rowheight
  const getView = (viewType) => {
    switch (viewType) {
      case "Admin":
        return {
          rowHeight: 315,
          col: { lg: 4, md: 3, sm: 2, xs: 1, xxs: 1 },
        };
      case "Supervisor":
        return {
          rowHeight: 315,
          col: { lg: 4, md: 3, sm: 2, xs: 1, xxs: 1 },
        };
      case "settingsPozicionet":
        return {
          rowHeight: 315,
          col: { lg: 4, md: 3, sm: 2, xs: 1, xxs: 1 },
        };
      case "settingsFitimet":
        return {
          rowHeight: 480,
          col: { lg: 5, md: 4, sm: 3, xs: 2, xxs: 1 },
        };
      case "settingsZbritjet":
        return {
          rowHeight: 480,
          col: { lg: 5, md: 4, sm: 3, xs: 2, xxs: 1 },
        };
      case "departments":
        return {
          rowHeight: 315,
          col: { lg: 4, md: 3, sm: 2, xs: 1, xxs: 1 },
        };
      case "profili":
        return {
          rowHeight: 275,
          col: { lg: 16, md: 12, sm: 8, xs: 4, xxs: 2 },
        };
      case "settingsInfoRrethKompanis":
        return {
          rowHeight: 380,
          col: { lg: 2, md: 2, sm: 1, xs: 1, xxs: 1 },
        };
      default:
        return {
          rowHeight: 315,
          col: { lg: 4, md: 3, sm: 2, xs: 1, xxs: 1 },
        };
    }
  };

  return (
    <div style={{ width: "100%" }}>
      {/* {layout?.length !== 0 && ( */}
      <LayoutBody
        {...{
          layouts: {
            [currentBreakpoint]: layout,
          },
          id: "dashboard-react-layout",
          className: "dashboard-react-layout",
          allowOverlap: false,
          breakpoints,
          cols: getView(view).col,
          rowHeight: getView(view).rowHeight,
          isBounded: false,
          margin: [10, 10],
          isDraggable: change,
          isResizable: change,
          currentBreakpoint,
          resizeHandles: ["sw", "se"],
          useCSSTransforms: true,
          draggableHandle: ".header-dashbord-card-title-container",
          onBreakpointChange: () => {
            const newBreakpoint = calcBreakpoint();
            setCurrentBreakpoint(newBreakpoint);
          },
          onLayoutChange(l) {
            editedLayout.current = l;
          },
        }}
      >
        {cards}
      </LayoutBody>
      {/* )} */}
    </div>
  );
}
