import {
  CloseOutlined,
  PlusOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";
import { Form, Modal } from "antd";
import ColorPickerWrapper from "../../ColorPicker/ColorPickerWrapper";
import MondayButton from "../../../commonComponents/MondayButton/MondayButton";
import InputComponent from "../../../InputComponent/InputComponent";
import { Tick } from "../../assets";
import ColorPalette from "./components/ColorPalette/ColorPalette";
import { cloneDeep } from "lodash";
import { useProgramFields } from "../../hooks/useProgramFields";
import "./DocStatusesModal.scss";

const antdModalProps = {
  closable: true,
  destroyOnClose: true,
  wrapClassName: "cardLayoutModal",
  footer: null,
  maskClosable: false,
  centered: true,
};

const DocStatusesModal = ({
  visible,
  setVisible,
  selectedDocumentation,
  setEditedCategory,
  onSave,
}) => {
  const programFields = useProgramFields();
  const colorPalette = programFields?.["Color Palette"];

  const [form] = Form.useForm();
  const { docName, docStatuses = [] } = selectedDocumentation;

  const updateFormItem = (key, value, index) => {
    const { docStatuses: formDocStatuses } = form.getFieldsValue();
    const clonedDocStatuses = cloneDeep(formDocStatuses);
    form.setFieldsValue({
      docStatuses: clonedDocStatuses.map((statusObj, idx) =>
        idx !== index ? statusObj : { ...statusObj, [key]: value }
      ),
    });
  };

  const changeStatusOrder = (currentIndex, nextIndex) => {
    const { docStatuses: formDocStatuses } = form.getFieldsValue();
    let clonedDocStatuses = cloneDeep(formDocStatuses);
    if (
      currentIndex !== nextIndex &&
      nextIndex < clonedDocStatuses.length &&
      nextIndex >= 0
    ) {
      [clonedDocStatuses[currentIndex], clonedDocStatuses[nextIndex]] = [
        clonedDocStatuses[nextIndex],
        clonedDocStatuses[currentIndex],
      ];
    }
    form.setFieldsValue({ docStatuses: clonedDocStatuses });
  };

  const validateStatusName = (value, idx) => {
    const { docStatuses: formDocStatuses } = cloneDeep(form.getFieldsValue());
    delete formDocStatuses[idx];
    const statusNames = formDocStatuses
      .map(({ statusName }) => statusName)
      .filter(Boolean);

    return statusNames.includes(value)
      ? Promise.reject(new Error("Statusi duhet të jetë unik"))
      : Promise.resolve();
  };

  const onCancel = () => {
    form.resetFields();
    setVisible(false);
  };

  const saveStatuses = () => {
    const { docStatuses: formDocStatuses = [] } = cloneDeep(
      form.getFieldsValue()
    );
    //! filters statusName, statusColor and then maps it
    const formattedDocStatuses = formDocStatuses
      .filter(({ statusName, statusColor }) => !!statusName && !!statusColor)
      .map(({ statusName, statusColor }) => ({ statusName, statusColor }));
    setEditedCategory(
      (prev) => ({
        ...prev,
        documentationsAvailable: prev?.documentationsAvailable?.map((item) =>
          item?.docName !== docName
            ? item
            : {
                ...item,
                docStatuses: formattedDocStatuses,
              }
        ),
      }),
      () => {
        onCancel();
        onSave();
      }
    );
  };

  return (
    <Modal
      {...{
        ...antdModalProps,
        visible,
        onCancel,
        title: "Statuset e Dokumentacionit",
      }}
    >
      <Form {...{ form }}>
        <div className="documentationStatusesModalContent">
          <div className="statusesLabel">Statuset</div>
          <Form.List
            name="docStatuses"
            initialValue={docStatuses.map((statusObj) => ({
              ...statusObj,
              pickedColor: statusObj.statusColor,
            }))}
          >
            {(fields, { add, remove }) => (
              <>
                <div className="documentStatusesContainer">
                  {fields.map(
                    //! maps fields from customFields and configure documents
                    ({ key, name, fieldKey, ...restField }, index) => (
                      <div {...{ key }} className="documentStatus">
                        <InputComponent
                          {...{
                            prefix: `${index + 1}.`,
                            placeholder: "Emri Statusit",
                            formItemName: [name, "statusName"],
                            fieldKey: [fieldKey, "statusName"],
                            required: true,
                            style: { minWidth: 220 },
                            rules: [
                              {
                                validator: (_, value) =>
                                  validateStatusName(value, index),
                              },
                            ],
                            ...restField,
                          }}
                        />
                        <div
                          className="mobile-div-status-modal"
                          style={{ display: "flex" }}
                        >
                          <Form.Item
                            {...{
                              name: [name, "statusColor"],
                              fieldKey: [fieldKey, "statusColor"],
                              rules: [
                                {
                                  required: true,
                                  message: "*Mungon ngjyra e statusit!",
                                },
                              ],
                            }}
                          >
                            <ColorPalette
                              {...{
                                colorPalette,
                                onChange: (color) =>
                                  updateFormItem("pickedColor", color, index),
                              }}
                            />
                          </Form.Item>

                          <ColorPickerWrapper
                            {...{
                              name: [name, "pickedColor"],
                              fieldKey: [fieldKey, "pickedColor"],
                              className: "statusColorPicker",
                              onChange: (statusColor) =>
                                updateFormItem(
                                  "statusColor",
                                  statusColor,
                                  index
                                ),
                            }}
                          />

                          <div
                            className="colorSquare"
                            onClick={() => changeStatusOrder(index, index - 1)}
                          >
                            <CaretUpOutlined />
                          </div>
                          <div
                            className="colorSquare"
                            onClick={() => changeStatusOrder(index, index + 1)}
                          >
                            <CaretDownOutlined />
                          </div>

                          <div
                            className="removeBtn"
                            style={index === 0 ? { zIndex: -1 } : {}}
                            onClick={() => remove(name)}
                          >
                            <CloseOutlined />
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </div>
                <div className="actionBtnContainer">
                  <MondayButton
                    {...{
                      Icon: <PlusOutlined />,
                      className: "addStatusBtn",
                      onClick: () => {
                        add();
                      },
                    }}
                  >
                    Shto Statusin
                  </MondayButton>

                  <div className="decisiveButtons">
                    <MondayButton
                      {...{
                        Icon: <CloseOutlined />,
                        className: "cancelBtn",
                        onClick: onCancel,
                      }}
                    >
                      Anulo
                    </MondayButton>
                    <MondayButton
                      {...{
                        Icon: <Tick style={{ width: 23 }} />,
                        className: "addStatusBtn",
                        onClick: () => {
                          form.validateFields().then(() => saveStatuses());
                        },
                      }}
                    >
                      Ruaj Ndryshimet
                    </MondayButton>
                  </div>
                </div>
              </>
            )}
          </Form.List>
        </div>
      </Form>
    </Modal>
  );
};

export default DocStatusesModal;
