import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import DOMPurify from "dompurify";
import "react-quill/dist/quill.snow.css";
import { Radio } from "antd";

const QuillEditor = ({ onChange, placeholder, value = "", formItemName }) => {
  // console.log(formItemName);
  const [open, setOpen] = useState(true);
  const [temporaryString, setTemporareString] = useState("");
  const handleChange = (value) => {
    if (value.replace(/<(.|\n)*?>/g, "").trim().length === 0) {
      value = "";
    }

    const santitizedValue = DOMPurify.sanitize(value);
    onChange(santitizedValue);
  };

  useEffect(() => {
    setTemporareString(value);
  }, [value]);

  return (
    <div className="custom-quill">
      <Radio.Group
        onChange={() => setOpen(!open)}
        value={open}
        className="radio-group"
      >
        <Radio value={true} id={`${formItemName}`}>
          Po
        </Radio>
        <Radio value={false} id={`${formItemName}`}>
          Jo
        </Radio>
      </Radio.Group>

      <ReactQuill
        value={open ? value : ""}
        theme="snow"
        readOnly={!open}
        placeholder={placeholder}
        className={`quill-editor ${open ? "allow" : "notAllowed"}`}
        onChange={handleChange}
        modules={{
          toolbar: false,
        }}
      />
    </div>
  );
};

export default QuillEditor;
