// type editedClockOut or editedClockIn
export const checkWhetherEdited = (clocks, clockId, type) => {
  let test = clocks.filter((el) => el.clockingId === clockId);
  if (test.length > 0) {
    let t = test.filter((el) => el[type] === true);
    if (t.length > 0) {
      return true;
    }
    return false;
  }
  return false;
};
