import "./Chart.scss";
import { useEffect, useMemo, useState } from "react";
import FilterDateButtons from "./FilterDateButtons";
import { Alert } from "antd";
import { calculateEmployeeChart } from "../../../ListPrezenca/timesheet/utils/calculate";
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import { Chart } from "react-google-charts";
// import {Bar, BarChart, Cell, ResponsiveContainer, XAxis, YAxis, Tooltip, } from "recharts";

export const data = [
  ["", "Orët", { role: "style" }],
  ["Mungesë", 0, "#EA3943"],
  ["Raport", 0, "#FCC94A"],
  ["Regullisht", 0, "#1DA193"],
];

export const options = {
  height: 170,
  width: 300,
};
export const colors = ["#1DA193", "#FCC94A", "#EA3943", "#EA3943"];

const ChartSummary = ({ employeeChart, dataTitle, dataSrc }) => {
  const [timeframe, setTimeframe] = useState({
    start: moment().startOf("month").valueOf(),
    end: moment().endOf("month").valueOf(),
  });

  const { employeeRequests } = useSelector((state) => state.employeeRequests);

  const filterMonths = async (monthInstance) => {
    // console.log("month instance", monthInstance);
    if (monthInstance !== null) {
      setTimeframe({
        start: moment(monthInstance).startOf("month").valueOf(),
        end: moment(monthInstance).endOf("month"),
      });
    }
    if (monthInstance === null) {
      setTimeframe({
        start: moment().startOf("month").valueOf(),
        end: moment().endOf("month").valueOf(),
      });
    }
  };

  const chartData = useMemo(() => {
    if (!!dataSrc && employeeChart !== null && !!employeeRequests) {
      let value = processData(
        dataSrc,
        employeeChart,
        dataTitle,
        timeframe,
        employeeRequests
      );
      return value;
    }
  }, [dataSrc, employeeChart, timeframe, employeeRequests]);

  return (
    <div className="chart-container">
      <FilterDateButtons filterMonths={filterMonths} />
      <div
        style={{ width: "100%", height: "100%" }}
        className="cart-responsive"
      >
        {chartData?.length > 0 ? (
          // old chart was sometimes buggy
          // <ResponsiveContainer width="100%" height="100%">
          //   <BarChart
          //     data={chartData}
          //     margin={{
          //       top: 40,
          //       right: 20,
          //       left: -30,
          //       bottom: 5,
          //     }}
          //     barSize={20}
          //   >
          //     <XAxis
          //       dataKey="name"
          //       scale="point"
          //       padding={{ left: 40, right: 40 }}
          //       fontSize={12}
          //     />
          //     <YAxis />
          //     <Tooltip />
          //     <Bar dataKey="Orët" barSize={50}>
          //       {data.map((entry, index) => (
          //         <Cell key={`cell-${index}`} fill={colors[index % 20]} />
          //       ))}
          //     </Bar>
          //   </BarChart>
          // </ResponsiveContainer>
          <Chart
            chartType="ColumnChart"
            height="inherit"
            data={chartData}
            options={{
              width: "inherit",
              height: "inherit",
              bar: { groupWidth: "80%" },
              legend: "none",
              allowHtml: true,
              tooltip: {
                isHtml: true,
                trigger: "focus",
                textStyle: { color: "red" },
              },
            }}
          />
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 20,
            }}
          >
            <Alert description="Nuk ka te dhena" />
          </div>
        )}
      </div>
    </div>
  );
};

const processData = (dataArr, employeeChart, title, timeframe, requests) => {
  let res = calculateEmployeeChart(dataArr, employeeChart, timeframe, requests);
  let initial = [
    [
      "Turni",
      "Orët",
      { role: "style" },
      { type: "string", role: "tooltip", p: { html: true } },
      {
        sourceColumn: 0,
        role: "annotation",
        type: "string",
        calc: "stringify",
      },
    ],
  ];
  res?.map((el, index) =>
    initial.push([
      el?.label,
      parseFloat(el?.value.toFixed(2)),
      colors[index],
      `<div class="custom-tooltip">
      <span><b>${el?.label}</b></span>
      <span>Orët: <b>${el?.value.toFixed(2)}</b></span>
      </div>`,
      null,
    ])
  );
  return initial;
};

export default ChartSummary;
