import { useEffect, useMemo, useState } from "react";
import { Modal } from "antd";
import MondayButton from "../../commonComponents/MondayButton/MondayButton";
import {
  CheckCircleFilled,
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  StarFilled,
} from "@ant-design/icons";
import { getAnnouncements, postResponse } from "../utils/methods";
import { htmlParser } from "../../../utils";
import { useAuth } from "../../../authentication/authHelpers";
import { HolidayIcon } from "../../dashboard/assets";
import "./ModalAnnounce.scss";
import moment from "moment";
import { ModalXIcon } from "../../../assets/icons";
// TO DO : IF HOLIDAY IS TODAY, SHOW MODAL WITH HOLIDAY MESSAGE
function ModalAnnounce({
  title = "Announcement",
  // open = false,
  onCancel = () => {},
  onOk = () => {},
  children,
  previewMode = false,
  previewAnnouncement = {},
  setPreview = () => {},
}) {
  const [visibility, setVisible] = useState(false);
  const [saving, setSaving] = useState(false);
  const auth = useAuth();
  const [activeAnnouncement, setActiveAnnouncement] = useState({
    announcementTitle: "",
    announcementBody: "",
    announcementUrl: "",
    userResponses: {},
    announcementId: "",
    announcementCategory: "",
    createdBy: "",
  });

  // /(id, response, user)

  const recordResponse = async (response) => {
    setSaving(true);
    console.log("recordResponse", { response, auth, activeAnnouncement });
    await postResponse(
      activeAnnouncement?.announcementId,
      response,
      auth?.employeeId
    )
      .then((r) => {
        console.log("recordResponse", r);
        setSaving(false);
        setVisible(false);
      })
      .catch((e) => {
        console.log("recordResponse", e);
        setSaving(false);
        // setVisible(false);
      });
  };

  useEffect(() => {
    if (auth?.employeeId && !previewMode) {
      getAnnouncements().then((r) => {
        if (Array.isArray(r) && r?.length > 0) {
          let currentEmployeeDepartment =
              auth?.employee?.employeeDepartmentName,
            currentEmployeeTeam = auth?.employee?.employeeTeam;
          let activeOne =
            r
              ?.filter(
                (a) =>
                  a?.announcementTarget?.departments?.includes(
                    currentEmployeeDepartment
                  ) &&
                  a?.announcementTeam?.teams?.includes(currentEmployeeTeam) &&
                  moment().isSameOrBefore(moment(a?.endAnnouncementDate), "day")
              )
              ?.sort((a, b) => b.createdAt - a.createdAt)[0] || {};

          const myResponse = activeOne?.usersResponses?.[auth?.employeeId];
          if (!!myResponse) {
            if (myResponse?.response === "skip") {
              // console.log("myResponse", myResponse);
              setActiveAnnouncement(activeOne);
              setVisible(true);
            } else setVisible(false);
          } else {
            if (Object.keys(activeOne).length > 0) {
              setActiveAnnouncement(activeOne);
              setVisible(true);
            }
          }
        }
      });
    }
  }, [auth?.employeeId, previewMode]);
  useEffect(() => {
    if (previewMode) {
      setActiveAnnouncement(previewAnnouncement);
      setVisible(true);
    }
  }, [previewMode]);

  return (
    <div>
      <Modal
        open={visibility}
        width={800}
        wrapClassName="modal-announce"
        closable={getClosable(
          previewMode,
          activeAnnouncement?.announcementCategory
        )}
        closeIcon={<ModalXIcon />}
        // centered
        title={activeAnnouncement?.announcementTitle}
        maskClosable={previewMode}
        onCancel={() => {
          setPreview(false);
        }}
        footer={
          previewMode ? null : (
            <DynamicFooterResponses
              {...{
                saving,
                announcementId: activeAnnouncement?.announcementId,
                announcementCategory: activeAnnouncement?.announcementCategory,
                userResponses: activeAnnouncement?.userResponses,
                auth,
                recordResponse,
              }}
            />
            // <div className="new-announcemenet-footer">
            // 	<MondayButton
            // 		// onClick={() => setVisible(false)}
            // 		className={saving ? "mondayButtonGrey" : "mondayButtonRed"}
            // 		Icon={<CloseCircleOutlined />}
            // 		disabled={saving}
            // 		onClick={() => recordResponse("refuse")}
            // 	>
            // 		Refuzo
            // 	</MondayButton>
            // 	<MondayButton
            // 		className={saving ? "mondayButtonGrey" : "mondayButtonBlue"}
            // 		Icon={<ClockCircleOutlined />}
            // 		disabled={saving}
            // 		onClick={() => recordResponse("skip")}
            // 	>
            // 		Shfaq me vone
            // 	</MondayButton>
            // 	<MondayButton
            // 		type="primary"
            // 		disabled={saving}
            // 		className={saving ? "mondayButtonGrey" : "mondayButtonGreen"}
            // 		onClick={() => recordResponse("confirm")}
            // 		Icon={<CheckCircleOutlined />}
            // 		// onClick={onSubmit}
            // 	>
            // 		Konfirmo
            // 	</MondayButton>
            // </div>
          )
        }
        // onCancel={() => setVisible(false)}
      >
        {htmlParser(activeAnnouncement?.announcementBody)}
      </Modal>
    </div>
  );
}

export default ModalAnnounce;

export const DynamicFooterResponses = ({
  saving,
  announcementId,
  announcementCategory,
  userResponses,
  auth,
  recordResponse,
}) => {
  const buttons = useMemo(() => {
    if (announcementCategory === "importantAnnouncement") {
      return (
        <div className="new-announcemenet-footer">
          <MondayButton
            className={saving ? "mondayButtonGrey" : "mondayButtonBlue"}
            Icon={<ClockCircleOutlined />}
            disabled={saving}
            onClick={() => recordResponse("skip")}
          >
            Shfaq me vone
          </MondayButton>
          <MondayButton
            type="primary"
            disabled={saving}
            className={saving ? "mondayButtonGrey" : "mondayButtonGreen"}
            onClick={() => recordResponse("confirm")}
            Icon={<CheckCircleOutlined />}
            // onClick={onSubmit}
          >
            Konfirmo
          </MondayButton>
        </div>
      );
    } else if (announcementCategory === "needAction") {
      return (
        <div className="new-announcemenet-footer">
          <MondayButton
            // onClick={() => setVisible(false)}
            className={saving ? "mondayButtonGrey" : "mondayButtonRed"}
            Icon={<CloseCircleOutlined />}
            disabled={saving}
            onClick={() => recordResponse("refuse")}
          >
            Refuzo
          </MondayButton>
          <MondayButton
            className={saving ? "mondayButtonGrey" : "mondayButtonBlue"}
            Icon={<ClockCircleOutlined />}
            disabled={saving}
            onClick={() => recordResponse("skip")}
          >
            Shfaq me vone
          </MondayButton>
          <MondayButton
            type="primary"
            disabled={saving}
            className={saving ? "mondayButtonGrey" : "mondayButtonGreen"}
            onClick={() => recordResponse("confirm")}
            Icon={<CheckCircleOutlined />}
            // onClick={onSubmit}
          >
            Konfirmo
          </MondayButton>
        </div>
      );
    } else if (announcementCategory === "otherWish") {
      return (
        <div
          className="new-announcemenet-footer"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <MondayButton
            className={saving ? "mondayButtonGrey" : "mondayButtonBlue"}
            Icon={<StarFilled />}
            disabled={saving}
            onClick={() => recordResponse("confirm")}
          >
            Mbyll dritaren
          </MondayButton>
        </div>
      );
    }
  }, [announcementCategory, saving]);
  return <>{buttons}</>;
};

const getClosable = (previewMode, category) => {
  // console.log("condition", previewMode || category === "otherWish");
  return previewMode || category === "otherWish" ? true : false;
};
