import Modal from "antd/lib/modal/Modal";
import "./PaisjetOraritLogs.scss";
import { ModalXIcon } from "../../../../assets/icons";
import { Button } from "antd";
import { htmlParser } from "../../../../utils";
import AgGridComponent from "../../../AG-grid/AgGridComponent";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";

const PaisjetOraritLogs = ({ data, isVisible, setIsVisible, logsName }) => {
  const [tableData, setTableData] = useState([]);
  const authorFormatter = (params) => {
    if (params.value) {
      const author = `${params.value.employeeFirstName} ${params.value.employeeLastname}`;
      return author;
    }
    return "";
  };

  const dateFormatter = (params) => {
    if (params.value) {
      const formattedDate = moment(params.value).format("DD/MM/YYYY HH:mm ");
      return formattedDate;
    }
    return "";
  };

  const columnDefs = [
    {
      headerName: "Autori",
      field: "author",
      valueFormatter: authorFormatter,
    },
    {
      headerName: "Pajisja e Orarit",
      field: "parentName",
    },
    {
      headerName: "Koha e ndryshimit",
      field: "changeDate",
      valueFormatter: dateFormatter,
    },
    {
      headerName: "Fusha  e ndryshuar",
      field: "field",
    },
    {
      headerName: "Activity",
      field: "activity",
      cellRendererFramework: (params) => {
        return htmlParser(params.value);
      },
    },
    {
      headerName: "Si Ishte",
      field: "previousData",
      cellRendererFramework: (params) => {
        return htmlParser(params.value);
      },
    },
    {
      headerName: "Si u Be",
      field: "currentData",
      cellRendererFramework: (params) => {
        return htmlParser(params.value);
      },
    },
  ];

  const pushimetColumnDefs = [
    {
      headerName: "Autori",
      field: "author",
      valueFormatter: authorFormatter,
    },
    {
      headerName: "Titulli",
      field: "title",
    },
    {
      headerName: "Koha e ndryshimit",
      field: "changeDate",
      valueFormatter: dateFormatter,
    },
    {
      headerName: "Fusha  e ndryshuar",
      field: "field",
    },
    {
      headerName: "Activity",
      field: "activity",
      cellRendererFramework: (params) => {
        return htmlParser(params.value);
      },
    },
    {
      headerName: "Si Ishte",
      field: "previousData",
      cellRendererFramework: (params) => {
        if (typeof params.value === "number") {
          return <span>{params.value}</span>;
        } else {
          return htmlParser(params.value);
        }
      },
    },
    {
      headerName: "Si u Be",
      field: "currentData",
      cellRendererFramework: (params) => {
        if (typeof params.value === "number") {
          return <span>{params.value}</span>;
        } else {
          return htmlParser(params.value);
        }
      },
    },
  ];

  const onFirstDataRendered = useCallback((param) => {
    param.columnApi.autoSizeAllColumns(false);
  }, []);

  useEffect(() => {
    let values = [];
    if (logsName === "Turnet") {
      if (data?.length !== 0) {
        data?.forEach((item) => {
          if (item?.shiftLogs && item?.shiftLogs.length !== 0) {
            item?.shiftLogs?.forEach((e) => {
              e.forEach((i) => {
                values.push({ ...i, parentName: item?.shiftName });
              });
            });
          }
        });
      }
    } else if (logsName === "Pushimet" || logsName === "Mënyrat e Pagesës") {
      if (data?.length !== 0) {
        data?.forEach((item) => {
          if (item?.logs && item?.logs.length !== 0) {
            item?.logs?.forEach((e) => {
              e.forEach((i) => {
                values.push({ ...i, title: item?.name });
              });
            });
          }
        });
      }
    } else if (logsName === "Pajisjet") {
      if (data?.fieldOptions?.length !== 0) {
        data?.fieldOptions.forEach((item) => {
          if (item?.logs && item?.logs?.length !== 0) {
            item?.logs?.forEach((e) => {
              e.forEach((i) => {
                values.push({ ...i, parentName: item?.name });
              });
            });
          }
        });
      }
    } else if (logsName === "InfoKompanis") {
      if (Object.keys(data[0]).length !== 0) {
        let dataKeys = Object.keys(data[0]);
        dataKeys.forEach((key) => {
          if (!!data[0][key]?.logs) {
            data[0][key]?.logs.forEach((item) => {
              item.forEach((e) => {
                values.push({ ...e, title: key });
              });
            });
          }
        });
      }
    }
    setTableData(values);
  }, [isVisible]);

  return (
    <Modal
      className="paisjetOraritLogs"
      open={isVisible}
      onCancel={() => setIsVisible(false)}
      destroyOnClose
      title={`${logsName} Logs`}
      closeIcon={<ModalXIcon />}
      width={1000}
      footer={[
        <Button className="decline" onClick={() => setIsVisible(false)}>
          Mbyllni faqen
        </Button>,
      ]}
    >
      <div
        className="ag-theme-alpine"
        style={{ width: "100%", height: "100%", borderRadius: "5px" }}
      >
        <AgGridComponent
          columnDefs={
            logsName === "Pushimet" ||
            logsName === "InfoKompanis" ||
            logsName === "Mënyrat e Pagesës"
              ? pushimetColumnDefs
              : columnDefs
          }
          customDefaultColDef={{ flex: undefined }}
          rowData={tableData}
          overlayLoadingTemplate="Historiku është bosh!"
          autoSizeStrategy={{ type: "fitCellContents" }}
          onFirstDataRendered={onFirstDataRendered}
        />
      </div>
    </Modal>
  );
};

export default PaisjetOraritLogs;
