import { Divider } from "antd";
import { FileTextFilled } from "@ant-design/icons";
import { useRef, useState } from "react";
import ConfirmationModal from "../../../ConfirmationModal/ConfirmationModal";
import MondayButton from "../../../commonComponents/MondayButton/MondayButton";
import { DocConfigIcon, Tick } from "../../assets";
import {
  CustomFields,
  DocStatusesModal,
  FormPreview,
  SquircleBackButton,
  ViewLogoHeader,
} from "../../components";
import {
  views,
  VIEW_TITLE,
  VIEW_SUBTITLE,
} from "../../documentationConfigurationData";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import { expirationDateObject } from "../utils/expirationDateObject";
import { useStateCallback } from "../hooks/useStateCallback";
import { updateCategory } from "../../utils/dispatchFunctions";
import "./DocumentationPreview.scss";
import { useDocumentationContext } from "../../context";
import PermissionsModal from "./PermissionsModal/PermissionsModal";
import { IconLocked } from "../../../../assets/icons";

const CANCEL_TEXT =
  "Ke ndryshime të paruajtura, a je i sigurt që dëshiron t'i anulosh?";

const DocumentationPreview = () => {
  const {
    selectedCategory = {},
    setActiveView,
    selectedDocumentationName,
    curryDispatch,
  } = useDocumentationContext();

  const [confirmationModalVisible, setConfirmationModalVisible] =
    useState(false);
  const [editedCategory, setEditedCategory] = useStateCallback(
    cloneDeep(selectedCategory)
  );

  const editedCategoryRef = useRef();
  editedCategoryRef.current = editedCategory;

  const [docStatusesModalVisible, setDocStatusesModalVisible] = useState();
  const [docPermissionsModalVisible, setDocPermissionsModalVisible] =
    useState(false);

  const { documentationsAvailable = [], customsFieldsAvailable = [] } =
    editedCategory;

  const selectedDocumentation =
    documentationsAvailable?.find(
      ({ docName: arrDocName }) => arrDocName === selectedDocumentationName
    ) || {};

  const { docName, docObject = [], expirationDate } = selectedDocumentation;

  const previewFieldsJSON = !!expirationDate
    ? [expirationDateObject, ...docObject]
    : docObject;

  const goBack = () => {
    setActiveView(views.CATEGORY_VIEW);
  };

  const onBack = () => {
    isEqual(editedCategory?.docObject, selectedCategory?.docObject)
      ? goBack()
      : setConfirmationModalVisible(true);
  };
  const onSave = async () =>
    await curryDispatch(updateCategory(editedCategoryRef.current));

  return (
    <>
      <div className="documentationPreviewContainer">
        <div className="coreInfoContainer">
          <div className="header">
            <SquircleBackButton className="backButton" onClick={onBack} />
            <ViewLogoHeader
              icon={DocConfigIcon}
              title={VIEW_TITLE}
              subtitle={VIEW_SUBTITLE}
              className="previewLogoHeader"
            />
            <Divider type="vertical" className="headerDivider" />
            <div className="categoryTitle">{docName}</div>
          </div>
          <CustomFields
            {...{
              customsFieldsAvailable,
              docObject,
              expirationDate,
              selectedDocumentation,
              setEditedCategory,
            }}
          />

          <MondayButton
            {...{
              className: "docStatusesBtn",
              Icon: <FileTextFilled />,
              onClick: () => {
                setDocStatusesModalVisible(true);
              },
            }}
          >
            Statuset e Dokumentacionit
          </MondayButton>
          <MondayButton
            {...{
              className: "docStatusesBtn",
              Icon: <IconLocked width={20} height={20} />,
              onClick: () => {
                setDocPermissionsModalVisible(true);
              },
            }}
          >
            Aksesi në Google Drive
          </MondayButton>
        </div>
        <FormPreview fieldJSON={previewFieldsJSON} />
        <MondayButton
          onClick={onSave}
          Icon={
            <div>
              <Tick className="submitIcon" />
            </div>
          }
          className="saveButton"
        >
          Ruaj
        </MondayButton>
      </div>
      <ConfirmationModal
        {...{
          visible: confirmationModalVisible,
          setVisible: setConfirmationModalVisible,
          text: CANCEL_TEXT,
          onConfirm: goBack,
        }}
      />
      <DocStatusesModal
        {...{
          visible: docStatusesModalVisible,
          setVisible: setDocStatusesModalVisible,
          selectedDocumentation,
          setEditedCategory,
          onSave,
        }}
      />
      <PermissionsModal
        {...{
          visible: docPermissionsModalVisible,
          setVisible: setDocPermissionsModalVisible,
          setEditedCategory,
          editedCategory,
          docName: selectedDocumentationName,
          currentPermissions: selectedDocumentation?.docPermissions,
          onSave,
        }}
      />
    </>
  );
};

export default DocumentationPreview;
