import { combineReducers } from "redux";
import clockingsReducer from "./clockings";
import departmentsReducer from "./departments";
import employeesReducer from "./employees";
import programFieldsReducer from "./programFields";
import userRightsReducer from "./userRightsReducer";
import usersReducer from "./users";
import documentationsReducer from "./documentationsReducer";
import accessTokenReducer from "./accessTokenReducer";
import employeeRequestsReducer from "./employeeRequests";
import earningsReducer from "./earnings";
import deductionsReducer from "./deductions";
import prepaymentsReducer from "./prepayments";
import wagesReducer from "./wages";
import currentPrepaymentsReducer from "./currentPrepaymentsReducer";
import problematikatReducer from "./problematikatReducer";
import { customReducer } from "../../hooks/useRedux";
import candidatesReducer from "./candidatesReducer";
import hotCredentialsReducer from "./hotCredentialsReducer";

const reducers = combineReducers({
	userRights: userRightsReducer,
	programFields: programFieldsReducer,
	departments: departmentsReducer,
	documentations: documentationsReducer,
	accessToken: accessTokenReducer,
	employeesList: employeesReducer,
	usersList: usersReducer,
	clockings: clockingsReducer,
	employeeRequests: employeeRequestsReducer,
	earnings: earningsReducer,
	deductions: deductionsReducer,
	prepayments: prepaymentsReducer,
	wages: wagesReducer,
	currentPrepayments: currentPrepaymentsReducer,
	problematikat: problematikatReducer,
	candidates: candidatesReducer,
	hotCredentials: hotCredentialsReducer,

	customReducer,
});
export default reducers;
