// import { message } from "antd";
import { message } from "antd";
import { API } from "aws-amplify";
import moment from "moment-timezone";
import swal from "sweetalert";
import {
  requestAllPages,
  requestData,
} from "../../../../../../helpers/API/RequestFactory";
import { dayCalculateFactory } from "../../../../../Pagat/PaySub/employeeCalc/dayCalculateFactory";
import axios from "axios";
export const editShift = async (
  clockingId,
  shiftData,
  employeeId,
  adminLog
) => {
  // console.log("to send", moment(shiftData.clockInDate).format("DD/MM/YYYY HH:mm"));
  // console.log("Adming Log", adminLog);
  let valid = await validateCrashingShifts(shiftData, employeeId, clockingId);
  const res = await axios.get("https://api.ipify.org/?format=json");
  const tempResp = adminLog?.ipResponse || {};
  tempResp.IPv4 = res?.data?.ip || "";
  // console.log("valid", valid);

  let clockInDate = moment(shiftData.clockInDate);
  let clockOutDate = moment(shiftData.clockOutDate);

  clockInDate.set({
    hour: moment(clockInDate).get("hour"),
    minute: moment(clockInDate).get("minute"),
    second: "00",
  });
  clockOutDate.set({
    hour: moment(clockOutDate).get("hour"),
    minute: moment(clockOutDate).get("minute"),
    second: "00",
  });

  if (valid.value === true) {
    let amounts = await dayCalculateFactory({
      ...shiftData,
      employeeId,
      clockingId,
      clockInDate: moment(clockInDate).valueOf(),
      clockOutDate: moment(clockOutDate).valueOf(),
    });
    console.log({ amounts });
    return API.put("clocking", `/clocking/${clockingId}`, {
      body: {
        ...shiftData,
        clockInDate: moment(clockInDate).valueOf(),
        clockOutDate: moment(clockOutDate).valueOf(),
        totalities: amounts || {},
      },
    })
      .then(
        async (val) =>
          await API.post("clockingLogs", "/clockingLogs", {
            body: {
              clockingId: clockingId,
              createdAt: moment().valueOf(),
              username: adminLog.username,
              note: adminLog.note,
              oldShift: [adminLog.oldShift],
              ipResponse: tempResp,
              newShift: [
                {
                  clockInDate: moment(clockInDate).valueOf(),
                  clockOutDate: moment(clockOutDate).valueOf(),
                  dayType: shiftData?.dayType,
                },
              ],
            },
          }).then((res) => console.log("Success log", res))
      )
      .catch((err) => {
        console.log(err);
      });
  } else {
    return Promise.reject([valid.crashShift, shiftData]);
  }
};
export const getShift = async (clockingId) => {
  return await API.get("clocking", `/clocking/${clockingId}`);
};
export const validateCrashingShifts = async (
  shiftData,
  employeeId,
  clockingId
) => {
  console.log("Shift Data", shiftData);
  let clockings;
  let res = { value: true, crashShift: {} };
  let startTimeInstance = moment(shiftData.clockInDate)
    .startOf("day")
    .valueOf();
  let endTimeInstance = moment(shiftData.clockInDate).endOf("day").valueOf();
  await requestData("clocking")
    .then((res) => {
      clockings = res.filter(
        (el) =>
          el.employeeId === employeeId &&
          el.clockInDate > startTimeInstance &&
          el.clockInDate < endTimeInstance &&
          el.clockingId !== clockingId
      );
    })
    .catch((err) => console.log("error on requestData", err));
  if (clockings.length > 0) {
    clockings.forEach((el) => {
      if (
        (el.clockInDate <= shiftData.clockInDate &&
          shiftData.clockInDate <= el.clockOutDate) ||
        (el.clockInDate <= shiftData.clockOutDate &&
          shiftData.clockOutDate <= el.clockOutDate) ||
        (shiftData.clockInDate <= el.clockInDate &&
          shiftData.clockOutDate >= el.clockInDate) ||
        (el.clockInDate <= shiftData.clockInDate &&
          el?.clockOutDate === null &&
          !shiftData.clockOutDate)
      ) {
        res = { value: false, crashShift: el }; // new edited shift is between an old shift, return false & shift (one shift)
      }
    });
    return res;
  } else {
    return { value: true };
  }
};
export const approveShift = async (clockingId, adminLog) => {
  return new Promise(async (resolve, reject) => {
    // setTimeout(async () => {
    let curr = await API.get("clocking", `/clocking/${clockingId}`).then(
      (res) => res
    );
    let amounts = await dayCalculateFactory({
      ...curr,
      clockInDate: curr?.clockInDate,
      clockOutDate: moment(curr?.clockOutDate).valueOf(),
    });
    amounts.adminLog = adminLog;
    await API.put("clocking", `/clocking/${clockingId}`, {
      body: {
        approved: true,
        totalities: amounts || {},
      },
    });
    resolve({ totalities: amounts });

    // }, 700);
  });
};
export const disapproveShift = async (clockingId, adminLog) => {
  return await API.put("clocking", `/clocking/${clockingId}`, {
    body: {
      approved: false,
      totalities: {
        adminLog: adminLog,
      },
    },
  });
};
//List of Promises
export const approveShifts = async (shiftsArr, adminLog) => {
  // console.log(shiftsArr);
  let promises;
  if (shiftsArr?.length > 0 && shiftsArr?.length > 10) {
    promises = await shiftsArr.map(async (el) => {
      return await approveShift(el.clockingId, adminLog);
    });

    console.log("promises", promises);

    return await Promise.all(promises).then(() =>
      swal({
        title: `${
          shiftsArr.length === 1
            ? "Turni u aprovua me sukses!"
            : "Turnet u aprovuan me sukses!"
        }`,
        icon: "success",
        className: "custum-swal",
      })
    );
  } else {
    promises = shiftsArr.map(async (el) => {
      return await approveShift(el.clockingId, adminLog);
    });
    return await Promise.all(promises).then(() =>
      swal({
        title: `${
          shiftsArr.length === 1
            ? "Turni u aprovua me sukses!"
            : "Turnet u aprovuan me sukses!"
        }`,
        icon: "success",
        className: "custum-swal",
      })
    );
  }
};
export const disapproveShifts = (shiftsArr, adminLog) => {
  // console.log(shiftsArr);
  let promises = shiftsArr.map(
    async (el) => await disapproveShift(el.clockingId, adminLog)
  );
  Promise.all(promises).then(() =>
    swal({
      title: `${
        shiftsArr.length === 1
          ? "Turnit ju hoq aprovimi me sukses!"
          : "Turnet u hoqen aprovimet me sukses!"
      }`,
      icon: "success",
      className: "custum-swal",
    })
  );
};
// Logs for Shift!
export const getLogsPerShift = async (clockingId) => {
  // const config = {
  // 	queryStringParameters: {
  // 		tableName: process.env.NODE_ENV === "production" ? "clockingLogs-prod" : "clockingLogs",
  // 		filterKey: "clockingId",
  // 		filterValue: `${clockingId}`,
  // 	},
  // };
  return await API.get("clockingLogs", "/clockingLogs").then((res) =>
    res?.filter((el) => el?.clockingId === clockingId)
  );
};
export const getAllUsers = async () => {
  return await API.get("users", "/users").then((res) => res);
};
export const deleteShift = async (clockingId) => {
  return await API.del("clocking", `/clocking/${clockingId}`).then(() =>
    message.info("Deleted Shift")
  );
};
export const deleteClockIns = async (shiftsArr) => {
  // console.log(shiftsArr);

  await shiftsArr.forEach(async (el, idx) => {
    if (idx === shiftsArr.length - 1) {
      setTimeout(async () => {
        await deleteShift(el.clockingId);
      }, 500);
      swal({
        title: `${
          shiftsArr.length === 1
            ? "Turni u fshi me sukses!"
            : "Turnet u fshine me sukses!"
        }`,
        icon: "success",
        className: "custum-swal",
      });
    } else {
      setTimeout(async () => {
        await deleteShift(el.clockingId);
      }, 500);
    }
  });

  // let promises = await shiftsArr.map(async (el) =>
  // 	setTimeout(async () => {
  // 		return await deleteShift(el.clockingId);
  // 	}, 500)
  // );
  // Promise.all(promises).then(() =>
  // 	swal({
  // 		title: `${shiftsArr.length === 1 ? "Turni u fshi me sukses!" : "Turnet u fshine me sukses!"}`,
  // 		icon: "success",
  // 		className: "custum-swal",
  // 	})
  // );
};
