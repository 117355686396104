import { Modal, Button, Form, message, Input } from "antd";
import React, { useEffect } from "react";
import { API } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import { useProgramFields } from "../../../../hooks/useProgramFields";
import { RichTextEditor } from "../../../commonComponents";
import { useAuth } from "../../../../authentication/authHelpers";
const programFieldsId = {
	MENYRAT_PAGESES: "01d6ec94-5ddf-4f48-9fdc-86a39a7289d9",
};
function MenyratPgaesesModal({
	setVisible,
	visible,
	request,
	setTableData,
	setRequests,
	tableData,
	dataLista,
	shouldShowError,
	setShouldShowError,
	socketHandler,
}) {
	const [form] = Form.useForm();
	const { MENYRAT_PAGESES } = programFieldsId;
	const { [MENYRAT_PAGESES]: records } = useProgramFields();
	const { userAccess } = useAuth();

	const username = {
		userId: userAccess[0].userSub,
		employeeFirstName: userAccess[0].given_name,
		employeeLastname: userAccess[0].family_name,
	};



	//function to compare 2 object and get the changes
	function compareObjects(previousObject, currentObject) {
		const keys1 = Object.keys(previousObject);
		const keys2 = Object.keys(currentObject);
		let changesArray = [];

		const commonKeys = keys1.filter((key) => keys2.includes(key));
		if (commonKeys.length === 0) {
		}
		commonKeys.forEach((key) => {
			if (typeof currentObject[key] === "object") {
			} else {
				if (previousObject[key] !== currentObject[key]) {
					let changedData = {
						id: uuidv4(),
						activity: "Ndryshuar",
						author: username,
						changeDate: Date.now(),
						field: key,
						previousData: previousObject[key],
						currentData: currentObject[key],
					};
					changesArray.push(changedData);
				}
			}
		});
		if (changesArray !== 0) {
			return changesArray;
		} else {
			return false;
		}
	}

	const handleSave = () => {
		if (!dataLista) {
			const formData = { ...form.getFieldsValue(), id: uuidv4() };
			form.resetFields();

			API.put("programFields", `/programFields/${MENYRAT_PAGESES}`, {
				body: {
					fieldOptions: [...request.fieldOptions, formData],
				},
			}).then(() => {
				/**
				 * * @EneaXharau - Added socket emitter
				 * * .send() takes -> { request: String, body: any} -> stringified
				 */
				// if (socketHandler.readyState === socketHandler.OPEN) {
				// 	socketHandler.send(JSON.stringify({ request: "wage_added", body: "a wage was added" }));
				// }

				message.success("Të dhënat u regjistruan me sukses !", 10);
				setTableData([...tableData, formData]);
				setVisible(false);
			});
			let field = [...request.fieldOptions, formData];
			setRequests((prev) => ({ ...prev, fieldOptions: field }));
		} else {
			let getData = { ...form.getFieldsValue(), id: !!dataLista?.id ? dataLista.id : uuidv4() };
			let logs = compareObjects(dataLista, getData)
			getData = { ...getData, logs: !!dataLista?.logs ? [...dataLista.logs, logs] : [] }
			API.put("programFields", "/programFields/01d6ec94-5ddf-4f48-9fdc-86a39a7289d9", {
				body: {
					fieldOptions: [...request.fieldOptions.filter((el) => el.id !== dataLista.id), getData],
				},
			}).then(() => {
				/**
				 * * @EneaXharau - Added socket emitter
				 * * .send() takes -> { request: String, body: any} -> stringified
				 */
				// if (socketHandler.readyState === socketHandler.OPEN)
				// 	socketHandler.send(JSON.stringify({ request: "wage_added", body: "a wage was added" }));
				message.success("Të dhënat u regjistruan me sukses !", 10);
				form.resetFields();
				setTableData([...request.fieldOptions.filter((el) => el.id !== dataLista.id), getData]);
				let field = [...request.fieldOptions.filter((el) => el.id !== dataLista.id), getData];
				setRequests((prev) => ({ ...prev, fieldOptions: field }));
				setVisible(false);
				setShouldShowError(false);
			});
		}
	};
	useEffect(() => {
		if (!dataLista) return;
		form.setFieldsValue(dataLista);
	}, [dataLista]);

	return (
		<div>
			<Modal
				title="Menyrat e pagesës"
				className="paisjet-per-orarin-modal"
				centered
				visible={visible}
				onOk={() => setVisible(false)}
				onCancel={() => setVisible(false)}
				width={550}
				footer={[
					<Button onClick={() => setVisible(false)} className="Mbyll-btn">
						Anuloje
					</Button>,
					<Button key="save" form="menyratPageses" htmlType="submit" className="ruaj-btn">
						Ruaj ndryshimet
					</Button>,
				]}
			>
				<Form name="menyratPageses" onFinish={handleSave} form={form}>
					<h3>Mënyra e pagesës:</h3>
					<Form.Item
						name="name"
						rules={[
							{
								required: true,
								message: "Ju lutem shkruani mënyra e pagesës!",
							},
							{
								validator: (_, value) =>
									shouldShowError &&
										!!value &&
										records.some(({ name }) => name.toLowerCase() === value.toLowerCase())
										? Promise.reject(new Error("Ekziston nje menyre pagese e till!"))
										: Promise.resolve(),
							},
						]}
					>
						<Input placeholder="Mënyra e pagesës" />
					</Form.Item>
					<h3>Përshkrimi për mënyrën e pagesës:</h3>
					<Form.Item
						name="description"
						rules={[
							{
								required: true,
								message: "Ju lutem përshkruani mënyrën e pagesës!",
							},
						]}
					>
						{/* <Input placeholder="Përshkrimi për mënyrën e pagesës" /> */}
						<RichTextEditor className={"modal-quill-edit"} />
					</Form.Item>
					<h3>Formula për llogaritjen:</h3>
					<Form.Item
						name="formula"
						rules={[
							{
								required: true,
								message: "Ju lutem shkruani formulën për llogaritjen!",
							},
						]}
					>
						<Input placeholder="Formula për llogaritjen e pagesës" />
					</Form.Item>
				</Form>
			</Modal>
		</div>
	);
}

export default MenyratPgaesesModal;
