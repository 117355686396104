import moment from "moment-timezone";
export const processClockings = (clockings, employees) => {
  let floorTime = moment("13:31pm", "HH:mm:ss").valueOf();
  let res = [];
  if (!!clockings && Array.isArray(clockings) && !!employees) {
    clockings
      .filter(
        (item) =>
          moment(item.clockInDate).format("DD/MM/YYYY") ===
          moment().format("DD/MM/YYYY")
      )
      .forEach((element) => {
        let employeeFirstName, employeeLastName;
        const test = employees?.find(
          (employee) => employee.employeeId === element?.employeeId
        );
        if (!!test) {
          employeeLastName = test?.employeeLastName || "";
          employeeFirstName = test?.employeeFirstName || "";
        }
        if (!!element?.clockOutDate) {
          res.push({
            ...element,
            employeeInfo: {
              name: employeeFirstName,
              surname: employeeLastName,
            },
            lateInfo: "clockOut",
            color: getColor("clockOut"),
            inOrOut: "out",
            time: element.clockOutDate,
          });
        }
        let minutesDiff = moment(element.clockInDate).diff(
          moment(floorTime),
          "minutes",
          true
        );
        if (minutesDiff < 0) {
          res.push({
            ...element,
            employeeInfo: {
              name: employeeFirstName,
              surname: employeeLastName,
            },
            lateInfo: "clockIn",
            color: getColor("clockIn"),
            inOrOut: "in",
            time: element.clockInDate,
          });
        } else if (minutesDiff > 0 && minutesDiff <= 5) {
          res.push({
            ...element,
            employeeInfo: {
              name: employeeFirstName,
              surname: employeeLastName,
            },
            lateInfo: "lateClockIn",
            color: getColor("lateClockIn"),
            inOrOut: "in",
            time: element.clockInDate,
          });
        } else if (minutesDiff > 5) {
          res.push({
            ...element,
            employeeInfo: {
              name: employeeFirstName,
              surname: employeeLastName,
            },
            lateInfo: "lateClockIn",
            color: getColor("extraLateClockIn"),
            inOrOut: "in",
            time: element.clockInDate,
          });
        }
      });
    return res.sort((a, b) => b.time - a.time);
  }
  return res;
};

const getColor = (type) => {
  let colors = {
    clockIn: "#1DA193",
    lateClockIn: "#FCC94A",
    extraLateClockIn: "#EA3943",
    clockOut: "#E8E8E8",
  };
  return colors[type];
};
