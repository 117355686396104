import { updateArrayElement } from "./utils/updateArrayElement";
import { actions } from "./actions";

export const docConfigReducer = (state, { type, payload }) => {
  switch (type) {
    case actions.SET_CATEGORIES:
      return payload;

    case actions.CREATE_CATEGORY:
      return [...state, payload];

    case actions.DELETE_CATEGORY:
      return state.filter(({ categoryName }) => categoryName !== payload);

    case actions.UPDATE_CATEGORY:
      return updateArrayElement(state, payload, "categoryName");
    default:
      throw new Error("docConfigReducer needs a type");
  }
};
