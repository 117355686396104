import { Tooltip } from "antd";
import moment from "moment-timezone";
import React from "react";
import { ApprovedIcon, UnapprovedIcon } from "../../../assets/icons";

function MobileViewTimeSheet({ toVisualize, totalHours }) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        marginBottom: 10,
        columnGap: 15,
      }}>
      <Tooltip
        placement="top"
        title={
          !toVisualize?.some((el) => el?.approved === false)
            ? "Aprovuar"
            : "I Pa Aprovuar"
        }>
        <span
          className="approved-ind-icons"
          style={{
            backgroundColor:
              toVisualize?.length > 0 &&
              toVisualize?.some((el) => el?.approved === false)
                ? "#EA3943"
                : "#1DA193",
          }}>
          {" "}
          {toVisualize?.length > 0 &&
          !toVisualize?.some((el) => el?.approved === false) ? (
            <ApprovedIcon />
          ) : (
            <UnapprovedIcon />
          )}{" "}
        </span>
      </Tooltip>
      <span className="clock-out-ind">
        {" "}
        {toVisualize?.length > 0 &&
          moment(toVisualize[toVisualize.length - 1]?.clockOutDate).format(
            "HH:mm"
          )}
      </span>
      <span className="total-hours">Totali: {totalHours || 0} orë</span>{" "}
    </div>
  );
}

export default MobileViewTimeSheet;
