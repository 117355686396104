import React, { useState, useEffect } from "react";
import { Row, Col, message, Popover, Alert } from "antd";
import { FlexLogo, IconPerdoruesi, IconEmail } from "../../assets/icons";
import "./auth-scss/forgotPassword.scss";
import { Form, Input, Button } from "antd";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { emailRegex, passwordRegex } from "../dashboard/utils/regex";
import { EyeFilled, InfoCircleOutlined, LockFilled } from "@ant-design/icons";
import swal from "sweetalert";
import { useAuth } from "../../authentication/authHelpers";
import { forceResetUserDisable } from "../punonjesit/EmployeeView/Personal/User/helpers/apiHelper";

function ResetPassword() {
  const [login, setLogin] = useState({ log_in: "" });
  const [email, setEmail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [sentCode, setSentCode] = useState(false);
  const [successChange, setSuccessChange] = useState(false);
  const navigate = useNavigate();
  const auth = useAuth();

  const validateReset = (code, passw, passw2) => {
    if (code !== "" && passw === passw2) {
      if (passw) return true;
    } else {
      return false;
    }
  };

  const onFinish = async (values) => {
    // console.log("Received values of form: ", values);
    setLoading(true);
    setEmail(values?.username);
    let identityId = auth?.userAccess[0]?.identityId;
    let forceReset = auth?.userAccess[0]?.forceReset;

    if (!!identityId && forceReset === true) {
      await Auth.currentAuthenticatedUser()
        .then((user) => {
          return Auth.changePassword(
            user,
            values?.oldPassword,
            values?.confirmPassword
          );
        })
        .then(async (success) => {
          await forceResetUserDisable(identityId);
          message.success("Fjalekalimi u ndryshua me sukses!");
          navigate("/kryefaqja");
        })
        .catch((err) => {
          message.error("Ndodhi nje gabim ne ndryshimin e fjalekalimit!");
          console.log(err);
        });
    }

    // await Auth.forgotPassword(values.username).then(
    //   (result) => {
    //     message.success("Kodi i sigurisë është dërguar në emailin tuaj. ");
    //     //   console.log("result ", result);
    //     setSentCode(true);
    //   },
    //   (err) => {
    //     console.log("error sending code", err);
    //     message.error("Nodhi një gabim!");
    //   }
    // );
    // Auth.changePassword();
    setSentCode(true);
  };

  const onReset = async (values) => {
    const { verification, password, confirmPassword } = values;
    let validation = validateReset(verification, password, confirmPassword);
    if (validation && email !== null) {
      await Auth.forgotPasswordSubmit(email, verification, password)
        .then((succ) => {
          setSuccessChange(true);
          swal({
            title: `Fjalëkalimi u ndryshua me sukses! ${email}`,
            icon: "success",
            className: "custum-swal",
          });
          navigate("/login");
        })
        .catch((err) => {
          console.error("error changing password", err);
          swal({
            title: `Fjalëkalimi nuk mund te ndryshohet, kontrollo të dhënat dhe provo përsëri.`,
            icon: "error",
            className: "custum-swal",
          });
        });
    }
  };

  useEffect(() => {
    let resetIndic = auth?.userAccess[0]?.forceReset;
    // console.log("Auth, a", auth);
    if (resetIndic !== true) {
      navigate("/kryefaqja");
    }
  }, [auth]);

  return (
    <div className="forgot-password">
      <Row>
        <Col span={24}>
          <div className="login-header">
            <img src={FlexLogo} alt="Flex" width={160} height={104} />
          </div>
        </Col>
      </Row>
      <Row>
        {
          <Col span={24}>
            <div className="background">
              <div className="login-grid">
                <div className="form-header">Ndrysho Fjalëkalimin</div>

                <Form
                  name="normal_login"
                  className="login-form"
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                >
                  <Alert
                    message="Kërkohet që ju të ndryshoni fjalëkalimin tuaj. Fjalëkalimi juaj duhet të ketë të paktën 8 karaktere, një shkronjë të vogël, një shkronjë të madhe, dhe një karakter të veçantë."
                    type="warning"
                    banner
                    style={{
                      width: 375,
                      lineHeight: 1.4,
                      marginLeft: 70,
                    }}
                  />
                  <br />
                  <Form.Item
                    name="oldPassword"
                    rules={[
                      {
                        required: true,
                        pattern: new RegExp(passwordRegex),
                        message:
                          "Ju lutem vendosni një fjalëkalim që zbaton rregullat e sigurisë.",
                      },
                    ]}
                  >
                    <Input.Password
                      placeholder="Fjalëkalimi i vjetër"
                      prefix={<LockFilled style={{ height: 25 }} />}
                      autoComplete="new-password"
                      iconRender={(visible) =>
                        visible ? <EyeFilled /> : <EyeFilled />
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        pattern: new RegExp(passwordRegex),
                        message:
                          "Ju lutem vendosni një fjalëkalim që zbaton rregullat e sigurisë.",
                      },
                    ]}
                  >
                    <Input.Password
                      placeholder="Fjalëkalimi i ri"
                      prefix={<LockFilled style={{ height: 25 }} />}
                      autoComplete="new-password"
                      iconRender={(visible) =>
                        visible ? <EyeFilled /> : <EyeFilled />
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    name="confirmPassword"
                    dependencies={["password"]}
                    // hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Ju lutem, konfirmoni fjalëkalimin e ri!",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }

                          return Promise.reject(
                            new Error("Fjalekalimet nuk bashkangjiten!")
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      placeholder="Konfirmo Fjalëkalimin i ri"
                      prefix={<LockFilled style={{ height: 25 }} />}
                      autoComplete="new-password"
                      iconRender={(visible) =>
                        visible ? <EyeFilled /> : <EyeFilled />
                      }
                    />
                  </Form.Item>

                  <br />
                  <br />
                  <Form.Item>
                    <Link to="/login">
                      <Button type="primary" className="back-form-button">
                        Kthehu pas
                      </Button>
                    </Link>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className={
                        login.log_in ? "filled-button" : "login-form-button"
                      }
                    >
                      Ndrysho
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </Col>
        }
      </Row>
    </div>
  );
}

export default ResetPassword;
