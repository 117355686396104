import React, { useState, useEffect } from "react";
import { Modal, TimePicker, message, Skeleton } from "antd";
import { approveShift, editShift, getShift } from "../utils/apiHelpers";
// import TextArea from "antd/lib/input/TextArea";
import moment from "moment-timezone";
import "./EditShiftModal.scss";
import { useAuth } from "../../../../../../authentication/authHelpers";
import {
  getDisabledAfterCustomHours,
  getDisabledAfterCustomMinutes,
  getDisabledBeforeCustomHours,
  getDisabledBeforeCustomMinutes,
} from "../../../../../dashboard/utils/disabledTimeFilters";
// import swal from "sweetalert";
import { ModalXIcon, WarningIcon } from "../../../../../../assets/icons";
import { LoopingRhombusesSpinner } from "react-epic-spinners";
import CrashShift from "../../../../../Pagat/approvals/crashShiftModal/CrashShift";
import SuccessComponent from "../../../../../commonComponents/SuccessComponent/SuccessComponent";
import { RichTextEditor } from "../../../../../commonComponents";
import { useDispatch, useSelector } from "react-redux";
import { Select } from "antd";
import {
  notificationToAllAdminAndSecifikUser,
  notificationUserToAdmin,
} from "../../../../../../utils/sendNotification";
import { clockings } from "../../../../../../store/actions";
import {
  requestAllPages,
  requestData,
  requestMultipleTables,
} from "../../../../../../helpers/API/RequestFactory";
import useReactIpLocation from "react-ip-details";

const { Option } = Select;
function EditShiftModal({
  editShiftModalVisibility,
  setEditShiftModalVisibility,
  editShiftModalData,
  // onRefresh,
  onSaveEditSuccess,
}) {
  const [oldShift, setOldShift] = useState(null);
  const auth = useAuth();
  const [clockIn, setClockIn] = useState(null);
  const [clockOut, setClockOut] = useState(null);
  const [note, setNote] = useState(null);
  const [approved, setApproved] = useState(null);
  const [templateShift, setTemplateShift] = useState({
    editedByAdmin: true,
  });
  const [saving, setSaving] = useState(false);
  const [crashError, setCrashError] = useState(false);
  const [crashData, setCrashData] = useState([]);
  const [successVisibility, setSuccessVisibility] = useState(false);
  const [dayType, setDayType] = useState(null);
  const { programFields } = useSelector((state) => state.programFields);
  const { users } = useSelector((state) => state.usersList);
  const { ipResponse } = useReactIpLocation();

  const dispatch = useDispatch();

  console.log("Edit shift modal data", editShiftModalData);

  const reload = async () => {
    const getData = async () => {
      await requestData("clocking").then((res) => {
        dispatch(clockings(res || []));
      });
    };
    await getData();
  };

  const handleSave = async () => {
    let toSend = templateShift;
    if (clockIn && oldShift !== null) {
      setSaving(true);

      const adminlog = {
        createdAt: moment().valueOf(),
        oldShift: { ...oldShift, clockingLogs: [] }, // Empty clockingLogs because not needed & API constraints
        username: auth?.user?.username,
        note: note,
        ipResponse: ipResponse || null,
      };
      toSend = {
        ...toSend,
        approved: approved,
        clockInDate: moment(clockIn).valueOf(),
        clockOutDate: clockOut ? moment(clockOut).valueOf() : null,
        dayType: dayType,
      };
      let adminlog2 = {
        approved: true,
        createdAt: moment().valueOf(),
        approvedBy:
          auth?.userAccess[0]?.given_name +
          " " +
          auth?.userAccess[0]?.family_name,
      };
      await editShift(
        oldShift.clockingId,
        toSend,
        oldShift.employeeId,
        adminlog
      )
        .then(async () => {
          if (approved) {
            message.info("Po aprovohet turni.");
            await approveShift(oldShift.clockingId, adminlog2)
              .then(async (res) => {
                console.log("Shift approved", res);
                onSaveEditSuccess(
                  oldShift.clockingId,
                  toSend,
                  oldShift.employeeId,
                  adminlog,
                  res
                );
                setSuccessVisibility(true);
              })
              .catch((err) => console.log("approveShift error", err));
          } else {
            onSaveEditSuccess(
              oldShift.clockingId,
              toSend,
              oldShift.employeeId,
              adminlog
            );
            setSuccessVisibility(true);
          }
        })
        .then(async () => {
          if (approved === true && oldShift.approved === false)
            notificationToAllAdminAndSecifikUser(
              {
                notificationTitle: "Turni u Aprovua",
                createdAt: moment().format("DD/MM/YYYY"),
                notificationPriority: false,
                notificationBody: `${auth?.userAccess[0]?.given_name} ${
                  auth?.userAccess[0]?.family_name
                } aprovoi turnin e ${
                  editShiftModalData[0].employee_details.employeeFirstName
                }  ${
                  editShiftModalData[0].employee_details.employeeLastName
                } për datën ${moment(editShiftModalData[0]?.data).format(
                  "DD/MM/YYYY"
                )}`,
                isAnnouncement: false,
                notificationUrl: "/aprovimet",
                notificationFirstName: auth?.userAccess[0]?.given_name,
                notificationLastName: auth?.userAccess[0]?.family_name,
              },
              users?.allUsers?.Items?.filter(
                (el) =>
                  el?.userSub ===
                    editShiftModalData[0]?.employee_details?.userSub ||
                  el?.userRole === "Admin" ||
                  el?.userRole === "Super Admin"
              ),
              users?.allUsers?.Items?.find(
                (el) =>
                  el?.userSub ===
                  editShiftModalData[0]?.employee_details?.userSub
              ),
              true
            );
        })
        .catch((errArr) => {
          setCrashError(true);
          setCrashData(errArr);
        });
      // .finally(async () => {
      // 	// await reload();
      // });
      setSaving(false);
    } else {
      message.error(
        "Ka një gabim. Sigurohuni që të plotësoni të gjitha fushat."
      );
    }
  };

  // console.log(editShiftModalData);

  const handleCancel = () => {
    setEditShiftModalVisibility(false);

    setOldShift(null);
    setClockIn(null);
    setClockOut(null);
    setNote(null);
  };

  const toggleApprove = () => {
    if (!!clockOut) {
      setApproved(!approved);
    } else {
      message.error("Ju nuk mund të aprovoni një turn të pa perfunduar!");
    }
  };

  const onEditClockIn = (e) => {
    const date = moment(oldShift?.clockInDate);
    const time = moment(e);
    date.set({
      hour: time.get("hour"),
      minute: time.get("minute"),
      second: "00",
    });
    if (e !== null && clockOut !== null) {
      if (moment(date).valueOf() < moment(clockOut).valueOf()) {
        setClockIn(date);
      } else {
        message.error("Fillimi i turnit nuk mund te jete me vone se mbarimi.");
      }
    } else {
      setClockIn(e);
    }
  };

  const onEditClockOut = (e) => {
    const date2 = moment(oldShift.clockInDate);
    const time2 = moment(e);
    date2.set({
      hour: time2.get("hour"),
      minute: time2.get("minute"),
      second: "00",
    });
    if (e !== null && clockIn !== null) {
      // changed clockIn
      if (moment(date2).valueOf() > moment(clockIn).valueOf()) {
        setClockOut(date2);
      } else {
        message.error(
          "Mbarimi i turnit nuk mund te jete me shpejt se fillimi."
        );
      }
    } else {
      setClockOut(e);
    }
  };

  const onClockinCateg = (value) => {
    setDayType(value);
  };

  useEffect(() => {
    if (editShiftModalData.length > 0) {
      getShift(editShiftModalData[0].clockingId).then((r) => {
        // console.log("SHIFTI", r);
        setOldShift(r);
        setClockIn(r.clockInDate);
        setClockOut(r.clockOutDate);
        setApproved(r?.approved === true ? true : false);
        setDayType(!!r?.dayType ? r.dayType : null);
      });
    }
  }, [editShiftModalData]);

  return (
    <>
      <Modal
        destroyOnClose={true}
        width={758}
        className="change-shift"
        title={`Ndrysho Turnin e ${
          editShiftModalData[0]?.employee_details?.employeeFirstName
        } ${
          editShiftModalData[0]?.employee_details?.employeeLastName
        } | ${moment(editShiftModalData[0]?.data).format("DD/MM/YYYY")}`}
        open={editShiftModalVisibility}
        onOk={() => setEditShiftModalVisibility(false)}
        onCancel={handleCancel}
        centered={true}
        closeIcon={<ModalXIcon />}
        footer={[
          <button className="close-btn" onClick={handleCancel}>
            Mbyll Faqen
          </button>,
          <button className="start-btn" onClick={handleSave}>
            Ruaj Ndryshimet
          </button>,
        ]}
      >
        {oldShift !== null ? (
          <div className="edit-shift-wrapper">
            <div className="warning-title">
              <span className="warn-icon">
                <WarningIcon />
              </span>

              <span style={{ color: "#EA3943" }}>
                Ju jeni duke ndryshuar nje turn, siguroni te dhenat perpara se
                te ruani ndryshimet!
              </span>
            </div>
            <div className="to-edit-fields">
              <div className="shift-field-start">
                <label style={{ fontWeight: "600" }}>Fillimi i Turnit:</label>
                <TimePicker
                  value={clockIn !== null ? moment(clockIn) : null}
                  placeholder="Fillimi"
                  format="HH:mm"
                  onChange={onEditClockIn}
                  disabledHours={
                    clockOut !== null
                      ? () => getDisabledAfterCustomHours(clockOut)
                      : false
                  }
                  disabledMinutes={
                    clockOut !== null
                      ? (val) => getDisabledAfterCustomMinutes(clockOut, val)
                      : false
                  }
                  showNow={false}
                  allowClear={false}
                />
              </div>
              <div className="shift-field-end">
                <label style={{ fontWeight: "600" }}>Mbarimi i Turnit: </label>
                <TimePicker
                  value={clockOut !== null ? moment(clockOut) : null}
                  placeholder="Mbarimi"
                  format="HH:mm"
                  allowClear
                  disabledHours={
                    clockIn !== null
                      ? () => getDisabledBeforeCustomHours(clockIn)
                      : false
                  }
                  disabledMinutes={
                    clockIn !== null
                      ? (val) => getDisabledBeforeCustomMinutes(clockIn, val)
                      : false
                  }
                  onChange={onEditClockOut}
                  showNow={false}
                  // allowClear={false}
                />
              </div>
              <div className="shift-field-end">
                <label style={{ fontWeight: "600" }}>Lloji i turnit: </label>

                <Select
                  className="daytype-select"
                  showSearch
                  value={dayType}
                  onChange={onClockinCateg}
                  placeholder="Zgjidh nje opsion"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {programFields
                    .find((el) => el.fieldName === "ClockIn Types")
                    ?.fieldOptions?.map((el, idx) => (
                      <Option key={`shiftType-${idx}`} value={el?.name}>
                        {el?.description}
                      </Option>
                    ))}
                </Select>
              </div>
              <div className="shift-field-end">
                <span
                  onClick={toggleApprove}
                  style={{
                    width: "100px",
                    backgroundColor: approved === true ? "#EA3943" : "#1DA193",
                    color: "white",
                    height: "32px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "5px",
                    marginTop: "20px",
                    marginLeft: "20px",
                    cursor: "pointer",
                  }}
                >
                  {approved === true ? "Hiq Aprovimin" : "Aprovo"}
                </span>
              </div>
            </div>
            <div className="to-note">
              <label style={{ fontWeight: "600" }}>
                {" "}
                <strong style={{ color: "red" }}>*</strong>Shenimi:
              </label>
              {/* <TextArea
                value={note}
                autoSize={{ minRows: 4, maxRows: 4 }}
                onChange={(e) => setNote(e.target.value)}
                className="to-note-textarea"
              /> */}

              <RichTextEditor
                className={"to-note-textarea"}
                value={note}
                onChange={(e) => setNote(e)}
              />
            </div>
            <div className="hours-generation">
              <span style={{ fontWeight: "600" }}>
                Oret totale:{" "}
                {moment(clockOut)
                  .diff(moment(clockIn), "hours", true)
                  .toFixed(2) > 0
                  ? moment(clockOut)
                      .diff(moment(clockIn), "hours", true)
                      .toFixed(2)
                  : 0}{" "}
                ore
              </span>
              <div className="animated-hours">
                <div
                  className="calculated-hours"
                  style={{
                    width: `${
                      moment(clockOut)
                        .diff(moment(clockIn), "hours", true)
                        .toFixed(2) > 0 &&
                      moment(clockOut)
                        .diff(moment(clockIn), "hours", true)
                        .toFixed(2) <= 8
                        ? (moment(clockOut)
                            .diff(moment(clockIn), "hours", true)
                            .toFixed(2) /
                            8) *
                          100
                        : moment(clockOut)
                            .diff(moment(clockIn), "hours", true)
                            .toFixed(2) > 8
                        ? 100
                        : 0
                    }%`,
                  }}
                ></div>
              </div>{" "}
              <br />
            </div>{" "}
            <div
              className="status-wrapper"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              {saving && <LoopingRhombusesSpinner color="#1DA193" />}
            </div>
          </div>
        ) : (
          <div>
            <Skeleton />
            <Skeleton />
          </div>
        )}
        <CrashShift
          type={"edit"}
          crashData={crashData}
          visible={crashError}
          setCrashError={setCrashError}
        />
        <SuccessComponent
          visibility={successVisibility}
          setSuccessVisibility={setSuccessVisibility}
          text="Turni u ndryshua me sukses!"
          onOk={() => {
            handleCancel();
          }}
        />
      </Modal>
    </>
  );
}

export default EditShiftModal;
