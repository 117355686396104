import {
  PaymentBrutoIcon,
  FrekuencaPages,
  PaymentNetoIcon,
  TurniPerkatesIcon,
  PagesPerOreIcon,
  LlojiPages,
} from "../../../../assets/icons";
export const paymentData = [
  {
    id: 1,
    icon: <PaymentBrutoIcon />,
    label: "Shuma Bruto",
    fieldName: "employeeGrossMonthlySalary",
    format: "currency",
  },
  {
    id: 2,
    icon: <PaymentNetoIcon />,
    label: "Shuma Neto",
    fieldName: "employeeMonthlyNetSalary",
    format: "currency",
  },
  {
    id: 3,
    icon: <PagesPerOreIcon />,
    label: "Pagesa per ore",
    fieldName: "employeeSalaryPerHour",
  },
  {
    id: 4,
    icon: <LlojiPages />,
    label: "Lloji i pages",
    fieldName: "employeePayrollType",
  },
  {
    id: 5,
    icon: <FrekuencaPages />,
    label: "Frekuenca e pages",
    fieldName: "employeePayrollFrequency",
  },
  {
    id: 6,
    icon: <TurniPerkatesIcon />,
    label: "Turni perkates",
    fieldName: "employeeShiftId",
    format: "shift",
  },
];
