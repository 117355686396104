import React, { useEffect, useState } from "react";
// import { IconExcel, IconNdrysho, DeleteIcon } from "../../../../assets/icons";
// import AgGridComponent from "../../../AG-grid/AgGridComponent";
import { API } from "aws-amplify";
import { Input, Button, Tooltip } from "antd";
import moment from "moment-timezone";
import { DeleteIcon, IconExcel, IconNdrysho } from "../../../../assets/icons";
import CreateShiftModal from "../SistemiTurnet/CreateShiftModal";
import AgGridComponent from "../../../AG-grid/AgGridComponent";
// import "./menaxhmi_orarit.scss";
// import CreateShiftModal from "./CreateShiftModal";
import LoadableComp from "../../../commonComponents/LoadableComp/LoadableComp";
import "./SistemiIp.scss";
import CreateNewIp from "./CreateNewIp/CreateNewIp";
import Swal from "sweetalert2";
import { useAuth } from "../../../../authentication/authHelpers";
import { v4 as uuidv4 } from "uuid";
import IpLogs from "./IpLogs";
import { LogsIcon } from "../../../Documentation/View/assets";

function MenaxhimiOrarit() {
  const columnDefs = [
    {
      headerName: "id",
      field: "id",
      checkboxSelection: true,
      headerCheckboxSelection: true,
      flex: 1,
      valueGetter: "node.rowIndex + 1",
    },
    {
      headerName: "Lloji i Ip",
      field: "shiftName",
      filter: "agTextColumnFilter",
      valueGetter: () => {
        return "Ip e lejuar";
      },
      flex: 3,
    },
    {
      headerName: "Ip Adresa",
      field: "value",
      filter: "agTextColumnFilter",
      flex: 3,
    },
  ];

  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  // const [selectedRows, setSelectedRows] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [programFields, setProgramFields] = useState({});

  const [logsVisible, setLogsVisible] = useState(false);
  const { userAccess } = useAuth();

  const username = {
    userId: userAccess[0].userSub,
    employeeFirstName: userAccess[0].given_name,
    employeeLastname: userAccess[0].family_name,
  };

  // making ag grid ready
  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  // add new data to grid
  const [data, setData] = useState({
    shiftName: "",
    shiftDescription: "",
    shiftStartTime: "",
    shiftEndTime: "",
    createdAt: moment().format("DD/MM/YYYY"),
  });

  const onChange = (objField, e) => {
    const value = objField;
    setData((prev) => ({ ...prev, [value]: e }));
  };

  // add the data to ag gird and server
  const addItems = (addIndex) => {
    const newItems = [data];
    gridApi.applyTransaction({
      add: newItems,
      addIndex: addIndex,
    });
    // API.post("shifts", "/shifts/", { body: { ...data } }).then((r) => setTableData(r));
    setVisible(false);
  };

  // ag grid global search

  const onFilterTextChange = (e) => {
    gridApi.setQuickFilter(e.target.value);
  };

  // clear filter ag grid

  const clearFilters = () => {
    gridApi.setFilterModel(null);
    gridApi.onFilterChanged(null);
    gridApi.setQuickFilter(null);
  };
  // amtd search

  const { Search } = Input;
  // const onSearch = (value) => console.log(value);

  // delete items from grid
  const deleteItems = async () => {
    const selectData = gridApi.getSelectedNodes();
    let selectedData = selectData.map((node) => node.data.shiftId);
    let selecData = selectData.map((node) => node.data?.value);
    // gridApi.applyTransaction({
    // 	remove: selecData,
    // });
    let temp = tableData?.filter((item) => !selecData.includes(item.value));

    let logs = programFields?.fieldOptions?.logs;
    if (selecData?.length !== 0) {
      selecData.forEach((item) => {
        let newLogsValue = {
          id: uuidv4(),
          activity: "U Hoq",
          author: username,
          changeDate: Date.now(),
          currentData: item,
        };
        if (logs) {
          logs?.push(newLogsValue);
        } else {
          logs = [newLogsValue];
        }
      });
    }
    setTableData(temp);
    setProgramFields({
      ...programFields,
      fieldOptions: { whiteList: temp, logs: logs },
    });
    await API.put("programFields", "/programFields/4fgtf65fgf-mnjlsdfv-89000nmnbhujj", {
      body: {
        fieldOptions: {
          whiteList: [...temp?.map((el) => el?.value)],
          logs: logs,
        },
      },
    }).then(() => {
      Swal.fire({
        title: "Sukses",
        text: "Ip Adresa u fshi me sukses",
        icon: "success",
      });
    });
    // API.del("shifts", `/shifts/${selectedData}`).then((r) => setTableData(r));
  };

  const onBtStartEditing = () => {
    const selectData = gridApi.getSelectedNodes();
    let selectedData = selectData.map((node) => node.data.shiftId);
    let selecData = selectData.map((node) => node.rowIndex);
    gridApi.startEditingCell({
      rowIndex: selecData,
      colKey: "shiftName",
    });
  };

  const getIps = async () => {
    await API.get("programFields", "/programFields/4fgtf65fgf-mnjlsdfv-89000nmnbhujj").then(
      (res) => {
        let whiteLists = res?.fieldOptions?.whiteList || [];
        // let blackLists = res?.fieldOptions?.blackList || [];
        setProgramFields(res);
        if (whiteLists.length > 0)
          setTableData(whiteLists.map((item, idx) => ({ value: item, id: idx + 1 })));
      }
    );
  };

  useEffect(() => {
    getIps();
  }, []);

  return (
    <LoadableComp loading={false}>
      <div className="menaxhimi-orarit">
        <div className="ag-grid-menaxhimi-orarit">
          <div className="menaxhimi-orarit-header">
            <div className="header-search">
              <Search
                placeholder="Kërko një rekord"
                onChange={onFilterTextChange}
                style={{ width: 200 }}
              />
              <button className="pastro-btn" onClick={clearFilters}>
                Pastro
              </button>
            </div>
            <div className="header-icons">
              {/* <IconNdrysho onClick={onBtStartEditing} /> */}
              <DeleteIcon onClick={deleteItems} />
              <IconExcel />
              <IconExcel />
              <Tooltip
                placement="top"
                overlayClassName="global-icon-tooltip"
                title={"Shfaq historikun"}
              >
                <LogsIcon onClick={() => setLogsVisible(true)} />
              </Tooltip>
            </div>
            <CreateNewIp
              {...{
                tableData,
                gridApi,
                setTableData,
                programFields,
                setProgramFields,
                username,
              }}
            />
            {/* <CreateShiftModal
							visible={visible}
							setVisible={setVisible}
							onChange={onChange}
							addItems={addItems}
						/> */}
          </div>
          <div className="ag-theme-alpine" style={{ height: 780, width: "100%" }}>
            <AgGridComponent
              rowData={tableData}
              rowSelection="single"
              onGridReady={onGridReady}
              paginationPageSize={9}
              columnDefs={columnDefs}
              serverSideStoreType={"full"}
              enableCellChangeFlash={true}
              rowModelType={"serverSide"}
            />
          </div>
        </div>
        {logsVisible && (
          <IpLogs isVisible={logsVisible} setIsVisible={setLogsVisible} data={programFields} />
        )}
      </div>
    </LoadableComp>
  );
}

export default MenaxhimiOrarit;
