import { Card } from "antd";
import LoaderComponent from "../../commonComponents/LoadableComp/LoadableComp";
import "./ProfileCustomCard.scss";
import ProfileCustomInput from "./ProfileCustomInput";
const CustomProfileCard = ({ data }) => {
  console.log({ data });
  return (
    <div style={{ width: "100%", height: "calc(100% - 35px)" }}>
      {data?.name === "KontaktCard" ? (
        <div className="infoCard">
          <ProfileCustomInput
            name="emri"
            label="Emri"
            value={data?.data?.given_name}
          />
          <ProfileCustomInput
            name="mbiemri"
            label="Mbiemri"
            value={data?.data?.family_name}
          />
          <ProfileCustomInput
            name="nrtelefoni"
            label="Nr.Telefoni"
            value={data?.data?.phone_number}
          />
          <ProfileCustomInput
            name="email"
            label="Email"
            value={data?.data?.email}
          />
        </div>
      ) : (
        <div className="infoCard">
          <ProfileCustomInput
            name="custom:department"
            label="Departamenti"
            value={data?.data?.["department"]}
          />
          <ProfileCustomInput
            name="roliIpunonjesit"
            label="Roli i Punonjësit"
            value={data?.data?.["departmentRole"]}
          />
          <ProfileCustomInput
            name="roliIperdoruesit"
            label="Roli i përdoruesit"
            value={data?.data?.["userRole"]}
          />
        </div>
      )}
    </div>
  );
};

export default CustomProfileCard;
