import React from "react";
import { AuthStatus } from "./authHelpers";

function Layout({ children, test }) {
	return (
		<div className="test" style={{ height: "100%" }}>
			<AuthStatus children={children} test={test} />
			{/* <Outlet /> */}
		</div>
	);
}

export default Layout;
