import React from "react";
import { Form, Input } from "antd";
import { InformationIcon } from "../../../assets/icons";

const ProfileCustomInput = ({ name, label, value }) => {
  return (
    <>
      <div className="inputInfo">
        <Form>
          <Form.Item
            name={name}
            label={label}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <>
              <Input type="text" placeholder={value} disabled />
              <InformationIcon />
            </>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default ProfileCustomInput;
