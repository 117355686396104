import types from "../types/types";

const departments = (departments) => {
  return (dispatch) => {
    dispatch({
      type: types.SET_DEPARTMENTS,
      payload: departments,
    });
  };
};

export default departments;
