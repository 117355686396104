import types from "../types/types";
const initialState = {
  departments: undefined,
};

const departmentsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.SET_DEPARTMENTS:
      return {
        ...state,
        departments: payload,
      };
    default:
      return state;
  }
};

export default departmentsReducer;
