import CardComponetDepartment from "../../../../../Departments/DepartmentCard/CardComponetDepartment";
// import DepartmentCardView from "../../../../../Departments/DepartmentCard/DepartmentCardView";
// import CardComonentFitimet from "../../../../Konfigurimet/PagatSettings/FitmetSetings/CardComonentFitimet";
// import Card from "../../../components/Card";
// import ChartSummary from "../../../components/charts/Chart";
import DashboardChart from "../../../components/charts/DashboardChart";
import ClockIn from "../../../components/clockInOut/ClockIn";
// import RequestForm from "../../../components/requestPermission/RequestForm";
import UserRequest from "../../../components/requestPermission/UserRequest";
import WeeklyMonthly from "../../../components/summary/WeeklyMonthly";
import ComponentCard from "../ComponentCard";



const defaultData = {

  x: 0,
  y: 0,
  h: 1,
  w: 1,
  minW: 1,
  minH: 1,
  static: false

}

export const userDashboardCards = [
  {
    id: 0,
    name: "ClockIn",
    emri: "Veprime Turni",
    src: ComponentCard,
    content: ClockIn,
    parent: true,
    data: {},
    hidden: false,
  },
  {
    id: 1,
    name: "WeeklyMonthly",
    emri: "Oret Javore/Mujore",
    src: ComponentCard,
    content: WeeklyMonthly,
    parent: true,
    data: {},
    hidden: false,
  },
  {
    id: 3,
    name: "RequestCard",
    emri: "Kerkese Leje",
    src: ComponentCard,
    content: UserRequest,
    parent: true,
    data: {},
    hidden: false,
  },
  {
    id: 4,
    name: "Chart",
    emri: "Grafike",
    src: ComponentCard,
    content: DashboardChart,
    parent: true,
    data: {},
    hidden: false,
  },
];

const processMap = (dynamicMap) => {
  let a = 0;
  let b = 0;

  if (!!dynamicMap) {
    return dynamicMap?.map((el, idx) => {
      {
        if (a === 5) {
          a = 0;
          b++;
        }
        const result = {
          i: `${idx}`,
          data: {

            name: el?.departmentName,
            emri: el?.departmentName,
            src: CardComponetDepartment,
            parent: false,
            data: el,
            hidden: false,
          }, ...defaultData,
          x: a,
          y: b
        }
        a++
        return result;
      }
    })
  }
};




export const getDepartmentsSaved = (dynamicMap) => {
  let test = processMap(dynamicMap);
  const ls = JSON.parse(localStorage.getItem("draggableOrderDepartments"));
  if (ls !== null) {
    let temporary = [];
    ls.forEach((el, idx) => {
      let found = test.findIndex((l, x) => l.i === el.i);
      if (found !== -1) {
        temporary.push({
          ...test[found],
          data: {
            ...test[found].data,
            hidden: el.data.hidden
          },
          x: ls[found].x,
          y: ls[found].y,
          h: ls[found].h,
          w: ls[found].w,
          minW: ls[found].minW,
          minH: ls[found].minH,
        });
      }
    });
    return temporary;
  } else if (ls === null) {
    localStorage.setItem("draggableOrderDepartments", JSON.stringify(processMap(dynamicMap)));
    return processMap(dynamicMap);
  }
};
