import React from "react";
import "./dynamic_elements.scss";

function HtmlExelTabel({ tableData, loadData = false, componentRef, id, header }) {
	// html tabel given the data dinamicly body its tabelData and header array with string , and unique id , componentRef its userd to print the tabel

	return (
		<>
			{typeof loadData === "boolean" && (
				<div className="Print-Punojsit" ref={componentRef}>
					<table id={id}>
						<thead>
							<tr>
								{header?.map((el) => (
									<th>{el}</th>
								))}
							</tr>
						</thead>
						<tbody>
							{tableData?.map((el) => (
								<tr>
									{Object.values(el)?.map((v) => (
										<td>{v}</td>
									))}
								</tr>
							))}
						</tbody>
					</table>
				</div>
			)}
		</>
	);
}

export default HtmlExelTabel;
