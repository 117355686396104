import React, { useEffect } from "react";
import { Modal } from "antd";
import "./CrashShift.scss";
import { MotionWarningIcon } from "../../../../assets/icons";
import moment from "moment-timezone";
const CrashShift = ({ type, visible, setCrashError, crashData }) => {
  console.log({ type });
  console.log({ setCrashError });
  console.log({ crashData });
  return (
    <div>
      <Modal
        className="crash-shift-modal"
        width={546}
        centered={true}
        title={<MotionWarningIcon />}
        destroyOnClose={true}
        open={visible}
        footer={
          <button className="return-btn" onClick={() => setCrashError(false)}>
            Kthehu Pas
          </button>
        }
        // footer={false}
      >
        {crashData.length > 0 && (
          <div className="crash-shift-wrapper">
            <span className="date">
              {moment(crashData?.[0].clockInDate).format("DD MMMM YYYY")}
            </span>
            <span className="warning-text">
              Ju nuk mund ta {type === "new" ? "shtoni" : "ndryshoni"} këtë turn
              sepse ka të paktën një turn që ka përplasje:
            </span>
            <div className="crash-shifts">
              <div className="crash-old-shift">
                <span className="shift-label">Turni ekzistues</span>
                <span
                  className="time-shift-format"
                  style={{ backgroundColor: "#EA3943" }}
                >
                  {moment(crashData?.[0]?.clockInDate).format("HH:mm")} -{" "}
                  {!!crashData?.[0]?.clockOutDate
                    ? moment(crashData?.[0]?.clockOutDate).format(
                        "HH:mm | DD/MM/YYYY"
                      )
                    : "Pa percaktuar"}
                </span>
              </div>
              <div className="crash-new-shift">
                <span className="shift-label">Turni i deshiruar</span>
                <span
                  className="time-shift-format"
                  style={{ backgroundColor: "#1DA193" }}
                >
                  {moment(crashData[1]?.clockInDate).format("HH:mm")} -{" "}
                  {moment(crashData[1]?.clockOutDate).format(
                    "HH:mm | DD/MM/YYYY"
                  ) !== "Invalid date"
                    ? moment(crashData[1]?.clockOutDate).format(
                        "HH:mm | DD/MM/YYYY"
                      )
                    : "Pa Percaktuar"}
                </span>
              </div>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default CrashShift;
