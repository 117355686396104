// import { createStore, applyMiddleware } from "redux";
// import reducers from "./reducers/index";
// import thunk from "redux-thunk";
// import { composeWithDevTools } from "redux-devtools-extension";

// const middleware = [thunk];

// // Creating the Redux Store
// export const store = createStore(
//   reducers,
//   { devTools: false },
//   composeWithDevTools(applyMiddleware(...middleware))
// );

import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

// import { rootReducer } from "./reducers";
import reducers from "./reducers/index";

const middleware = [thunk];
export const appStore = (initialState = {}) =>
  createStore(
    reducers,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
  );

export const store = appStore();
