import React, { useState, useEffect } from "react";
import { Modal, TimePicker, message, Alert } from "antd";

import "../../../Pagat/approvals/newShiftModal/NewShiftModal.scss";
import { ModalXIcon, WarningIcon } from "../../../../assets/icons";
// import TextArea from "antd/lib/input/TextArea";
import { DatePicker, Select } from "antd";
import moment from "moment-timezone";
import {
  getDisabledAllHours,
  getDisabledBeforeCustomHours,
  getDisabledBeforeCustomMinutes,
  getDisabledButGracePeriodMins,
  getDisabledAfterCustomHours,
  getDisabledAfterCustomMinutes,
  getDisabledAfterMinutes,
} from "../../../dashboard/utils/disabledTimeFilters";
import { BreedingRhombusSpinner } from "react-epic-spinners";
import { createNewShiftByAdmin } from "../../../Pagat/approvals/utils";
import { useAuth } from "../../../../authentication/authHelpers";
import SuccessComponent from "../../../commonComponents/SuccessComponent/SuccessComponent";
import { RichTextEditor } from "../../../commonComponents";
import { useDispatch, useSelector } from "react-redux";
import CrashShift from "../../../Pagat/approvals/crashShiftModal/CrashShift";
import { API } from "aws-amplify";
import { calculateDayType } from "../../utils/calculateDayType";
import {
  notificationUserToAdmin,
  notificationUserToAdminNoDbPost,
} from "../../../../utils/sendNotification";
import useReactIpLocation from "react-ip-details";
import { getSocketUrl } from "../../../../utils/websocketConfig";
import { requestAllPages } from "../../../../helpers/API/RequestFactory";
import { htmlParser } from "../../../../utils";
import { debounce } from "lodash";
import { addClocking } from "../../../../store/actions";
import { getAppliedShifts } from "./clockOutModal/utils";
import { WitchIcon } from "../../../../assets/holidays/icons";
import axios from "axios";

// * @EneaXharau - Websocket handler added
const socketHandler = new WebSocket(getSocketUrl("/dashboard/sockets"));

const { Option } = Select;

function NewUserShift({
  selfType,
  newShiftVisib,
  setNewShiftVisib,
  onRefresh,
  employeeId,
  userData,
  setPrevShift,
  userSub,
  liveTime,
  shiftConfiguration,
  employeeTeam,
}) {
  const [newShift, setNewShift] = useState({
    clockInDate: moment(),
    clockOutDate: null,
    note: "",
    employeeId: null,
  });
  const [dateMoment, setDateMoment] = useState(moment());
  const auth = useAuth();
  const [saving, setSaving] = useState(false);
  const [crashError, setCrashError] = useState(false);
  const [crashData, setCrashData] = useState([]);
  const [successVisibility, setSuccessVisibility] = useState(false);
  const [dayType, setDayType] = useState(null);
  const [leaves, setLeaves] = useState([]);
  const { programFields } = useSelector((state) => state.programFields);
  const { users } = useSelector((state) => state.usersList);
  const [gracePeriod, setGracePeriod] = useState(0);
  const [graceWarnings, setGraceWarning] = useState({
    enable: false,
    text: "",
  });
  const [ip, setIp] = useState("");
  const dispatch = useDispatch();
  const { employeeRequests } = useSelector((state) => state.employeeRequests);
  const [manualShift, setManualShift] = useState(false);
  //   const { ipResponse } = useReactIpLocation();
  const [disabled, setDisabled] = useState(false);

  const getExactLocation = () => {
    navigator.geolocation.getCurrentPosition(function (position) {});
  };

  const {
    currency,
    exchangeRate,
    ipResponse,
    exchangeRateResponse,
    errorMessage,
    geoLocationPosition,
    geoLocationErrorMessage,
    currencyString,
  } = useReactIpLocation({ numberToConvert: 100 });

  const onSave = async () => {
    // message.info("u thirr");
    const temp = newShift?.note.replace(/<[^>]+>/g, "");
    const noteLen = temp.replace(/\s/g, "").length;
    const noteReq = graceWarnings.enable && noteLen < 5 ? false : true;

    if (
      !!newShift.clockInDate &&
      !!newShift.employeeId &&
      dayType !== null &&
      noteReq
    ) {
      setSaving(true);

      let date1 = moment();
      let clockIn = moment(newShift.clockInDate);
      let date2 = moment();
      let clockOut = moment(newShift.clockOutDate);
      date1.set({
        hour: clockIn.get("hour"),
        minute: clockIn.get("minute"),
        second: "00",
      });
      date2.set({
        hour: clockOut.get("hour"),
        minute: clockOut.get("minute"),
        second: "00",
      });

      const toSend = {
        clockInDate: moment(date1).valueOf(),
        clockOutDate:
          newShift.clockOutDate !== null ? moment(date2).valueOf() : null,
        employeeId: newShift.employeeId,
        clockingCategory: "user",
        dayType: dayType,
        clockInNote: newShift.note,
        clockOutNote: "",
        editedClockIn:
          moment().format("HH:mm") !== moment(date1).format("HH:mm")
            ? true
            : false,
        editedClockOut: false,
        editedByAdmin: false,
        forgotClockOut: false,
        approved: false,
        clockingLogs: [
          {
            createdAt: moment().valueOf(),
            note: "Krijuar nga vete punonjesi.",
            newShiftByAdmin: false,
            newShiftByUser: true,
            username: auth?.user?.username,
            ipResponse:
              {
                ...ipResponse,
                IPv4: ip,
              } || null,
          },
        ],
      };

      await createNewShiftByAdmin(toSend, newShift.employeeId, employeeRequests)
        .then(async (res) => {
          /**
           * * @EneaXharau - Added socket emitter
           * * .send() takes -> { request: String, body: any} -> stringified
           */
          dispatch(addClocking(res));
          // setSuccessVisibility(true);
          handleReset();
          setSuccessVisibility(true);
          if (socketHandler.readyState === socketHandler.OPEN) {
            socketHandler.send(
              JSON.stringify({
                request: "user_clockIn",
                body: res,
                id: newShift.employeeId,
              })
            );
          }

          // notificationUserToAdminNoDbPost(
          // 	{
          // 		notificationTitle: "Filloi Turni",
          // 		createdAt: moment().format("DD/MM/YYYY"),
          // 		notificationPriority: false,
          // 		notificationCategory: "Info",
          // 		notificationBody: `${auth?.userAccess[0]?.given_name} ${
          // 			auth?.userAccess[0]?.family_name
          // 		} filloi turnin ${moment(toSend.clockInDate).format("HH:mm")}`,
          // 		isAnnouncement: false,
          // 		notificationUrl: "/aprovimet",
          // 		notificationFirstName: auth?.userAccess[0]?.given_name,
          // 		notificationLastName: auth?.userAccess[0]?.family_name,
          // 	},
          // 	users?.allUsers?.Items
          // );
        })
        .catch((errArr) => {
          setCrashError(true);
          setCrashData(errArr);
        });
      setSaving(false);
      setPrevShift(true);
    } else {
      message.error(
        "Gabim ne plotesimin e te dhenave! Ju lutem plotesoni te gjitha fushat!"
      );
    }
  };

  const handleReset = () => {
    setNewShift({
      clockInDate: moment(),
      clockOutDate: null,
      note: "",
      employeeId: null,
    });
    setDateMoment(moment());
    setNewShiftVisib(false);
    onRefresh();
    setSuccessVisibility(true);
  };

  /**
   * // Note to add: retrieve grace period from settings, and add it to the logic below same with 0 grace period
   * @param {*} date
   */
  const checkGracePeriod = (date) => {
    const cond = moment().diff(date, "minutes", true);
    let h_moment = moment();
    h_moment.set({
      hour: "13",
      minute: "30",
      second: "30",
    });
    if (moment().diff(date, "minutes", true) <= gracePeriod) {
      if (moment().diff(date, "minutes", true) < 1) {
        setGraceWarning({
          text: "",
          enable: false,
        });
        return true;
      } else {
        setGraceWarning({
          text: "Nevojitet një shënim për këtë ndryshim!",
          enable: true,
        });
        return true;
      }
    } else {
      setGraceWarning({
        text: "",
        enable: false,
      });
      return false;
    }
  };

  const onEditClockIn = (e) => {
    const date = moment();
    const time = moment(e);
    setGraceWarning({
      text: "",
      enable: false,
    });
    if (moment(time).diff(moment(), "minutes", true) > 0.8) {
      message.error("Nuk mund te zgjedhesh nje kohe ne te ardhmen!");
      setNewShift((prev) => ({ ...prev, clockInDate: moment() }));
    } else if (gracePeriod === 0) {
      message.error("Ju nuk mund te ndryshoni oren e fillimit!");
      let h_moment = moment();
      h_moment.set({
        hour: "13",
        minute: "30",
        second: "30",
      });
      if (moment().diff(moment(h_moment), "minutes", true) > 1) {
        setGraceWarning({
          text: "Nevojitet një shënim! (Të paktën 6 karaktere!)",
          enable: true,
        });
      }
      return;
    } else {
      date.set({
        hour: time.get("hour"),
        minute: time.get("minute"),
        second: time.get("second"),
      });
      const graceTime = checkGracePeriod(date);

      if (e !== null && newShift.clockOutDate !== null && graceTime) {
        if (moment(date).valueOf() < moment(newShift.clockOutDate).valueOf()) {
          setNewShift((prev) => ({ ...prev, clockInDate: date }));
          setManualShift(true);
        } else {
          message.error(
            "Fillimi i turnit nuk mund te jete me vone se mbarimi."
          );
        }
      } else if (!graceTime) {
        message.error(
          "Ju nuk mund te ndryshoni oren e fillimit me shume se vlera e lejuar!"
        );
        setGraceWarning({
          text: "Nevojitet një shënim!",
          enable: true,
        });
        setNewShift((prev) => ({ ...prev, clockInDate: moment() }));
      } else {
        setNewShift((prev) => ({ ...prev, clockInDate: date }));
        setManualShift(true);
      }
    }
  };

  const onEditClockOut = (e) => {
    const date2 = moment();
    const time2 = moment(e);
    date2.set({
      hour: time2.get("hour"),
      minute: time2.get("minute"),
      second: time2.get("second"),
    });
    if (e !== null && newShift.clockInDate !== null) {
      // changed clockIn
      if (moment(date2).valueOf() > moment(newShift.clockInDate).valueOf()) {
        setNewShift((prev) => ({ ...prev, clockOutDate: date2 }));
      } else {
        message.error(
          "Mbarimi i turnit nuk mund te jete me shpejt se fillimi."
        );
      }
    } else {
      setNewShift((prev) => ({ ...prev, clockInDate: date2 }));
    }
  };

  const onCalendarPick = (e) => {
    if (e !== null && e !== undefined) {
      setDateMoment(e);
    }
  };

  const getAutoSuggest = (time) => {
    // let time = moment(time);
    let l_moment = moment();
    l_moment.set({
      hour: "13",
      minute: "15",
      second: "00",
    });
    let h_moment = moment();
    h_moment.set({
      hour: "13",
      minute: "31",
      second: "30",
    });
    let autoSuggestion = moment();
    autoSuggestion.set({
      hour: "13",
      minute: "30",
      second: "00",
    });
    // Morning Shift
    let l_moment2 = moment();
    l_moment2.set({
      hour: "09",
      minute: "40",
      second: "00",
    });
    let h_moment2 = moment();
    h_moment2.set({
      hour: "10",
      minute: "01",
      second: "30",
    });

    let lower_bound2 = moment(l_moment2).valueOf();
    let upper_bound2 = moment(h_moment2).valueOf();

    if (
      moment(time).valueOf() > lower_bound2 &&
      moment(time).valueOf() < upper_bound2
    ) {
      autoSuggestion.set({
        hour: "10",
        minute: "00",
        second: "00",
      });
      return autoSuggestion;
    } else {
      let lower_bound = moment(l_moment).valueOf();
      let upper_bound = moment(h_moment).valueOf();
      if (
        moment(time).valueOf() > lower_bound &&
        moment(time).valueOf() < upper_bound
      ) {
        // setShift((prev) => ({ ...prev, clockOutDate: autoSuggestion }));
        return autoSuggestion;
      } else {
        return moment();
        // return autoSuggestion;
      }
    }
  };

  const getIp = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    setIp(res?.data?.ip);
  };

  useEffect(() => {
    getIp();
  }, []);

  useEffect(() => {
    if (selfType === true && !!userData) {
      setNewShift((prev) => ({
        ...prev,
        employeeId: userData?.employeeId || null,
      }));
    }
  }, [selfType, userData]);

  useEffect(() => {
    const getLeave = async () => {
      const tempReqs = employeeRequests || [];
      setLeaves(
        tempReqs.filter((el) => el?.userSub === userSub?.userAccess[0]?.userSub)
      );
    };
    if (!!userSub && !!employeeRequests) {
      getLeave();
    }
  }, [employeeRequests]);

  useEffect(() => {
    if (leaves.length >= 0 && !!userSub && !!programFields) {
      let test = calculateDayType(
        programFields,
        leaves,
        newShift.clockInDate,
        employeeTeam
      );
      const temp =
        programFields
          ?.find((el) => el.fieldName === "Politika e oreve jashte orarit")
          ?.fieldOptions?.find((el) => el.name === "Grace Period")?.formula ||
        0;
      setGracePeriod(parseInt(temp));
      let tempTime = moment();
      tempTime = tempTime.set({
        hour: "13",
        minute: "30",
        second: "00",
      });
      let tempTime2 = moment();
      tempTime2 = tempTime2.set({
        hour: "10",
        minute: "01",
        second: "00",
      });
      // Greater than 13:30
      if (moment().diff(moment(tempTime), "minutes", true) > 1) {
        setGraceWarning({
          text: "Nevojitet një shënim i vlefshëm për këtë vonesë! (Të paktën 6 karaktere.)",
          enable: true,
        });
      } else {
        if (
          moment().diff(moment(tempTime2), "minutes", true) > 1 &&
          moment().diff(moment("13:00", "HH:mm"), "minutes", true) < 1
        ) {
          setGraceWarning({
            text: "Nevojitet një shënim i vlefshëm për këtë vonesë! (Të paktën 6 karaktere.)",
            enable: true,
          });
        } else {
          setGraceWarning({
            text: "",
            enable: false,
          });
        }
      }
      // else if (
      // 	moment().diff(moment(tempTime), "minutes", true) < 1 ||
      // 	moment().diff(moment(tempTime2), "minutes", true) < 1
      // ) {
      // 	setGraceWarning({
      // 		text: "",
      // 		enable: false,
      // 	});
      // }

      if (!!test) {
        setDayType(test);
      } else {
        setDayType();
      }
    } else {
      setDayType("Regular");
    }
  }, [leaves, newShift, programFields, employeeTeam]);

  useEffect(() => {
    if (!manualShift) {
      setNewShift((prev) => ({
        ...prev,
        clockInDate: getAutoSuggest(liveTime),
      }));
    }
  }, [liveTime, manualShift]);

  return (
    <>
      <Modal
        destroyOnClose={true}
        width={918}
        className="new-shift clockInModal"
        title={`Konfirmo Turn Të Ri (Turni I përcaktuar: ${
          shiftConfiguration?.appliedShifts?.length > 0
            ? getAppliedShifts(shiftConfiguration?.appliedShifts)
            : getAppliedShifts(shiftConfiguration?.applicableShifts)
        })`}
        open={newShiftVisib}
        onOk={() => setNewShiftVisib(false)}
        onCancel={() => setNewShiftVisib(false)}
        centered={true}
        closeIcon={<ModalXIcon />}
        footer={[
          <button className="close-btn" onClick={() => setNewShiftVisib(false)}>
            Mbyll Faqen
          </button>,
          <button className="start-btn" disabled={saving} onClick={onSave}>
            Shto Turnin
          </button>,
        ]}
      >
        <div className="new-shift-wrapper">
          <div className="warning-title">
            <span className="warn-icon">
              <WarningIcon />
            </span>

            <span style={{ color: "#EA3943" }}>
              Përshëndetje,{" "}
              <strong>
                {userData?.userAccess[0]?.given_name +
                  " " +
                  userData?.userAccess[0]?.family_name}
              </strong>
              . Ju jeni duke shtuar një turn, siguroni të dhënat përpara se të
              ruani ndryshimet!
            </span>
          </div>{" "}
          {gracePeriod > 0 && (
            <div>
              <br />
              <Alert
                message={`Ju mund të ndryshoni orarin e fillimit deri në ${gracePeriod} minuta para vetëm me arsye të vlefshme! `}
                type="info"
                showIcon
              />
            </div>
          )}
          <div className="to-edit-fields">
            {" "}
            <div className="shift-field-start" style={{ marginLeft: "30px" }}>
              <label style={{ fontWeight: "600" }}>
                <strong style={{ color: "red" }}>*</strong>Fillimi i Turnit:
              </label>
              <TimePicker
                value={newShift.clockInDate}
                placeholder="Fillimi"
                // inputReadOnly
                format="HH:mm"
                onChange={onEditClockIn}
                // disabledHours={() => getDisabledAllHours()}
                // disabledMinutes={(val) => getDisabledButGracePeriodMins(newShift.clockOutDate, val)}
                disabledHours={() => getDisabledAfterCustomHours()}
                disabledMinutes={(val) => getDisabledAfterMinutes(val)}
                showNow={false}
                allowClear={false}
              />
            </div>
            <div style={{ marginLeft: "20px" }} className="shift-field-end">
              <label style={{ fontWeight: "600" }}>Mbarimi i Turnit: </label>
              <TimePicker
                value={newShift.clockOutDate}
                placeholder="Mbarimi"
                disabled={true}
                format="HH:mm"
                disabledHours={
                  newShift.clockInDate !== null
                    ? () => getDisabledBeforeCustomHours(newShift.clockInDate)
                    : false
                }
                disabledMinutes={
                  newShift.clockInDate !== null
                    ? (val) =>
                        getDisabledBeforeCustomMinutes(
                          newShift.clockInDate,
                          val
                        )
                    : false
                }
                onChange={onEditClockOut}
                showNow={false}
                allowClear={false}
              />
            </div>
            <div style={{ marginLeft: "20px" }} className="shift-field-end">
              {/* <Alert message="Warning" type="warnings" showIcon /> */}
              <label style={{ fontWeight: "600", marginLeft: "0px" }}>
                <strong style={{ color: "red" }}>*</strong>Data e Turnit:
              </label>

              <span
                style={{
                  width: "140px",
                  color: "white",
                  height: "32px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "5px",
                  marginLeft: "0px",
                  cursor: "pointer",
                }}
              >
                <DatePicker
                  allowClear={false}
                  format={"DD/MM/YYYY"}
                  disabled={true}
                  defaultValue={dateMoment}
                  //   value={dateMoment}
                  onChange={onCalendarPick}
                  dropdownClassName="calendar-date"
                  placeholder="Zgjidh Datën"
                  disabledDate={(current) => {
                    return moment() < current;
                  }}
                />
              </span>
            </div>
            <div className="shift-field-end" style={{ marginLeft: "20px" }}>
              <label style={{ fontWeight: "600" }}>
                <strong style={{ color: "red" }}>*</strong>Lloji i turnit:{" "}
              </label>

              <Select
                className="daytype-select"
                disabled
                showSearch
                value={dayType}
                placeholder="Zgjidh nje opsion"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {programFields
                  ?.find((el) => el.fieldName === "ClockIn Types")
                  ?.fieldOptions?.map((el, idx) => (
                    <Option key={`shiftType-${idx}`} value={el?.name}>
                      {el?.description}
                    </Option>
                  ))}
              </Select>
            </div>
          </div>
          <div className="to-note">
            {graceWarnings.enable && (
              <>
                <Alert message={graceWarnings?.text} type="warning" showIcon />
                <br />
              </>
            )}

            <label style={{ fontWeight: "600" }}>Shënimi:</label>
            <RichTextEditor
              className={"to-note-textarea"}
              value={newShift.note}
              onChange={(e) => setNewShift((prev) => ({ ...prev, note: e }))}
            />
          </div>
          <div className="hours-generation">
            <span style={{ fontWeight: "600" }}>
              Orët totale:{" "}
              {moment(newShift.clockOutDate)
                .diff(moment(newShift.clockInDate), "hours", true)
                .toFixed(2) > 0
                ? moment(newShift.clockOutDate)
                    .diff(moment(newShift.clockInDate), "hours", true)
                    .toFixed(2)
                : 0}{" "}
              orë
            </span>
            <div className="animated-hours">
              <div
                className="calculated-hours"
                style={{
                  width: `${
                    moment(newShift.clockOutDate)
                      .diff(moment(newShift.clockInDate), "hours", true)
                      .toFixed(2) > 0 &&
                    moment(newShift.clockOutDate)
                      .diff(moment(newShift.clockInDate), "hours", true)
                      .toFixed(2) <= 8
                      ? (moment(newShift.clockOutDate)
                          .diff(moment(newShift.clockInDate), "hours", true)
                          .toFixed(2) /
                          8) *
                        100
                      : moment(newShift.clockOutDate)
                          .diff(moment(newShift.clockInDate), "hours", true)
                          .toFixed(2) > 8
                      ? 100
                      : 0
                  }%`,
                }}
              ></div>
            </div>{" "}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                marginTop: "20px",
              }}
            >
              {saving && <BreedingRhombusSpinner size={40} color="#1DA193" />}
              {/* BCA THEME */}
              {/* {saving && <BreedingRhombusSpinner size={40} color="#c3174b" />} */}
            </div>
          </div>{" "}
        </div>
        <CrashShift
          type={"new"}
          crashData={crashData}
          visible={crashError}
          setCrashError={setCrashError}
        />
      </Modal>{" "}
    </>
  );
}

export default NewUserShift;
