import types from "../types/types";

const programFields = (fields) => {
  return (dispatch) => {
    dispatch({
      type: types.SET_PROGRAM_FIELDS,
      payload: fields,
    });
  };
};

export default programFields;
