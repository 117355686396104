import types from "../types/types";
const initialState = {
	prepayments: undefined,
};

const prepaymentsReducer = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case types.SET_PREPAYMENTS:
			return {
				...state,
				prepayments: payload,
			};
		default:
			return state;
	}
};
export default prepaymentsReducer;
