import { message } from "antd";
import axios from "axios";
import * as FileSaver from "file-saver";
import moment from "moment-timezone";
export const exportPagatExcel = (data, toGenerate) => {
	console.log("data", toGenerate);
	const toSend = data?.map((el, idx) => {
		return [
			idx,
			el?.employeeInfo?.employeeFirstName + " " + el?.employeeInfo?.employeeLastName || "",
			el?.bruto?.totalBruto || 0,
			el?.taxes?.netoPay?.contributions || 0,
			el?.taxes?.netoPay?.socialIns || 0,
			el?.taxes?.netoPay?.healthIns || 0,
			el?.taxes?.netoPay?.totalIns || 0,
			el?.taxes?.netoPay?.insuranceEmployeer || 0,
			el?.taxes?.netoPay?.healthInsEmployeer || 0,
			el?.taxes?.netoPay?.totalEmployeeerIns || 0,
			el?.bruto?.totalBruto || 0,
			el?.taxes.netoPay?.progressiveTax || 0,
			el?.taxes?.netoPay?.netoPay || 0,
			el?.taxes?.netoPay?.totalEmployeeerIns + el?.bruto?.totalBruto || 0,
		];
	});
	console.log("toSend", toSend);
	let fileName = "PAGAT_" + moment(toGenerate?.timeFrame?.start).format("MMMM YYYY") + ".xlsx";
	const url = "https://leadmanager-express-backend.ue.r.appspot.com/api/export/xlsx";
	// const url = "http://10.0.0.85:8080/api/export/xlsx";
	const hideLoading = message.loading("File is being processed. Please wait...", 0);
	axios({
		method: "post",
		url,
		headers: {
			"Content-Type": "application/json",
		},
		responseType: "blob",
		data: JSON.stringify({
			apiKey: "22dee947-f44e-4674-b1e4-419a8de4292f",
			rows: toSend,
			month: moment(toGenerate?.timeFrame?.start).format("MMMM YYYY"),
			// data,
			// ...result,
		}),
	})
		.then((response) => {
			hideLoading();
			console.log("response", response);
			FileSaver.saveAs(response.data, fileName);
			message.success("Export successful.");
		})
		.catch((err) => {
			console.log("my error", err);
			hideLoading();
			console.error(err);
			message.error("Something went wrong...");
		});
};
