import React, { useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Col, Row, Button, Input, Tooltip, DatePicker } from "antd";
import moment from "moment-timezone";
import { IconExcel, IconKerko, IconNgargo } from "../../assets/icons";
import AgGridComponent from "../AG-grid/AgGridComponent";
import DetailsModal from "./DetailsModal";
import "./raportet.scss";
import HtmlExelTabel from "../Konfigurimet/dynamic components/HtmlExelTabel";
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import { useReactToPrint } from "react-to-print";
import ExportToPdf from "../Konfigurimet/dynamic components/ExportToPdf";
import ShiftModal from "../punonjesit/EmployeeView/Payment/EmployeeShifts/ShiftModal";
import LoadableComp from "../commonComponents/LoadableComp/LoadableComp";
import ShiftGrid from "../punonjesit/EmployeeView/Payment/EmployeeShifts/ShiftGrid";
import "./ClockIns.scss";
import PunonjesitContext from "../punonjesit/store/PunonjesitContext";
function ClockIns() {
  const { employee } = useContext(PunonjesitContext);
  const { employeeRequests } = useSelector((state) => state.employeeRequests);
  const { clockings } = useSelector((state) => state.clockings);
  const [aggridData, setAggridData] = useState([]);
  const allLeaves = employeeRequests?.filter(
    (rqs) => rqs?.userSub === employee?.userSub
  );
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [visible, setVisible] = useState(false);
  // const [isModalVisible, setIsModalVisible] = useState(true);
  const [dateFilter, setDateFilter] = useState(moment());
  const getColor = (reqStatus) => {
    switch (reqStatus) {
      case "Aprovuar":
        return "#1DA193";
      case "Refuzuar":
        return "#EA3943";
      case "Pending":
        return "#FCC94A";
    }
  };
  const columnDefs = [
    {
      headerName: "Nr.",
      field: "fieldId",
      valueGetter: "node.rowIndex + 1",
      flex: 1,
    },
    {
      headerName: "Data",
      field: "clockInDate",
      flex: 3,
      filter: "agTextColumnFilter",
      cellRendererFramework: (params) => (
        <div className="status-column">
          <span style={{ color: "black" }}>
            {moment(params.data?.clockInDate).format("DD/MM/YYYY")}
          </span>
        </div>
      ),
    },
    {
      headerName: "Fillimi",
      field: "clockInDate",
      flex: 3,

      filter: "agTextColumnFilter",
      cellRendererFramework: (params) => (
        <div className="status-column">
          <span style={{ color: "black" }}>
            {moment(params.data?.clockInDate).format("HH:mm")}
          </span>
        </div>
      ),
    },
    {
      headerName: "Mbarimi",
      field: "clockOutDate",
      flex: 3,

      filter: "agTextColumnFilter",
      cellRendererFramework: (params) => (
        <div className="status-column">
          <span style={{ color: "black" }}>
            {!!params?.data?.clockOutDate ? (
              moment(params.data?.clockOutDate).format("HH:mm")
            ) : (
              <strong style={{ color: "red" }}>I pacaktuar</strong>
            )}
          </span>
        </div>
      ),
    },
    {
      headerName: "Statusi",
      field: "approved",
      flex: 3,

      filter: "agTextColumnFilter",
      valueGetter: ({ data }) => {
        if (data?.approved === true) {
          return "Aprovuar";
        } else {
          return "PaAprovuar";
        }
      },
      cellRendererFramework: ({ data }) => (
        <div style={{ cursor: "pointer" }}>
          <div variant="outlined">
            {data?.approved === true ? (
              <div
                style={{
                  background: "#1DA193",
                  // width: "80px",
                  marginTop: "8px",

                  cursor: "pointer",
                  height: "22px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "5px",
                  color: "white",
                }}
              >
                Aprovuar
              </div>
            ) : data?.approved === false ? (
              <div
                style={{
                  background: "#EA3943",
                  // width: "80px",
                  marginTop: "8px",

                  cursor: "pointer",
                  height: "22px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "5px",
                  color: "white",
                }}
              >
                Pa Aprovuar
              </div>
            ) : (
              <div
                style={{
                  background: "grey",
                  marginTop: "8px",
                  cursor: "pointer",
                  height: "22px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "5px",
                  color: "white",
                }}
              >
                Pa Përcaktuar
              </div>
            )}
          </div>
        </div>
      ),
    },
    {
      headerName: "Kohëzgjatja",
      field: "clockOutDate",
      flex: 3,
      filter: "agTextColumnFilter",
      cellRendererFramework: ({ data }) => (
        <span>
          {!!data.clockOutDate
            ? moment(data.clockOutDate)
                .diff(moment(data.clockInDate), "hours", true)
                .toFixed(2)
            : 0}
        </span>
      ),
    },
  ];
  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const clearFilters = () => {
    gridApi.setFilterModel(null);
    gridApi.onFilterChanged(null);
    gridApi.setQuickFilter(null);
  };

  const onFilterTextChange = (e) => {
    gridApi.setQuickFilter(e.target.value);
  };
  const handleClickOpen = () => {
    setVisible(true);
  };

  const componentRef = useRef("Print-Punojsit");
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    if (!!clockings && Array.isArray(clockings)) {
      setAggridData(
        clockings
          .filter(
            (el) =>
              el.employeeId === employee?.employeeId &&
              el.clockInDate >= moment(dateFilter).startOf("month").valueOf() &&
              el.clockInDate <= moment(dateFilter).endOf("month").valueOf()
          )
          .sort((a, b) => b?.clockInDate - a?.clockInDate)
      );
    }
  }, [clockings, employee, dateFilter]);

  return (
    <>
      <LoadableComp loading={!!employee ? false : true}>
        <div style={{ height: "100%" }}>
          <div className="user-requests-aggrid">
            <div className="ag-grid-punojsit">
              <div className="ag-grid-header">
                <Row style={{ width: "100%" }}>
                  <Col
                    flex={4}
                    style={{ display: "flex", gap: 20 }}
                    className="responsive-col-grid-pagesa"
                  >
                    <div className="header-search">
                      <div className="icon-search-header">
                        <IconKerko width={15} height={15} />
                      </div>
                      <Input
                        placeholder="Kërko një rekord"
                        onChange={onFilterTextChange}
                        style={{ width: 180 }}
                      />
                      <Button className="pastro-btn" onClick={clearFilters}>
                        Pastro
                      </Button>
                    </div>
                    <div className="header-icons">
                      <ReactHtmlTableToExcel
                        id="test-table-xls-button"
                        className="download-table-xls-button"
                        table="Lejet-employees-view"
                        filename="lejet-punojsit"
                        sheet="lejet-punojsit"
                        buttonText={
                          <Tooltip
                            placement="top"
                            overlayClassName="global-icon-tooltip"
                            title={"Eksporto në eksel"}
                          >
                            <IconExcel />
                          </Tooltip>
                        }
                      />
                      <ExportToPdf
                        id={"#Lejet-employees-view"}
                        tabelName={"lejet-punojsit"}
                      />
                      <Tooltip
                        placement="top"
                        overlayClassName="global-icon-tooltip"
                        title={"Printo tabelën"}
                      >
                        <IconNgargo onClick={handlePrint} />
                      </Tooltip>
                      <DatePicker
                        onChange={(val) => setDateFilter(val)}
                        picker="month"
                        allowClear={false}
                        format={"MMMM"}
                        placeholder="Zgjidh Muajin"
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              <div
                className="ag-theme-alpine"
                style={{ height: 760, width: "100%" }}
              >
                <AgGridComponent
                  rowData={aggridData}
                  rowSelection="single"
                  onGridReady={onGridReady}
                  paginationPageSize={15}
                  columnDefs={columnDefs}
                />
                <HtmlExelTabel
                  tableData={allLeaves?.map(
                    ({ requestType, requestStatus, requestPeriod }) => ({
                      requestType,
                      requestStatus,
                      requestPeriod: moment(requestPeriod[0]).format(
                        "DD/MM/YYYY"
                      ),
                      requestPeriodEnd: moment(
                        requestPeriod.slice(-1)[0]
                      ).format("DD/MM/YYYY"),
                    })
                  )}
                  header={[
                    "Lloji i lejes",
                    "Statusi i lejes",
                    "Data e fillimit",
                    "Data e mbarimit",
                  ]}
                  id="Lejet-employees-view"
                  componentRef={componentRef}
                />
              </div>
            </div>
          </div>
          <DetailsModal
            {...{
              visible,
              setVisible,
              allLeaves,
            }}
          />
        </div>
      </LoadableComp>
    </>
  );
}

export default ClockIns;
