import React, { useEffect, useRef, useState } from "react";
import Modal from "antd/lib/modal/Modal";
import _ from "lodash";
import {
  Button,
  Form,
  Select,
  Badge,
  Popconfirm,
  Space,
  Typography,
  Modal as confirmModal,
  message,
} from "antd";
import { API } from "aws-amplify";
import "./ProblemsModal.scss";
import { FolderIcon, ModalXIcon } from "../../../../../../assets/icons";
import MondayButton from "../../../../../commonComponents/MondayButton/MondayButton";
import { RichTextEditor } from "../../../../../commonComponents";
import SiderModal from "../SiderModal/SiderModal";
import {
  notificationUserToAdmin,
  notificationAdminToOnlyOneUser,
} from "../../../../../../utils/sendNotification";
import { useAuth } from "../../../../../../authentication/authHelpers";
import LogsModal from "../LogsModal/LogsModal";
import moment from "moment";
import ProblemsDocUploader from "../ProblemsDocUploader/ProblemsDocUploader";
import { useUploadedFiles } from "../../../../../Documentation/hooks/useUploadedFiles";
import { driveApi } from "../../../../../DriveRequest";
import { useDispatch, useSelector } from "react-redux";
import { problematikat as problematikatReducer } from "../../../../../../store/actions";
import types from "../../../../../../store/types/types";
import { LogsIcon, Delete } from "../../../../../Documentation/View/assets";
import { QuestionsMark } from "../../../../../Intervistat/Kandidatet/ShtoKandidat/Image";
import ActivityModal from "../../../../../punonjesit/EmployeeView/components/ActivityModal/ActivityModal";

const ACCESS_RIGHTS = ["Admin", "Super Admin"];
const STATUS_OPTIONS = [
  {
    key: 0,
    value: "Open",
    label: "Në pritje",
    backgroundColor: "#FCC94A",
    color: "#1D3445",
  },
  {
    key: 1,
    value: "Completed",
    label: "Zgjidhur",
    backgroundColor: "#1DA193",
    color: "#FBFBFB",
  },
];
const PRIORITY_OPTIONS = [
  {
    key: 0,
    value: "Low",
    label: "Low",
  },
  {
    key: 1,
    value: "Medium",
    label: "Medium",
  },
  {
    key: 2,
    value: "High",
    label: "High",
  },
];

const ProblemsModal = ({
  isAddModalVisible,
  setIsAddModalVisible,
  editData,
  setEditData,
}) => {
  const { userAccess, user } = useAuth();
  const { accessToken } = useSelector((state) => state.accessToken);
  const { activeEmployees } = useSelector((state) => state.employeesList);
  const { hotCredentials } = useSelector((state) => state.hotCredentials);
  const { programFields } = useSelector((state) => state.programFields);
  const driveRequest = driveApi({ accessToken });
  const [form] = Form.useForm();

  const username = `${user?.attributes?.given_name} ${user?.attributes?.family_name}`;

  const newInputs = useRef(
    programFields?.find((el) => el?.fieldName === "Kategorite e Problematikave")
  );
  const Text = Typography;

  const dispatch = useDispatch();

  const [status, setStatus] = useState("");
  const [parentFolderId, setParentFolderId] = useState("");
  const [isFieldTouched, setIsFieldTouched] = useState(false);
  const [tempUploadedFiles, setTempUploadedFiles] = useState([]);
  const [googleDriveFolderId, setGoogleDriveFolderId] = useState("");
  const [isLogsModalVisible, setIsLogsModalVisible] = useState(false);
  const [isSiderModalVisible, setIsSiderModalVisible] = useState(false);

  const [uploadHandlers, deleteHandlers] = useUploadedFiles(
    editData?.googleDriveFiles || []
  );
  const [
    uploadedFiles,
    setUploadedFiles,
    formattedDriveFiles,
    onPickerSuccess,
  ] = uploadHandlers;
  const [filesToBeDeleted, setFilesToBeDeleted, onDelete] = deleteHandlers;

  const employeesOption = [];

  activeEmployees?.forEach((employee) => {
    if (
      employee.hasOwnProperty("userSub") &&
      employee.employeePayrollType === "Page bazuar ne ore"
    ) {
      let test = {
        value: `${employee.employeeFirstName} ${employee.employeeLastName}`,
        label: `${employee.employeeFirstName} ${employee.employeeLastName}`,
      };

      employeesOption.push(test);
    }
  });

  const openSiderModal = () => {
    setIsSiderModalVisible(true);
  };

  const handleOk = () => {
    if (filesToBeDeleted.length !== 0) {
      filesToBeDeleted.forEach((item) => driveRequest.deleteDriveItem(item));
    }
    setEditData({});
    setIsAddModalVisible(false);
  };

  //region HANDLE CANCEL
  const handleCancel = () => {
    //check's if we made any change to the form
    if (isFieldTouched || tempUploadedFiles.length !== 0) {
      confirmModal.confirm({
        title: "A jeni të sigurt?",
        icon: <QuestionsMark />,
        content: (
          <Space direction="vertical">
            <Text>Ju po anulloni krijimin e problematikës.</Text>
            <Text>
              Nëse jeni të sigurt për këtë veprim, klikoni
              <span className="text-bold">"Po, anulloje"</span>
            </Text>
          </Space>
        ),
        okText: "Po, anulloje",
        cancelText: "Jo, mos e anullo",
        width: "660px",
        className: "cancelModal",
        centered: true,
        cancelButtonProps: {
          type: "primary",
          style: {
            background: "#EA3943",
            borderColor: "#EA3943",
            borderRadius: "5px",
          },
        },
        okButtonProps: {
          className: "ant-btn-confirm",
        },
        onOk() {
          confirmCancel();
        },
        onCancel() {},
      });
    } else {
      confirmCancel();
    }
  };

  //region >cancel()
  const confirmCancel = async () => {
    if (Object.keys(editData).length === 0) {
      driveRequest.deleteDriveItem(googleDriveFolderId);
    } else {
      await Promise.allSettled(
        tempUploadedFiles.map((id) => driveRequest.deleteDriveItem(id))
      ).catch((err) => {
        console.log("Error deleting files: ", err);
      });
    }
    setEditData({});
    setIsAddModalVisible(false);
  };

  //region COMPARE&LOGS
  function compareObjects(previousObject, currentObject) {
    const keys1 = Object.keys(previousObject);
    const keys2 = Object.keys(currentObject);
    let changesArray = [];

    const commonKeys = keys1.filter((key) => keys2.includes(key));
    if (commonKeys.length === 0) {
    }
    commonKeys.forEach((key) => {
      if (typeof currentObject[key] === "object") {
        if (key === "googleDriveFiles") {
          const oldDocNames = previousObject[key]?.map((el) => el.name);
          const newDocNames = currentObject[key]?.map((el) => el.name);
          if (oldDocNames?.length !== newDocNames?.length) {
            changesArray.push({
              activity: "Ndryshuar",
              author: username,
              changeDate: moment().valueOf(),
              field: "Skedarë",
              oldValue: oldDocNames?.join(", ") || oldDocNames,
              newValue: newDocNames?.join(", ") || newDocNames,
            });
          }
          // const differenceAddedFiles = _.differenceBy(
          //   currentObject[key],
          //   previousObject[key],
          //   "id"
          // );
          // console.log(differenceAddedFiles);
          // const differenceRemovedFiles = _.differenceBy(
          //   previousObject[key],
          //   currentObject[key],
          //   "id"
          // );
          // if (differenceAddedFiles.length !== 0) {
          //   const name = differenceAddedFiles.map((obj) => obj.name).join(", ");
          //   changesArray.push({
          //     activity: "U shtuan",
          //     author: username,
          //     changeDate: moment().valueOf(),
          //     field: key,
          //     oldValue: " ",
          //     newValue: name,
          //   });
          // }
          // if (differenceRemovedFiles.length !== 0) {
          //   const name = differenceRemovedFiles
          //     .map((obj) => obj.name)
          //     .join(", ");
          //   changesArray.push({
          //     activity: "U hoqen",
          //     author: username,
          //     changeDate: moment().valueOf(),
          //     field: key,
          //     oldValue: " ",
          //     newValue: name,
          //   });
          // }
        }
      } else {
        if (previousObject[key] !== currentObject[key]) {
          changesArray.push({
            activity: "Ndryshuar",
            author: username,
            changeDate: moment().valueOf(),
            field: key,
            oldValue: previousObject[key],
            newValue: currentObject[key],
          });
        }
      }
    });
    if (changesArray !== 0) {
      return changesArray;
    } else {
      return false;
    }
  }

  //region ON FINISH
  const onFinish = (formData) => {
    let toSend = {};
    // if we are in edit
    if (Object.keys(editData).length !== 0) {
      //we add to the form the items added/removed to the google drive
      toSend = {
        ...formData,
        googleDriveFiles: formattedDriveFiles,
        googleDriveFolderId,
      };
      const newValues = {
        ...editData,
        createdBy: username,
      };
      //checks if we have changes
      const ticketLogs = compareObjects(newValues, toSend);
      // if we dont have we return
      if (!ticketLogs) {
        handleOk();
      } else {
        //if we have we push to the db
        toSend = {
          ...toSend,
          keylog: [...(toSend?.keylog || []), ...ticketLogs],
          googleDriveFiles: formattedDriveFiles,
        };
        handleOnSubmit(toSend);
      }
    } else {
      //in create we check if a problem its created by admin or user
      let toSend = {
        ...formData,
        googleDriveFolderId,
        googleDriveFiles: formattedDriveFiles,
        keylog: [
          {
            activity: "U Krijua Problematika",
            author: username,
            changeDate: moment().valueOf(),
            field: "",
            oldValue: "",
            newValue: "",
          },
        ],
      };
      //if it's created by admin
      if (formData.hasOwnProperty("createdBy")) {
        toSend = { ...toSend, createdBy: username };
      }
      handleOnSubmit(toSend);
    }

    handleOk();
  };

  //region >submit()
  const handleOnSubmit = async (data) => {
    let toSend = {
      ticketPriority: data.ticketPriority,
      ticketCategory: data.ticketCategory,
      ticketDescription: data.ticketDescription,
      ticketStatus: data.ticketStatus || "Open",
      googleDriveFiles: data.googleDriveFiles,
      googleDriveFolderId: data.googleDriveFolderId,
      createdBy: data.createdBy,
      keylog: [...(editData?.keylog || []), ...(data?.keylog || [])],
    };
    //on create
    if (Object.keys(editData).length === 0) {
      await API.post("tickets", `/tickets`, {
        body: {
          ...toSend,
        },
      })
        .then((resp) => {
          dispatch(problematikatReducer(resp));
          const notificationTitle = "U krijua nje Problematike";
          const notificationBody = `${toSend.createdBy} krijoi një problematikë në lidhje me ${resp?.ticketCategory}`;
          notificationUserToAdmin(
            {
              notificationTitle: notificationTitle,
              notificationBody: notificationBody,
              notificationUrl: "/problematikat",
            },
            userAccess[0].allUsers.Items
          );
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      //in edit
      await API.put("tickets", `/tickets/${editData?.ticketId}`, {
        body: { ...toSend },
      })
        .then(() => {
          dispatch(
            problematikatReducer({
              ...toSend,
              ticketId: editData?.ticketId,
              resolvedTicket:
                toSend?.ticketStatus === "Completed"
                  ? moment().format("DD/MM/YYYY")
                  : null,
            })
          );
          if (toSend?.ticketStatus === "Completed") {
            // we send a notification to the user that the problem is solved
            const notificationTitle = "U Zgjidh Problematika";
            const notificationBody = `${toSend.createdBy} e ndryshoi statusin e problematikës ${toSend.ticketCategory} në të zgjidhur`;
            let identi = userAccess[0]?.allUsers?.Items?.find(
              (el) => el?.userSub === editData.createdBy.userId
            );
            notificationAdminToOnlyOneUser(
              {
                notificationTitle: notificationTitle,
                notificationBody: notificationBody,
                notificationUrl: "/kryefaqja",
              },
              identi
            );
          }
        })
        .catch((err) => {
          console.log("err", { err });
        });
    }
  };

  //Function to change the status to completed
  //region STATUS 'completed'
  const handleSetAsDone = async () => {
    const keylogs = compareObjects(editData, { ticketStatus: "Completed" });
    if (!!keylogs?.length) {
      const toSend = {
        ticketStatus: "Completed",
        keylog: [...(editData?.keylog || []), ...keylogs],
      };

      await API.put("tickets", `/tickets/${editData?.ticketId}`, {
        body: { ...toSend },
      })
        .then(() => {
          dispatch(
            problematikatReducer({
              ...toSend,
              ticketId: editData?.ticketId,
              resolvedTicket: moment().format("DD/MM/YYYY"),
            })
          );
          //send notification to the user that the problem is solved
          const notificationTitle = "U Zgjidh Problematika";
          const notificationBody = `${toSend.createdBy} e ndryshoi statusin e kësaj problematikës në të zgjidhur`;
          let identi = userAccess[0]?.allUsers?.Items?.find(
            (el) => el?.userSub === editData.createdBy.userId
          );
          notificationAdminToOnlyOneUser(
            {
              notificationTitle: notificationTitle,
              notificationBody: notificationBody,
              notificationUrl: "/kryefaqja",
            },
            identi
          );
        })
        .catch((err) => {
          console.log("err", { err });
        });
      handleOk();
    } else {
      handleOk();
    }
  };

  const handleStatusChange = (e) => {
    setStatus(e);
  };

  //region DELETE PROBLEMATIKA
  const handleDeleteProblematika = async () => {
    if (Object.keys(editData).length !== 0) {
      try {
        await API.del("tickets", `/tickets/${editData.ticketId}`).then(() => {
          dispatch(problematikatReducer(editData, types.REMOVE_PROBLEMS));
          driveRequest.deleteDriveItem(googleDriveFolderId);
          setEditData({});
          setIsAddModalVisible(false);
        });
      } catch (err) {
        console.log("Error deleting Problem:", err);
      }
    }
  };

  //region CREATE FOLDER
  const handleFolderCreate = async () => {
    const folderName = `Problematika ${moment().format("DD/MM/YYYY h:mm:ss")}`;
    const hasFolderId =
      !!editData?.hasOwnProperty("googleDriveFolderId") &&
      !!editData?.googleDriveFolderId?.length;
    if (!hasFolderId) {
      await driveRequest
        .getFolderIdOrCreate({ name: folderName, parent: parentFolderId })
        .then((res) => setGoogleDriveFolderId(res));
    }
  };

  //useEffect used to populate the data of the form if we are in edit
  useEffect(() => {
    if (Object.keys(editData).length !== 0) {
      form.setFieldsValue({
        ...editData,
      });
      setStatus(editData.ticketStatus);
      setGoogleDriveFolderId(editData.googleDriveFolderId);
    }
    if (!!hotCredentials?.length) {
      const parentFolder = hotCredentials.find(
        (el) => el?.credentialId === "7"
      );
      setParentFolderId(parentFolder.credentialValue);
    }
  }, [isAddModalVisible, editData]);

  //useEffect used to create the folder after we get the partentFolderId
  useEffect(() => {
    if (!!isAddModalVisible) handleFolderCreate();
  }, [parentFolderId, editData]);

  //region RETURN
  return (
    <div>
      <Modal
        centered={true}
        className={"problematikaModal"}
        title="Problematika"
        open={isAddModalVisible}
        // onOk={handleOk}
        onCancel={googleDriveFolderId?.length !== 0 && handleCancel}
        destroyOnClose={true}
        closeIcon={<ModalXIcon />}
        footer={[
          <div className="leftSideButtonFooter">
            <Button
              disabled={googleDriveFolderId?.length === 0}
              className="decline"
              onClick={handleCancel}
            >
              Mbyllni faqen
            </Button>
            {Object.keys(editData).length !== 0 && (
              <Popconfirm
                title="A doni ta fshihni problematikën"
                description="A doni ta fshihni problematikën?"
                onConfirm={handleDeleteProblematika}
                okText="Po"
                cancelText="Jo"
              >
                <Button
                  className="showLogsButton"
                  icon={<Delete width={20} height={20} fill="#fff" />}
                />
              </Popconfirm>
            )}
            {Object.keys(editData).length !== 0 && (
              <Button
                className={
                  "showLogsButton" +
                  (isSiderModalVisible ? " showButtonDisabled" : "")
                }
                onClick={() => setIsLogsModalVisible(true)}
                disabled={isSiderModalVisible ? true : false}
                icon={<LogsIcon width={23} height={23} />}
              />
            )}
          </div>,
          <div className="confirmButtonModal">
            {Object.keys(editData).length !== 0 &&
              ACCESS_RIGHTS.includes(userAccess[0].userRole) && (
                <Button
                  disabled={editData.ticketStatus === "Open" ? false : true}
                  className={
                    "confirmClosedButton" +
                    (editData.ticketStatus === "Open"
                      ? ""
                      : " showButtonDisabled")
                  }
                  onClick={() => handleSetAsDone()}
                >
                  Shenojeni si të rregulluar
                </Button>
              )}
            <Button
              className="send-request"
              htmlType="submit"
              key="submit"
              form="add-problem"
            >
              {Object.keys(editData).length === 0
                ? "Krijo kërkesën"
                : "Ruani ndryshimet"}
            </Button>
          </div>,
        ]}
      >
        <Form
          form={form}
          onFinish={onFinish}
          name="add-problem"
          layout="vertical"
          autoComplete="off"
          autoFocus
          initialValues={editData}
          requiredMark={false}
          onFieldsChange={(a, b) => {
            setIsFieldTouched(true);
          }}
        >
          <div className="formFirstRowContainer">
            {ACCESS_RIGHTS.includes(userAccess[0].userRole) &&
              employeesOption?.length !== 0 && (
                <Form.Item
                  className="selectUserFormItem"
                  label="Zgjidhni userin"
                  name="createdBy"
                  rules={[
                    {
                      required: true,
                      message: "Kjo fushë nuk mund të jetë bosh!",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    disabled={!(Object.keys(editData).length === 0)}
                    placeholder="Zgjidhni userin"
                    options={employeesOption}
                  />
                </Form.Item>
              )}
            <Form.Item
              className="selectUserFormItem"
              label="Zgjidhni Prioritetin"
              name="ticketPriority"
              rules={[
                {
                  required: true,
                  message: "Kjo fushë nuk mund të jetë bosh!",
                },
              ]}
            >
              <Select
                disabled={
                  (ACCESS_RIGHTS.includes(userAccess[0].userRole) &&
                    Object.keys(editData).length !== 0) ||
                  status === "Completed"
                    ? true
                    : false
                }
                placeholder="Zgjidhni prioritetin"
                options={PRIORITY_OPTIONS}
              />
            </Form.Item>
          </div>
          <div className="formSecondRowContainer">
            <Form.Item
              label="Kategoria"
              name="ticketCategory"
              rules={[
                {
                  required: true,
                  message: "Kjo fushë nuk mund të jetë bosh!",
                },
              ]}
            >
              <Select
                disabled={
                  Object.keys(editData).length !== 0 || status === "Completed"
                    ? true
                    : false
                }
                style={{ maxWidth: "270px" }}
                placeholder="Selektoni kategorinë"
                options={newInputs.current?.fieldOptions.map((item) => ({
                  key: item.id,
                  value: item.name,
                  label: item.name,
                }))}
              />
            </Form.Item>

            <div className="formItemButtonContainer">
              {uploadedFiles?.length !== 0 && (
                <Badge
                  offset={[-113, 2]}
                  count={uploadedFiles?.length}
                  status="success"
                >
                  <MondayButton
                    className="documentsMondayButton"
                    hasIcon={false}
                    onClick={openSiderModal}
                    disabled={isLogsModalVisible ? true : false}
                  >
                    Dokumente
                  </MondayButton>
                </Badge>
              )}
              <div className="ngarkoniDokumenteButtonContainer">
                <FolderIcon />
                <ProblemsDocUploader
                  disabled={
                    googleDriveFolderId?.length === 0 ||
                    (!ACCESS_RIGHTS.includes(userAccess[0].userRole) &&
                      Object.keys(editData).length !== 0) ||
                    status === "Completed"
                      ? true
                      : false
                  }
                  {...{
                    onPickerSuccess(param) {
                      onPickerSuccess(param);
                      setTempUploadedFiles([
                        ...tempUploadedFiles,
                        ...param.uploadedFiles.map(({ id }) => id),
                      ]);
                    },
                    uploadedFiles,
                    folderId: googleDriveFolderId,
                  }}
                />
              </div>
              {Object.keys(editData).length !== 0 && (
                <Form.Item
                  name="ticketStatus"
                  style={{ maxWidth: "155px" }}
                  rules={[
                    {
                      required: true,
                      message: "Kjo fushë nuk mund të jetë bosh!",
                    },
                  ]}
                >
                  <Select
                    type="select"
                    className="statusSelect"
                    width="100%"
                    onChange={handleStatusChange}
                    disabled={!ACCESS_RIGHTS.includes(userAccess[0].userRole)}
                    style={
                      status === "Open"
                        ? {
                            backgroundColor: "#FCC94A",
                            color: "#1D3445",
                          }
                        : {
                            backgroundColor: "#1DA193",
                            color: "white",
                          }
                    }
                    showSearch={false}
                    allowClear={false}
                    filterOption={false}
                    popupClassName={"dropdownStatus"}
                  >
                    {STATUS_OPTIONS.map((option, index) => (
                      <Select.Option
                        key={index}
                        value={option.value}
                        style={{
                          backgroundColor: option.backgroundColor,
                          color: option.color,
                          borderRadius: 5,
                          marginBottom: 2,
                        }}
                      >
                        {option.title}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </div>
          </div>
          <Form.Item
            className="textAreaFormItem"
            name="ticketDescription"
            rules={[
              { required: true, message: "Kjo fushë nuk mund të jetë bosh!" },
            ]}
          >
            <RichTextEditor
              disabled={
                (!ACCESS_RIGHTS.includes(userAccess[0].userRole) &&
                  Object.keys(editData).length !== 0) ||
                status === "Completed"
                  ? true
                  : false
              }
              placeholder="Shkruani problematikën..."
              className={"lajmerime-quill"}
            />
          </Form.Item>
        </Form>
      </Modal>
      <SiderModal
        {...{
          editData,
          onDelete,
          setEditData,
          uploadedFiles,
          setIsFieldTouched,
          isSiderModalVisible,
          setIsSiderModalVisible,
        }}
      />
      {!!isLogsModalVisible ? (
        <ActivityModal
          keylog={editData?.keylog}
          setIsModalVisible={setIsLogsModalVisible}
        />
      ) : null}
    </div>
  );
};

export default ProblemsModal;
