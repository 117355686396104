import { Form, Input } from "antd";
import FormActionButtons from "../../FormActionButtons/FormActionButtons";

const NEW_CATEGORY_LABEL = "Si e ka emrin?";

const NewDocPanel = ({ form, onSubmit, onDiscard }) => (
  <Form {...{ form }}>
    <div className="newDocPanel">
      <div className="newCatLabel">{NEW_CATEGORY_LABEL}</div>
      <div className="newCatInput">
        <Form.Item name="categoryName">
          <Input
            onPressEnter={onSubmit}
            required
            autoComplete="off"
            placeholder="Emërtojeni këtu..."
          />
        </Form.Item>
      </div>
      <FormActionButtons
        {...{ className: "newCatButtons", onSubmit, onDiscard }}
      />
    </div>
  </Form>
);

export default NewDocPanel;
