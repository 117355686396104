import { message, Modal, Popconfirm, Select } from "antd";
import { API } from "aws-amplify";
import React, { useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Employees from "../../../../dashboard/AdminDashboard/AdminCards/Employees/Employees";
import { users } from "../../../../../../src/store/actions";
import "./MergeEmpl.scss";
import { requestMultipleTables } from "../../../../../helpers/API/RequestFactory";
import { ModalXIcon } from "../../../../../assets/icons";
const { Option } = Select;
function MergeEmployeeUser({ visible, setVisible, selected }) {
  const { users } = useSelector((state) => state.usersList);
  const navigate = useNavigate();
  const { employees } = useSelector((state) => state.employeesList);
  const [userSub, setUserSub] = useState(null);
  const dispatch = useDispatch();
  const handleOk = async () => {
    await onMerge();
    setVisible(false);
  };
  const handleCancel = () => {
    setVisible(false);
  };
  const handleReduxRefresh = async () => {
    await requestMultipleTables(["users", "employees"]).then((res) => {
      dispatch(users(res));
      dispatch(employees(res));
    });
    await API.get("users", "/users").then((res) => {
      dispatch(users(res[0]));
      dispatch(employees(res[1]));
    });
  };
  const onMerge = async () => {
    let emplId = selected?.employeeId || null;
    if (!!emplId && !!userSub) {
      await API.put("employees", `/employees/${emplId}`, {
        body: {
          userSub: userSub,
          accountStatus: "Merged",
        },
      })
        .then(() => {
          handleReduxRefresh();
          message.success("U lidh me sukses!");
          navigate(`/punonjesit/specifikat/${emplId}`, {
            state: { ...selected, userSub: userSub, accountStatus: "Merged" },
          });
          handleCancel();
        })
        .catch((err) => {
          message.error("Ndodhi nje gabim!");
          console.error(err);
        });
    } else {
      message.error("Ndodhi nje gabim ne perditesimin e te dhenave!");
    }
  };
  const userOptions = useMemo(() => {
    if (!!users && !!employees) {
      let tempUsers = users?.allUsers?.Items || [];
      let userOptions = [];
      tempUsers.map((user) => {
        let isFound = employees.find((empl) => empl?.userSub === user?.userSub);
        userOptions.push(
          <Option value={user?.userSub} disabled={!!isFound ? true : false}>
            {user?.given_name + " " + user?.family_name}
          </Option>
        );
      });
      return userOptions;
    }
  }, [users, employees]);
  const handleChange = (val) => {
    setUserSub(val);
  };
  return (
    <div>
      <Modal
        className="merge-user-empl"
        width={618}
        destroyOnClose={true}
        title="Lidh punonjësin me një adresë përdoruesi ekzistues!"
        open={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        closeIcon={<ModalXIcon />}
        footer={[
          <button className="close-btn" onClick={handleCancel}>
            Mbyll Faqen
          </button>,
          <Popconfirm
            title={"A jeni te sigurt?"}
            okText={"Po, konfirmo."}
            cancelText={"Jo, anullo"}
            onConfirm={onMerge}
          >
            <button className="start-btn">Lidh Userin</button>
          </Popconfirm>,
        ]}
      >
        <div className="merge-user-wrapper">
          <span className="title-merge">
            Llogaria ekzistuese do të lidhet me punonjësin e selektuar.
          </span>
          <br />
          <Select
            style={{ width: 220 }}
            showSearch
            onChange={handleChange}
            optionFilterProp="children"
            placeholder="Zgjidh një user"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {userOptions}
          </Select>
          {/* <button onClick={onMerge}>Lidh</button> */}
        </div>
      </Modal>
    </div>
  );
}
export default MergeEmployeeUser;
