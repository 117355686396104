import { message } from "antd";
import { API } from "aws-amplify";

export const disableUser = async (identitiyId) => {
  return await API.put("users", `/users/${identitiyId}`, {
    body: {
      disabled: true,
    },
  })
    .then((success) => message.success("Useri i caktivizua me sukses!"))
    .catch((err) => {
      console.error(err);
      message.error("Ndodhi nje gabim.");
    });
};

export const enableUser = async (identitiyId) => {
  return await API.put("users", `/users/${identitiyId}`, {
    body: {
      disabled: false,
    },
  })
    .then((success) => message.success("Useri i aktivizua me sukses!"))
    .catch((err) => {
      console.error(err);
      message.error("Ndodhi nje gabim.");
    });
};

export const forceResetUser = async (identitiyId) => {
  return await API.put("users", `/users/${identitiyId}`, {
    body: {
      forceReset: true,
    },
  })
    .then((success) => message.success("Veprimi u ruajt me sukses!"))
    .catch((err) => {
      console.error(err);
      message.error("Ndodhi nje gabim.");
    });
};

export const forceResetUserDisable = async (identitiyId) => {
  return await API.put("users", `/users/${identitiyId}`, {
    body: {
      forceReset: false,
    },
  })
    .then((success) => message.success("Veprimi u ruajt me sukses!"))
    .catch((err) => {
      console.error(err);
      message.error("Ndodhi nje gabim.");
    });
};
