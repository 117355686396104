import { createContext, useReducer, useState } from "react";
import NotifReducer from "./notifReducer";
import { API } from "aws-amplify";

const INITIAL_STATE = {
  user: "flex@flex.al",
  isFetching: true,
  error: false,
  notificationsArr: [],
};

export const NotifContext = createContext(INITIAL_STATE);

export const NotifContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(NotifReducer, INITIAL_STATE);

  return (
    <NotifContext.Provider
      value={{
        user: state.user,
        isFetching: state.isFetching,
        error: state.error,
        notificationsArr: state.notificationsArr,
        dispatch,
      }}
    >
      {children}
    </NotifContext.Provider>
  );
};
