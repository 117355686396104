import { useState } from "react";
import "./Notifications.scss";
import { Tabs, Badge } from "antd";
import NotificationList from "./notificationList/NotificationList";
import { API } from "aws-amplify";
import moment from "moment-timezone";
import FilterHeader from "./notificationList/filterHeader/FilterHeader";
import { ModalXIcon } from "../../../../assets/icons";
const { TabPane } = Tabs;

function Notifications({ handleCancel }) {
  const [activeKey, setActiveKey] = useState(1);
  const [badgeCount, setBadgeCount] = useState({
    All: 0,
    Hidden: 0,
    Relevant: 0,
    Mentioned: 0,
  });
  const [activeFilter, setActiveFilter] = useState(null);

  function callbackActiveKey(key) {
    setActiveKey(key);
  }

  const processActiveFilter = (filterID) => {
    if (filterID !== activeFilter) {
      setActiveFilter(filterID);
    } else {
      setActiveFilter(null);
    }
  };

  const countNotifs = (notifs) => {
    // console.log(notifs);
    if (!!notifs) {
      setBadgeCount(notifs);
    }
  };

  const notifProps = {
    activeFilter,
    processActiveFilter,
    activeKey,
    countNotifs,
  };

  return (
    <div className="dashboard-notifications">
      {/* <div className="notifications-title">
        <span className="title">Njoftime</span>
      </div> */}

      <Tabs
        animated={{ inkBar: true, tabPane: false }}
        tabPosition={"top"}
        defaultActiveKey={1}
        onChange={() => callbackActiveKey}
        className="notification-tabs"
        tabBarExtraContent={
          <div className="extraContentContainer">
            <ModalXIcon
              style={{ cursor: "pointer" }}
              onClick={() => handleCancel()}
            />
            <div className="extraContentTitle">Njoftimet</div>
          </div>
        }
      >
        {notifsPages.map((n) => (
          <TabPane
            tab={
              <Badge
                key={n.id}
                style={{
                  position: "relative",
                  top: -7,
                  paddingTop: 1,
                  paddingBottom: 1,
                }}
                count={badgeCount[n.type]}
              >
                {n.name}
              </Badge>
            }
            key={n.id}
          >
            {" "}
            <NotificationList
              handleCancel={handleCancel}
              notifsType={n.type}
              {...notifProps}
            />
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
}

const notifsPages = [
  { id: 1, name: "Të gjitha", type: "All" },
  // { id: 2, name: "Relevante", type: "Relevant" },
  { id: 3, name: "Të Fshehura", type: "Hidden" },
  // { id: 4, name: "Caktuar Mua", type: "Mentioned" },
];
export default Notifications;
