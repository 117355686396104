import React from "react";
import { ChristmasLights, SpiderWeb } from "../../../assets/holidays/icons";
import { BreakTimeIcon } from "../../../assets/icons";
import { useAuth } from "../../../authentication/authHelpers";
import "./MoreInformationCard.scss";
import moment from "moment";

const MoreInformationCard = () => {
  const { employee } = useAuth();
  return (
    <>
      <div className="container" style={{ paddingBottom: 0 }}>
        <div className="wrapperIcon">
          <BreakTimeIcon />
          {/* <div style={{ width: "100px", marginTop: "-20px", position: "relative" }}>
						<ChristmasLights />
					</div> */}
        </div>
        <div className="breakContent">
          <span className="breakText">Koha e pushimit</span>
          {employee?.breakTime?.length ? (
            <p className="oret">{`${employee?.breakTime[0]}-${employee?.breakTime[1]}`}</p>
          ) : (
            <p className="text">Nuk ka te dhena</p>
          )}
        </div>
      </div>
    </>
  );
};

export default MoreInformationCard;
