import { Button, Card, message } from "antd";
import React, { useState, useEffect } from "react";
import { useAuth } from "../../../authentication/authHelpers";
import CustomTransfer from "../../commonComponents/CustomTransfer/CustomTransfer";
import "./Preferencat.scss";
const Preferencat = () => {
  const [selectedCards, setSelectedCards] = useState([]);
  const [dashboardData, setDashboardData] = useState([]);
  const [dashboardType, setDashboardType] = useState();
  const auth = useAuth();
  useEffect(() => {
    if (auth.userAccess.length > 0) {
      if (
        auth.userAccess[0].userRole === "Admin" ||
        auth.userAccess[0].userRole === "Super Admin"
      ) {
        const dashboardData = JSON.parse(
          localStorage.getItem("draggableOrderAdmin")
        );
        setDashboardData(dashboardData);

        setSelectedCards(
          dashboardData
            ?.filter?.(({ data: { hidden } }) => !!hidden)
            ?.map?.(({ i }) => i)
        );
        setDashboardType("draggableOrderAdmin");
      } else {
        const dashboardData = JSON.parse(
          localStorage.getItem("draggableOrderUser")
        );
        setDashboardData(dashboardData);
        setSelectedCards(
          dashboardData
            ?.filter?.(({ data: { hidden } }) => !!hidden)
            ?.map?.(({ i }) => i)
        );
        setDashboardType("draggableOrderUser");
      }
    }
  }, [auth]);

  const handleStorage = () => {
    const key = "updatable";
    let temp = dashboardData;
    if (selectedCards.length > 0) {
      temp = dashboardData.map((el, i) => {
        if (selectedCards.some((sc) => sc === el.i)) {
          return { ...el, data: { ...el.data, hidden: true } };
        }
        return { ...el, data: { ...el.data, hidden: false } };
      });
      dashboardType === "draggableOrderAdmin"
        ? localStorage.setItem("draggableOrderAdmin", JSON.stringify(temp))
        : localStorage.setItem("draggableOrderUser", JSON.stringify(temp));
    }
    if (selectedCards.length === 0) {
      temp = temp.map((el) => ({ ...el, data: { ...el.data, hidden: false } }));
      setDashboardData(temp);
      dashboardType === "draggableOrderUser"
        ? localStorage.setItem("draggableOrderUser", JSON.stringify(temp))
        : localStorage.setItem("draggableOrderAdmin", JSON.stringify(temp));
    }
    message.loading({ content: "Duke u ruajtur...", key });
    setTimeout(() => {
      message.success({
        content: "Kartat u ruajten me sukses!",
        key,
        duration: 2,
      });
    }, 1000);
  };


  return (
    <div className="div-pref">
      <div className="preferencat">
        <Card className="KartatEShfaqura" title="Preferencat">
          <div>
            <CustomTransfer
              value={selectedCards}
              onChange={setSelectedCards}
              dataSource={dashboardData?.map(({ i, data }) => ({
                key: i,
                title: data?.emri,
              }))}
              label={
                <div className="textCards">
                  <h3>Kartat e shfaqura</h3>
                  <h3>Kartat e fshehura</h3>
                </div>
              }
            />
          </div>
          <div className="save-btn">
            <Button onClick={handleStorage} className="saveStorage">
              Ruaj
            </Button>
          </div>
        </Card>
      </div>
    </div>
  );
};
export default Preferencat;
