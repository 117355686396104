import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const QuillRenderer = ({ value }) => {
  return (
    <ReactQuill
      readOnly
      value={value || ""}
      modules={{
        toolbar: false,
      }}
    />
  );
};

export default QuillRenderer;
