import {
  IT,
  IconDepartment,
  EngenieringIcon,
  Flex,
  SalesManagement,
  InternalStaff,
  HR,
  ProjectCoordinating,
} from "../../../../../assets/icons";
import { CalculatorFilled, QuestionCircleOutlined } from "@ant-design/icons";
import "./ClockInStats.scss";
import { InternalStaffIcon } from "../../../../../Departments/assets";

//Zgjedh icon sipas emrit te departamentit
export const iconPicker = (depName) => {
  switch (depName) {
    case "IT & Software Development":
      return <IT />;
      break;
    case "Finance & Accounting":
      return (
        <CalculatorFilled style={{ fontSize: "20px", color: "#323338" }} />
      );
      break;
    case "Human Resources":
      return <HR style={{ fontSize: "20px", color: "#323338" }} />;
      break;
    case "Administration":
      return <Flex width={22} height={20} />;
      break;
    case "Architecture & Engineering":
      return <EngenieringIcon style={{ fontSize: "20px", color: "#323338" }} />;
      break;
    case "Internal Staff":
      return <InternalStaff style={{ fontSize: "20px", color: "#323338" }} />;
      break;
    case "Sales Management":
      return <SalesManagement style={{ fontSize: "20px", color: "#323338" }} />;
      break;
    case "Project Monitoring & Coordinating":
      return (
        <ProjectCoordinating style={{ fontSize: "20px", color: "#323338" }} />
      );
      break;
    default:
      return <QuestionCircleOutlined />;
      break;
  }
};
