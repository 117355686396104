import React, { useState, useEffect } from "react";
import {
  Drawer,
  Input,
  Select,
  DatePicker,
  Form,
  Col,
  Row,
  Button,
  Table,
} from "antd";
import "./ProblemsDrawer.scss";
import moment from "moment-timezone";
import { useAuth } from "../../../authentication/authHelpers";
import ProblemsModal from "../../dashboard/AdminDashboard/AdminCards/Problems/PromblemsModal/ProblemsModal";
import { useSelector } from "react-redux";
import { htmlParser } from "../../../utils";
import ProblemStatus from "../../dashboard/AdminDashboard/AdminCards/Problems/ProblemStatus/ProblemStatus";
import { ModalXIcon } from "../../../assets/icons";
const { Option } = Select;
const ProblemsDrawer = ({ problemsActive, setProblemsActive }) => {
  const { problematikat } = useSelector((state) => state.problematikat);
  const { userAccess } = useAuth();

  const [form] = Form.useForm();
  const [filteredData, setFilteredData] = useState(null);
  const [editData, setEditData] = useState({});
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);

  const username = {
    userId: userAccess[0].userSub,
    employeeFirstName: userAccess[0].given_name,
    employeeLastname: userAccess[0].family_name,
  };

  const handleRowClick = (data) => {
    setEditData(data);
    setIsAddModalVisible(true);
  };

  const onClose = () => {
    setProblemsActive(false);
  };

  const handleClear = () => {
    form.resetFields();
    setFilteredData(
      problematikat.filter((item) => item.ticketStatus === "Open")
    );
  };

  const handleFilter = (value) => {
    let test = problematikat?.filter((x) => {
      return (
        (!value?.status || x?.ticketCategory?.includes(value.status)) &&
        (!value?.emplName ||
          x?.createdBy?.toLowerCase().includes(value.emplName.toLowerCase())) &&
        (!value?.filterDate ||
          moment(value?.filterDate).utc().format("DD MM YYYY") ===
            moment(x?.createdAt).utc().format("DD MM YYYY"))
      );
    });

    setFilteredData(test.filter((item) => item.ticketStatus === "Open"));
  };

  const columns = [
    {
      title: "Emër Mbiemër",
      dataIndex: "createdBy",
      key: "createdBy",
      align: "center",
      ellipsis: true,
      // render(text) {
      //   return (
      //     <span>{`${text.employeeFirstName} ${text.employeeLastname}`}</span>
      //   );
      // },
    },
    {
      title: "Përshkrimi",
      dataIndex: "ticketDescription",
      key: "ticketDescription",
      align: "center",
      ellipsis: true,
      render(text) {
        return (
          <span className="problemsDescriptionCell">
            {Array.isArray(htmlParser(text))
              ? htmlParser(text)[0]
              : htmlParser(text)}
          </span>
        );
      },
    },
    {
      title: "Kategoria",
      dataIndex: "ticketCategory",
      key: "ticketCategory",
      align: "center",
    },
    {
      title: "Statusi",
      dataIndex: "ticketStatus",
      key: "ticketStatus",
      align: "center",
      width: "180px",
      render: (text) => <ProblemStatus status={text} />,
    },
    {
      title: "Data e Krijimit",
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      ellipsis: true,
      render: (record) => <span>{moment(record).format("DD/MM/YYYY")}</span>,
    },
  ];

  useEffect(() => {
    setFilteredData(
      problematikat.filter((item) => item.ticketStatus === "Open")
    );
  }, [problematikat]);

  return (
    <div>
      <Drawer
        className="req-drawer"
        title="Problematikat"
        placement="right"
        onClose={onClose}
        open={problemsActive}
        key={"Problematikat"}
        closeIcon={<ModalXIcon />}
      >
        <Form
          className="filters-container"
          onFinish={handleFilter}
          form={form}
          name="filter-requests"
          labelCol={{
            span: 9,
          }}
          wrapperCol={{
            span: 16,
          }}
          autoComplete="off"
          autoFocus
        >
          <Form.Item name="emplName">
            <Input
              className="name-filter-input"
              placeholder="Kërko punonjësin..."
            />
          </Form.Item>
          <Form.Item name="status">
            <Select className="status-filter-select" defaultValue="Të gjithë">
              <Option key="1" value="">
                Të gjithë
              </Option>
              <Option key="2" value="option1">
                Option 1
              </Option>
              <Option key="3" value="option2">
                Option 2
              </Option>
              <Option key="4" value="option3">
                Option 3
              </Option>
            </Select>
          </Form.Item>
          <Form.Item name="filterDate">
            <DatePicker className="date-filter" placeholder="Zgjidh datën" />
          </Form.Item>
          <Button
            className="filter-button"
            htmlType="submit"
            key="submit"
            form="filter-requests"
          >
            Filtro
          </Button>
          <Button className="clear-filter" onClick={handleClear}>
            Pastro
          </Button>
        </Form>
        <Table
          columns={columns}
          dataSource={filteredData}
          pagination={false}
          rowKey={(record) => record.ticketId}
          onRow={(record) => ({
            onClick: () => handleRowClick(record),
          })}
          scroll={{
            x: 650,
          }}
        />
      </Drawer>
      {!!isAddModalVisible && (
        <ProblemsModal
          isAddModalVisible={isAddModalVisible}
          setIsAddModalVisible={setIsAddModalVisible}
          username={username}
          editData={editData}
          setEditData={setEditData}
        />
      )}
    </div>
  );
};
export default ProblemsDrawer;
