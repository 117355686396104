import types from "../types/types";

const initialState = {
  hotCredentials: [],
};
const hotCredentialsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_HOT_CREDENTIALS:
      return {
        ...state,
        hotCredentials: payload,
      };
    default:
      return state;
  }
};
export default hotCredentialsReducer;
