import "./UserCard.scss";
import React from "react";

function UserActionCards({ contentChild, headerName }) {
  return (
    <div className="user-action-card">
      <div className="user-action-header">
        <span className="user-header-title">{headerName}</span>
      </div>
      {contentChild}
    </div>
  );
}

export default UserActionCards;
