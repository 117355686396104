import React, { useState, useMemo } from "react";
import PayStubTable from "../../../PaySub/payStubTable/PayStubTable";
import { prepareAdditions, prepareDeductions } from "./calculations";
import "./Additionals.scss";
import { DeleteFilled } from "@ant-design/icons";

import { isNumber } from "lodash";
function Additionals({ additionsData, setAdditionsData }) {
	const [tableData, setTableData] = useState({});
	useMemo(() => setTableData(prepareAdditions(additionsData)), [additionsData]);

	const deleteRow = (data, index) => {
		// setTableData((prev) => ({ ...prev, rowData: tableData?.rowData?.filter((el) => el !== data) }));
		setAdditionsData(additionsData.filter((el, idx) => idx !== index));
		// console.log("deductionsData", additionsData);
	};
	return (
		<div className="additions-table">
			{/* <br />
			<h2>Fitime</h2> */}
			<Table
				deletable={true}
				columnsDef={tableData?.columnsDef}
				rowData={tableData?.rowData}
				deleteRow={deleteRow}
				color={"#00A115"}
			/>{" "}
		</div>
	);
}

export default Additionals;

function Table({ columnsDef, rowData, deleteRow, deletable, color }) {
	return (
		<div className="add-table">
			<table>
				<tr>
					{columnsDef?.map((el, idx) => (
						<th style={idx !== 0 ? { textAlign: "right" } : null}>{el}</th>
					))}
				</tr>
				{rowData?.length > 0 ? (
					rowData?.map((rd, idx) => (
						<tr>
							{rd?.map((trd, idx) => (
								<td style={idx !== 0 ? { textAlign: "right" } : null}>
									{isNumber(trd) ? trd?.toFixed(2) : trd}
								</td>
							))}

							{idx !== rowData?.length - 1 && deletable ? (
								<td>
									<DeleteFilled
										onClick={() => deleteRow(rd, idx)}
										style={{
											fontSize: "18px",
											color: "red",
											cursor: "pointer",
										}}
									/>
								</td>
							) : (
								<td></td>
							)}
						</tr>
					))
				) : (
					<strong style={{ display: "flex", justifyContent: "center", color: "red" }}>
						{/* Nuk ka te dhena per te shfaqur */}
					</strong>
				)}
			</table>
		</div>
	);
}
