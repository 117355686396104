import { Modal } from 'antd'
import React from 'react'
import { TickIcon, XIcon, Warning } from '../../../assets/icons';
import MondayButton from '../../commonComponents/MondayButton/MondayButton';
import { API } from 'aws-amplify';
import Swal from 'sweetalert2';

const ConfirmModal = ({
    showClearConfirmModal,
    confirmChangesType,
    setShowClearConfirmModal,
    setRowData,
    programFields,
    setChanges,
    gridApi,
    deletedFields
}) => {
    const handleSubmit = () => {
        const QuestionProgramField = programFields.find(({ fieldName }) => fieldName === "Konfigurimi i Kandidateve")
        Swal.fire({
            title: "Ju lutem prisni...",
            didOpen: () => {
                Swal.showLoading();
            },
        });
        let gridRowData = [];
        gridRowData.push(...deletedFields)
        gridApi.forEachNode(({ data }) => gridRowData.push(data));
        if (confirmChangesType === "Confirm") {
            API.put("programFields", `/programFields/${QuestionProgramField.fieldId
                }`, {
                body: {
                    fieldOptions: gridRowData
                }
            }).then(() => {
                setShowClearConfirmModal(false)
                setChanges(false)
                QuestionProgramField.fieldOptions = gridRowData
                Swal.fire({
                    icon: "success",
                    title: "Ndryshimet u ruajten!",
                });
            }).catch(err => {
                console.log("Error: ", err)
            })
        } else {
            setRowData(QuestionProgramField.fieldOptions)
            setShowClearConfirmModal(!showClearConfirmModal)
            setChanges(false)
            Swal.fire({
                icon: "success",
                title: "Ndryshimet u anulluan!",
                didOpen: () => {
                    Swal.hideLoading()
                }
                // timer: 1500,
            });
        }
    }

    return (
        <Modal
            open={showClearConfirmModal}
            centered
            onCancel={() => setShowClearConfirmModal(!showClearConfirmModal)}
            title="Warning Message"
            className='confirm-modal'
            closeIcon={<XIcon />}
            footer={[
                <>
                    <MondayButton className='mondayButtonRed whiteIconBackground' Icon={<XIcon width={10} height={10} />} onClick={() => setShowClearConfirmModal(!showClearConfirmModal)}>Cancel</MondayButton>
                    <MondayButton Icon={<TickIcon width={18} height={18} />} onClick={handleSubmit} className='mondayButtonGreen'>Save</MondayButton>
                </>
            ]}
        >
            <div className='confirm-body'>
                <img src={Warning} width={60} height={60} alt='Warning Icon' />
                <p>{`Are you sure you want to ${confirmChangesType === "Confirm" ? "save" : "cancel"} this changes?`}</p>
            </div>
        </Modal>
    )
}

export default ConfirmModal