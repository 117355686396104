import React from "react";
import { Transfer } from "antd";

const AntdTransfer = ({
  dataSource,
  showSearch,
  filterOption,
  onSearch,
  render,
  targetKeys,
  setTargetKeys,
}) => {
  const onChange = (newTargetKeys) => setTargetKeys(newTargetKeys);

  return (
    <Transfer
      dataSource={dataSource}
      targetKeys={targetKeys}
      showSearch={showSearch}
      filterOption={filterOption}
      onChange={onChange}
      onSearch={onSearch}
      render={render}
    />
  );
};

export default AntdTransfer;
