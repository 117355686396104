import React, { useEffect } from "react";
import { Form, DatePicker, Select, Radio, InputNumber } from "antd";
import "./TypeOfWork.scss";
import { useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment-timezone";

const TypeOfWork = () => {
  const { programFields } = useSelector((state) => state.programFields);
  const { departments } = useSelector((state) => state.departments);
  const [active, setActive] = useState(true);
  const [roles, setRoles] = useState();
  const { Option } = Select;
  const [value, setValue] = React.useState(1);
  const [paymentType, setPaymentType] = useState();
  const [workType, setWorkType] = useState();
  const [contractDuration, setContractDuration] = useState();
  const [startDate, setStartDate] = useState();
  const [fixedPay, setFixedPay] = useState(true);
  const [hourPay, setHourPay] = useState(true);

  //! validates date
  const date = moment([2018]);
  const correctDate = (current) => {
    return current && current < date;
  };

  //! get departmentRoles from departments
  const setRoleOptions = (value) => {
    setRoles(
      departments.find(({ departmentName }) => departmentName === value)
        .departmentRoles
    );
  };

  //! gets type of work from programFields
  useEffect(() => {
    const wt = programFields.find(
      (res) => res.fieldName === "Lloji i punesimit"
    );
    setWorkType(wt);
  }, [programFields]);

  //! gets payment type from programFields
  useEffect(() => {
    const pt = programFields.find(
      (input) => input.fieldName === "Menyrat e pageses"
    );
    setPaymentType(pt);
  }, [programFields]);

  //! gets contract duration from programFields
  useEffect(() => {
    const contractTime = programFields?.find(
      (el) => el?.fieldName === "Periudha e Kontrates"
    );
    setContractDuration(contractTime);
  }, [programFields]);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <div className="content">
      <div className="secondModalContainer">
        <div className="modalContainer">
          <Form.Item
            className="input-container"
            name="employeeWorkStartDate"
            label="Data e Fillimit"
          >
            <DatePicker
              allowClear
              format="DD/MM/YYYY"
              className="work-date"
              placeholder="Selekto datën e fillimit të punës"
            />
          </Form.Item>
          <Form.Item
            className="input-container"
            name="employeeDepartmentName"
            label="Departamenti"
            rules={[
              {
                required: true,
                message: "Departamenti është i detyrueshëm",
              },
            ]}
          >
            <Select
              className="select-3"
              allowClear
              placeholder="Selekto departamentin"
              style={{
                background: "#f5f5f7",
                borderRadius: 5,
                border: "none",
              }}
              onSelect={setRoleOptions}
            >
              {departments.map((departmentData) => (
                //* map all departments and gets departmentName from DB
                <Option value={departmentData.departmentName}>
                  {departmentData.departmentName}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            className="input-container"
            name="employeeRoleName"
            label="Pozicioni"
            rules={[
              {
                required: true,
                message: "Pozicioni punonjësit është i detyrueshëm",
              },
            ]}
          >
            <Select
              allowClear
              className="select-3"
              placeholder="Selekto pozicionin e punës së punonjësit"
              style={{
                background: "#f5f5f7",
                borderRadius: 5,
                border: "none",
              }}
            >
              {roles?.map((roleData) => (
                //* map all roles from departments and gets name from DB
                <Option value={roleData?.name}>{roleData?.name}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            className="input-container"
            name="employeeTypeOfEmployment"
            label="Lloji i Punësimit"
            rules={[
              {
                required: true,
                message: "Lloji i punësimit është e detyrueshme",
              },
            ]}
          >
            <Select
              className="select-3"
              placeholder="Selekto llojin e punësimit"
              allowClear
              style={{
                background: "#f5f5f7",
                borderRadius: 5,
              }}
            >
              {workType?.fieldOptions?.map((work) => (
                //* map workType for each work in DB
                <Option value={work}>{work}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            className="input-container"
            name="employeePayrollType"
            label="Mënyra e Pagesës"
            // value={view}
            rules={[
              {
                required: true,
                message: "Mënyra e pagesës është e detyrushme",
              },
            ]}
          >
            <Select
              className="select-3"
              placeholder="Selekto mënyrën e pagesës"
              allowClear
              style={{
                background: "#f5f5f7",
                borderRadius: 5,
                border: "none",
              }}
              //* makes employeeMonthlyNetSalary and emplyeeSalaryPerHour visible or not
              onSelect={(value) => {
                if (value === "Page Fikse") {
                  setFixedPay(false);
                  setHourPay(true);
                } else {
                  setHourPay(false);
                  setFixedPay(true);
                }
              }}
            >
              {paymentType?.fieldOptions?.map((pay) => (
                //* map paymentType for each payment on DB
                <Option value={pay.name}>{pay.name}</Option>
              ))}
            </Select>
          </Form.Item>
          {!fixedPay && (
            <Form.Item
              className="input-container"
              name="employeeMonthlyNetSalary"
              label="Paga neto mujore"
              hidden={fixedPay}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber
                autoComplete="off"
                allowClear
                className="inputNumber"
                type="number"
                placeholder="Shkruaj pagën neto mujore"
              />
            </Form.Item>
          )}
          {!hourPay && (
            <Form.Item
              className="input-container"
              name="employeeSalaryPerHour"
              label="Paga për orë"
              hidden={hourPay}
            >
              <InputNumber
                autoComplete="off"
                type="number"
                className="inputNumber"
                placeholder="Shkruaj pagën për orë"
              />
            </Form.Item>
          )}
        </div>
        <div className="contract">
          <Form.Item
            className="input-container"
            name="employeeContractType"
            label="Lloji i kontratës"
            rules={[
              {
                required: true,
                message: "Lloji i kontratës është i detyrueshëm",
              },
            ]}
          >
            <Radio.Group onChange={onChange} value={value}>
              <Radio value={"E përcaktuar"} onClick={() => setActive(false)}>
                E përcaktuar
              </Radio>
              <Radio
                value={"E papërcaktuar"}
                onClick={() => {
                  setActive(true);
                }}
              >
                E papërcaktuar
              </Radio>
            </Radio.Group>
          </Form.Item>
          {!active && (
            <div className="dates">
              <Form.Item
                className="content"
                name="employeeHireDate"
                label="Fillimi Kontratës"
                hidden={active}
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  className="input"
                  onChange={(date) => {
                    setStartDate(date);
                  }}
                  disabledDate={correctDate}
                  placeholder="Selekto fillimin e kontratës"
                />
              </Form.Item>
              <Form.Item
                className="content"
                name="employeeContractDuration"
                label="Periudha e kontratës"
                hidden={active}
              >
                <Select
                  style={{ width: 258 }}
                  className="inputDuration"
                  placeholder="Selekto kohëzgjatjen e kontratës"
                  allowClear
                >
                  {contractDuration?.fieldOptions?.map((duration) => (
                    //* map employeeContractDuration for each payment on DB
                    <Option value={duration.name}>{duration.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TypeOfWork;
