import types from "../types/types";

const initialState = {
    problematikat: []
};
function problematikatReducer(state = initialState, action) {
    const { type, payload } = action;

    if (type === types.SET_PROBLEMS) {
        if (Array.isArray(payload)) {
            return {
                problematikat: mergeProblematikat(state.problematikat, payload)
            }
        } else {
            const existingIndex = state.problematikat.findIndex(({ ticketId }) => ticketId === payload?.ticketId);
            if (existingIndex > -1) {
                let tmp = [...state.problematikat];
                tmp[existingIndex] = {
                    ...tmp[existingIndex],
                    ...payload
                }

                return {
                    problematikat: tmp
                }
            } else {
                return {
                    problematikat: [
                        ...state.problematikat,
                        payload
                    ]
                }
            }
        }
    } else if (type === types.REMOVE_PROBLEMS) {
        if (Array.isArray(payload)) {
            const idsToRemove = payload.map(({ ticketId }) => ticketId);
            return {
                problematikat: state.problematikat.filter(({ ticketId }) => !idsToRemove.includes(ticketId))
            };
        } else {
            return {
                problematikat: state.problematikat.filter(({ ticketId }) => ticketId !== payload.ticketId)
            };
        }
    } else {
        return state
    }
}

function mergeProblematikat(initial, newEntries) {
    const initialDictionary = initial.reduce((acc, val) => ({ ...acc, [val?.ticketId]: { ...val } }), {});
    const finalState = [];

    for (const entry of newEntries) {
        const existingEntry = initialDictionary?.[entry?.ticketId];
        if (existingEntry) {
            finalState.push({
                ...existingEntry,
                ...entry
            })
        } else {
            finalState.push(entry)
        }
    }

    return finalState;
}

export default problematikatReducer;
