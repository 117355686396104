import React, { useMemo } from "react";
import { getSummary } from "./calculations";
import Table from "./Table";

function Summary({ summaryData }) {
	return (
		<div>
			<br />
			<h2>Permbledhja</h2>
			<Table
				deletable={false}
				columnsDef={summaryData?.columnsDef}
				rowData={summaryData?.rowData}
			/>
		</div>
	);
}

export default Summary;
