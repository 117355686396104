import React, { useMemo } from "react";
import "./UserWageHistory.scss";
import {
	AreaChart,
	Area,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	ResponsiveContainer,
} from "recharts";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import { getMonthStatistic } from "./calculations";
import { useAuth } from "../../../authentication/authHelpers";

function UserWageHistory({ employee }) {
	const { wages } = useSelector((state) => state.wages);
	const auth = useAuth();

	const wagesHistoryData = useMemo(() => {
		if (!!wages) {
			// console.log("Wages", wages);
			let currentMom = moment().startOf("month");
			let lastSixMonths = [];
			[...Array(6)].map((_, idx) => {
				lastSixMonths.push(moment(currentMom).subtract(5 - idx, "months"));
			});
			if (!employee && !!auth?.employeeId) {
				return lastSixMonths.map((month) => {
					let res = getMonthStatistic(wages, month, auth?.employeeId);
					return {
						name: moment(month).format("MMMM YYYY"),
						// "Shuma Bruto": res?.bruto.toFixed(2),
						"Shuma Neto": res?.neto?.toFixed(2),
						// "Kosto e Kompanise": res?.totalCost.toFixed(2),
					};
				});
			} else if (!!employee) {
				return lastSixMonths.map((month) => {
					let res = getMonthStatistic(wages, month, employee);
					return {
						name: moment(month).format("MMMM YYYY"),
						// "Shuma Bruto": res?.bruto.toFixed(2),
						"Shuma Neto": res?.neto?.toFixed(2),
						// "Kosto e Kompanise": res?.totalCost.toFixed(2),
					};
				});
			}
		}
	}, [wages, employee, auth]);
	return (
		<div
			className="wages-data-chart"
			style={{
				width: !employee ? 795 : 390,
				height: !employee ? 225 : 425,
				padding: 10,
				boxShadow: "0px 1px 4px #00000029",
				borderRadius: "0 0 5px 5px",
			}}
		>
			<ResponsiveContainer>
				<AreaChart
					data={wagesHistoryData}
					margin={{
						top: 10,
						right: 30,
						left: 0,
						bottom: 0,
					}}
				>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis dataKey="name" />
					<YAxis />
					<Tooltip />
					<Area type="monotone" dataKey="Shuma Neto" stackId="1" stroke="#82ca9d" fill="#82ca9d" />
				</AreaChart>
			</ResponsiveContainer>
		</div>
	);
}

export default UserWageHistory;
