import { Button } from "antd";
import { API } from "aws-amplify";
import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { InfoBluecIcon, InfoOvertime } from "../../../../assets/icons";
import { useAuth } from "../../../../authentication/authHelpers";
import InfoOvertimeModal from "./InfoOvertimeModal";
import "./WorkBeforeShift.scss";
import WorkBeforeShiftModal from "./WorkBeforeShiftModal";
const WorkBeforeShift = () => {
  const [visible, setVisible] = useState(false);
  // const [open, setOpen] = useState(false);
  const [overtime, setOvertime] = useState([]);
  const [infoVisible, setInfoVisible] = useState(false);
  const [overtimeUser, setOvertimeUser] = useState([]);
  // const [specificData, setSpecificData] = useState([]);
  const { employee } = useAuth();
  const workBeforeShiftData = [
    "Data e krijimit",
    "Kërkesë për",
    "Statusi",
    "Ora e fillimit",
    "Ora e mbarimit",
    "Lloji i kërkesës",
  ];
  const getColor = (reqStatus) => {
    switch (reqStatus) {
      case "Aprovuar":
        // autumn theme
        return "#244431";
      case "Refuzuar":
        return "#CD1818";
      case "Në pritje":
        return "#1D3445";
      default:
        return "#9c5708";
    }
  };

  useEffect(() => {
    if (overtime?.records?.length > 0) {
      setOvertimeUser(
        overtime?.records?.filter((el) => el?.userSub === employee?.userSub)
      );
    }
  }, [overtime]);

  useEffect(async () => {
    await API.get("employeeOvertime", "/employeeOvertime").then((e) =>
      setOvertime(e)
    );
  }, []);

  return (
    <>
      {overtimeUser?.length > 0 ? (
        <>
          <div className="workBeforeShiftData">
            {workBeforeShiftData?.map((el) => {
              return <div className="header-item-tip">{el}</div>;
            })}
          </div>
          <>
            <div className="containerOvertime">
              {overtimeUser?.map?.((el, i) => (
                <div
                  className="item-overtime"
                  style={{
                    backgroundColor: i % 2 !== 0 ? "#F5F5F7" : "#fff",
                  }}
                >
                  <div
                    style={{
                      width: "16%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {moment(el?.createdAt).format("DD/MM/YYYY")}
                  </div>
                  <div
                    style={{
                      width: "16%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {moment(el?.overtimeDay).format("DD/MM/YYYY")}
                  </div>
                  <div
                    style={{
                      //   paddingRight: 20,
                      width: "16%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        width: "100px",
                        backgroundColor: getColor(el?.requestStatus),
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#fff",
                        borderRadius: 5,
                      }}
                    >
                      {el.requestStatus}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "16%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {el?.requestPeriod[0]}
                  </div>
                  <div
                    style={{
                      width: "16%",
                      display: "flex",
                      justifyContent: "center",
                      paddingRight: 30,
                    }}
                  >
                    {el?.requestPeriod[1]}
                  </div>
                  <div
                    style={{
                      width: "16%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {el.requestType}
                  </div>
                </div>
              ))}
            </div>
          </>

          <div className="buttonFooterOvertime">
            <Button className="infoBtn" onClick={() => setInfoVisible(true)}>
              Kur mund të krijosh leje? <InfoOvertime />
            </Button>
            <Button className="createBtn" onClick={() => setVisible(true)}>
              Krijo nje kërkesë për orë shtesë
            </Button>{" "}
          </div>
        </>
      ) : (
        <div className="noOvertimeUser">
          <InfoBluecIcon
            style={{
              height: 45,
              width: 45,
            }}
          />
          <div className="noOvertime">Nuk ke kërkesë për orë shtesë.</div>
          <div className="noOvertime-greet">
            Përshëndetje <strong>{employee?.employeeFirstName}</strong>,
            aktualisht ti nuk ke asnjë kërkesë për orë shtesë. Kliko butonin më
            poshtë për të krijuar një.
          </div>
          <div className="noOvertime-actionButtons">
            <Button className="infoBtn" onClick={() => setInfoVisible(true)}>
              Kur mund të krijosh leje? <InfoOvertime />
            </Button>
            <Button className="createBtn" onClick={() => setVisible(true)}>
              Krijo nje kërkesë për orë shtesë
            </Button>{" "}
          </div>
        </div>
      )}
      <>
        <WorkBeforeShiftModal
          {...{ visible, setVisible, overtime, setOvertimeUser }}
        />
        <InfoOvertimeModal {...{ infoVisible, setInfoVisible, setVisible }} />
      </>
    </>
  );
};

export default WorkBeforeShift;
