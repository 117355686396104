import React, { useState } from "react";
import { Row, Col, Button, Modal, Select, Tooltip } from "antd";
import { CaretDownFilled } from "@ant-design/icons";
import { IconFilter } from "../../../../assets/icons";

function SiguriaPerdoruesitFilterModal({
  gridApi,
  clearFilters,
  duplicateCheck,
  tableData,
}) {
  const [visibleFilter, setVisibleFilter] = useState(false);

  const { Option } = Select;

  function closeFilter() {
    clearFilters();
    setVisibleFilter(false);
  }

  //AG GRID MODAL FILTER OPTIONS

  const emriFilter = (e) => {
    var emriFilterComponent = gridApi.getFilterInstance("given_name");
    emriFilterComponent.setModel({
      type: "contains",
      filter: `${e}`,
    });
    gridApi.onFilterChanged();
  };

  const mbiemriFilter = (e) => {
    var mbiemriFilterComponent = gridApi.getFilterInstance("family_name");
    mbiemriFilterComponent.setModel({
      type: "contains",
      filter: `${e}`,
    });
    gridApi.onFilterChanged();
  };
  const departamentiFilter = (e) => {
    var departamentiFilterComponent = gridApi.getFilterInstance("department");
    departamentiFilterComponent.setModel({
      type: "contains",
      filter: `${e}`,
    });
    gridApi.onFilterChanged();
  };

  // const pozicioniFilter = (e) => {
  //   var pozicioniFilterComponent = gridApi.getFilterInstance("userRole");
  //   pozicioniFilterComponent.setModel({
  //     type: "contains",
  //     filter: `${e}`,
  //   });
  //   gridApi.onFilterChanged();
  // };

  return (
    <div style={{ marginLeft: 10 }}>
      <Tooltip
        placement="top"
        overlayClassName="global-icon-tooltip"
        title={"Filtrim i avancuar"}
      >
        <IconFilter onClick={() => setVisibleFilter(true)} />
      </Tooltip>
      <Modal
        className="perdoruesit-filter"
        title="Kërkim i Avancuar"
        open={visibleFilter}
        onOk={() => setVisibleFilter(false)}
        onCancel={() => setVisibleFilter(false)}
        destroyOnClose={true}
        footer={[
          <Button onClick={closeFilter} className="charge-close-btn">
            Mbyll
          </Button>,
          <Button
            onClick={() => setVisibleFilter(false)}
            className="charge-search-btn"
          >
            Kërko
          </Button>,
        ]}
      >
        <Row gutter={16}>
          <Col span={8}>
            <h3>Emri :</h3>
            <Select
              showSearch
              className="departamenti-dropdown"
              bordered={false}
              dropdownClassName="dropdown-konfiguro"
              suffixIcon={<CaretDownFilled />}
              placeholder="Emri"
              style={{ width: "100%", background: "#F5F5F7", borderRadius: 5 }}
              onChange={emriFilter}
            >
              {tableData.map((Data) =>
                Data === "" ? (
                  Data
                ) : (
                  <Option value={Data.given_name} key={Data.given_name}>
                    {Data.given_name}
                  </Option>
                )
              )}
            </Select>
          </Col>
          <Col span={8}>
            <h3>Mbiemri :</h3>
            <Select
              showSearch
              className="departamenti-dropdown"
              bordered={false}
              dropdownClassName="dropdown-konfiguro"
              suffixIcon={<CaretDownFilled />}
              placeholder="Mbiemri"
              style={{ width: "100%", background: "#F5F5F7", borderRadius: 5 }}
              onChange={mbiemriFilter}
            >
              {tableData.map((Data) =>
                Data === "" ? (
                  Data
                ) : (
                  <Option value={Data.family_name} key={Data.family_name}>
                    {Data.family_name}
                  </Option>
                )
              )}
            </Select>
          </Col>
          <Col span={8}>
            <h3>Departamenti :</h3>
            <Select
              showSearch
              className="departamenti-dropdown"
              bordered={false}
              dropdownClassName="dropdown-konfiguro"
              suffixIcon={<CaretDownFilled />}
              placeholder="Departamenti"
              style={{ width: "100%", background: "#F5F5F7", borderRadius: 5 }}
              onChange={departamentiFilter}
            >
              {duplicateCheck.map((Data) =>
                Data === "" ? (
                  Data
                ) : (
                  <Option key={Data} value={Data}>
                    {Data}
                  </Option>
                )
              )}
            </Select>
          </Col>
          {/* <Col span={6}>
            <h3>Pozicioni :</h3>
            <Input
              className="filter-input"
              onChange={(e) => {
                pozicioniFilter(e.target.value);
              }}
              placeholder="Statusi"
            ></Input>
          </Col> */}
        </Row>
      </Modal>
    </div>
  );
}

export default SiguriaPerdoruesitFilterModal;
