import React from "react";
import Loader from "./Loader";
import "./FlexLogoLoader.scss";
import { Snowflake } from "../../../assets/holidays/icons";
import HappyHolidaysModal from "../../../assets/holidays/Modals/HappyHolidaysModal";
import AutumnLeaves from "./Autumn/AutumnLeaves";
const LoaderComponent = ({ loading, reRender = true, children, LoaderComponent = Loader }) =>
	reRender ? (
		loading ? (
			<LoaderComponent />
		) : (
			<>
				{/* {" "}
				{[...Array(50)].map((el, idx) => (
					<div className="snowflake">
						<Snowflake />
					</div>
				))} */}
				{/* <div class="snow_wrap">
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
				</div>{" "} */}
				{children}
			</>
		)
	) : (
		<>
			{" "}
			{/* {[...Array(50)].map((el, idx) => (
				<div className="snowflake">
					<Snowflake />
				</div>
			))} */}
			{loading && <LoaderComponent absolute />}
			{/* <div class="snow_wrap">
				<div class="snow"></div>
				<div class="snow"></div>
				<div class="snow"></div>
				<div class="snow"></div>
				<div class="snow"></div>
				<div class="snow"></div>
				<div class="snow"></div>
				<div class="snow"></div>
			</div> */}
			{children}
		</>
	);
export default LoaderComponent;
