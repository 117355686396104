import React, { useMemo } from "react";
import { List, Badge } from "antd";
import "./Active.scss";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import { useNavigate } from "react-router";
import Avatar from "react-avatar";
import { HollowDotsSpinner } from "react-epic-spinners";
import _ from "lodash";
import ReturnImg from "../../../../../utils/ReturnImg";

// const socketHandler = new WebSocket(getSocketUrl("/dashboard/sockets"));

function Active() {
  const { users } = useSelector((state) => state.usersList);
  const { activeEmployees } = useSelector((state) => state.employeesList);
  const userData = users?.allUsers.Items;

  const navigate = useNavigate();

  let merged = _.merge(
    _.keyBy(userData, "userSub"),
    _.keyBy(activeEmployees, "userSub")
  );
  let mergeData = _.values(merged);

  const usersList = useMemo(() => {
    if (!!users && !!activeEmployees) {
      return mergeData
        ?.filter((i) => {
          return activeEmployees?.some(
            (empl) => empl?.userSub === i?.userSub && !!empl?.userSub
          );
        })
        .map((e) => {
          let lastSeen = e?.lastLoggedIn;

          if (!!lastSeen) {
            let isInactive = moment().diff(lastSeen, "minutes", true) > 15;
            if (isInactive) {
              return { ...e, active: false };
            } else {
              return { ...e, active: true };
            }
          }
          return { ...e, active: false };
        });
    }
    return [];
  }, [users, activeEmployees]);

  return (
    <>
      {!!activeEmployees && usersList?.length > 0 ? (
        <div className="active-users-wrapper">
          <List
            itemLayout="horizontal"
            dataSource={usersList?.sort(
              (a, b) => b?.lastLoggedIn - a?.lastLoggedIn
            )}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  style={{ alignItems: "Center" }}
                  avatar={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Badge status={item.active ? "success" : "error"} />
                      {
                        <ReturnImg
                          width={35}
                          height={35}
                          borderRadius={50}
                          googleDriveFileId={item?.googleDriveFileId}
                          defaultImg={
                            <Avatar
                              name={item?.given_name + " " + item?.family_name}
                              size="30"
                              round={true}
                            />
                          }
                        />
                      }
                    </div>
                  }
                  title={
                    <a
                      onClick={() =>
                        navigate(`/punonjesit/specifikat/${item.employeeId}`)
                      }
                    >
                      {item?.given_name} {item?.family_name}
                    </a>
                  }
                  description={
                    "Parë për herë të fundit: " +
                      moment(item?.lastLoggedIn).fromNow() !==
                    "Invalid date"
                      ? moment(item?.lastLoggedIn).fromNow()
                      : "Asnjëherë"
                  }
                />
              </List.Item>
            )}
          />
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <HollowDotsSpinner color="#1DA193" size={24} />
        </div>
      )}
    </>
  );
}

export default Active;
