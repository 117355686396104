export const numberInput = /^[0-9]+$/;
export const textInput = /^[a-zA-Z\s]+$/;
export const personalNumber = /^[a-zA-Z0-9]+$/;
export const workEmail = /^[a-zA-Z0-9._%+-]+@(corescaffold|flex)+\.(com|al)$/;
export const personalEmail = /^\S+@\S+$/;
export const password = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6}$/;
// export const value = /\d{1,3}(?:[,]\d{3})*(?:[.]\d{2})/g;
export const usCurrencyFormat = (value) => {
  return new Intl.NumberFormat("us-US", {
    style: "currency",
    currency: "USD",
  }).format(value);
};
