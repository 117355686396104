import API from "@aws-amplify/api";

export const fetchData = async (endpoint, query = endpoint) => {
  let response = [];
  await API.get(endpoint, `/${query}`)
    .then((res) => {
      response = res;
    })
    .catch((e) => console.error(`${endpoint} fetch failed `, e));

  return response;
};
