import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  Card,
  Form,
  Select,
  message,
  Checkbox,
  Skeleton,
  Input,
  InputNumber,
  Switch,
  DatePicker,
  Button,
} from "antd";
import { CloseIcon, TickIcon } from "../../../../assets/icons";
import "./EmployeePayment.scss";
import moment from "moment-timezone";
import { API } from "aws-amplify";
import { useSelector } from "react-redux";
import { useAuth } from "../../../../authentication/authHelpers";
import ActivityModal from "../components/ActivityModal/ActivityModal";
import PunonjesitContext from "../../store/PunonjesitContext";

const retrievePrev = (data) => {
  let last = data?.employeeEffectives?.sort(
    (a, b) => b?.editedOn - a?.editedOn
  )?.[0];
  return {
    effectiveDay: moment(last?.effectiveDate),
    employeeGrossMonthlySalary: last?.employeeGrossMonthlySalary,
    employeeMonthlyNetSalary: last?.employeeMonthlyNetSalary,
    employeePayrollFrequency: last?.employeePayrollFrequency,
    employeeSalaryPerHour: last?.employeeSalaryPerHour,
  };
};

const PAYMENT_METHODS_ID = "01d6ec94-5ddf-4f48-9fdc-86a39a7289d9";

export const PaymentInfo = ({ handleHidePayment, shifts }) => {
  const { programFields } = useSelector((state) => state.programFields);
  const { employee, setEmployee, auth } = useContext(PunonjesitContext);
  const { Option } = Select;
  // const [brutAmount, setBrutAmount] = useState(employee?.employeeGrossMonthlySalary);
  // const [netoAmount, setNetoAmount] = useState(employee?.employeeMonthlyNetSalary);

  // const [pagesaPerOre, setPagesaPerOre] = useState(employee?.employeeSalaryPerHour);
  const [brutAmount, setBrutAmount] = useState(
    employee?.employeeGrossMonthlySalary
  );
  const [netoAmount, setNetoAmount] = useState(
    employee?.employeeMonthlyNetSalary
  );
  const [pagesaPerOre, setPagesaPerOre] = useState(
    employee?.employeeSalaryPerHour
  );
  const [payrollType, setPayrollType] = useState(employee?.employeePayrollType);
  const [frekuencaPages, setFrekuencaPages] = useState(
    employee?.employeePayrollFrequency
  );
  const [turni, setTurni] = useState(
    shifts?.find((el) => el.shiftId === employee?.employeeShiftId[0])
  );
  const [activityWage, setActivityWage] = useState([]);

  const [taksa, setTaksa] = useState(0);
  const [kontributet, setKontributet] = useState(0);
  const [newValue, setNewValue] = useState("");
  const [oldData, setOldData] = useState([]);
  const [disableBruto, setDisableBruto] = useState(false);
  const [disableNeto, setDisableNeto] = useState(false);
  const [turniActivity, setTurniActivity] = useState([]);
  const [pagesaDitore, setPagesaDitore] = useState();
  const [openActivity, setOpenActivity] = useState(false);

  /// LEDJO

  const [effectiveDate, setEffectiveDate] = useState(
    retrievePrev(employee)?.effectiveDay
  );

  // useEffect(async () => {
  //   API.get("shifts", "/shifts").then(setDataShift);
  // }, []);

  const paymentMethods = useMemo(() => {
    if (!programFields?.length) return [];

    return (
      programFields?.find((data) => data?.fieldId === PAYMENT_METHODS_ID)
        ?.fieldOptions || []
    );
  }, [programFields]);

  //region >handle bruto
  const handleBruto = (brutoA) => {
    let taksVal, kontributetVal, netoVal;

    if (!!brutoA && brutoA > 0) {
      if (!!brutoA) {
        if (brutoA > 141133) {
          kontributetVal = 141133;
        } else if (brutoA < 40000) {
          kontributetVal = 40000;
        } else {
          kontributetVal = parseFloat(brutoA);
        }
        setKontributet(kontributetVal);
        if (brutoA <= 40000) {
          taksVal = 0;
        } else if (brutoA <= 50000) {
          taksVal = (brutoA - 30000) * 0.13 * 0.5;
        } else if (brutoA <= 200000) {
          taksVal = (brutoA - 30000) * 0.13;
        } else {
          taksVal = (brutoA - 200000) * 0.23 + 22100;
        }
        setTaksa(parseFloat(taksVal));
        let sigShen = parseFloat(brutoA) * 0.017;
        let sigSHoq = kontributetVal * 0.095;
        let totalSig = parseFloat(sigShen) + parseFloat(sigSHoq);
        netoVal = parseFloat(brutoA) - totalSig - taksVal;
        setNetoAmount(netoVal);
        setPagesaDitore(netoVal / 22);
      }
    }
    setPagesaPerOre(netoVal / 187);
  };

  //region >handle neto
  const handleNeto = (netoA) => {
    let neto;
    if (netoA > 0) {
      if (netoA <= 35520) {
        neto = netoA / 0.888;
      } else if (netoA <= 43100) {
        neto = (netoA - 1950) / 0.823;
      } else if (netoA <= 110882) {
        neto = (netoA - 3900) / 0.758;
      } else if (netoA > 110879 && netoA <= 161092) {
        neto = (netoA + 9507.635) / 0.853;
      } else {
        neto = (netoA - 10492.365) / 0.753;
      }
      setBrutAmount(neto);
      setPagesaPerOre(netoA / 187);
      setPagesaDitore(netoA / 22);
    }
  };

  useEffect(() => {
    setOldData([
      {
        oldValueBruto: employee?.employeeGrossMonthlySalary,
        oldValueNeto: employee?.employeeMonthlyNetSalary,
        oldValuePayrollType: employee?.employeePayrollType,
        oldValueFrekuencaPages: employee?.employeePayrollFrequency,
        oldValueTurni: employee?.employeeShiftId,
      },
    ]);
  }, [employee]);

  //region HANDLE PAGA
  const handleChange = (type, e) => {
    if (type === "Paga neto") {
      setNetoAmount(e.target.value.toString());
      handleNeto(e.target.value !== "" ? parseInt(e.target.value) : 0);
    } else if (type === "Paga bruto") {
      setBrutAmount(e.target.value.toString());
      handleBruto(e.target.value !== "" ? parseInt(e.target.value) : 0);
    } else if (type === "dite") {
      setPagesaDitore(e.target.value);
    }
    setNewValue(e.target.value);
    const dataKeyLog = [
      {
        activity: "Ndryshuar",
        author:
          auth?.userAccess[0]?.given_name +
          " " +
          auth?.userAccess[0]?.family_name,
        changeDate: moment().valueOf(),
        field: type === "Paga bruto" ? "Paga bruto" : "Paga neto",
        oldValue:
          type === "Paga bruto"
            ? currencyFormatter.format(oldData[0]?.oldValueBruto)
            : currencyFormatter.format(oldData[0]?.oldValueNeto),

        newValue: currencyFormatter.format(e.target.value),
      },
    ];
    setActivityWage([...dataKeyLog]);
  };

  const handleSelectFrekuencaAndPayrollType = (type, e) => {
    if (type === "Menyra e pageses") {
      setPayrollType(e);
    } else if (type === "Frekuenca e pages") {
      setFrekuencaPages(e);
    }
    const selectedLogs = [
      {
        activity: "Ndryshuar",
        author:
          auth?.userAccess[0]?.given_name +
          " " +
          auth?.userAccess[0]?.family_name,
        changeDate: moment().valueOf(),
        field:
          type === "Menyra e pageses"
            ? "Menyra e pageses"
            : "Frekuenca e pages",
        oldValue:
          type === "Menyra e pageses"
            ? oldData[0]?.oldValuePayrollType
            : oldData[0]?.oldValueFrekuencaPages,
        newValue: e,
      },
    ];
    setActivityWage((prev) => [...prev, ...selectedLogs]);
  };

  //region HANDLE TURNI
  const handleTurni = (type, e) => {
    if (type === "Turni") {
      shifts?.map((el) => (el.shiftId === e[0] ? setTurni(el) : null));
    }
    const seletedTurni = [
      {
        activity: "Ndryshuar",
        author:
          auth?.userAccess[0]?.given_name +
          " " +
          auth?.userAccess[0]?.family_name,
        changeDate: moment().valueOf(),
        field: "Turni",
        oldValue:
          oldData[0]?.oldValueTurni[0]?.slice(-1) === "6"
            ? "Turni Paradite"
            : "" || oldData[0]?.oldValueTurni[0]?.slice(-1) === 3
            ? "Turni Pasdite"
            : "" || !oldData[0]?.oldValueTurni
            ? undefined
            : undefined || oldData[0]?.oldValueTurni?.length === 2
            ? "Turni paradite edhe pasdite"
            : undefined,

        newValue:
          e[0]?.slice(-1) === "6"
            ? "Turni Paradite"
            : "" || e[0]?.slice(-1) === "3"
            ? "Turni Pasdite"
            : "" || e?.length === 2
            ? "Turni paradite edhe mbasdite"
            : "",
      },
    ];
    setTurniActivity([...seletedTurni]);
  };

  //region VALIDATE EFFECTIVES
  const validateEffectives = (test1, test2, log) => {
    let toReturn;
    // 1. Check if the object has employeeEffectives key
    const hasEffective = employee?.employeeEffectives?.length > 0;
    // 2. If it has old values
    if (hasEffective) {
      let oldTemp = employee?.employeeEffectives?.find(
        (el) =>
          moment(el?.effectiveDate).format("DD/MM/YYYY") ===
          moment(effectiveDate).format("DD/MM/YYYY")
      );
      if (!!oldTemp) {
        let temp = employee?.employeeEffectives?.filter(
          (el) => moment(el?.effectiveDate) !== moment(effectiveDate)
        );
        toReturn = [
          ...temp,
          { ...test2, effectiveDate: moment(effectiveDate).valueOf(), ...log },
        ];
        return toReturn;
      } else {
        toReturn = [
          ...employee?.employeeEffectives,
          { ...test2, effectiveDate: moment(effectiveDate).valueOf(), ...log },
        ];

        return toReturn;
      }
    } else {
      toReturn = [
        { ...test2, effectiveDate: moment(effectiveDate).valueOf(), ...log },
      ];

      return toReturn;
    }
  };

  //region HANDLE SAVE
  const handleSaveData = async (
    brutAmount,
    netoAmount,
    pagesaPerOre,
    payrollType,
    frekuencaPages,
    turni
  ) => {
    if (
      !!brutAmount ||
      !!netoAmount ||
      !!payrollType ||
      !!frekuencaPages ||
      !!turni
    ) {
      await API.put("employees", `/employees/${employee?.employeeId}`, {
        body: {
          employeeGrossMonthlySalary: parseFloat(brutAmount),
          employeeMonthlyNetSalary: parseFloat(netoAmount),
          employeeSalaryPerHour: parseFloat(pagesaPerOre),
          employeePayrollType: payrollType,
          employeePayrollFrequency: frekuencaPages,
          employeeShiftId: [turni?.shiftId],
          breakTime: [
            moment(turni?.shiftBreak.breakStartTime).format("HH:mm"),
            moment(turni?.shiftBreak.breakEndTime).format("HH:mm"),
          ],
          isBreakTimePaid: turni?.shiftBreak.isBreakTimePaid,
          employeeEffectives: validateEffectives(
            employee,
            {
              employeeGrossMonthlySalary: parseFloat(brutAmount),
              employeeMonthlyNetSalary: parseFloat(netoAmount),
              employeeSalaryPerHour: parseFloat(pagesaPerOre),
              employeePayrollType: payrollType,
              employeePayrollFrequency: frekuencaPages,
              employeeShiftId: [turni?.shiftId],
            },
            {
              editedBy:
                auth?.userAccess[0]?.given_name +
                " " +
                auth?.userAccess[0]?.family_name,
              editedOn: Date.now(),
            }
          ),
          keylog:
            employee?.keylog?.length > 0
              ? [
                  ...employee?.keylog,
                  ...activityWage,
                  ...turniActivity,
                  {
                    activity: "Ndryshuar",
                    author:
                      auth?.userAccess[0]?.given_name +
                      " " +
                      auth?.userAccess[0]?.family_name,
                    changeDate: moment().valueOf(),
                    field: "Data Efektive",
                    oldValue: moment(
                      retrievePrev(employee)?.effectiveDay
                    ).format("DD/MM/YYYY"),

                    newValue: moment(effectiveDate).format("DD/MM/YYYY"),
                  },
                ]
              : [...activityWage, ...turniActivity],
        },
      })
        .then((res) => {
          setEmployee(res?.employee?.Item);
          console.log("res: ", res);
          message.success("Te dhenat u ruajten me sukses!");
          handleHidePayment();
        })
        .catch((err) => {
          console.log(err);
          message.error("Ndodhi nje gabim!");
        });
      setDisableBruto(false);
      setDisableNeto(false);
    }
  };

  useEffect(() => {
    setActivityWage([]);
  }, []);

  const currencyFormatter = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 2,
  });

  const onFocusBruto = () => {
    setDisableNeto(true);
  };

  const onBlurBruto = () => {
    setDisableNeto(false);
  };

  const onFocusNeto = () => {
    setDisableBruto(true);
  };

  const onBlurNeto = () => {
    setDisableBruto(false);
  };

  //region DATA
  const data = [
    {
      label: "Paga Bruto :",
      type: (
        <Input
          type="number"
          value={brutAmount}
          disabled={disableBruto}
          onPressEnter={handleBruto}
          onFocus={onFocusBruto}
          onBlur={onBlurBruto}
          onChange={(e) => handleChange("Paga bruto", e)}
          placeholder="Shkruaj shifren"
          style={{
            width: "204px",
            height: "32px",
            borderRadius: "5px",
            background: "#F5F5F7 0% 0% no-repeat padding-box",
          }}
        />
      ),
      calcValue: (
        <Input
          disabled
          value={`Paga Neto : ${
            isNaN(currencyFormatter.format(netoAmount))
              ? 0
              : currencyFormatter.format(netoAmount)
          }`}
          placeholder="Paga Neto"
          style={{
            width: "180px",
            height: "32px",
            background: "#1DA193 0% 0% no-repeat padding-box",
            borderRadius: "5px",
            color: "#FFFFFF",
            paddingBottom: "8px",
          }}
        />
      ),
    },
    {
      label: "Paga Neto :",
      type: (
        <Input
          type="number"
          value={netoAmount}
          onFocus={onFocusNeto}
          onBlur={onBlurNeto}
          disabled={disableNeto}
          onPressEnter={handleNeto}
          onChange={(e) => handleChange("Paga neto", e)}
          placeholder="Shkruaj shifren"
          style={{
            width: "204px",
            height: "32px",
            borderRadius: "5px",
            background: "#F5F5F7 0% 0% no-repeat padding-box",
          }}
        />
      ),
      calcValue: (
        <Input
          disabled
          value={`Paga Bruto : ${
            isNaN(currencyFormatter.format(brutAmount))
              ? 0
              : currencyFormatter.format(brutAmount)
          }`}
          placeholder="Paga Bruto"
          style={{
            width: "180px",
            height: "32px",
            background: "#1DA193 0% 0% no-repeat padding-box",
            borderRadius: "5px",
            color: "#FFFFFF",
            paddingBottom: "8px",
          }}
        />
      ),
    },
    {
      label: "Menyra e pageses :",
      type: (
        <Select
          style={{
            width: "100%",
            height: "32px",
          }}
          value={payrollType}
          onChange={(e) =>
            handleSelectFrekuencaAndPayrollType("Menyra e pageses", e)
          }
        >
          {paymentMethods?.map((data) =>
            data === "" ? (
              data
            ) : (
              <Option value={data?.name}>{data?.name}</Option>
            )
          )}
        </Select>
      ),
    },
    {
      label: "Pagesa per ore :",
      type: (
        <Input
          style={{
            width: "364px",
            height: "32px",
            background: "#F5F5F7 0% 0% no-repeat padding-box",
            borderRadius: "5px",
          }}
          // type="number"
          placeholder="Pagesa per ore"
          value={pagesaPerOre}
          disabled
          onChange={(e) => setPagesaPerOre(e.target.value)}
        />
      ),
    },
    {
      label: "Pagesa ditore :",
      type: (
        <Input
          disabled
          value={pagesaDitore}
          onChange={(e) => setPagesaDitore("dite", e)}
          style={{ width: "364px", borderRadius: "5px" }}
          placeholder="Pagesa ditore"
        />
      ),
    },
    {
      label: "Data efektive :",
      type: (
        <DatePicker
          format={"DD/MM/YYYY"}
          style={{
            width: "300px",
            background: "#F5F5F7 0% 0% no-repeat padding-box",
            borderRadius: "5px",
          }}
          value={effectiveDate}
          onChange={(val) => setEffectiveDate(val)}
        />
      ),
      modal: (
        <Button type="primary" onClick={() => setOpenActivity(true)}>
          Logs
        </Button>
      ),
    },
    {
      label: "Frekuenca e pages :",
      type: (
        <Select
          style={{
            width: "354px",
            height: "32px",
            background: "#F5F5F7 0% 0% no-repeat padding-box",
            borderRadius: "5px",
          }}
          placeholder="Frekuenca e pages"
          value={frekuencaPages}
          onChange={(e) =>
            handleSelectFrekuencaAndPayrollType("Frekuenca e pages", e)
          }
        >
          {paymentMethods?.map((data) => (
            <Option value={data?.formula}>{data?.formula}</Option>
          ))}
        </Select>
      ),
    },
    {
      label: "Turni perkates:",
      type: (
        <Select
          style={{
            width: "364px",
            height: "32px",
            background: "#F5F5F7 0% 0% no-repeat padding-box",
            borderRadius: "5px",
          }}
          defaultValue={employee?.employeeShiftId}
          onChange={(e) => handleTurni("Turni", e)}
          mode="multiple"
          placeholder="Zgjidh turnin"
        >
          {shifts?.map((data) => {
            return data === "" ? (
              data
            ) : (
              <Option value={data?.shiftId}>{data?.shiftName}</Option>
            );
          })}
        </Select>
      ),
    },
  ];

  useEffect(() => {
    if (parseFloat(netoAmount) > 0) {
      setPagesaDitore(netoAmount / 22);
    }
  }, [netoAmount]);

  //region RETURN
  return (
    <>
      <Card
        className="paymentCard"
        title="Informacioni i pagesës"
        data-testid="payment-info-card"
        extra={
          <div className="icon-button">
            <button
              id="close"
              data-testid="close-btn"
              style={{ marginRight: 10 }}
              onClick={handleHidePayment}
            >
              <CloseIcon className="action-icon" />
            </button>
            <button
              id="tick"
              htmlType="submit"
              onClick={() =>
                handleSaveData(
                  brutAmount,
                  netoAmount,
                  pagesaPerOre,
                  payrollType,
                  frekuencaPages,
                  turni
                )
              }
            >
              <TickIcon className="action-icon" />
            </button>
          </div>
        }
      >
        <span className="infoHeader" data-testid="infoHeader">
          Informacioni i pageses - {employee?.employeeFirstName}{" "}
          {employee?.employeeLastName}
        </span>
        {/* should update to antd Form */}
        <div className="paymentForm">
          {data?.map(({ label, type, modal, calcValue }) => {
            return (
              <div className="formItem" data-testid="formItem">
                <span style={{ fontWeight: 600 }}>{label}</span>
                <div className="input">
                  {type}
                  {modal}
                  {calcValue}
                </div>
              </div>
            );
          })}
        </div>
      </Card>
      {!!openActivity ? (
        <ActivityModal
          setIsModalVisible={setOpenActivity}
          keylog={employee?.keylog}
          view="effectiveDate"
        />
      ) : null}
    </>
  );
};
