import { Card, Checkbox, Col } from "antd";
import React from "react";

function CardComonentFitimet({ data }) {
	return (
		<Col
			style={{
				width: "100%",
				marginLeft: "0px",
				height: "calc(100% - 35px)"
			}}
			key={data?.data?.earningId}
		><div
			style={{
				borderTopLeftRadius: "0px", width: "100%",
				height: "100%",
				borderTopRightRadius: "0px",
				display: "flex",
				justifyContent: 'center', alignItems: "center"
			}}
			key={data?.data?.earningId}
			// title={data?.earningName}
			bordered={false}
		>
				<div className="Fitimet-view-card">
					<div style={{ width: "100%", height: 55 }}>
						<h3 style={{ width: "100%" }}>Lloji i fitimit:</h3>
						<div className="krijo-data">{data?.data?.earningType}</div>
					</div>

					<div style={{ width: "100%", height: 55 }}>
						<h3 style={{ width: "100%" }}>Rregulli i llogaritjes:</h3>
						<div className="krijo-data">{data?.data?.earningFormula}</div>
					</div>
					<div style={{ width: "100%", height: 55 }}>
						<h3>Raporti:</h3>
						<div className="krijo-data">{data?.data?.earningRate}</div>
					</div>
					<div style={{ width: "100%", height: 55 }}>
						<h3>Rregulli:</h3>
						<div className="krijo-data">{data?.data?.rateFactor}</div>
					</div>
					<div style={{ width: "100%", height: 55 }}>
						{/* <h3>Vlera:</h3>
                <div className="krijo-data">{data.earningAmount}</div> */}
					</div>

					<Checkbox
						defaultChecked={data?.data?.excludeFromTotalHours}
						className="festat-zyrtare-checkbox"
						style={{ width: "100%" }}
					>
						<h3 style={{ width: 222 }}>Përjashtoje në shfaqjen e orëve totale</h3>
					</Checkbox>
				</div>
			</div>
		</Col>
	);
}

export default CardComonentFitimet;
