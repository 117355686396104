import React, { useState, useEffect } from "react";
import {
  Drawer,
  Input,
  Select,
  DatePicker,
  Form,
  Col,
  Row,
  Button,
  Skeleton,
} from "antd";
import "./RequestsDrawer.scss";
import { API } from "aws-amplify";
import moment from "moment-timezone";
import { useDispatch, useSelector } from "react-redux";
import { HolidayIcon, DayOffIcon } from "../../dashboard/assets";
import "./RequestsDrawer";
import LeaveModal from "../../dashboard/AdminDashboard/AdminCards/WorkLeave/LeaveModal";
import { employeeRequests as requestsAction } from "../../../store/actions";
import { useAuth } from "../../../authentication/authHelpers";
import { requestAllPages } from "../../../helpers/API/RequestFactory";
import { lazyFetch } from "../../../utils/lazyFetch";
import { ModalXIcon } from "../../../assets/icons";
const { Option } = Select;
const RequestsDrawer = ({ requestsActive, setRequestsActive }) => {
  let auth = useAuth();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { employeeRequests } = useSelector((state) => state.employeeRequests);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [noEmpId, setNoEmpId] = useState(false);
  const [content, setContent] = useState(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  //Mbyll drawer
  const onClose = () => {
    setRequestsActive(false);
  };

  // console.log("employeeRequestsemployeeRequests", employeeRequests);

  const handleClear = () => {
    //Shfaq brenda drawer-it vetem kerkesat e user-ave qe kane useSub te sakte
    //dhe i rendit nga me te fundit te me te hershmet
    setData(
      employeeRequests
        .filter((res) => res.userSub != null && res.userSub != "undefined")
        .sort((a, b) => b?.createdAt - a?.createdAt)
    );
    form.resetFields();
  };
  const iconStyle = {
    marginTop: 2,
    marginLeft: 2,
    width: 18,
    height: 18,
  };
  const handleFilter = (success) => {
    const filD = employeeRequests
      .filter((res) => res.userSub != null && res.userSub != "undefined")
      .sort((a, b) => b?.createdAt - a?.createdAt)
      ?.map((td) => {
        if (!!success) {
          return {
            ...td,
            requestStatus:
              td?.requestPeriod?.length > 1
                ? moment().valueOf() - td?.requestPeriod?.slice(-1) > 0
                  ? "Përfunduar"
                  : td.requestStatus
                : moment().valueOf() - td.requestPeriod[0] > 0
                ? "Përfunduar"
                : td.requestStatus,
          };
        }
      })
      ?.filter((x) => {
        return (
          (!success?.status || x?.requestStatus?.includes(success?.status)) &&
          (!success?.emplName ||
            x?.given_name
              ?.toLowerCase()
              .includes(success?.emplName.toLowerCase())) &&
          (!success?.filterDate ||
            moment(success?.filterDate).utc().format("DD MM YYYY") ===
              moment(x?.createdAt).utc().format("DD MM YYYY"))
        );
      });
    setData(filD);
  };
  // opens the modal with the clicked row state
  const getNotif = async (filterId) => {
    await API.get("employeeRequests", `/employeeRequests/${filterId}`).then(
      (el) => {
        setContent(el);
      }
    );

    setIsModalVisible(true);
  };
  // get the employesRequest data from api
  const getEmployeRequest = async () => {
    setLoading(true);
    // await API.get("employeeRequests", "/employeeRequests")
    await lazyFetch("employeeRequests", [
      "requestId",
      "family_name",
      "given_name",
      "userSub",
      "requestType",
      "requestStatus",
      "requestPeriod",
      "createdAt",
    ]).then((res) =>
      dispatch(requestsAction(res.sort((a, b) => b?.createdAt - a?.createdAt)))
    );
    setLoading(false);
  };
  //Percakton ikonen ne varesi te llojit te kerkeses
  const getIcon = (reqType, style) => {
    switch (reqType) {
      // case "Leje lindje":
      //   return <MaternityLeaveIcon style={style} />;
      case "Pushime":
        return <HolidayIcon width={18} height={18} style={style} />;
      case "Dite Personale":
        return <DayOffIcon style={style} />;
      default:
        return;
    }
  };
  //Percakton ngjyren ne varssi te statusit te kerkeses
  const getColor = (reqStatus) => {
    switch (reqStatus) {
      case "Aprovuar":
        return "#1DA193";
      case "Refuzuar":
        return "#EA3943";
      case "Pending":
        return "#FCC94A";
      default:
        return;
    }
  };
  useEffect(() => {
    setLoading(true);
    if (!!employeeRequests) {
      setData(
        employeeRequests
          ?.filter?.(
            (res) => res.userSub !== null && res.userSub !== "undefined"
          )
          ?.map((el) => ({
            ...el,
            requestStatus:
              el?.requestPeriod?.length > 1
                ? moment().valueOf() - el?.requestPeriod?.slice(-1) > 0
                  ? "Përfunduar"
                  : el.requestStatus
                : moment().valueOf() - el.requestPeriod[0] > 0
                ? "Përfunduar"
                : el.requestStatus,
          }))
          ?.sort?.((a, b) => moment(b?.createdAt) - moment(a?.createdAt))
      );
      setLoading(false);
    }
  }, [employeeRequests]);
  useEffect(() => {
    if (auth.employeeId === null) {
      setNoEmpId(true);
    }
  }, [auth]);

  const isDisabled = (x, y) => {
    return x - y > 0;
  };

  return (
    <Drawer
      className="req-drawer"
      title="Pushimet vjetore"
      placement="right"
      onClose={onClose}
      open={requestsActive}
      closeIcon={<ModalXIcon />}
    >
      <Form
        className="filters-container"
        onFinish={handleFilter}
        form={form}
        name="filter-requests"
        labelCol={{
          span: 9,
        }}
        wrapperCol={{
          span: 16,
        }}
        autoComplete="off"
        autoFocus
      >
        <Form.Item name="emplName">
          <Input
            className="name-filter-input"
            placeholder="Kërko punonjësin..."
          />
        </Form.Item>
        <Form.Item name="status">
          <Select className="status-filter-select" defaultValue="Të gjithë">
            <Option key="1" value="">
              Të gjithë
            </Option>
            <Option key="2" value="Refuzuar">
              Refuzuar
            </Option>
            <Option key="3" value="Pending">
              Pending
            </Option>
            <Option key="4" value="Aprovuar">
              Aprovuar
            </Option>
            <Option key="5" value="Përfunduar">
              Përfunduar
            </Option>
          </Select>
        </Form.Item>
        <Form.Item name="filterDate">
          <DatePicker className="date-filter" placeholder="Zgjidh datën" />
        </Form.Item>
        <Button
          className="filter-button"
          htmlType="submit"
          key="submit"
          form="filter-requests"
        >
          Filtro
        </Button>
        <Button className="clear-filter" onClick={handleClear}>
          Pastro
        </Button>
      </Form>
      <div className="wrapDrawer">
        <div className="requests-headers">
          <h3>Emri i punonjësit</h3>
          <h3>Data e krijimit të kërkesës</h3>
          <h3>Statusi i kërkesës</h3>
        </div>
        {loading === false ? (
          <>
            <div className="requests-drawer">
              {data?.map((dt) => {
                return (
                  <Row
                    key={dt.requestId}
                    className="leave-request-row"
                    style={{
                      width: "100%",
                      paddingLeft: 15,
                      paddingRight: 15,
                      marginBottom: 5,
                    }}
                  >
                    <Col span={3}>
                      {" "}
                      <div
                        className="icon-container"
                        style={{
                          backgroundColor: isDisabled(
                            moment().valueOf(),
                            dt?.requestPeriod?.slice(-1)
                          )
                            ? "#a4a0a0"
                            : getColor(dt?.requestStatus),
                          borderRadius: 2,
                          height: 22,
                          width: 22,
                        }}
                      >
                        {getIcon(dt?.requestType, iconStyle)}
                      </div>
                    </Col>
                    <Col
                      span={7}
                      style={{
                        color: isDisabled(
                          moment().valueOf(),
                          dt?.requestPeriod?.slice(-1)
                        )
                          ? "#a4a0a0"
                          : "",
                      }}
                    >
                      {dt?.given_name} {dt?.family_name}
                    </Col>
                    <Col
                      span={9}
                      style={{
                        color: isDisabled(
                          moment().valueOf(),
                          dt?.requestPeriod?.slice(-1)
                        )
                          ? "#a4a0a0"
                          : "",
                      }}
                    >
                      {moment(dt?.createdAt).format("DD/MM/YYYY")}
                    </Col>
                    <Col span={5}>
                      <Button
                        key={dt?.requestId}
                        className="leave-request-btn"
                        disabled={isDisabled(
                          moment().valueOf(),
                          dt?.requestPeriod?.slice(-1)
                        )}
                        style={{
                          backgroundColor: isDisabled(
                            moment().valueOf(),
                            dt?.requestPeriod?.slice(-1)
                          )
                            ? "#a4a0a0"
                            : getColor(dt?.requestStatus),
                        }}
                        onClick={() => getNotif(dt?.requestId)}
                      >
                        {dt?.requestStatus}
                      </Button>
                    </Col>
                  </Row>
                );
              })}
            </div>
          </>
        ) : (
          <Skeleton paragraph={{ rows: 5 }} />
        )}
      </div>
      <LeaveModal
        id={content?.requestId}
        key={content?.requestId}
        content={content}
        isModalVisible={isModalVisible}
        getEmployeRequest={getEmployeRequest}
        setIsModalVisible={setIsModalVisible}
        getIcon={getIcon}
        getColor={getColor}
      />
    </Drawer>
  );
};
export default RequestsDrawer;
