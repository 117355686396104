import { useEffect } from "react";
import moment from "moment-timezone";
import { useState } from "react";
import "../../../components/dashboard/components/clockInOut/clockShiftLog/ShiftLogButtonsFilter.scss";

function TimeSheetButtonsFilter({ filterMonths }) {
	const [activeFilter, setActiveFilter] = useState(null);

	const ButtonFilter = (idx) => {
		if (
			activeFilter ===
			moment()
				.subtract(2 - idx, "months")
				.format("MMMM")
		) {
			setActiveFilter(null);
			filterMonths(null);
		} else {
			setActiveFilter(
				moment()
					.subtract(2 - idx, "months")
					.format("MMMM")
			);
			filterMonths(
				moment()
					.startOf("month")
					.subtract(2 - idx, "months")
			);
		}
	};

	const getComputedClassname = (idx) => {
		let month = moment()
			.subtract(2 - idx, "months")
			.format("MMMM");
		if (month === activeFilter) {
			return "active-filter";
		}
		return "inactive-filter";
	};

	useEffect(() => {
		ButtonFilter(2);
	}, []);

	return (
		<div className="header-filter-buttons">
			{Array.apply(null, Array(3)).map((_, idx) => (
				<button key={idx} className={getComputedClassname(idx)} onClick={() => ButtonFilter(idx)}>
					{moment()
						.subtract(2 - idx, "months")
						.format("MMMM YYYY")}
				</button>
			))}
		</div>
	);
}

export default TimeSheetButtonsFilter;
