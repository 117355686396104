import "../PayStub.scss";
import React from "react";
import { isNumber } from "lodash";
import { currencyformatter } from "../employeeCalc/methods";

function PayStubTable({ columnsDef, rowData }) {
  return (
    <>
      {!!rowData && (
        <>
          {rowData.length > 0 && (
            <div className="payStub-table">
              <table>
                <tr>
                  {columnsDef?.map((el, idx) => (
                    <th
                      style={
                        idx !== 0
                          ? { textAlign: "right" }
                          : { textAlign: "left" }
                      }
                    >
                      {el}
                    </th>
                  ))}
                </tr>
                {rowData?.length > 0 ? (
                  rowData?.map((rd) => (
                    <tr>
                      {rd?.map((trd, idx) => (
                        <td style={idx !== 0 ? { textAlign: "right" } : null}>
                          {isNumber(trd)
                            ? currencyformatter
                                .format(trd?.toFixed(2))
                                .replace("ALL", "")
                            : trd}
                        </td>
                      ))}
                    </tr>
                  ))
                ) : (
                  <strong
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      color: "red",
                    }}
                  >
                    Nuk ka te dhena per te shfaqur
                  </strong>
                )}
              </table>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default PayStubTable;
