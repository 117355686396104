import React, { useContext, useEffect, useState } from "react";
import { Card, Form, message, Input, Skeleton } from "antd";
import { CloseIcon, TickIcon } from "../../../../../assets/icons";
import {
  personalEmail,
  numberInput,
  workEmail,
} from "../../RegEx/RegExpressions";
import { API } from "aws-amplify";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./details.scss";
import { useAuth } from "../../../../../authentication/authHelpers";
import PunonjesitContext from "../../../store/PunonjesitContext";

const { TextArea } = Input;

function ContactInformation({
  handleHideContact,
  handleChange,
  changes,
  setChanges,
  socketHandler,
}) {
  const { employee, setEmployee } = useContext(PunonjesitContext);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const showMessage = () => {
    message.success("Të dhënat u ndryshuan!");
  };

  const handleSave = (success) => {
    setLoading(true);
    if (!!employee?.keylog) {
      API.put("employees", `/employees/${employee?.employeeId}`, {
        body: { ...success, keylog: [...employee?.keylog, ...changes] },
      }).then((r) => {
        // if (socketHandler.readyState === socketHandler.OPEN)
        // 	socketHandler.send(
        // 		JSON.stringify({
        // 			request: "user_edit",
        // 			body: r?.employee?.Item,
        // 			id: auth?.employeeId,
        // 		})
        // 	);
        showMessage(r);
        handleHideContact(r);
        setLoading(false);
        setEmployee(r?.employee?.Item);
      });
    } else {
      API.put("employees", `/employees/${employee?.employeeId}`, {
        body: { ...success },
      }).then((r) => {
        // if (socketHandler.readyState === socketHandler.OPEN)
        // 	socketHandler.send(
        // 		JSON.stringify({
        // 			request: "user_edit",
        // 			body: r?.employee?.Item,
        // 			id: auth?.employeeId,
        // 		})
        // 	);
        showMessage(r);
        handleHideContact(r);
        setLoading(false);
        setEmployee(r?.employee?.Item);
      });
    }
  };
  React.useEffect(() => {
    form.setFieldsValue({
      employeeEmergencyContact: employee?.employeeEmergencyContact,
      employeePhoneNumber: employee?.employeePhoneNumber,
      employeeEmailWork: employee?.employeeEmailWork,
      employeePersonalEmail: employee?.employeePersonalEmail,
      employeeAddress: employee?.employeeAddress,
    });
  }, [employee, form]);

  useEffect(() => {
    setChanges([]);
  }, [employee]);

  return (
    <div className="contact-infe-edit">
      <Card
        className="info-card"
        title="Informacione kontakti"
        extra={
          <div className="icon-button">
            <Form.Item>
              <button
                id="close"
                htmlType="submit"
                style={{ marginRight: 10 }}
                onClick={() => {
                  handleHideContact();
                }}
              >
                <CloseIcon className="action-icon" />
              </button>
              <button onClick={() => form.submit()} id="tick" htmlType="submit">
                <TickIcon className="action-icon" />
              </button>
            </Form.Item>
          </div>
        }
        style={{ width: "100%", height: 470 }}
      >
        {loading === false ? (
          <Form
            colon={false}
            form={form}
            labelCol={{ flex: "110px" }}
            labelAlign="left"
            labelWrap
            autoComplete="off"
            onFinish={handleSave}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="Numri i  emergjencës:"
              name="employeeEmergencyContact"
              rules={[
                {
                  // required: numberInput,
                  message: "Kjo fushe duhet te permbaje vetem numra",
                },
              ]}
            >
              <PhoneInput
                enableAreaCodes={true}
                name="phoneNumber"
                className="phone-number"
                country={"al"}
                onChange={(value) =>
                  handleChange(
                    "employeeEmergencyContact",
                    value,
                    "Nr. emergjence"
                  )
                }
                masks={{ al: "... ... ..." }}
              />
            </Form.Item>
            <Form.Item
              label="Numri i celularit:"
              name="employeePhoneNumber"
              rules={[
                {
                  // pattern: numberInput,
                  message: "Kjo fushe duhet te permbaje vetem numra",
                },
              ]}
            >
              <PhoneInput
                className="phone-number"
                country={"al"}
                onChange={(value) =>
                  handleChange("employeePhoneNumber", value, "Nr. i celularit")
                }
                masks={{ al: "... ... ..." }}
              />
            </Form.Item>
            <Form.Item
              label="Emaili i punës:"
              name="employeeEmailWork"
              rules={[
                {
                  pattern: workEmail,
                  message: "Email-i nuk është në formatin e duhur!",
                },
              ]}
            >
              <Input
                className="my-form"
                onChange={(e) =>
                  handleChange(
                    "employeeEmailWork",
                    e.target.value,
                    "Email i punës"
                  )
                }
                placeholder="Shkruaj emailin e punës"
              />
            </Form.Item>
            <Form.Item
              label="Email personal:"
              name="employeePersonalEmail"
              rules={[
                {
                  pattern: personalEmail,
                  message: "Email-i nuk është në formatin e duhur!",
                },
              ]}
            >
              <Input
                className="my-form"
                onChange={(e) =>
                  handleChange(
                    "employeePersonalEmail",
                    e.target.value,
                    "Email personal"
                  )
                }
                placeholder=" Shkruaj emailin personal"
              />
            </Form.Item>
            <Form.Item label="Adresa:" name="employeeAddress">
              <TextArea
                rows={4}
                className="address-field"
                onChange={(e) =>
                  handleChange("employeeAddress", e.target.value, "Adresa")
                }
                placeholder="Shkruaj adresën e punonjësit"
              />
            </Form.Item>
          </Form>
        ) : (
          <Skeleton active paragraph={{ rows: 8 }} />
        )}
      </Card>
    </div>
  );
}
export default ContactInformation;
