import { currencyformatter } from "../utils/formatter";
import moment from "moment-timezone";
export const prepareMultis = (savedArr, toGenerate) => {
	console.log("saved Arr", savedArr);
	const tempTable = {
		columnsDef: [
			"",
			"EMRI",
			"MBIEMRI",
			"MUAJI",
			"DEPARTAMENTI",
			"BRUTO",
			"NETO",
			"INDIVID ",
			"PUNEDHENES",
			"BANKA",
			"TOTAL",
		],
		rowData: [],
		totalities: {
			bruto: 0,
			neto: 0,
			individual: 0,
			employeer: 0,
			bank: 0,
			total: 0,
		},
	};
	if (savedArr.length > 0) {
		savedArr.map((paysub, idx) => {
			tempTable.totalities.bruto += paysub?.bruto?.totalBruto || 0;
			tempTable.totalities.neto += paysub?.taxes?.netoPay?.netoPay || 0;
			tempTable.totalities.individual += paysub?.taxes?.netoPay?.totalIns || 0;
			tempTable.totalities.employeer += paysub?.taxes?.netoPay?.totalEmployeeerIns || 0;
			tempTable.totalities.bank += paysub?.taxes?.netoPay?.netoPay || 0;
			let temp = idx + 1;
			tempTable.rowData.push([
				temp.toString(),
				paysub?.employeeInfo?.employeeFirstName,
				paysub?.employeeInfo?.employeeLastName,
				moment(toGenerate?.timeframe?.start).format("MMMM YYYY"),

				paysub?.employeeInfo?.employeeDepartmentName,
				currencyformatter.format(paysub?.bruto?.totalBruto || 0).replace("ALL", ""),
				currencyformatter.format(paysub?.taxes?.netoPay?.netoPay || 0).replace("ALL", ""),
				currencyformatter.format(paysub?.taxes?.netoPay?.totalIns || 0).replace("ALL", ""),
				currencyformatter
					.format(paysub?.taxes?.netoPay?.totalEmployeeerIns || 0)
					.replace("ALL", ""),
				currencyformatter.format(paysub?.taxes?.netoPay?.netoPay || 0).replace("ALL", ""),
				currencyformatter.format(
					paysub?.bruto?.totalBruto + paysub?.taxes?.netoPay?.totalEmployeeerIns || 0
				),
			]);
		});
		tempTable.totalities.total += tempTable.totalities.bruto + tempTable.totalities.employeer;

		tempTable.rowData.push([
			"TOTALI",
			"",
			"",
			"",
			"",
			currencyformatter.format(tempTable.totalities.bruto).replace("ALL", ""),
			currencyformatter.format(tempTable.totalities.neto).replace("ALL", ""),
			currencyformatter.format(tempTable.totalities.individual).replace("ALL", ""),
			currencyformatter.format(tempTable.totalities.employeer).replace("ALL", ""),
			currencyformatter.format(tempTable.totalities.bank).replace("ALL", ""),
			currencyformatter.format(tempTable.totalities.total),
		]);
	}
	return tempTable;
};
