import { add, multiply, partialRight, toPath } from "lodash";
import { currencyformatter } from "./methods";
import { store } from "../../../../store/store";
import moment from "moment-timezone";
import { sliceShifts } from "./slicing";
import { getCoefficentDayType, getDayTypeTranslation } from "./wageHours";
import { message } from "antd";

export const preparePaySub = (payStubObj) => {
	let tempTable = {
		columnsDef: ["PAGESË ORARI ", "ORËT", "PAGESA/ORË", "FITIMET"],
		rowData: [],
	};

	let { earnings } = payStubObj || [];
	let totalAmount = 0;
	let totalHours = 0;
	if (Array.isArray(earnings)) {
		console.log("earnings ", earnings);
		earnings.forEach((rate) => {
			if (rate.normalHours.hours > 0) {
				tempTable.rowData.push([
					"Deri në 19:00",
					rate?.normalHours?.hours,
					rate?.normalHours?.rate || "--",
					rate?.normalHours?.normalEarnings || "--",
				]);
			}
			if (rate.lateHours.hours > 0) {
				tempTable.rowData.push([
					"19:00 - 22:00",
					rate?.lateHours?.hours,
					rate?.lateHours?.rate || "--",
					rate?.lateHours?.lateEarnings || "--",
				]);
			}
			if (rate.extraHours.hours > 0) {
				tempTable.rowData.push([
					"22:00 - 07:30",
					rate?.extraHours?.hours || "--",
					rate?.extraHours?.rate || "--",
					rate?.extraHours?.extraEarnings || "--",
				]);
			}
			totalAmount += rate.totalWage;
			totalHours += rate.totalHours;
		});
		tempTable.rowData.push(["TOTAL", totalHours, "", currencyformatter.format(totalAmount || 0)]);
	}
	// let payrollType = "";
	// if (payStubObj?.employeeInfo?.employeePayrollType !== "Page bazuar ne ore") payrollType = "--";

	// if (payStubObj?.allhours?.normalHours > 0)
	// 	tempTable.rowData.push([
	// 		"Para ores 19:00",
	// 		payStubObj?.allhours?.normalHours,
	// 		payStubObj?.earnings?.normalHours?.rate || "--",
	// 		payStubObj?.earnings?.normalHours?.normalEarnings || "--",
	// 	]);
	// if (payStubObj?.allhours?.lateHours > 0)
	// 	tempTable.rowData.push([
	// 		"Midis 19:00 - 22:00",
	// 		payStubObj?.allhours?.lateHours,
	// 		payStubObj?.earnings?.lateHours?.rate || "--",
	// 		payStubObj?.earnings?.lateHours?.lateEarnings || "--",
	// 	]);
	// if (payStubObj?.allhours?.extraHours > 0)
	// 	tempTable.rowData.push([
	// 		"OVERTIME 22:00 - 07:30",
	// 		payStubObj?.allhours?.extraHours,
	// 		payStubObj?.earnings?.extraHours?.rate || "--",
	// 		currencyformatter.format(payStubObj?.earnings?.extraHours?.extraEarnings).replace("ALL", ""),
	// 	]);
	// tempTable.rowData.push([
	// 	"TOTAL",
	// 	payStubObj?.allhours?.totalHours,
	// 	payrollType,
	// 	currencyformatter.format(payStubObj?.earnings?.totalWage || 0),
	// ]);
	return tempTable;
};

export const prepareRequests = (payStubObj, otherTypes, programFields) => {
	console.log("payStubObj ", payStubObj);
	const holidayRequests = payStubObj?.holidays?.getHolidayNumbers() || null;

	const tempTable = {
		columnsDef: ["TË TJERA", "ORËT", "PAGESA/ORË", "FITIMET"],
		rowData: [],
		totalities: { hours: 0, earnings: 0 },
		others: {},
	};
	// console.log("Program", holidayRequests);
	let holidays = programFields?.find((el) => el?.fieldName === "Festat Zyrtare")?.fieldOptions;
	let today = moment(payStubObj?.momentInstance?.start).format("MMMM");
	let listOfHolidays = holidays.filter((el) => moment(el?.effectiveDate).format("MMMM") === today);
	const rate = payStubObj?.employeeInfo?.employeeSalaryPerHour || 0;

	// tempTable.totalities.hours += listOfHolidays?.length * 8;
	tempTable.totalities.earnings += tempTable.totalities.hours * rate;
	let festeZyrtareInital = {
		hours: 0,
		earningsRate: rate,
		amount: 0,
		description: "Festë Zyrtare",
	};

	let festeZyrtareWorking = {
		hours: 0,
		earningsRate: rate * 1.25,
		amount: 0,
		description: "Festë Zyrtare në punë",
	};

	// if (listOfHolidays?.length > 0) {
	// 	// festeZyrtareInital.hours = listOfHolidays?.length * 8;
	// 	festeZyrtareInital.earnings = rate;
	// 	festeZyrtareInital.amount = rate * listOfHolidays?.length * 8;
	// 	// festeZyrtareInital.push([
	// 	// 	"Feste Zyrtare",
	// 	// 	"",
	// 	// 	// listOfHolidays?.length * 8,
	// 	// 	rate,
	// 	// 	rate * listOfHolidays?.length * 8,
	// 	// ]);
	// }

	if (Array.isArray(otherTypes)) {
		if (otherTypes.length > 0) {
			let desired = programFields?.find((el) => el?.fieldName === "ClockIn Types")?.fieldOptions;
			if (!!desired) {
				console.log("otherTypes ", otherTypes);
				otherTypes.map((otherClocking) => {
					console.log("otherClocking ledjojojojooj ", otherClocking.dayType);
					if (
						otherClocking?.dayType !== "NationalHolidayWorking" &&
						otherClocking?.dayType !== "NationalHoliday"
						// otherClocking?.dayType !== "Maternity" &&
						// moment(otherClocking?.clockInDate).day() !== 0 &&
						// moment(otherClocking?.clockInDate).day() !== 0
					) {
						console.log("otherClocking ledjo ", otherClocking);
						let tempHours = 0;
						let tempEarnings = 0;
						let applied = desired.find((d) => d.name === otherClocking?.dayType);
						console.log("applied ", applied);
						if (!!applied) {
							tempHours += moment(otherClocking.clockOutDate).diff(
								moment(otherClocking.clockInDate),
								"hours",
								true
							);
							tempEarnings +=
								rate *
								applied?.coefficent *
								moment(otherClocking.clockOutDate).diff(
									moment(otherClocking.clockInDate),
									"hours",
									true
								);
							tempTable.rowData.push([
								applied?.description || "",
								moment(otherClocking.clockOutDate).diff(
									moment(otherClocking.clockInDate),
									"hours",
									true
								),
								rate * applied?.coefficent,
								rate *
									applied?.coefficent *
									moment(otherClocking.clockOutDate).diff(
										moment(otherClocking.clockInDate),
										"hours",
										true
									),
							]);
							tempTable.totalities.hours += tempHours || 0;
							tempTable.totalities.earnings += tempEarnings || 0;
						}
					} else {
						let tempHours2 = 0;
						let tempEarnings2 = 0;
						let temp = Object.assign({}, otherClocking);
						let applied = desired.find((d) => d.name === otherClocking?.dayType);
						let moreThan8Hours = 0;

						// sliceShifts([temp]).map((shift) => {
						// 	let difference = moment(shift.clockOutDate).diff(
						// 		moment(shift.clockInDate),
						// 		"hours",
						// 		true
						// 	);
						// 	if (difference > 8) {
						// 		moreThan8Hours += difference - 8;
						// 	}
						// 	tempHours2 += difference;
						// 	tempEarnings2 +=
						// 		((rate * applied?.coefficent) / 5) *
						// 		getCoefficentDayType(shift?.type) *
						// 		moment(shift.clockOutDate).diff(moment(shift.clockInDate), "hours", true);
						// 	console.log("tempEarnings2", {
						// 		temp,
						// 		shift,
						// 		difference,
						// 		moreThan8Hours,
						// 		tempHours2,
						// 		tempEarnings2,
						// 	});
						// 	// tempTable.rowData.push([
						// 	// 	applied?.description + " Shtese " + getDayTypeTranslation(shift?.type) || "",
						// 	// 	moment(shift.clockOutDate).diff(moment(shift.clockInDate), "hours", true),
						// 	// 	((rate * applied?.coefficent) / 5) * getCoefficentDayType(shift?.type),
						// 	// 	((rate * applied?.coefficent) / 5) *
						// 	// 		getCoefficentDayType(shift?.type) *
						// 	// 		moment(shift.clockOutDate).diff(moment(shift.clockInDate), "hours", true),
						// 	// ]);
						// });

						if (moreThan8Hours > 0) {
							tempHours2 += moreThan8Hours;
							tempEarnings2 += moreThan8Hours * rate;
						}
						// tempEarnings2 += festeZyrtareInital.amount;
						console.log("tempHours2", tempTable);

						tempTable.totalities.hours += otherClocking?.totalities?.totalHours || 0;
						// tempTable.totalities.earnings += tempEarnings2 || 0;
						if (otherClocking?.dayType === "NationalHolidayWorking") {
							festeZyrtareWorking.hours += otherClocking?.totalities?.totalHours || 0;
							festeZyrtareWorking.amount += otherClocking?.totalities?.totalPay || 0;
						} else if (otherClocking?.dayType === "NationalHoliday") {
							festeZyrtareInital.hours += otherClocking?.totalities?.totalHours || 0;
							festeZyrtareInital.amount += otherClocking?.totalities?.totalPay || 0;
						}
					}
				});
			}
		}
	}

	console.log("festat", {
		festeZyrtareInital,
		festeZyrtareWorking,
	});

	if (festeZyrtareInital.amount > 0) {
		console.log("festeZyrtareInital", festeZyrtareInital);
		tempTable.totalities.earnings += festeZyrtareInital.amount;
		tempTable.rowData.push([
			festeZyrtareInital?.description,
			festeZyrtareInital.hours,
			festeZyrtareInital.earningsRate,
			festeZyrtareInital.amount,
		]);
	}

	if (festeZyrtareWorking.amount > 0) {
		console.log("festeZyrtareWorking", festeZyrtareWorking);
		tempTable.totalities.earnings += festeZyrtareWorking.amount;
		tempTable.rowData.push([
			festeZyrtareWorking?.description,
			festeZyrtareWorking.hours,
			festeZyrtareWorking.earningsRate,
			festeZyrtareWorking.amount,
		]);
	}
	// if (!!holidayRequests) {
	// 	console.log("holiday Requests", holidayRequests);
	// 	const rate = payStubObj?.employeeInfo?.employeeSalaryPerHour || 0;
	// 	let totalHours = 0;
	// 	let totalEarnings = 0;
	// 	Object.entries(holidayRequests[0]).forEach((entry) => {
	// 		const [key, value] = entry;
	// 		const hours = multiply(value?.total, 8); // total is days!
	// 		const earnings = multiply(rate, hours);
	// 		totalHours += hours;
	// 		totalEarnings += earnings;
	// 		if (earnings > 0) {
	// 			tempTable.rowData.push([key, hours, rate, earnings]);
	// 		}
	// 	});
	// 	tempTable.totalities.hours += totalHours || 0;
	// 	tempTable.totalities.earnings += totalEarnings || 0;
	// }
	tempTable.rowData.push([
		"TOTAL",
		tempTable.totalities.hours,
		"",
		currencyformatter.format(tempTable.totalities.earnings),
	]);

	console.log("Temp Table", tempTable);

	return tempTable;
};

export const prepareSummaryEarnings = (payStubObj) => {
	const { allhours, earnings, holidays, prepayments, bruto, requests, taxes } = payStubObj;
	// console.log("Pushimet", holidays);
	console.log("earnings", earnings);
	const pushimet = Object.hasOwnProperty("getHolidayNumbers")
		? holidays?.getHolidayNumbers()
		: null;

	let totalPushimetDays,
		totalPushimetHours,
		totalPushimetPay = 0;
	let totalAdditionsEarnings,
		totalAdditionsHours,
		totalDeductionsEarnings,
		totalDeductionsHours = 0;
	let earningsWage = Array.isArray(earnings)
		? earnings?.reduce((a, b) => a + b?.totalWage, 0) || 0
		: 0;
	let earningsHours = Array.isArray(earnings)
		? earnings?.reduce((a, b) => a + b?.totalHours, 0) || 0
		: 0;
	if (!!pushimet) {
		totalPushimetDays = pushimet[1];
		totalPushimetHours = totalPushimetDays * 8;
		totalPushimetPay = totalPushimetHours * payStubObj?.employeeInfo?.employeeSalaryPerHour;
	}
	if (!!prepayments) {
		totalAdditionsEarnings = prepayments?.earnings?.totalities?.amount || 0;
		totalAdditionsHours = prepayments?.earnings?.totalities?.hours || 0;
		totalDeductionsEarnings = prepayments?.deductions?.totalities?.amount || 0;
		totalDeductionsHours = prepayments?.deductions?.totalities?.hours || 0;
	}
	const finalTable = {
		columnsDef: ["PËRMBLEDHJE", "ORËT", "FITIMET"],
		rowData: [],
		finalWage: earningsWage + totalPushimetPay,
	};
	console.log("Final Table", earningsHours);
	// finalTable.rowData.push(["ORET E PUNES", allhours?.totalHours || 0, earningsWage]);
	finalTable.rowData.push(["Orët e punës", earningsHours || 0, earningsWage]);

	// if (totalPushimetHours > 0 || totalPushimetPay > 0) {
	// 	finalTable.rowData.push(["PUSHIMET", totalPushimetHours, totalPushimetPay]);
	// }
	if (totalAdditionsEarnings > 0) {
		finalTable.rowData.push(["Shtimet (+)", totalAdditionsHours, totalAdditionsEarnings]);
	}
	if (totalDeductionsEarnings > 0) {
		finalTable.rowData.push(["Zbritjet (-)", totalDeductionsHours, totalDeductionsEarnings]);
	}
	if (!!requests) {
		let otherRow = requests?.rowData || [];
		if (otherRow.length > 0) {
			otherRow.map((row, idx) => {
				if (idx !== otherRow.length - 1) {
					finalTable.rowData.push([row[0], row[1], row[3]]);
				}
			});
		}
	}

	const hoursSum = finalTable.rowData?.reduce((a, b) => a + b[1], 0);
	const amountSum = finalTable.rowData?.reduce((a, b) => add(Number(a), Number(b[2])), 0);

	finalTable.rowData.push([
		"Total",
		hoursSum,
		//  currencyformatter.format(bruto?.totalBruto || 0),
		currencyformatter.format(amountSum || 0),
	]);

	return finalTable;
};
