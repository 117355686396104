import { API, Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAuth } from "../../authentication/authHelpers";
import LoaderComponent from "../commonComponents/LoadableComp/LoadableComp";
import AdminDashboard from "./AdminDashboard/AdminDashboard";
import UserDashboard from "./UserDashboard";
import { v4 as uuidv4 } from "uuid";
import { assignAccessRights } from "../../constantsFactory/accessRights";
import { useLocation } from "react-router-dom";
import { Button } from "antd";
import "./dashboard.scss";
import { REGULAR_USER } from "../../constantsFactory/regularUser";
import moment from "moment-timezone";
import ModalAnnounce from "../Announcement/ModalAnnounce/ModalAnnounce";

function DashboardBase() {
	const auth = useAuth();
	const [loading, setLoading] = useState(true);
	const [viewType, setViewType] = useState(null);
	const { programFields } = useSelector((state) => state.programFields);

	const onInitAdmin = async () => {
		window.OneSignal = window.OneSignal || [];
		const OneSignal = window.OneSignal;
		// OneSignal.setDefaultTitle("My Title");
		await OneSignal.push(() => {
			OneSignal.init({
				appId: "cd2085f7-5cf7-4a64-be0a-b53f049a34fc", //STEP 9
			});
			OneSignal.sendTags({
				identityId: `${auth?.userAccess[0]?.identityId}`,
				userRole: "Admin",
			});
		});
	};

	const onInitAdminDev = async () => {
		window.OneSignal = window.OneSignal || [];
		const OneSignal = window.OneSignal;
		// OneSignal.setDefaultTitle("My Title");
		await OneSignal.push(() => {
			OneSignal.init({
				appId: "9e32441c-55a6-4dcf-b0dd-d3ecca7aa921", //STEP 9
			});
			OneSignal.sendTags({
				identityId: `${auth?.userAccess[0]?.identityId}`,
				userRole: "Admin",
			});
		});
	};

	const onInitEmpl = async () => {
		window.OneSignal = window.OneSignal || [];
		const OneSignal = window.OneSignal;
		// OneSignal.setDefaultTitle("My Title");
		await OneSignal.push(() => {
			OneSignal.init({
				appId: "cd2085f7-5cf7-4a64-be0a-b53f049a34fc", //STEP 9
			});
			OneSignal.sendTags({
				identityId: `${auth?.userAccess[0]?.identityId}`,
				userRole: "Employee",
			});
		});
	};

	const onInitEmplDev = async () => {
		window.OneSignal = window.OneSignal || [];
		const OneSignal = window.OneSignal;
		// OneSignal.setDefaultTitle("My Title");
		await OneSignal.push(() => {
			OneSignal.init({
				appId: "9e32441c-55a6-4dcf-b0dd-d3ecca7aa921", //STEP 9
			});
			OneSignal.sendTags({
				identityId: `${auth?.userAccess[0]?.identityId}`,
				userRole: "Employee",
			});
		});
	};

	const onCalculate = async () => {
		await assignAccessRights("User", "us-east-1:45f13494-550a-4057-830a-4b565baf0f56");
	};

	const onSubscribe = async () => {
		await API.get("userGroups", "/userGroups").then((res) => console.log(res));
	};

	// const onText = async () => {
	// 	const config = {
	// 		queryStringParameters: {
	// 			tableName: "clocking",
	// 			filterKey: "employeeId",
	// 			filterValue: "fb8781ba-59c3-478a-a61c-1a89d3aea43c",
	// 		},
	// 	};
	// 	await API.get("filterTables", "/filterTables", config).then((res) =>
	// 		console.log(
	// 			"filter",
	// 			res
	// 				.sort((a, b) => b.clockInDate - a.clockInDate)
	// 				.map((el) => moment(el?.clockInDate).format("MM/DD/YYYY"))
	// 		)
	// 	);
	// };

	useEffect(() => {
		if (auth.userAccess.length > 0) {
			if (
				auth.userAccess[0].userRole === "Admin" ||
				auth.userAccess[0].userRole === "Super Admin"
			) {
				setViewType("Admin");
				setLoading(false);

				process.env.NODE_ENV === "production" ? onInitAdmin() : onInitAdminDev();
				// onInitAdminDev();
			} else {
				setViewType("User");
				setLoading(false);

				// onInitEmpl();
				process.env.NODE_ENV === "production" ? onInitEmpl() : onInitEmplDev();
			}
		}
	}, [auth]);
	return (
		<LoaderComponent loading={loading}>
			{/* <Button onClick={() => onSubscribe()}>test</Button> */}
			{/* <button onClick={onText}> Test</button> */}
			{viewType === "Admin" ? (
				<AdminDashboard />
			) : viewType === "User" ? (
				<UserDashboard role={auth?.userAccess[0]?.userRole || "User"} />
			) : (
				<></>
			)}
			<ModalAnnounce open={true} />
		</LoaderComponent>
	);
}
export default DashboardBase;
