import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import Amplify from "aws-amplify";
import config from "./config";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";
import { store } from "./store/store";

if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.debug = () => {};
}

const endpointNames = [
  "employees",
  "departments",
  "clocking",
  "employeeRequests",
  "programFields",
  "documentation",
  "hotCredentials",
  "filterTables",
  "docConfiguration",
  "shifts",
  "earnings",
  "deductions",
  "clocking",
  "notifications",
  "userGroups",
  "users",
  "filterTables",
  "clockingLogs",
  "leave",
  "getIp",
  "wages",
  "prepayments",
  "reportsLogs",
  "lazyFetching",
  "employeeOvertime",
  "announcements",
  "interviews",
  "tickets",
];

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    oauth: {
      domain: "hrtest.auth.us-east-1.amazoncognito.com",
      redirectSignIn: "http://localhost:3000/",
      redirectSignOut: "http://localhost:3000/",
      responseType: "token",
      scope: [
        "phone",
        "email",
        "profile",
        "openid",
        "aws.cognito.signin.user.admin",
      ],
    },
  },
  API: {
    endpoints: endpointNames.map((name) => {
      return {
        name: name,
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
      };
    }),
  },
});
ReactDOM.render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <Router>
        <App />
      </Router>
    </ReduxProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
