import React, { useRef, useState, useEffect } from "react";

const originalTitle = "Burimet Njerezore";
const originalIcon = "flexhr.svg";

/**
 * * @EneaXharau
 * * Params -> message = STRING
 * * It changes the document title based on the parameter
 */
const tick = (message) => {
	document.title = document.title === message ? originalTitle : message;
};

/**
 * * @EneaXharau
 * * Params -> icon = STRING(route in local)
 * * It changes the document href icon based on the parameter
 */
const tickIcon = (icon) => {
	document.getElementById("favicon").href =
		document.getElementById("favicon").href === icon ? originalIcon : icon;
};

export const useTabNotification = (interval) => {
	/**
	 * * @EneaXharau - 2 useStates to hold the parameter for above functions
	 * * 2 useRef to keep the value predictable and not change randomly
	 * * it's done this way to prevent unpredictable user scenarios from
	 * * using pure javascript in a react project
	 */
	const [message, setMessage] = useState(null);
	const [icon, setIcon] = useState(null);
	const intervalRef = useRef(null);
	const intervalIconRef = useRef(null);

	/**
	 * * @EneaXharau - clears the document title and href icon
	 * * sets state to their initial value or "empty"
	 */
	const clearMessage = () => {
		document.title = originalTitle;
		document.getElementById("favicon").href = originalIcon;
		setMessage(null);
		setIcon(null);
	};

	/**
	 * * @EneaXharau - Function clears intervals being held in refs
	 * * then sets refs to null to completely prevent unwanted effects
	 */
	const resetTimer = () => {
		clearInterval(intervalRef.current);
		clearInterval(intervalIconRef.current);
		intervalRef.current = null;
		intervalIconRef.current = null;
	};

	/**
	 * * @EneaXharau - useEffect runs the interval based on a validation
	 * * it re-runs each time the message state changes
	 */
	useEffect(() => {
		!intervalRef.current && message
			? (intervalRef.current = setInterval(() => {
					tick(message);
			  }, interval))
			: resetTimer();
	}, [message]);

	/**
	 * * @EneaXharau - useEffect runs the interval based on a validation
	 * * it re-runs each time the icon state changes
	 */
	useEffect(() => {
		!intervalIconRef.current && icon
			? (intervalIconRef.current = setInterval(() => {
					tickIcon(icon);
			  }, interval))
			: resetTimer();
	}, [icon]);

	/**
	 * * @EneaXharau - useEffect to run when hook "re-runs" to clearIntervals
	 */
	useEffect(() => {
		clearInterval(intervalRef?.current);
		clearInterval(intervalIconRef?.current);
	}, []);

	/**
	 * * Returns a destructured array for better optimization using its functions
	 */
	return [setMessage, setIcon, clearMessage];
};
