import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  IconNgargo,
  IconFilter,
  IconExcel,
  DeleteIcon,
} from "../../../../assets/icons";
import { Tooltip } from "antd";
import AgGridComponent from "../../../AG-grid/AgGridComponent";
// import totalValue from './totalValue';
import ShiftModal from "./EmployeeShifts/ShiftModal";
import { getMonthsOfYear, getNextMonthsOfYear } from "./utils/calculations";
import moment from "moment-timezone";
import { calcMonthInd } from "../../../Pagat/PaySub/employeeCalc/calculateMonthlyInd";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import HtmlExelTabel from "../../../Konfigurimet/dynamic components/HtmlExelTabel";
import ExportToPdf from "../../../Konfigurimet/dynamic components/ExportToPdf";
import "./PaymentGrid.scss";
import { useMediaQuery } from "react-responsive";
import MobileHtmlTabel from "../../../../utils/MobileComponents/MobileHtmlTabel";
import MobilePaymentGrid from "./MobilePaymentGrid";
import PunonjesitContext from "../../store/PunonjesitContext";

function PaymentGrid() {
  const { wages } = useSelector((state) => state.wages);
  const { employee } = useContext(PunonjesitContext);

  const [rowData, setRowData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [gridApi, setGridApi] = useState(null);
  // const [selectedRows, setSelectedRows] = useState([]);
  // const [agGridRow, setAgGridRow] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [nextGen, setNextGen] = useState({ next: 0, prev: 0 });

  const columnDefs = [
    {
      headerName: "Nr",
      field: "nr",
      checkboxSelection: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
    },
    {
      headerName: "Muaji",
      field: "muaji",
      cellRendererFramework: ({ data }) => (
        <div style={{ cursor: "pointer" }}>
          <div variant="outlined">
            <span
              style={{ color: "#1DA193", fontWeight: 600 }}
              onClick={() => handleMonthModal(data)}
            >
              {moment(data.muaji).format("MMMM YYYY")}
            </span>
          </div>
        </div>
      ),
    },
    {
      headerName: "Shuma Neto",
      field: "neto",

      // cellRendererFramework: ({ data }) => (
      // 	<div style={{ cursor: "pointer" }}>
      // 		<div variant="outlined">
      // 			<span style={{ color: "green", fontWeight: 600 }}>
      // 				{calcMonthInd("neto", wages, data.muaji, location?.state)}
      // 			</span>
      // 		</div>
      // 	</div>
      // ),
    },
    {
      headerName: "Shuma Bruto",
      field: "bruto",
      // cellRendererFramework: ({ data }) => (
      // 	<div style={{ cursor: "pointer" }}>
      // 		<div variant="outlined">
      // 			<span style={{ color: "green", fontWeight: 600 }}>
      // 				{calcMonthInd("bruto", wages, data.muaji, location?.state)}
      // 			</span>
      // 		</div>
      // 	</div>
      // ),
    },
  ];

  let rowDataGrid = [];
  gridApi?.forEachNodeAfterFilter?.((node) => {
    rowDataGrid?.push(node?.data);
  });

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const handleMonthModal = (data) => {
    setSelectedData([data]);
    setIsModalVisible(true);
  };
  const componentRef = useRef("Print-Punojsit");
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const initializeMonths = () => {
    let temp = getMonthsOfYear()?.map((el) => ({
      ...el,
      neto: calcMonthInd("neto", wages, el.muaji, employee),
      bruto: calcMonthInd("bruto", wages, el.muaji, employee),
    }));

    setRowData(temp);
  };

  const onNextGen = (type) => {
    if (!!type) {
      let temp = getNextMonthsOfYear(type, nextGen[type] + 1)?.map((el) => ({
        ...el,
        neto: calcMonthInd("neto", wages, el.muaji, employee),
        bruto: calcMonthInd("bruto", wages, el.muaji, employee),
      }));

      setRowData(temp);
      setNextGen((prev) => ({ ...prev, [type]: prev[type] + 1 }));
    } else {
      initializeMonths();
      setNextGen({ next: 0, prev: 0 });
    }
  };

  useEffect(() => {
    if (!!wages) {
      initializeMonths();
    }
  }, [wages]);

  const isMobileView = useMediaQuery({
    query: "(min-width: 460px)",
  });

  const headerIcons = useMemo(() => {
    return (
      <div className="header-icons">
        <ReactHtmlTableToExcel
          id="test-table-xls-button"
          className="download-table-xls-button"
          table="Employess-view-paymentGrid"
          filename={`paga-${employee?.employeeFirstName}.${employee?.employeeLastName}`}
          sheet="punojsit-grid"
          buttonText={
            <Tooltip
              placement="top"
              overlayClassName="global-icon-tooltip"
              title={"Eksporto në eksel"}
            >
              <IconExcel />
            </Tooltip>
          }
        />
        <ExportToPdf
          id={"#Employess-view-paymentGrid"}
          tabelName={`paga-${employee?.employeeFirstName}.${employee?.employeeLastName}`}
        />
        <Tooltip
          placement="top"
          overlayClassName="global-icon-tooltip"
          title={"Printo tabelën"}
        >
          <IconNgargo onClick={handlePrint} />
        </Tooltip>
        <button
          className="paygrid-btn"
          data-testid="on-next-btn"
          onClick={() => onNextGen("next")}
        >
          Shfaq Muajin Pasardhes
        </button>{" "}
        <button
          className="paygrid-btn"
          data-testid="on-prev-btn"
          onClick={() => onNextGen("prev")}
        >
          Shfaq Muajin Paraardhes
        </button>
        <button
          className="paygrid-btn"
          onClick={() => onNextGen(null)}
          data-testid="pastro-btn"
        >
          Pastro
        </button>
      </div>
    );
  }, [employee, nextGen, rowData]);

  return isMobileView ? (
    <div className="payment-grid">
      <div className="punonjesit">
        <div className="ag-grid-punojsit">
          <div className="ag-grid-header">{headerIcons}</div>
          <div
            className="ag-theme-alpine"
            style={{ height: 620, width: "100%" }}
          >
            <AgGridComponent
              rowData={rowData}
              rowSelection="single"
              onGridReady={onGridReady}
              paginationPageSize={12}
              columnDefs={columnDefs}
              suppressRowClickSelection={true}
              dataTestId="payment-ag-grid"
            />
          </div>
        </div>
        <HtmlExelTabel
          tableData={rowDataGrid?.map?.(({ muaji, neto, bruto }) => ({
            muaji: moment(muaji).format("MMMM - YYYY"),
            neto,
            bruto,
          }))}
          header={["Muaji", "Shuma neto", "Shuma bruto"]}
          id="Employess-view-paymentGrid"
          componentRef={componentRef}
        />
      </div>
      <ShiftModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        {...{ selectedData, gridApi }}
      />
    </div>
  ) : (
    <>
      {headerIcons}
      <MobilePaymentGrid rowData={rowData} dataTestId="payment-ag-grid" />
    </>
  );
}
export default PaymentGrid;
