export const albanianTranslations = {
	//Employees
	employeeId: "employeeId",
	employeeFirstName: "Emri",
	employeeLastName: "Mbiemri",
	employeeGender: "Gjinia",
	employeeDayOfBirth: "Ditelindja",
	employeeSupervisor: "Supervizori",
	employeeEmailWork: "Email",
	employeeEmailPersonal: "Emaili personal",
	//
	employeePersonalEmail: "Emaili personal",
	employeePhoneNumber: "Telefoni",
	employeePhonePersonal: "Telefoni personal",
	employeeAddress: "Adresa",
	employeeCity: "Qyteti",
	employeeCountry: "Shteti",
	employeeZipCode: "Kodi postal",
	employeeDepartmentName: "Departamenti",
	employeePosition: "Pozicioni",
	employeeStartDate: "Data fillimit",
	employeeEndDate: "Data mbarimit",
	employeeStatus: "Statusi",
	employeeReasonforTermination: "Arsyeja e përfundimit",
	employeeTypeOfEmployment: "Lloji i punesimit",
	employeeTypeOfContract: "Lloji i kontratës",
	employeeTypeOfContractDescription: "Përshkrimi i kontratës",
	employeeTypeOfContractStartDate: "Data fillimit të kontratës",
	employeeTypeOfContractEndDate: "Data mbarimit të kontratës",
	employeeTypeOfContractSalary: "Shpenzimi",
	employeeSalaryPerHour: "Pagesa per orë",
	employeeMonthlyNetSalary: "Paga neto mujore",
	employeeGrossMonthlySalary: "Paga bruto mujore",
	employeeStartDate: "Data fillimit",
	employeeHireDate: "Data e fillimit",
	// Clockings
	clockInDate: "Ora e fillimit",
	clockOutDate: "Ora e mbarimit",
	clockDate: "Data",
	approved: "Aprovimi",
	clockInNote: "Shënim i hyrjes",
	clockOutNote: "Shënim i mbarimit",
	duration: "Kohëzgjatja",
	employeeFullName: "Punonjësi",
	clockInPayment: "Pagesa",
	clockInRate: "Pagesa për orë/turn",
	before19: "Deri në 19",
	between19and22: "19:00 - 22:00",
	after22: "22:00 - 07:30",
	employee: "Punonjësi",
	dayType: "Kategoria e turnit",
	breakTime: "Pushimi i ditës",
	breakTimeVal: "Pagesa e pushimit",
	// Employee Requests
	requestType: "Lloji i kerkesës",
	requestStart: "Data e fillimit",
	requestEnd: "Data e mbarimit",
	requestStatus: "Statusi",
	createdAt: "Krijuar më",
	requestDescription: "Përshkrimi",
	// prepayments
	additions: "Shtesat",
	deductions: "Zbritjet",
	timeframe: "Periudha",
	total: "Totali",
	//bankForwarding
	idNumber: "Nr.",
	totalBruto: "Bruto",
	totalNeto: "Neto",
	totalIns: "Indiv.",
	totalEmployeeerIns: "Punedhenesi",
	bankTotal: "Banka",
	total: "Totali",
	// Detailed Taxes
	healthIns: "Shendet.",
	socialIns: "Shoq.",
	progressiveTax: "Prog.",
	healthInsEmployeer: "Shend. (Punedh.)",
	insuranceEmployeer: "Punedhenesi",
	// Trendi i punesimeve largimeve "suspensions"
	employeeLatestStatusUpdate: "Aktiviteti i fundit",
	employeeTeam: "Kompania",
	regular: "Normal",
	usual: "Normal",
	sick: "Sëmurë",
	maternity: "Leje Lindje",
	nationalholiday: "Festë Zyrtare",
	nationalholidayworking: "Festë Zyrtare në punë",
	holidayrequest: "Kërkesë Leje",
	breaktime: "Pushim i ditës",
	// Clocking Types Report
	regularDays: "Ditë Normale",
	sickDays: "Ditë Sëmurë",
	holidayRequestDays: "Ditë Leje",
	nationalHolidayDays: "Ditë festash zyrtare",
	nationalHolidayWorkedDays: "Ditë Festash Zyrtare në Punë",
	maternityDays: "Ditë Leje Lindje",
	missingDays: "Ditë mungesë",
};
export const getTranslation = (key) => {
	if (albanianTranslations.hasOwnProperty(key)) {
		return albanianTranslations[key];
	}
	return key;
};
