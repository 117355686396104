import React, { useState } from "react";
import { Switch, Row, Col, Button, message } from "antd";
import { API } from "aws-amplify";
import { useNavigate } from "react-router-dom";

function KryefaqjaAcessRights({ savedData, setSaveData }) {
  const navigate = useNavigate();
  const [btndisable, setBtnDisable] = useState(null);

  const onChange = (path, val) => {
    // console.log(path);
    // console.log(val);
    let prev = savedData?.accessConfig?.sidebarConfig[0]?.find(
      (el) => el?.to === path
    );
    prev.auth = val;
    if (!!prev) {
      setSaveData((prev) => ({
        ...prev,
        accessConfig: {
          routeConfig: savedData.accessConfig.routeConfig,
          settingsConfig: savedData.accessConfig.settingsConfig,

          sidebarConfig: savedData.accessConfig.sidebarConfig?.filter(
            (el) => el.to !== path
          ),
        },
      }));
    } else {
      setSaveData(savedData);
    }
    setBtnDisable(val);
  };

  const changeRights = async () => {
    await API.put("users", `/users/${savedData?.identityId}`, {
      body: {
        accessConfig: {
          routeConfig: savedData.accessConfig?.routeConfig,
          settingsConfig: savedData.accessConfig?.settingsConfig,
          sidebarConfig: [savedData?.accessConfig?.sidebarConfig[0]],
        },
      },
    })
      .then((success) => {
        message.success("Aksesi u dha me sukses");
        // console.log(success);
        // console.log("test13", savedData);
        navigate("/konfigurimet/siguria/rolet/siguris", { state: savedData });
      })
      .catch((err) => {
        message.error("Ndodhi nje gabim!");
        // console.log("Error Instance", err);
      });
    // console.log("forma", savedData);
  };

  //   console.log(savedData);
  return (
    <div className="konfigurimet-detjat">
      <Row
        className="kryefaqja-detajet-row"
        style={{ display: "flex", height: 160 }}
      >
        {savedData?.accessConfig?.sidebarConfig[0]?.map((el) => (
          <Col
            className="gutter-row"
            span={6}
            style={{ display: "flex", height: 40 }}
          >
            <div className="body-kryefaqja" style={{ display: "flex" }}>
              <h3 style={{ fontSize: 13, marginTop: 7 }}>{el.title}</h3>
              <div className="switch-div" style={{ display: "flex" }}>
                <Switch
                  defaultChecked={el?.auth}
                  onChange={(val) => onChange(el?.to, val)}
                />
              </div>
            </div>
          </Col>
        ))}
      </Row>
      <div className="konfigurimet-detjat-fotter">
        <Button
          disabled={btndisable !== null ? false : true}
          className="ruaj-ndrryshimet-btn"
          onClick={() => changeRights()}
        >
          Ruaj ndryshimet
        </Button>
      </div>
    </div>
  );
}

export default KryefaqjaAcessRights;
