import FormCard from "../../../FormCard/FormCard";
import { CardHeader, DocumentationCard } from "./components";
import maxBy from "lodash/maxBy";
import { v4 as uuidv4 } from "uuid";
import { memo, useContext, useMemo } from "react";
import { useLocation } from "react-router";
import "./DocumentationlistCard.scss";
import PunonjesitContext from "../../../../punonjesit/store/PunonjesitContext";

const DocumentationListCard = ({ activeFilter }) => {
  const { documentation } = useContext(PunonjesitContext);
  const openDocModal = useLocation()?.state?.openDocModal;

  const filteredDocs = useMemo(() => {
    if (!!activeFilter) {
      return documentation?.filter(
        ({ docStatus }) => docStatus === activeFilter
      );
    }
    return documentation;
  }, [activeFilter, documentation]);

  //* filters type of document
  // const filterDocsByType = (type) =>
  //   documentation?.filter(({ docType: arrDocType }) => arrDocType === type);

  // const getMaxByDocumentation = (docTypeToCompare) =>
  //   maxBy(filterDocsByType(docTypeToCompare), "createdAt") || {};

  return (
    <>
      <FormCard
        {...{
          // title: <CardHeader getMaxByDocumentation={getMaxByDocumentation} />,
          title: <CardHeader />,
          className: "documentationListCard",
          childrenClassName: "documentationListContainer",
        }}
      >
        {filteredDocs?.map((el) => (
          <DocumentationCard
            doc={el}
            {...{
              // renewal:
              // !!el?.renewalId &&
              // getMaxByDocumentation(el?.docType)?.docId === el?.docId,
              // original:
              //   filterDocsByType(el?.docType).length > 1 && !el?.renewalId,
              key: uuidv4(),
              openAutomatically: el?.docId === openDocModal,
            }}
          />
        ))}
      </FormCard>
    </>
  );
};

export default memo(DocumentationListCard);
