import { Divider } from "antd";
import { DocConfigIcon } from "../../assets";
import {
  CategoriesPanel,
  NewCategoryPanel,
  ViewLogoHeader,
} from "../../components";
import {
  VIEW_TITLE,
  VIEW_SUBTITLE,
} from "../../documentationConfigurationData";
import "./DocumentationCategoriesView.scss";

const DocumentationCategoriesView = () => (
  <div className="documentationConfigurationContainer">
    <div className="header">
      <ViewLogoHeader
        icon={DocConfigIcon}
        title={VIEW_TITLE}
        subtitle={VIEW_SUBTITLE}
      />

      <Divider type="vertical" className="headerDivider" />
      <NewCategoryPanel />
    </div>
    <CategoriesPanel />
  </div>
);

export default DocumentationCategoriesView;
