import "./AnimatedCheckBox.scss";
import { Checkbox, Tooltip } from "antd";
import { Fragment, useEffect, useState } from "react";
import LabeledInput from "../../../../components/Documentation/LabeledInput/LabeledInput";

const AnimatedCheckBox = ({
  noFormItem = false,
  formItemName,
  initialValue = false,
  form,
  containerClassName = "",
  containerStyle = {},
  primaryClassName = "",
  primaryStyle = {},
  secondaryClassName = "",
  secondaryStyle = {},
  labelClassName = "",
  checkBoxProps = {},
  label = "",
  tooltipVisible = true,
  ...rest
}) => {
  const { onChange: proppedOnChange = () => {}, ...restCheckBoxProps } =
    checkBoxProps;

  const { disabled } = restCheckBoxProps;

  const [checked, setChecked] = useState(initialValue);

  const LabelWrapper = !!tooltipVisible ? Tooltip : Fragment;
  useEffect(() => {
    form && form.setFieldsValue({ [formItemName]: checked });
    form && proppedOnChange(checked);
  }, [checked, form]);

  return (
    <LabeledInput
      {...{
        ...rest,
        formItemName,
        noFormItem,
        initialValue,
        valuePropName: "checked",
      }}
    >
      <div
        className={`configElementContainer  ${containerClassName} ${
          !!disabled ? "disabledConfigElementContainer" : ""
        }`.trim()}
        style={containerStyle}
        onClick={() => {
          !disabled && setChecked(!checked);
        }}
      >
        <div
          className={`bluContainer ${primaryClassName}`.trim()}
          style={{ width: !!checked ? 36 : null, ...primaryStyle }}
        >
          <div
            className={`navyBlueContainer ${secondaryClassName}`.trim()}
            style={{ opacity: !!checked ? 1 : null, ...secondaryStyle }}
          >
            <span style={!checked ? { opacity: 0 } : {}}>
              <Checkbox {...restCheckBoxProps} />
            </span>
          </div>
        </div>
        <LabelWrapper {...(!!tooltipVisible ? { title: label } : {})}>
          <span className={`configElementTitle ${labelClassName}`.trim()}>
            {label}
          </span>
        </LabelWrapper>
      </div>
    </LabeledInput>
  );
};

export default AnimatedCheckBox;
