import { Badge, Col, Row, Tooltip } from "antd";
import moment from "moment-timezone";
import React from "react";
import "./InterviewToday.scss";

import { getRandomColor } from "../Employees/Employees";
import { useSelector } from "react-redux";

function InterviewToday() {
  const { candidates } = useSelector((state) => state.candidates);
  //Filter throught the candidates and gets the candidates that have the interview today
  const interviewsToday = candidates
    ?.filter(
      (item) =>
        moment(item?.applicantInterviewDate).format("YYYY-MM-DD") ===
        moment().format("YYYY-MM-DD")
    )
    .sort((a, b) =>
      moment(a?.applicantInterviewDate).diff(
        b?.applicantInterviewDate,
        "hours"
      ) > 0
        ? 1
        : -1
    );

  return (
    <div className="interviewtodayContainer">
      {interviewsToday?.length !== 0 ? (
        <>
          <div className="interviewTodayHeaderContainer">
            <div>Emër</div>
            <div>Mbiemër</div>
            <div>Pozicioni</div>
            <div>Ora</div>
          </div>
          <div className="interview-container">
            {interviewsToday
              .filter(
                (items) =>
                  moment(items?.applicantInterviewDate).format("YYYY-MM-DD") ===
                  moment().format("YYYY-MM-DD")
              )
              ?.map?.((doc) => {
                return (
                  <div style={{ display: "flex" }} key={doc?.applicantId}>
                    <Row key={doc?.applicantId} gutter={24}>
                      <Col>
                        <Badge
                          color={getRandomColor()}
                          style={{
                            position: "absolute",
                            margin: "auto",
                            left: "5px",
                          }}
                        />
                        <span>{doc?.applicantFirstName}</span>
                      </Col>
                      <Col>
                        <span>{doc?.applicantLastName}</span>
                      </Col>
                      <Col>
                        <Tooltip title={doc?.applicantDepartment}>
                          <span>
                            {Array.isArray(doc?.applicantRoles)
                              ? doc?.applicantRoles.join(", ")
                              : doc?.applicantRoles}
                          </span>
                        </Tooltip>
                      </Col>
                      <Col>
                        <span>
                          {moment(doc?.applicantInterviewDate).format("HH:mm ")}
                        </span>
                      </Col>
                    </Row>
                  </div>
                );
              })}
          </div>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: 225,
          }}
        >
          <div style={{ font: "normal normal bold 14px/38px Open Sans" }}>
            Nuk keni intervista të planifikuara sot
          </div>
        </div>
      )}
    </div>
  );
}

export default InterviewToday;
