import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Input, Switch, Button, Form, message, Select } from "antd";
import { API } from "aws-amplify";
import { LajmerimeIcon } from "../../../../../../assets/icons/index";
import moment from "moment-timezone";
import "./lajmerime.scss";
import { useAuth } from "../../../../../../authentication/authHelpers";
import { RichTextEditor } from "../../../../../commonComponents";
import { htmlParser } from "../../../../../../utils";
import GlobalPopupKonfirm from "../../../../../commonComponents/GlobalPopupKonfirm";
import { sendNotification } from "../../../../../../utils/sendNotification";
import { NotifContext } from "../../../../components/notifications/context/notifContext";
import { HollowDotsSpinner } from "react-epic-spinners";
import { useSelector } from "react-redux";
import { requestAllPages } from "../../../../../../helpers/API/RequestFactory";

function Lajmerime() {
  //authenticated user
  let auth = useAuth();
  const { Option } = Select;
  const { dispatch } = useContext(NotifContext);
  // get departments and users with redoux
  const { departments } = useSelector((state) => state.departments);
  const { users } = useSelector((state) => state.usersList);
  const [form] = Form.useForm();
  const [tableData, setTableData] = useState(null);
  //state for the toogle select department and employes retun bolean
  const [switchDep, setSwitchDep] = useState(false);
  //state slecected department return string
  const [depVal, setDepVal] = useState(null);
  //state with all users return array with all users
  const [allusers, setAllUsers] = useState([]);
  // state with the selected employe return string
  const [employeesToSend, setEmployeesToSend] = useState(null);
  //state with the selected notification return object
  const [content, setContent] = useState(null);

  // state with the data for new record
  const [saveData, setSavedData] = useState({
    // notificationId: 12 * Math.random(),
    notificationTitle: "",
    createdAt: moment().format("DD/MM/YYYY"),
    notificationPriority: false,
    notificationCategory: "Important",
    userDetails: {},
    notificationBody: "",
    isAnnouncement: true,
    notificationUrl: "/kryefaqja",
    notificationFirstName: auth?.userAccess[0]?.given_name,
    notificationLastName: auth?.userAccess[0]?.family_name,
  });

  // get notificatins table from Api
  const getNotifications = async () => {
    await API.get("notifications", "/notifications")
      .then((r) => setTableData(r))
      .catch((err) => console.log("error fetching notifications", err));
  };

  // get the clicked notification and store in state
  const getNotif = (emplId) => {
    setContent(tableData?.filter((el) => el.notificationId === emplId)[0]);
  };

  // get the selected employe value and store in state
  const singleEmp = (value) => {
    setEmployeesToSend(value);
  };

  // toggle for the department and employe switch
  const handleChangeDepSutch = (value) => {
    setSwitchDep(value);
    setDepVal(null);
    setEmployeesToSend(null);
  };

  // store in state the selected department
  const handleChangeDep = (val) => {
    setDepVal(val);
    setEmployeesToSend(null);
  };

  //find all the users for the selected department return array
  // let userDepartments =
  // 	depVal === "Të gjithë departamentet"
  // 		? allusers
  // 		: allusers?.filter?.((el) => el?.department === depVal);

  //find the employee for the selected emplye return object
  // let employeSendNotif = allusers?.find?.(
  // 	(el) => `${el?.given_name} ${el?.family_name}` === notifSingleEmp
  // );

  // change the state of data with new value
  const handleChange = (objField, e) => {
    let test = objField;
    setSavedData((prev) => ({ ...prev, [test]: e }));
  };

  // load the notifications
  async function loadNotifications() {
    await API.get("notifications", "/notifications").then(
      (notifs) =>
        dispatch({
          type: "LOAD_NOTIFS",
          payload: notifs.map((el) => {
            let test = [];
            Object.entries(el?.userDetails).forEach((ent) => {
              if (ent[0] === auth?.userAccess[0]?.identityId) {
                test.push({ identityId: ent[0], ...ent[1] });
              }
            });
            let userD = {};

            if (test.length > 0) return { ...el, ...test[0] };
          }),
        }),
      (err) => console.log("error loading notifs", err)
    );
  }

  // post new notification
  const postLajmerim = async () => {
    if (!!switchDep) {
      message.info("U dërgua me sukses", 5);
      let employeSendNotif = allusers?.filter?.((el) =>
        employeesToSend.some(
          (s) => s === `${el?.given_name} ${el?.family_name}`
        )
      );
      let test = {};
      employeSendNotif?.forEach((el) => {
        test[el?.identityId] = {
          notificationMarkedHidden: false,
          notificationMarkedDeleted: false,
          notificationMarkedRed: false,
        };
      });
      await API.post("notifications", "/notifications", {
        body: {
          ...saveData,
          sentTo: employeesToSend?.join(", ") || "General",
          userDetails: { ...test },
        },
      }).then((r) => {
        sendNotifMultiUsers();
        // let div = document.createElement("div");
        // div.innerHTML = saveData.notificationBody;
        // sendNotification({
        // 	headings: { en: saveData.notificationTitle },
        // 	contents: { en: div.innerText },
        // 	url: "https://portal.flex.al/kryefaqja",
        // 	key: "identityId",
        // 	value: `${employeSendNotif?.identityId}`, // included_segments: ["s"]
        // });
        setTableData((prev) => [...prev, r]);
      });
    } else if (!switchDep) {
      let userDepartments =
        depVal === "allDepartments"
          ? allusers
          : allusers?.filter?.((el) => el?.department === depVal);
      message.info("U dërgua me sukses", 5);
      let test = {};
      userDepartments?.forEach((el) => {
        test[el.identityId] = {
          notificationMarkedHidden: false,
          notificationMarkedDeleted: false,
          notificationMarkedRed: false,
        };
      });
      await API.post("notifications", "/notifications", {
        body: {
          ...saveData,
          sentTo: depVal || "General",
          userDetails: { ...test },
        },
      }).then((r) => {
        sendNotifDep();
        setTableData((prev) => [...prev, r]);
      });
    } else if (depVal === null && employeesToSend === null) {
      message.info("U dërgua me sukses", 5);
      let test = {};
      allusers?.forEach((el) => {
        test[el.identityId] = {
          notificationMarkedHidden: false,
          notificationMarkedDeleted: false,
          notificationMarkedRed: false,
        };
      });
      await API.post("notifications", "/notifications", {
        body: { ...saveData, sentTo: "General", userDetails: { ...test } },
      }).then((r) => {
        let div = document.createElement("div");
        div.innerHTML = saveData.notificationBody;
        sendNotification({
          headings: { en: saveData.notificationTitle },
          contents: { en: div.innerText },
          url: "https://portal.flex.al/kryefaqja",
        });
        setTableData((prev) => [...prev, r]);
      });
    }
    onReset();
    loadNotifications();
  };

  const sendNotifDep = () => {
    let userDepartments =
      depVal === "allDepartments"
        ? allusers
        : allusers?.filter?.((el) => el?.department === depVal);
    let div = document.createElement("div");
    div.innerHTML = saveData.notificationBody;
    userDepartments?.forEach?.((el) => {
      sendNotification({
        headings: { en: saveData.notificationTitle },
        contents: { en: div.innerText },
        url: "https://portal.flex.al/kryefaqja",
        key: "identityId",
        value: el?.identityId, // included_segments: ["s"]
      });
    });
  };

  const sendNotifMultiUsers = () => {
    let employeSendNotif = allusers?.filter?.((el) =>
      employeesToSend.some((s) => s === `${el?.given_name} ${el?.family_name}`)
    );
    let div = document.createElement("div");
    div.innerHTML = saveData.notificationBody;
    employeSendNotif?.forEach?.((el) => {
      sendNotification({
        headings: { en: saveData.notificationTitle },
        contents: { en: div.innerText },
        url: "https://portal.flex.al/kryefaqja",
        key: "identityId",
        value: el?.identityId, // included_segments: ["s"]
      });
    });
  };

  // reset the form
  const onReset = () => {
    form.resetFields();
  };

  // delete one notification
  const deleteItems = async () => {
    await API.del(
      "notifications",
      `/notifications/${content.notificationId}`
    ).then(() => {
      setTableData((prev) =>
        prev.filter((item) => item.notificationId !== content.notificationId)
      );
      loadNotifications();
    });
    message.success("Njoftimi u fshi me sukses !", 10);
    setContent(null);
  };

  // shof notification by date range
  const lajmerimeFilter = tableData?.sort(
    (a, b) => b?.createdAt - a?.createdAt
  );

  // useEffect(() => {
  //   // console.log("content", content);
  // }, [content]);

  // execucate function when page is renderd
  useEffect(() => {
    getNotifications();
  }, []);

  // execucate function when page is renderd
  useEffect(() => {
    if (!!users) {
      setAllUsers(users?.allUsers.Items || []);
    }
  }, [users]);

  return (
    <>
      {!!tableData ? (
        <div className="lamerimet-tab">
          <Row className="lajmerime">
            {/* first container list of notifications */}
            <Col span={8}>
              <Row
                className="row-messages-njoftime"
                style={{ display: "flex", width: "100%" }}
              >
                <Col flex="519px">
                  <div className="notification-container">
                    {lajmerimeFilter?.length > 0 ? (
                      lajmerimeFilter
                        ?.filter((lajm) => lajm.isAnnouncement === true)
                        ?.map((Data) => (
                          <div
                            className={
                              Data?.notificationId === content?.notificationId
                                ? "notification-open"
                                : "notification"
                            }
                            style={{ cursor: "pointer" }}
                            onClick={() => getNotif(Data.notificationId)}
                            key={Data?.notificationId}
                          >
                            <Row
                              className="row-messages-njoftime-second"
                              style={{ width: "100%" }}
                            >
                              <Col span={11}>
                                <LajmerimeIcon
                                  // style={{ fill: "#fff" }}
                                  className={
                                    Data.notificationPriority !== true
                                      ? "normal"
                                      : "priority"
                                  }
                                />{" "}
                                &nbsp;&nbsp;
                                {Data?.notificationTitle}
                              </Col>
                              <Col span={5}>
                                {moment(Data?.createdAt).format("DD/MM/YYYY")}
                              </Col>
                              <Col span={8}>
                                {Data?.notificationFirstName}&nbsp;&nbsp;
                                {Data?.notificationLastName}
                              </Col>
                            </Row>
                          </div>
                        ))
                    ) : (
                      <div className="nuk-ka-lajmerim">
                        Nuk ka asnje lajmërim
                      </div>
                    )}
                  </div>
                </Col>
                <Col flex="5px" className="line-col">
                  <div className="line"></div>
                </Col>
              </Row>
            </Col>
            {/* second container will display if notification is clicked content of notifications */}
            {!content ? null : (
              <Col span={6}>
                <Row className="row-container-open">
                  <Col flex="auto">
                    <div className="notification-open-container">
                      <div className="notification-content">
                        <div
                          className="notification-header"
                          key={content?.notificationId}
                        >
                          <h3>
                            {" "}
                            {content !== null && content?.notificationTitle}
                          </h3>
                          {content?.notificationFirstName}&nbsp;&nbsp;
                          {content?.notificationLastName}
                          {/* {content !== null && content?.employeeFirstName}&nbsp; */}
                          {/* {content !== null && content?.employeeLastName} */}
                          &nbsp;{" "}
                          {content !== null &&
                            moment(content?.createdAt).format("DD/MM/YYYY")}
                        </div>
                        <div className="content">
                          {htmlParser(content?.notificationBody)}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            paddingRight: 20,
                          }}
                        >
                          Dërguar : <b>{content?.sentTo}</b>
                        </div>
                      </div>
                    </div>
                    <GlobalPopupKonfirm
                      title={"jeni të sigurt që do të fshini këtë njoftim?"}
                      Button={
                        <Button
                          className="fshij-lajmerim-btn"
                          // onClick={deleteItems}
                        >
                          Fshij Lajmerimin
                        </Button>
                      }
                      cancelText={"JO"}
                      okText={"PO"}
                      onConfirm={deleteItems}
                    />
                  </Col>
                  <Col flex="5px" className="line-col">
                    <div className="line"></div>
                  </Col>
                </Row>
              </Col>
            )}
            {/* third container create notifications */}
            <Col span={10}>
              <div className="notification-post-container">
                <Form
                  name="notification-lajmerime-form"
                  onFinish={postLajmerim}
                  form={form}
                >
                  <Row style={{ width: "100%" }} className="lajmerime-post-row">
                    <Col span={12}>
                      <h1 style={{ width: 250 }}>
                        <LajmerimeIcon />
                        <span className="star-color">&nbsp;*</span>Titulli i
                        lajmërimit
                      </h1>
                      <Form.Item
                        name="notificationTitle"
                        rules={[
                          {
                            required: true,
                            message: "Ju lutem shkruaj Titullin !",
                          },
                        ]}
                      >
                        <Input
                          style={{
                            width: 250,
                            background: "#F1F7FB",
                            border: 0,
                            borderRadius: 5,
                          }}
                          onChange={(e) =>
                            handleChange("notificationTitle", e.target.value)
                          }
                          placeholder="Shkruaj titullin e lajmërimit"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <div className="notification-post-header">
                        <h1>Prioritet i lartë?</h1>
                        <div className="switch-div">
                          <Form.Item name="notificationPriority">
                            <Switch
                              checked={saveData.notificationPriority}
                              onChange={(e) =>
                                handleChange("notificationPriority", e)
                              }
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Col>
                    <div className="dep-specifik-slection">
                      <div>
                        {!!switchDep ? (
                          <>
                            <b>Punonjësit:</b>{" "}
                            <Select
                              placeholder="Punonjësit"
                              showSearch={true}
                              bordered={false}
                              mode="multiple"
                              value={!!employeesToSend ? employeesToSend : []}
                              style={{
                                width: 180,
                                background: "#f1f7fb",
                                borderRadius: 10,
                              }}
                              onChange={(e) => singleEmp(e)}
                            >
                              {allusers?.map?.(
                                ({ given_name, family_name, identityId }) => (
                                  <Option
                                    value={`${given_name} ${family_name}`}
                                    key={identityId}
                                  >
                                    {`${given_name} ${family_name}`}
                                  </Option>
                                )
                              )}
                            </Select>
                          </>
                        ) : (
                          <>
                            <b>Departamenti:</b>{" "}
                            <Select
                              placeholder="Departamenti"
                              bordered={false}
                              showSearch={true}
                              labelInValue={false}
                              value={depVal}
                              style={{
                                width: 180,
                                background: "#f1f7fb",
                                borderRadius: 10,
                              }}
                              onChange={handleChangeDep}
                            >
                              {[
                                <Option
                                  value={"allDepartments"}
                                  key={"allDepartments"}
                                >
                                  Të gjithë departamentet
                                </Option>,
                                ...(departments?.map?.(({ departmentName }) => (
                                  <Option
                                    value={departmentName}
                                    key={departmentName}
                                  >
                                    {departmentName}
                                  </Option>
                                )) || []),
                              ]}
                            </Select>
                          </>
                        )}
                      </div>{" "}
                      <div style={{ marginLeft: 20 }}>
                        <div
                          className="switch-div"
                          style={{ width: 260, height: 35 }}
                        >
                          Department: &nbsp;
                          <Switch
                            // checked={saveData.notificationPriority}
                            onChange={(e) => handleChangeDepSutch(e)}
                          />{" "}
                          &nbsp;: Punonjës
                        </div>
                      </div>
                    </div>
                    <div
                      className="permbajtja-lajmerimit"
                      style={{ width: "93%" }}
                    >
                      <h1 style={{ width: 250 }}>
                        <LajmerimeIcon />
                        <span className="star-color">&nbsp;*</span>Përmbajtja e
                        lajmërimit
                      </h1>
                      <div className="lajmerimet-conent">
                        <Form.Item
                          name="notificationBody"
                          rules={[
                            {
                              required: true,
                              message:
                                "Ju lutem shto permbajtjen e lajmerimit !",
                            },
                          ]}
                        >
                          <RichTextEditor
                            className={"lajmerime-quill"}
                            onChange={(e) =>
                              handleChange("notificationBody", e)
                            }
                          />
                        </Form.Item>
                      </div>
                      <Form.Item>
                        <div className="lajmerimet-fotter">
                          <Button
                            className="lajmerimet-anullo-btn"
                            htmlType="button"
                            onClick={onReset}
                          >
                            Anulo
                          </Button>
                          <Button
                            htmlType="submit"
                            className="lajmerimet-publiko-btn"
                          >
                            Publiko
                          </Button>
                        </div>
                      </Form.Item>
                    </div>
                  </Row>
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      ) : (
        <HollowDotsSpinner
          color="#1DA193"
          size={24}
          style={{ position: "relative", top: "50%", left: "45%" }}
        />
      )}
    </>
  );
}
export default Lajmerime;
