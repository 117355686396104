import { useContext, useEffect, useState } from "react";
import { Button, Card, Form, Spin } from "antd";
import ActivityModal from "./ActivityModal/ActivityModal";
import "../Personal/employee.scss";
import "./NameCard.scss";
import { Row } from "antd";
import moment from "moment-timezone";
import driveApi from "../../../DriveRequest/driveApi";
import { useSelector } from "react-redux";
import { API } from "aws-amplify";
import { message } from "antd";
import {
  showLoadingMsg,
  showSuccessMsg,
} from "../../../Documentation/utils/Messages";
import EmployeeReasonModal from "./EmployeeReasonModal/EmployeeReasonModal";
import { getSocketUrl } from "../../../../utils/websocketConfig";
import GoogleDriveUpload from "../../../Konfigurimet/Profile/GoogleDriveUpload";
import PunonjesitContext from "../../store/PunonjesitContext";

// * @EneaXharau - Added Websocket connection
// const socketHandler = new WebSocket(getSocketUrl("/dashboard/sockets"));

function NameCard() {
  const { employee, setEmployee, auth } = useContext(PunonjesitContext);

  const { users } = useSelector((state) => state.usersList);
  const { accessToken } = useSelector((state) => state.accessToken);
  const driveRequest = driveApi({ accessToken });

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [currentFile, setCurrentFile] = useState("");
  const [spinning, setSpinning] = useState(true);

  //#region CHANGE STATUS
  const changeEmployeeStatus = async ({ data }) => {
    let changes = [];
    changes.push({
      activity: "Ndryshuar",
      author:
        auth?.user?.attributes?.given_name +
        " " +
        auth?.user?.attributes?.family_name,
      changeDate: moment().valueOf(),
      field: "Statusi i punonjësit",
      oldValue: employee?.employeeStatus,
      newValue: data?.employeeStatus,
    });
    const toSend = {
      ...data,
      // employeeReasonforSuspension: reason || "Nuk ka arsye largimi!",
      // employeeReasonforTermination: reason || "Nuk ka arsye shkarkimi!",
      employeeDateOfReEmployment:
        data?.employeeStatus === "Aktiv" ? moment().valueOf() : undefined,
      keylog: [...(employee?.keylog || []), ...changes],
    };
    await API.put("employees", `/employees/${employee.employeeId}`, {
      body: { ...toSend },
    }).then(async (res) => {
      /**
       * * @EneaXharau - Added socket emitter
       * * .send() takes -> { request: String, body: any} -> stringified
       */
      // if (socketHandler.readyState === socketHandler.OPEN) {
      //   socketHandler.send(
      //     JSON.stringify({
      //       request: "user_edit",
      //       body: res?.employee?.Item,
      //       id: auth?.employeeId,
      //     })
      //   );
      // }
      showLoadingMsg({ content: "Duke ruajtur..." });
      showSuccessMsg({ content: "Të dhënat u ruajtën!" });
      setEmployee(res?.employee?.Item);
    });
  };

  function disableStatusButtons() {
    const options = ["Larguar", "Shkarkuar"];
    if (options.includes(employee?.employeeStatus)) {
      return true;
    }
  }

  //#region ON PICKER SUCCESS
  const onPickerSuccess = async (params) => {
    setSpinning(true);
    const fileId = params?.uploadedFiles?.[0]?.id || "";
    const userIdentityId = users?.allUsers?.Items?.find(
      (el) => el?.userSub === employee?.userSub
    )?.identityId;
    if (!!fileId) {
      const toSend = {
        googleDriveFileId: fileId,
        keylog: [
          ...(employee?.keylog || []),
          {
            activity: "Ndryshuar",
            author:
              auth?.user?.attributes?.given_name +
              " " +
              auth?.user?.attributes?.family_name,
            changeDate: moment().valueOf(),
            field: "Fotoja e profilit",
            oldValue: "",
            newValue: params?.uploadedFiles?.[0]?.name || "",
          },
        ],
      };
      await API.put("users", `/users/${userIdentityId}`, {
        body: { ...toSend },
      })
        .then((r) => {
          setSpinning(false);
          setEmployee((prev) => ({ ...prev, ...toSend }));
          message.success("U ndryshua me sukses!");
        })
        .catch((err) => {
          message.error("Ndodhi nje gabim!");
        });
    }
  };

  const getProfilePicture = async () => {
    await driveRequest
      .getImageSrc(employee?.googleDriveFileId)
      .then((image) => {
        setCurrentFile(image?.src);
        setSpinning(false);
      });
  };

  useEffect(() => {
    if (!!employee?.googleDriveFileId) {
      getProfilePicture();
    } else {
      setTimeout(() => {
        setSpinning(false);
      }, 1000);
    }
  }, [employee?.googleDriveFileId]);

  //#region RETURN
  return (
    <div style={{ width: "100%" }}>
      <div style={{ width: "100%", height: "100%" }}>
        {/* Card e emrit dhe aktivitetit */}
        <Card
          className="name-card"
          style={{ width: "100%", justifyContent: "center", height: "470px" }}
          title={
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {employee?.employeeFirstName} {employee?.employeeLastName}
            </span>
          }
        >
          <Row
            style={{
              width: "100%",
              justifyContent: "center",
              height: "100%",
              marginTop: "-16px",
            }}
          >
            <div className="act">Aktiv qe prej:</div>
            <div id="active">
              {moment(employee?.employeeHireDate).format("DD/MM/YYYY")}
            </div>
            <div className="nameCard-buttons">
              <button
                className="changes"
                onClick={() => setIsModalVisible(true)}
                data-testid="shiko-ndryshimet-btn"
              >
                Shiko ndryshimet
              </button>
              {employee?.employeeStatus === "Aktiv" ? (
                <Button
                  className="cardDeleteButton"
                  data-testid="largo-btn"
                  onClick={() => {
                    setModalVisible("largo");
                  }}
                  disabled={
                    employee?.employeeStatus === "Larguar" ? true : false
                  }
                >
                  Largo
                </Button>
              ) : (
                <Button
                  onClick={() =>
                    changeEmployeeStatus({ data: { employeeStatus: "Aktiv" } })
                  }
                  data-testid="aktivizo-btn"
                  className={
                    employee?.employeeStatus === "Shkarkuar"
                      ? "disableActiveButton"
                      : "cardActivateButton"
                  }
                  disabled={
                    employee?.employeeStatus === "Shkarkuar" ? true : false
                  }
                >
                  Aktivizo
                </Button>
              )}
              {!!modalVisible ? (
                <EmployeeReasonModal
                  {...{
                    modalVisible,
                    setModalVisible,
                    employee,
                    changeEmployeeStatus,
                  }}
                />
              ) : null}
              <Button
                className={
                  employee?.employeeStatus === "Shkarkuar"
                    ? "disableTerminateButton"
                    : "terminateEmployeeButton"
                }
                data-testid="shkarko-btn"
                onClick={() => {
                  setModalVisible("shkarko");
                }}
                disabled={disableStatusButtons()}
              >
                Shkarko
              </Button>
            </div>
          </Row>

          {employee?.employeeStatus === "Larguar" ? (
            <div className="showReason">
              <span className="reasonLabel">Arsyeja largimit:</span>
              <div className="reason">
                {employee?.employeeReasonforSuspension}
              </div>
            </div>
          ) : (
            ""
          )}
          {employee?.employeeStatus === "Shkarkuar" ? (
            <div className="showReason">
              <span className="reasonLabel">Arsyeja e shkarkimit:</span>
              <div className="reason">
                {employee?.employeeReasonforTermination}
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="image-empView-div">
            <Spin spinning={spinning}>
              {!!currentFile && (
                <img
                  className="empView-img"
                  data-testid="pfp-section"
                  src={currentFile}
                  alt="Flex"
                />
              )}
              {!spinning && !currentFile && (
                <div data-testid="pfp-section">No photo uploaded</div>
              )}
            </Spin>
          </div>
          {users?.userRole === "Admin" &&
            employee?.employeeStatus === "Aktiv" && (
              <GoogleDriveUpload
                emplyeesObj={employee}
                accessToken={accessToken}
                onPickerSuccess={onPickerSuccess}
                setCurrentFile={setCurrentFile}
                adminUpload={true}
                uploadImages={true}
                data-testid="google-upload-btn"
              />
            )}
        </Card>
      </div>
      {!!isModalVisible ? (
        <ActivityModal
          setIsModalVisible={setIsModalVisible}
          keylog={employee?.keylog}
        />
      ) : null}
    </div>
  );
}
export default NameCard;
