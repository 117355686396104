import { useEffect, useState } from "react";
import axios from "axios";
import { getAddress } from "../utils/getAddress";

const useIpAddress = () => {
  const [data, setData] = useState("");
  useEffect(() => {
    axios
      .get("https://geolocation-db.com/json/")
      .then(async (res) => {
        const address = await getAddress({
          lat: res.data.latitude,
          lng: res.data.longitude,
        });

        setData({ ...res.data, address });
      })
      .catch((err) => console.log(err.message));
  }, []);

  return data;
};

export default useIpAddress;
