import moment from "moment-timezone";
import { getDayTypeTranslations } from "../../../Reports/processFactory/approvals/approvals";

export const prepareTimesheetTable = (payObj) => {
	const tempTable = {
		columnsDef: ["DATA", "FILLIMI", "MBARIMI", "ORE", "SHTESA", "ZBRITJE", "PAGESA"],
		rowData: [],
		totalities: {},
	};

	console.log("prepareTimesheetTable", payObj);

	const calculations = payObj.allShiftsUser || [];

	const tempSortedCalcs = [];

	if (calculations.length > 0) {
		// calculations.map((innerArr) => {
		// 	innerArr.map((el) => tempSortedCalcs.push(el));
		// });
		calculations
			.sort((a, b) => moment(a.clockInDate).valueOf() - moment(b.clockInDate).valueOf())
			.map((clocking) => {
				console.log("clocking", clocking);
				tempTable.rowData.push([
					moment(clocking?.clockInDate).format("DD/MM/YYYY") +
						`${
							clocking?.dayType !== "Regular"
								? " *" + getDayTypeTranslations(clocking?.dayType)
								: ""
						}`,
					moment(clocking?.clockInDate).format("HH:mm"),
					moment(clocking?.clockOutDate).format("HH:mm"),
					moment(clocking?.clockOutDate).diff(moment(clocking?.clockInDate), "hours", true),
					clocking?.totalities?.additions || "",
					clocking?.totalities?.deductions || "",
					clocking?.totalities?.totalPay,
				]);
			});

		return tempTable;
	}
	return tempTable;
};

const categorizeHours = (type) => {
	if (type === "normalHours") {
		return "Deri në 19:00";
	} else if (type === "lateHours") {
		return "19:00 - 22:00";
	} else if (type === "extraHours") {
		return "22:00 - 07:30";
	} else {
		return "TURN I PAKATEGORIZUAR";
	}
};
