import { Drawer } from "antd";
import React, { useMemo, useState } from "react";
import { Select } from "antd";
import "./FilterDrawer.scss";
import { useReportsData } from "../generate/reportContext/reportHelpers";
import { ModalXIcon } from "../../../assets/icons";
const { Option } = Select;
function FilterDrawer({
  visible,
  setVisible,
  contentData,
  onGenerate,
  resetCustom,
  onCustomSort,
}) {
  const [customFilter, setCustomFilter] = useState(null);
  const [customValue, setCustomValue] = useState(null);
  const [customFilter2, setCustomFilter2] = useState(null);
  const [customValue2, setCustomValue2] = useState(null);
  const [customFilter3, setCustomFilter3] = useState(null);
  const [customValue3, setCustomValue3] = useState(null);
  const [customSort, setCustomSort] = useState({
    column: null,
    type: "ascending",
  });
  const [noFilters, setNoFilters] = useState(1);
  const { drawerFilter, changeDrawerFilter } = useReportsData();

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };
  const onClearFilter = () => {
    resetCustom();
    setCustomFilter(null);
    setCustomValue(null);
    setCustomFilter2(null);
    setCustomValue2(null);
    setCustomFilter3(null);
    setCustomValue3(null);
    setNoFilters(1);
  };

  const getCustomFilterVals = useMemo(() => {
    const res = [];
    if (!!customFilter) {
      let idx = contentData.columnsDef.indexOf(customFilter);
      if (idx !== -1) {
        contentData.rowData.map((el, id) => {
          el?.map((e, id) => {
            if (id === idx) {
              res.push(e);
            }
          });
        });
      }
      const unique = new Set(res);
      console.log("content data", contentData);
      return [...unique].map((el) => <Option value={el}>{el}</Option>);
    }
    return null;
  }, [customFilter]);

  const getCustomFilterVals2 = useMemo(() => {
    const res = [];
    if (!!customFilter2) {
      let idx = contentData.columnsDef.indexOf(customFilter2);
      if (idx !== -1) {
        contentData.rowData.map((el, id) => {
          el?.map((e, id) => {
            if (id === idx) {
              res.push(e);
            }
          });
        });
      }
      const unique = new Set(res);
      console.log("content data", contentData);
      return [...unique].map((el) => <Option value={el}>{el}</Option>);
    }
    return null;
  }, [customFilter2]);

  const getCustomFilterVals3 = useMemo(() => {
    const res = [];
    if (!!customFilter3) {
      let idx = contentData.columnsDef.indexOf(customFilter3);
      if (idx !== -1) {
        contentData.rowData.map((el, id) => {
          el?.map((e, id) => {
            if (id === idx) {
              res.push(e);
            }
          });
        });
      }
      const unique = new Set(res);
      console.log("content data", contentData);
      return [...unique].map((el) => <Option value={el}>{el}</Option>);
    }
    return null;
  }, [customFilter3]);

  const customGenerate = () => {
    if (!!customFilter && !!customValue) {
      // onGenerate(customFilter, customValue);
      changeDrawerFilter({ filter: customFilter, value: customValue });
    }
    if (!!customFilter2 && !!customValue2) {
      // onGenerate(customFilter2, customValue2);
      changeDrawerFilter({ filter: customFilter, value: customValue });
    }
    if (!!customFilter3 && !!customValue3) {
      // onGenerate(customFilter3, customValue3);
      changeDrawerFilter({ filter: customFilter, value: customValue });
    }
  };

  const onAddFilter = () => {
    if (noFilters < 4) setNoFilters(noFilters + 1);
  };

  return (
    <div>
      <Drawer
        width={700}
        title="Më shumë filtrime"
        placement="right"
        onClose={onClose}
        open={visible}
        closeIcon={<ModalXIcon />}
      >
        <div
          className="filter-item-wrapper"
          style={{
            marginLeft: 20,
            display: "flex",
            flexDirection: "column",
            gap: 30,
          }}
        >
          <FilterItem
            {...{
              customFilter,
              setCustomFilter,
              customValue,
              setCustomValue,
              contentData,
              getCustomFilterVals,
            }}
          />
          {noFilters >= 2 && (
            <FilterItem
              setCustomFilter={setCustomFilter2}
              customFilter={customFilter2}
              customValue={customValue2}
              setCustomValue={setCustomValue2}
              getCustomFilterVals={getCustomFilterVals2}
              {...{
                // customFiler: customFilter2,
                // setCustomFilter: setCustomFilter2,

                contentData,
              }}
            />
          )}
          {noFilters >= 3 && (
            <FilterItem
              setCustomFilter={setCustomFilter3}
              customFilter={customFilter3}
              customValue={customValue3}
              setCustomValue={setCustomValue3}
              getCustomFilterVals={getCustomFilterVals3}
              {...{
                contentData,
              }}
            />
          )}
          <div className="action-btns">
            <button className="add-filter-btn" onClick={onAddFilter}>
              Shto filtrim
            </button>
            <button
              className="clear-filter-btn"
              disabled={!!customFilter ? false : true}
              onClick={onClearFilter}
            >
              Pastro Filtrimin
            </button>{" "}
            <button className="generate-btn" onClick={customGenerate}>
              Gjenero
            </button>
          </div>
        </div>
      </Drawer>
    </div>
  );
}

export default FilterDrawer;

const FilterItem = ({
  customFilter,
  setCustomFilter,
  customValue,
  setCustomValue,
  contentData,
  getCustomFilterVals,
}) => {
  return (
    <div className="filter-item-drawer">
      <span>Filtrimi</span>
      <Select
        placeholder={"Zgjidh nje fushe filtrimi..."}
        style={{
          width: 230,
        }}
        value={customFilter || null}
        onChange={(e) => {
          setCustomValue(null);
          setCustomFilter(e);
        }}
        showSearch
      >
        {contentData?.columnsDef
          .filter((f) => f !== "")
          .map((el) => (
            <Option value={el}>{el}</Option>
          ))}
      </Select>
      {customFilter && (
        <>
          {"   "}
          <Select
            style={{
              width: 230,
            }}
            placeholder={"Zgjidh Vleren"}
            value={customValue || null}
            onChange={(e) => setCustomValue(e)}
            showSearch
          >
            {getCustomFilterVals}
          </Select>
        </>
      )}
    </div>
  );
};
