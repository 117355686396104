import React, { useState, useEffect, useMemo, useContext } from "react";
import { Modal, Button } from "antd";
import "./ShiftModal.scss";
import ShiftGrid from "./ShiftGrid";
import InnerModal from "./InnerModal";
import moment from "moment-timezone";
import { useLocation } from "react-router-dom";
import PunonjesitContext from "../../../store/PunonjesitContext";

const ShiftModal = ({
  isModalVisible,
  setIsModalVisible,
  selectedData,
  setSelectedData,
}) => {
  const { employee } = useContext(PunonjesitContext);
  const [selected, setSelected] = useState([]);
  // const [selectedEmployee, setSelectedEmployee] = useState(
  //   JSON.parse(localStorage.getItem("selectedRows"))
  // );
  const { state } = useLocation();
  const handleOk = () => {
    setSelectedData(null);
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedData(null);
    setSelected(null);
  };

  const titleModal = useMemo(() => {
    if (!employee && !!state) {
      return `${state?.employeeFirstName} ${state?.employeeLastName} - ${moment(
        selected[0]?.muaji
      ).format("MMMM YYYY")}`;
    } else if (!!employee) {
      return `${employee?.employeeFirstName} ${
        employee?.employeeLastName
      } - ${moment(selectedData[0]?.muaji).format("MMMM YYYY")}`;
    }
  }, [employee, state]);
  useEffect(() => {
    setSelected(selectedData);
  }, [selectedData]);
  return (
    <>
      <Modal
        className="shift-modal"
        title={titleModal}
        onOk={handleOk}
        onCancel={handleCancel}
        open={isModalVisible}
        centered={true}
        destroyOnClose
        footer={[
          <Button key="1" className="close" onClick={handleCancel}>
            Mbyll faqen
          </Button>,
          // <Button key="2" className="save">
          //   Ruaj
          // </Button>,
          <InnerModal />,
        ]}
      >
        <ShiftGrid
          selected={selectedData}
          {...{ setSelectedData }}
          employee={employee}
        />
      </Modal>
    </>
  );
};
export default ShiftModal;
