import moment from "moment-timezone";
import { multiply, add } from "lodash";
// import { groupByKey } from "../../../../utils/loadash/methods";
// import { COEFFICENTS_WAGES } from "../../../../constantsFactory/wageHours";
import { groupByKey } from "./methods";
import { COEFFICENTS_WAGES } from "./wageHours";
import { performSliceShifting } from "./slicing";
// Worst Case Time Complexity: n*O(1) + 4*O(n) + O((log n)^2) = O(n) Linear

export const calculateEmployeeHoursGeneral = (info) => {
  const { employee, clockings, momentInstance } = info; // O(1) T.C
  let result = {
    allhours: { normalHours: 0, lateHours: 0, extraHours: 0, totalHours: 0 },
    employeeInfo: employee[0],
    momentInstance: momentInstance,
    calculations: [],
    earnings: {},
    generations: {},
  };
  // console.log("Employee 0", employee);

  let payrollType = employee[0]?.employeePayrollType; // O(1)
  let processedShifts = processShifts(
    momentInstance,
    clockings,
    employee[0].employeeId
  ); // O(n) Linear T.C
  result.calculations = groupShifts(processedShifts); // O(n) + O(n) => O(n) Linear T.C
  // Sum different hours all Linear cost O(n) operations
  result.allhours.normalHours =
    result.calculations.reduce(
      (acc, currVal) => acc + currVal?.NORMAL_HOURS?.amount,
      0
    ) || 0;
  result.allhours.lateHours =
    result.calculations.reduce(
      (acc, currVal) => acc + currVal?.LATE_HOURS?.amount,
      0
    ) || 0;
  result.allhours.extraHours =
    result.calculations.reduce(
      (acc, currVal) => acc + currVal?.EXTRA_HOURS?.amount,
      0
    ) || 0;
  result.allhours.totalHours =
    result?.allhours?.normalHours +
    result?.allhours?.lateHours +
    result?.allhours?.extraHours;

  if (processedShifts.length > 0) {
    let earnings =
      payrollType === "Page bazuar ne ore"
        ? performWageCalc(employee[0]?.employeeSalaryPerHour, result) // O(n) Linear cost
        : { totalWage: parseFloat(employee[0]?.employeeGrossMonthlySalary) }; // O(1) constant
    result.earnings = earnings;
    return result;
  } else if (processedShifts.length === 0) {
    // O(1) meta-data .length is saved
    result.earnings = {
      totalWage: parseFloat(employee[0]?.employeeGrossMonthlySalary),
    };
    return result;
  }
  return result;
};

/**
 * In the case of wage calculations (hourly pay),
 * earnings calculations are performed
 * @param {Number} amountPerHour
 * @param {Object} referenceObj
 * @returns {Object} of different categories
 * Worst Time Complexity: O(n)
 */

export const performWageCalc = (amountPerHour, referenceObj) => {
  // O(1) assignment and multiplication
  let totalities = {
    normalHours: {
      normalEarnings: 0,
      coefficent: COEFFICENTS_WAGES?.NORMAL_HOURS,
      rate: multiply(amountPerHour, COEFFICENTS_WAGES?.NORMAL_HOURS),
    },
    lateHours: {
      lateEarnings: 0,
      coefficent: COEFFICENTS_WAGES?.LATE_HOURS,
      rate: multiply(amountPerHour, COEFFICENTS_WAGES?.LATE_HOURS),
    },
    extraHours: {
      extraEarnings: 0,
      coefficent: COEFFICENTS_WAGES?.EXTRA_HOURS,
      rate: multiply(amountPerHour, COEFFICENTS_WAGES?.EXTRA_HOURS),
    },
    totalWage: 0,
  };
  let tempRef = referenceObj?.allhours || null;
  if (!!tempRef) {
    // O(n) worst time complexity cost iteration
    Object.entries(tempRef).forEach((valArr) => {
      switch (valArr[0]) {
        case "normalHours":
          totalities.totalWage = add(
            totalities.totalWage,
            multiply(totalities?.normalHours?.rate, valArr[1])
          );
          totalities = {
            ...totalities,
            normalHours: {
              ...totalities.normalHours,
              normalEarnings: add(
                totalities?.normalHours?.normalEarnings,
                multiply(totalities?.normalHours?.rate, valArr[1])
              ),
            },
          };
        case "lateHours":
          totalities.totalWage = add(
            totalities.totalWage,
            multiply(totalities?.lateHours?.rate, valArr[1])
          );
          totalities = {
            ...totalities,
            lateHours: {
              ...totalities.lateHours,
              lateEarnings: add(
                totalities?.lateHours?.lateEarnings,
                multiply(totalities?.lateHours?.rate, valArr[1])
              ),
            },
          };
        case "extraHours":
          totalities.totalWage = add(
            totalities.totalWage,
            multiply(totalities?.extraHours?.rate, valArr[1])
          );
          totalities = {
            ...totalities,
            extraHours: {
              ...totalities.extraHours,
              extraEarnings: add(
                totalities?.extraHours?.extraEarnings,
                multiply(totalities?.extraHours?.rate, valArr[1])
              ),
            },
          };
        default:
          totalities.totalWage += 0;
      }
    });
    return totalities;
  }
};

// Time Complexity O(n)
export const processShifts = (momentInstance, clockings, employeeId) => {
  const result =
    momentInstance !== null
      ? clockings.filter(
          (el) =>
            el.clockInDate !== null &&
            el.clockOutDate !== null &&
            el.clockInDate > moment(momentInstance?.start) &&
            el.clockInDate < moment(momentInstance?.end) &&
            el.employeeId === employeeId
        )
      : clockings.filter(
          (el) => el.clockInDate !== null && el.clockOutDate !== null
        );

  return result || [];
};

/**
 * Used to group shifts by Date || O(n) + O(1) = O(n) time complexity
 *  Categorize the timeshift
 *  {1. Before 19:00 (coefficent=1), 2. Between 19.00 - 22:00(c=1.2), After 22:00 (c=1.5)
 * @param {Array Of Objects} shifts
 * @returns {Array}
 */

export const groupShifts = (shifts) => {
  let result = shifts;
  let differentHours = [];
  if (!!result) {
    if (result.length > 0) {
      result.forEach((el) => {
        if (!!el?.clockInDate)
          el.shiftDate = moment(el?.clockInDate).format("DD/MM/YYYY");
      });
      let groupedShiftDays = groupByKey(result, "shiftDate"); // Likely O(n)
      Object.values(groupedShiftDays).forEach((el) => {
        let cat = groupSingleShift(el);
        if (!!cat) differentHours.push(cat);
      });
      return differentHours;
    }
  }
  return differentHours;
};

export const groupSingleShift = (
  shift,
  isNotSubjetToOtherCoefficients = false
) => {
  if (!!shift) {
    let NORMAL_HOURS = {
      coefficent: !isNotSubjetToOtherCoefficients
        ? COEFFICENTS_WAGES.NORMAL_HOURS
        : 1,
      start: moment("08:00:01", "HH:mm:ss"),
      end: moment("18:59", "HH:mm"),
      amount: 0,
    };
    let LATE_HOURS = {
      coefficent: !isNotSubjetToOtherCoefficients
        ? COEFFICENTS_WAGES.LATE_HOURS
        : 1,
      start: moment("19:00", "HH:mm"),
      end: moment("21:59", "HH:mm"),
      amount: 0,
    };
    let EXTRA_HOURS = {
      coefficent: !isNotSubjetToOtherCoefficients
        ? COEFFICENTS_WAGES.EXTRA_HOURS
        : 1,
      start: moment("22:00", "HH:mm"),
      end: moment("23:59", "HH:mm"),
      amount: 0,
    };
    let EXTRA_HOURS2 = {
      coefficent: COEFFICENTS_WAGES.EXTRA_HOURS,
      start: moment("00:00", "HH:mm"),
      end: moment("08:00", "HH:mm"),
      amount: 0,
    };
    let EARNINGS_REFERENCE = {
      NORMAL_HOURS: {
        coefficent: !isNotSubjetToOtherCoefficients
          ? COEFFICENTS_WAGES.NORMAL_HOURS
          : 1,
        start: moment("07:30", "HH:mm"),
        end: moment("19:00:00", "HH:mm:ss"),
        amount: 0,
      },
      LATE_HOURS: {
        coefficent: !isNotSubjetToOtherCoefficients
          ? COEFFICENTS_WAGES.LATE_HOURS
          : 1,
        start: moment("19:00", "HH:mm"),
        end: moment("22:00:00", "HH:mm:ss"),
        amount: 0,
      },
      EXTRA_HOURS: {
        coefficent: !isNotSubjetToOtherCoefficients
          ? COEFFICENTS_WAGES.EXTRA_HOURS
          : 1,
        start: moment("22:00", "HH:mm"),
        end: moment("23:59:59", "HH:mm:ss"),
        amount: 0,
      },
      EXTRA_HOURS2: {
        coefficent: !isNotSubjetToOtherCoefficients
          ? COEFFICENTS_WAGES.EXTRA_HOURS
          : 1,
        start: moment("00:00", "HH:mm"),
        end: moment("07:00", "HH:mm:ss"),
        amount: 0,
      },
      // EXTRA_HOURS: {
      // 	coefficent: COEFFICENTS_WAGES.EXTRA_HOURS,
      // 	start: moment("00:01", "HH:mm"),
      // 	end: moment("06:59:59", "HH:mm:ss"),
      // 	amount: 0,
      // },
    };
    let result = Object.assign({}, shift);
    if (!!result?.clockOutDate) {
      let slicedShift = performSliceShifting(result, EARNINGS_REFERENCE) || [];
      slicedShift?.forEach((shift) => {
        if (shift?.type === "normalHours") {
          NORMAL_HOURS.amount += shift?.duration || 0;
        } else if (shift?.type === "lateHours") {
          LATE_HOURS.amount += shift?.duration || 0;
        } else if (shift?.type === "extraHours") {
          EXTRA_HOURS.amount += shift?.duration || 0;
        }
      });
    }

    return { ...{ NORMAL_HOURS, LATE_HOURS, EXTRA_HOURS } };
  } else {
    return {};
  }
};

/**
 * Used to categorize hours for different categories
 * Earnings are depended on these calculations (Coefficents too)
 * @param {Array} hoursSource
 * @returns {Object} of Categorized hours
 * Worst time complexity: O(n)
 */

export const categorizeHours = (hoursSource) => {
  // O(1)
  let temp = hoursSource;
  let NORMAL_HOURS = {
    coefficent: COEFFICENTS_WAGES.NORMAL_HOURS,
    start: moment("08:00:01", "HH:mm:ss"),
    end: moment("18:59", "HH:mm"),
    amount: 0,
  };
  let LATE_HOURS = {
    coefficent: COEFFICENTS_WAGES.LATE_HOURS,
    start: moment("19:00", "HH:mm"),
    end: moment("21:59", "HH:mm"),
    amount: 0,
  };
  let EXTRA_HOURS = {
    coefficent: COEFFICENTS_WAGES.EXTRA_HOURS,
    start: moment("22:00", "HH:mm"),
    end: moment("23:59", "HH:mm"),
    amount: 0,
  };
  let EXTRA_HOURS2 = {
    coefficent: COEFFICENTS_WAGES.EXTRA_HOURS,
    start: moment("00:00", "HH:mm"),
    end: moment("08:00", "HH:mm"),
    amount: 0,
  };

  // 10:00 - 23:00 (normale: 9 ore, 2 ore late, 1 ore shtese)
  // O(n) + O(1) = O(n)
  temp.forEach((t) => {
    let clockOut = moment(t?.clockOutDate);
    let clockOutDate = moment(NORMAL_HOURS?.start);
    clockOutDate.set({
      hour: clockOut.get("hour"),
      minute: clockOut.get("minute"),
      second: clockOut.get("second"),
      millisecond: clockOut.get("millisecond"),
    });

    // O(1) operations
    let l1 = moment(clockOutDate).diff(EXTRA_HOURS?.start, "hours", true); // 23:00 - 22:00 // 1 ore
    if (l1 < 0 || isNaN(l1)) l1 = 0;
    let l2 = moment(clockOutDate).diff(LATE_HOURS?.start, "hours", true); // 23:00 - 19:00 // 4 ore\    console.log("l1", l1)
    if (l2 < 0 || isNaN(l2)) l2 = 0;
    let originalTime = moment(t?.clockOutDate).diff(
      t?.clockInDate,
      "hours",
      true
    ); // 13 ore
    let l3 = originalTime - (l2 - l1 + l1);
    if (l3 < 0 || isNaN(l3)) l3 = 0;
    NORMAL_HOURS.amount += l3;
    LATE_HOURS.amount += l2 - l1;
    EXTRA_HOURS.amount += l1;
  });
  return { ...{ NORMAL_HOURS, LATE_HOURS, EXTRA_HOURS } };
};

// helpers used to categorize hours

const getNormals = (start, end) => {
  let NORMAL_HOURS = {
    coefficent: COEFFICENTS_WAGES.NORMAL_HOURS,
    start: moment("08:00:01", "HH:mm:ss"),
    end: moment("18:59", "HH:mm"),
    amount: 0,
  };
  let temp = moment(start);
  let temp2 = moment(end);
  let intervalStart = temp;
  let intervalEnd = temp;
  intervalStart.set({
    hour: NORMAL_HOURS.start.get("hour"),
    minute: NORMAL_HOURS.start.get("minute"),
    second: NORMAL_HOURS.start.get("second"),
    millisecond: NORMAL_HOURS.start.get("millisecond"),
  });
  intervalEnd.set({
    hour: NORMAL_HOURS.end.get("hour"),
    minute: NORMAL_HOURS.end.get("minute"),
    second: NORMAL_HOURS.end.get("second"),
    millisecond: NORMAL_HOURS.end.get("millisecond"),
  });

  // Check if start is before start and end is after start
  if (
    moment(temp).isBefore(intervalStart) &&
    moment(temp2).isAfter(intervalStart)
  ) {
    // 03:00 - 17:00
    let differenceBetweenStarts = moment(intervalStart).diff(
      temp,
      "hours",
      true
    ); // 5 ore
  }
  // Check if start is after start and end is before end
  if (
    moment(temp).isAfter(intervalStart) &&
    moment(temp2).isBefore(intervalEnd)
  ) {
    temp2 = intervalEnd;
  }

  // otherwise its zero
};
