import React, { useEffect, useState } from "react";
import { Input, Form, DatePicker, Select } from "antd";
import { useGeneralInformationsContext } from "./context";
import "./AddEmployeeModal.scss";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Chair } from "../../../assets/images";
import moment from "moment-timezone";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

const GeneralInformations = () => {
  const [value, setValue] = useState();
  const { setGetName, getName } = useGeneralInformationsContext();
  const { programFields } = useSelector((state) => state.programFields);
  const [genderType, setGenderType] = useState();
  const [team, setTeam] = useState();
  const { Option } = Select;
  const { TextArea } = Input;

  //* finds the gender from programFields and sets the genderType state
  useEffect(() => {
    const employeeGender = programFields?.find(
      (res) => res?.fieldName === "Gjinia"
    );
    setGenderType(employeeGender);

    const companyTeam = programFields?.find(
      (res) => res?.fieldName === "Employee Team"
    );
    setTeam(companyTeam);
  }, [programFields]);

  //! birthday validation it does not allow you to select birthdate over 67 years and under 18 years
  const disabledDate = (current) => {
    const youngerThen18 = dayjs().subtract(18, "years").valueOf();
    const olderThan67 = dayjs().subtract(67, "years").valueOf();

    return current > moment(youngerThen18) || current < moment(olderThan67);
  };

  return (
    <div className="form-data">
      <div className="form">
        <Form.Item
          className="input-label"
          name="employeeFirstName"
          label="Emri"
          rules={[
            {
              required: true,
              message: "Emri punonjësit është i detyrueshëm",
            },
          ]}
        >
          <Input
            autoComplete="off"
            allowClear
            onChange={(name) => {
              //* takes name from emplyeesFirstName form
              setGetName(name.target.value);
            }}
            className="input-form"
            placeholder="Shkruaj emrin e punonjësit"
          />
        </Form.Item>
        <Form.Item
          className="input-label"
          name="employeeFathersName"
          label="Atësia"
          rules={[
            {
              required: true,
              message: "Atësia e punonjësit është e detyrueshme",
            },
          ]}
        >
          <Input
            autoComplete="off"
            allowClear
            className="input-form"
            placeholder="Shkruaj atësinë e punonjësit"
          />
        </Form.Item>
        <Form.Item
          className="input-label"
          name="employeeLastName"
          label="Mbiemri"
          rules={[
            {
              required: true,
              message: "Mbiemri punonjësit është i detyrueshëm",
            },
          ]}
        >
          <Input
            autoComplete="off"
            allowClear
            className="input-form"
            placeholder="Shkruaj mbiemrin e punonjësit"
          />
        </Form.Item>
        <Form.Item
          className="input-label"
          name="employeeGender"
          label="Gjinia"
          rules={[
            {
              required: true,
              message: "Mbiemri punonjësit është i detyrueshëm",
            },
          ]}
        >
          <Select
            className="select-3"
            placeholder="Selekto gjininë e punonjësit"
            allowClear
            style={{
              background: "#f5f5f7",
              borderRadius: 5,
            }}
          >
            {genderType?.fieldOptions?.map((gender) => (
              //* maps employeeGender
              <Option value={gender}>{gender}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          className="input-label"
          name="employeeDayOfBirth"
          label="Ditëlindja"
        >
          <DatePicker
            allowClear
            format="DD/MM/YYYY"
            className="birthdate"
            disabledDate={disabledDate}
            placeholder="Selekto ditëlindjen e punonjësit"
          />
        </Form.Item>
        <Form.Item
          className="input-label"
          name="employeePersonalNumber"
          label="Numri Personal (ID)"
        >
          <Input
            autoComplete="off"
            allowClear
            className="input-form"
            placeholder="Shkruaj numrin personal të punonjësit"
          />
        </Form.Item>
        <Form.Item
          className="input-label"
          name="employeePhoneNumber"
          label="Numri Telefonit"
        >
          <PhoneInput
            regions={"europe"}
            country="al"
            placeholder="Vendos numrin e telefonit"
            value={value}
            onChange={setValue}
            masks={{ al: "... ... ..." }}
            autoComplete="off"
          />
        </Form.Item>
        <Form.Item
          className="input-label"
          name="employeePersonalEmail"
          label="Email Personal"
        >
          <Input
            className="input-form"
            autoComplete="off"
            allowClear
            placeholder="Shkruaj emailin e punonjësit"
          />
        </Form.Item>
        <Form.Item
          className="input-label"
          name="employeeAddress"
          label="Adresa"
        >
          <TextArea autoComplete="off" allowClear className="inputAddress" />
        </Form.Item>
        <Form.Item
          className="input-label"
          name="employeeTeam"
          label="Team"
          rules={[
            {
              required: true,
              message: "Team që punon punonjësi është i detyrueshëm",
            },
          ]}
        >
          <Select
            className="select-3"
            placeholder="Ku punon ky punonjës"
            allowClear
            style={{
              background: "#f5f5f7",
              borderRadius: 5,
            }}
          >
            {team?.fieldOptions?.map((val) => (
              <Option value={val}>{val}</Option>
            ))}
          </Select>
        </Form.Item>
      </div>
      <img className="images" src={Chair} alt="Chair" />
      <div className="span">
        <span className="showName">{getName}</span>
      </div>
    </div>
  );
};

export default GeneralInformations;
