import FormCard from "../../../Documentation/FormCard/FormCard";
import InfoLabel from "../../../Documentation/InfoLabel/InfoLabel";
import { DeleteIcon } from "../../assets/index";
import { keyByTitle } from "../DocumentationsPanel/documentationsPanelData";
import { getProppedValue } from "./utils";
import "./DocCard.scss";

const DocCard = ({
  title,
  data = [],
  className = "",
  childrenClassName,
  hasSubtitle = false,
  onDelete,
}) => {
  return (
    <FormCard
      {...{
        title,
        className: `docConfigCard ${className}`.trim(),
        childrenClassName,
      }}
    >
      {data?.map(({ label, value }, index) => (
        //* maps all header of doc configuration cards
        <InfoLabel
          {...{
            label,
            value: !!index ? (
              <div className="valueContainer">
                <span>{value}</span>
                <DeleteIcon
                  className="deleteIcon"
                  onClick={() =>
                    onDelete({
                      key: keyByTitle[title],
                      item: {
                        label: getProppedValue(label),
                        value: getProppedValue(value),
                      },
                    })
                  }
                />
              </div>
            ) : (
              value
            ),
          }}
          className={!!hasSubtitle ? "labelSubtitle" : ""}
        />
      ))}
    </FormCard>
  );
};

export default DocCard;
