import { Badge, Card, Col } from "antd";
import React from "react";
import { useNavigate } from "react-router";
import { TotalNumber } from "../../../../assets/icons";

function CardComponent({ data }) {
  const navigate = useNavigate();

  return (
    <Col
      className="kompania-pozicionet-col"
      style={{ width: "100%", height: "calc(100% - 35px)", marginLeft: "0px" }}
      key={data?.data?.lengthForEachDepartment?.employeeDepartmentId}
    >
      <div
        style={{ cursor: "pointer", height: "100%", width: "100%", padding: "20px" }}
        className="card-pozicionet-settings"
        key={data?.data?.lengthForEachDepartment?.employeeDepartmentId}
        onClick={() =>
          navigate("/konfigurimet/kompania/rolet", {
            state: data.data,
          })
        }
      >

        <div className="body-card">
          <div className="logo-with-content">
            <TotalNumber />
            <div className="DESC">
              {/* {data?.lengthForEachDepartment?.length > 0 ? (
                  <Badge status="success" />
                ) : (
                  <Badge status="error" />
                )} */}
              {data?.data?.lengthForEachDepartment?.length} Punonjës
            </div>
          </div>
          <div className="rolet-desc">
            <h1>Rolet</h1>
            {data?.data?.departmentRoles?.map((data) => (
              <div className="role-content">
                <Badge status="default" />
                {data?.name}
              </div>
            ))}
          </div>
        </div>
      </div>
    </Col>
  );
}

export default CardComponent;
