import moment from "moment-timezone";
export const calculateDayType = (programFields, leaves, timeInstance, employeeTeam) => {

	let nationalHolidays = programFields?.find((el) => el.fieldName === "Festat Zyrtare");
	let day = moment(timeInstance).format("DD/MM/YYYY");
	let result = "Regular"; // Turn normal

	if (
		!!nationalHolidays &&
		Array.isArray(nationalHolidays?.fieldOptions) &&
		employeeTeam === "Flex Tirana"
	) {
		let el = nationalHolidays?.fieldOptions?.find(
			(el) => moment(el?.effectiveDate).format("DD/MM/YYYY") === day
		);
		if (!!el) result = "NationalHolidayWorking"; //Feste Zyrtare
	}
	if (leaves.length > 0) {
		leaves?.forEach((leave) => {
			let el = leave?.requestPeriod?.find((el) => moment(el).format("DD/MM/YYYY") === day);
			if (el !== undefined && result !== "NationalHolidayWorking") {
				switch (leave.requestType) {
					case "Pushime":
						if (leave.requestStatus === "Aprovuar") result = "HolidayRequest"; //Leje Pushime
					case "Leje Ditore":
						if (leave.requestStatus === "Aprovuar") result = "HolidayRequest"; //Leje Ditore
				}
			}
		});
	}
	return result;
};
