import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import Chart from "react-google-charts";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import { Alert, message } from "antd";
import { groupBy } from "lodash";
import { HollowDotsSpinner } from "react-epic-spinners";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
} from "recharts";
import { API } from "aws-amplify";

const ClockInStats = () => {
  const { clockings } = useSelector((state) => state.clockings);
  const [dataShifts, setDataShifts] = useState([]);
  // const [allClockIns, setAllClockIns] = useState(null);
  // const [allClockOuts, setAllClockOuts] = useState(null);
  // const [lateClockIns, setLateClockIns] = useState(null);
  const { eligibleEmployeesClockIn } = useSelector(
    (state) => state.employeesList
  );
  // console.log(programFields?.find(
  //   (data) => data?.fieldId === "0e37f115-ac0a-4ea3-b96c-ea321193d1af"
  // ));
  // const [missedClockIns, setMissedClockIns] = useState([]);

  //NQS PUNONJESIT NUK KANE BERE CLOCK OUT NJE DITE ME PARE, ATA DO TE SHFAQEN SI PUNONJES QE NUK KANE BERE CLOCK IN PER DITEN E SOTME
  const data = useMemo(() => {
    let allClockIns = [];
    let allClockOuts = [];
    let lateClockIns = [];
    let missedClockIns = [];

    if (clockings?.length >= 0) {
      //Merr clock in qe jane bere diten e sotme
      let filteredClockIns = clockings
        ?.filter(
          (el) =>
            moment(el?.clockInDate).format("DD/MM/YYYY") ===
            moment().format("DD/MM/YYYY")
        )
        ?.sort((a, b) => a?.clockInDate - b?.clockInDate);

      //Grupon clock in sipas id se punonjesit sepse mund te kete punonjes qe bejne clock in dhe clock out disa here ne dite
      let groupedClockings = groupBy(filteredClockIns, "employeeId");
      let appliedClockings = [];
      let tempClockIns = Object.keys(groupedClockings).length || 0;
      //Iteron array e kthyer nga groupBy per rastet kur nje punonjes ka bere clock in me shume se nje here ne nje dite,
      // i ben sort nga clock in me oren me te hershme deri te ora me e vone dhe shton vetem clock in e pare
      // ne array appliedClockings
      Object.entries(groupedClockings).map(([key, val]) => {
        if (val.length > 1) {
          let innerSort = val.sort((a, b) => a?.clockInDate - b?.clockInDate);
          appliedClockings.push(innerSort[0]);
        } else if (val.length === 1) {
          appliedClockings.push(val[0]);
        }
      });
      //Nga array me clock in-et e para per cdo punonjes, filtrohen ata qe kane bere clock in pas turnit perkates
      let result = appliedClockings;
      result = result?.map((el) => {
        let tempApp =
          eligibleEmployeesClockIn?.find(
            (d) => d?.employeeId === el?.employeeId
          )?.employeeShiftId?.[0] || null;
        let tempShiftStartApplicable =
          dataShifts?.find((d) => d?.shiftId === tempApp)?.shiftStartTime ||
          null;
        return {
          ...el,
          shiftStartApplicable: !!tempShiftStartApplicable
            ? moment(tempShiftStartApplicable).format("HH:mm")
            : "13:00",
        };
      });
      result = result?.filter(
        (cl) =>
          moment(cl?.clockInDate).format("HH:mm:ss") >
          moment(cl?.shiftStartApplicable, "HH:mm")
            .add(1, "minute", true)
            .format("HH:mm:ss")
      );
      //Merr nga employees emrat e punonjesve qe kane bere clock in me vonese
      let finalResult = eligibleEmployeesClockIn
        ?.filter((el) => {
          return result?.find((clk) => el?.employeeId === clk?.employeeId);
        })
        .sort(
          (b, a) =>
            result?.find((d) => d?.employeeId === a?.employeeId).clockInDate -
            result?.find((d) => d?.employeeId === b?.employeeId).clockInDate
        );
      lateClockIns = finalResult;
      allClockIns = tempClockIns;
    }

    //Merr nga employees emrat e punonjesve qe kane bere clock in me vonese
    if (clockings?.length >= 0 && eligibleEmployeesClockIn?.length >= 0) {
      let filteredClockIns = clockings?.filter(
        (el) =>
          moment(el).format("DD/MM/YYYY") === moment().format("DD/MM/YYYY")
      );
      //Nga tabela employees merr ata punonjes id e te cileve, nuk gjendet te clock in e dites se sotme
      let filteredUsers = eligibleEmployeesClockIn?.filter(
        (el) =>
          !filteredClockIns?.find((clk) => el?.employeeId === clk?.employeeId)
      );

      allClockOuts = filteredUsers;
    }
    if (clockings?.length > 0 && eligibleEmployeesClockIn?.length > 0) {
      //Merr clock in e bere diten e sotme
      let filteredClockIns = clockings?.filter((el) =>
        moment()?.isSame(el?.clockInDate, "day")
      );
      //Nga tabela employees merr ata punonjes id e te cileve, nuk gjendet te clock in e dites se sotme
      let filteredUsers = eligibleEmployeesClockIn?.filter(
        (el) =>
          !filteredClockIns?.find((clk) => el?.employeeId === clk?.employeeId)
      );
      missedClockIns = filteredUsers;
    }

    return [
      {
        name: "Clock In",
        Punonjës: allClockIns,
      },
      {
        name: "Clock In me vonesë",
        Punonjës: lateClockIns?.length,
      },
      {
        name: "No clock In",
        Punonjës: missedClockIns?.length,
      },
    ];

    // if (!!wages) {
    //   // console.log("Wages", wages);
    //   let currentMom = moment().startOf("month");
    //   let lastSixMonths = [];
    //   [...Array(6)].map((_, idx) => {
    //     lastSixMonths.push(moment(currentMom).subtract(5 - idx, "months"));
    //   });
    //   if (!employee) {
    //     return lastSixMonths.map((month) => {
    //       let res = getMonthStatistic(wages, month);
    //       return {
    //         name: moment(month).format("MMMM YYYY"),
    //         "Shuma Bruto": res?.bruto.toFixed(2),
    //         "Shuma Neto": res?.neto.toFixed(2),
    //         "Kosto e Kompanise": res?.totalCost.toFixed(2),
    //       };
    //     });
    //   }
    // }
  }, [clockings, eligibleEmployeesClockIn]);

  //Array qe vendoset te google chart

  // const data = [
  //   {
  //     name: "Clock In",
  //     Punonjës: allClockIns,

  //   }, {
  //     name: "Clock In me vonesë",
  //     Punonjës: lateClockIns?.length,

  //   }, {
  //     name: "No clock In",
  //     Punonjës: missedClockIns?.length,

  //   }
  // ]

  // const data = [
  //   ["Element", "Punonjës", { role: "style" }],
  //   ["Clock In", allClockIns, "#1DA193"],
  //   ["Clock In me vonesë", lateClockIns?.length, "#FCC94A"],
  //   ["No clock In", missedClockIns?.length, "silver"],
  // ];
  // useEffect(() => {
  //   if (clockings?.length >= 0) {
  //     //Merr clock in qe jane bere diten e sotme
  //     let filteredClockIns = clockings
  //       ?.filter(
  //         (el) =>
  //           moment(el?.clockInDate).format("DD/MM/YYYY") ===
  //           moment().format("DD/MM/YYYY")
  //       )
  //       ?.sort((a, b) => a?.clockInDate - b?.clockInDate);

  //     //Grupon clock in sipas id se punonjesit sepse mund te kete punonjes qe bejne clock in dhe clock out disa here ne dite
  //     let groupedClockings = groupBy(filteredClockIns, "employeeId");
  //     let appliedClockings = [];
  //     let tempClockIns = Object.keys(groupedClockings).length || 0;
  //     //Iteron array e kthyer nga groupBy per rastet kur nje punonjes ka bere clock in me shume se nje here ne nje dite,
  //     // i ben sort nga clock in me oren me te hershme deri te ora me e vone dhe shton vetem clock in e pare
  //     // ne array appliedClockings
  //     Object.entries(groupedClockings).map(([key, val]) => {
  //       if (val.length > 1) {
  //         let innerSort = val.sort((a, b) => a?.clockInDate - b?.clockInDate);
  //         appliedClockings.push(innerSort[0]);
  //       } else if (val.length === 1) {
  //         appliedClockings.push(val[0]);
  //       }
  //     });
  //     //Nga array me clock in-et e para per cdo punonjes, filtrohen ata qe kane bere clock in pas ores 1:30
  //     let filteredLateClockIns = appliedClockings?.filter(
  //       (el) =>
  //         moment(el?.clockInDate).format("HH:mm:ss") >=
  //         moment("13:32pm", "HH:mm:ss").format("HH:mm:ss")
  //     );
  //     setLateClockIns(filteredLateClockIns);
  //     setAllClockIns(tempClockIns);
  //   }
  // }, [clockings, eligibleEmployeesClockIn]);
  // useEffect(() => {
  //   //Merr nga employees emrat e punonjesve qe kane bere clock in me vonese
  //   if (clockings?.length >= 0 && eligibleEmployeesClockIn?.length >= 0) {
  //     let filteredClockIns = clockings?.filter(
  //       (el) =>
  //         moment(el).format("DD/MM/YYYY") === moment().format("DD/MM/YYYY")
  //     );
  //     console.log("empl", eligibleEmployeesClockIn);
  //     //Nga tabela employees merr ata punonjes id e te cileve, nuk gjendet te clock in e dites se sotme
  //     let filteredUsers = eligibleEmployeesClockIn?.filter(
  //       (el) =>
  //         !filteredClockIns?.find((clk) => el?.employeeId === clk?.employeeId)
  //     );

  //     setAllClockOuts(filteredUsers);
  //     console.log("filter", filteredUsers);
  //   }
  // }, [clockings, eligibleEmployeesClockIn]);

  // useEffect(() => {
  //   if (clockings?.length > 0 && eligibleEmployeesClockIn?.length > 0) {
  //     //Merr clock in e bere diten e sotme
  //     let filteredClockIns = clockings?.filter((el) =>
  //       moment()?.isSame(el?.clockInDate, "day")
  //     );
  //     //Nga tabela employees merr ata punonjes id e te cileve, nuk gjendet te clock in e dites se sotme
  //     let filteredUsers = eligibleEmployeesClockIn?.filter(
  //       (el) =>
  //         !filteredClockIns?.find((clk) => el?.employeeId === clk?.employeeId)
  //     );
  //     setMissedClockIns(filteredUsers);
  //   }
  // }, [clockings, eligibleEmployeesClockIn]);

  /**
   * * @EneaXharau - Added independent loading functionality, visual loader based on validation below
   */

  const colors = ["#1DA193", "#FCC94A", "#EA3943"];

  const getShifts = async () => {
    await API.get("shifts", "/shifts").then(setDataShifts);
  };
  useEffect(() => {
    getShifts();
  }, []);

  return (
    <>
      {!!clockings && !!eligibleEmployeesClockIn ? (
        <div className="clock-in-dashboard-card">
          {!!data.length ? (
            <ResponsiveContainer width="100%" height="95%">
              <BarChart
                data={data}
                margin={{
                  top: 40,
                  right: 40,
                  left: 0,
                  bottom: 5,
                }}
                barSize={20}
              >
                <XAxis
                  dataKey="name"
                  scale="point"
                  padding={{ left: 50, right: 50 }}
                />
                <YAxis />
                <Tooltip />
                <Bar dataKey="Punonjës" fill="#8884d8" barSize={50}>
                  {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={colors[index % 20]} />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: 60,
              }}
            >
              <Alert
                type="info"
                showIcon
                message="Nuk ka te dhena statistikore..."
              />
            </div>
          )}
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <HollowDotsSpinner color="#1DA193" size={24} />
        </div>
      )}
    </>
  );
};

export default ClockInStats;
