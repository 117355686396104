import MondayButton from "../../../commonComponents/MondayButton/MondayButton";
import FormCard from "../../../Documentation/FormCard/FormCard";
import { DeleteIcon, OpenInNewTabIcon } from "../../assets/index";
import "./CategoryCard.scss";

const CategoryCard = ({
  title,
  onClick = () => {},
  onDelete,
  className = "",
  noDeleteIcon = false,
  documentationsAvailable,
}) => (
  <FormCard className={`docCategoryCard ${className}`.trim()}>
    {!noDeleteIcon && (
      <DeleteIcon className="deleteIcon" onClick={() => onDelete(title)} />
    )}
    <div className="docCategoryContainer">
      <div className="categoryTitle">{title}</div>
      <MondayButton
        onClick={() => onClick(title)}
        Icon={<OpenInNewTabIcon />}
        className="configuration"
      >
        Hap Konfigurimin
      </MondayButton>
    </div>
  </FormCard>
);

export default CategoryCard;
