import moment from "moment";
import React from "react";
import { BirthdayCake } from "../../../../../../assets/images";
import BirthdayCalendar from "./BirthdayCalendar";

const DitlindjeMobile = ({ renderdBirthdays, longYear, filterFoter }) => {
  const now = moment().add(2, "M");
  function compare(a, b) {
    if (
      moment(a.employeeDayOfBirth).format("DD") <
      moment(b.employeeDayOfBirth).format("DD")
    ) {
      return -1;
    }
    if (
      moment(a.employeeDayOfBirth).format("DD") >
      moment(b.employeeDayOfBirth).format("DD")
    ) {
      return 1;
    }
    return 0;
  }
  return (
    <div className="ditlindje-mobile">
      <div className="ditlindjet-container">
        {renderdBirthdays?.length > 0 ? (
          <div className="ditlindjet-header">
            {moment(renderdBirthdays[0]?.employeeDayOfBirth).format("MMMM")}{" "}
            {longYear} - {renderdBirthdays?.length} punonjës
          </div>
        ) : (
          <div className="ditlindjet-header">
            Në këtë muajn nuk ka asnjë ditëlindje
          </div>
        )}
        <div className="ditlindjet-body">
          {filterFoter?.sort(compare).map((Data) => (
            <div key={Data?.employeeId} className="ditlindjet-card-content">
              <img
                className="photo-ditlindje"
                src={BirthdayCake}
                alt={BirthdayCake}
                width={35}
                height={35}
              />{" "}
              &nbsp;
              <div className="ditlindjet-card-info">
                {Data?.employeeFirstName}&nbsp;&nbsp;
                {Data?.employeeLastName} -{" "}
                {moment(Data?.employeeDayOfBirth).format("DD/MM/YYYY")}
                <div className="bold">
                  ({moment.duration(now.diff(Data?.employeeDayOfBirth)).years()}{" "}
                  Vjec )
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="calendar-container">
        <BirthdayCalendar renderdBirthdays={renderdBirthdays} />
      </div>
    </div>
  );
};

export default DitlindjeMobile;
