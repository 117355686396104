import { Button } from "antd";
import NormalSizedModal from "../commonComponents/NormalSizedModal/NormalSizedModal";
import "./ConfirmationModal.scss";

const CONFIRMATION_QUESTION = "Jeni të sigurt që dëshironi ta anuloni?";
const TITLE = "Anulo";
const CONFIRM_BUTTON_TEXT = "Po";
const CANCEL_BUTTON_TEXT = "Jo";

const ConfirmationModal = ({
  visible,
  setVisible,
  onConfirm = () => {},
  onCancel = () => {},
  text = CONFIRMATION_QUESTION,
  title = TITLE,
  confirmButtonText = CONFIRM_BUTTON_TEXT,
  cancelButtonText = CANCEL_BUTTON_TEXT,
  zIndex = 1500,
  children,
}) => {
  return (
    <NormalSizedModal
      visible={visible}
      setVisible={setVisible}
      destroyOnClose
      title={title}
      className="confirmationModalContainer"
      zIndex={zIndex}
      centered
    >
      <div>
        <div className="question">{text}</div>
        {children}
        <div className="buttonContainer">
          <Button
            type="primary"
            danger
            onClick={() => {
              onCancel();
              setVisible(false);
            }}
          >
            {cancelButtonText}
          </Button>
          <Button
            type="primary"
            onClick={() => {
              onConfirm();
              setVisible(false);
            }}
          >
            {confirmButtonText}
          </Button>
        </div>
      </div>
    </NormalSizedModal>
  );
};

export default ConfirmationModal;
