import moment from "moment";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useAuth } from "../../../../authentication/authHelpers";
import HolidayCalendarContent from "./HolidayCalendarContent";
import "./HolidayWidget.scss";
import { useMediaQuery } from "react-responsive";
import MobileHtmlTabel from "../../../../utils/MobileComponents/MobileHtmlTabel";
function HolidayWidget() {
  const [holidays, setHolidays] = useState([]);
  const { programFields } = useSelector((state) => state.programFields);
  const { employee } = useAuth();
  const retrieveHolidays = () => {
    const getFestat = programFields.find(
      (field) => field.fieldId === "a3a610f9-5a60-40ec-8a1d-d94ffbb10054"
    );
    let temp = getFestat?.fieldOptions?.map((option) => ({
      ...option,
      date: moment(option?.date, "DD/MM/YYYY"),
    }));
    setHolidays(temp || []);
  };

  useEffect(() => {
    if (!!programFields) {
      retrieveHolidays();
    }
  }, [programFields]);

  const isMobileView = useMediaQuery({
    query: "(min-width: 460px)",
  });

  return (
    <div className="holiday-widget-comp">
      {isMobileView ? (
        <>
          <div className="holiday-widget-header">
            <div className="item-header" style={{ width: "20%" }}>
              Dita e javës
            </div>
            <div className="item-header" style={{ width: "20%" }}>
              Data Zyrtare
            </div>
            <div className="item-header" style={{ width: "40%" }}>
              Titulli
            </div>
            <div className="item-header" style={{ width: "20%" }}>
              Data efektive
            </div>
          </div>
          <div className="holiday-calendar-content">
            {holidays
              ?.filter(
                (el) =>
                  moment(el?.effectiveDate).format("MM/DD/YYYY") >=
                  moment().format("MM/DD/YYYY")
              )
              ?.filter((a) => !a?.users?.includes(employee?.employeeId))
              ?.sort((a, b) => a?.effectiveDate - b?.effectiveDate)
              .map?.((el, key) => (
                <div className="content-holiday" key={key}>
                  <div style={{ width: "20%" }} className="content-itme">
                    <div
                      className="item-day"
                      style={{
                        background: el?.color,
                      }}
                    >
                      {el?.dayOfWeek}
                    </div>
                  </div>
                  <div style={{ width: "20%" }} className="content-itme">
                    {" "}
                    {moment(el?.date).format("DD/MM/YYYY")}
                  </div>
                  <div className="content-itme" style={{ width: "40%" }}>
                    {el?.title}
                  </div>
                  <div className="content-itme" style={{ width: "20%" }}>
                    {moment(el?.effectiveDate).format("DD/MM/YYYY")}{" "}
                  </div>
                </div>
              ))}
          </div>
        </>
      ) : (
        <MobileHtmlTabel
          header={["Dita e javës", "Data Zyrtare", " Titulli", "Data efektive"]}
          tableData={holidays
            ?.filter(
              (el) =>
                moment(el?.effectiveDate).format("MM/DD/YYYY") >=
                moment().format("MM/DD/YYYY")
            )
            ?.filter((a) => !a?.users?.includes(employee?.employeeId))
            ?.sort((a, b) => a?.effectiveDate - b?.effectiveDate)
            .map?.((el) => {
              return {
                dayOfWeek: el?.dayOfWeek,
                officialDay: moment(el?.date).format("DD/MM/YYYY"),
                title: el?.title,
                effectiveDate: moment(el?.effectiveDate).format("DD/MM/YYYY"),
              };
            })}
        />
      )}
    </div>
  );
}

export default HolidayWidget;
