import React from "react";
import { IconI } from "../../../../assets/icons";
import { Input, Form } from "antd";
import PhoneInput from "react-phone-input-2";

function InformacioneKontakti({ handleChangeDetajet }) {
  return (
    <div className="info-kontakti">
      <div className="input" style={{ width: 700 }}>
        <h3>
          <span className="star-color">*&nbsp;</span>Emri:
        </h3>
        <Form.Item
          name="emri"
          rules={[
            {
              required: true,
              message: "Ju lutem Skruaj Emrin",
            },
          ]}
        >
          <Input
            autoComplete="off"
            className="info-kontatki-input"
            placeholder="Emri"
            style={{ width: 450 }}
            onChange={(e) => handleChangeDetajet("emri", e.target.value)}
          />
        </Form.Item>
        <span className="card-input-icon">
          <IconI width={13} height={13} />
        </span>
      </div>
      <div className="input" style={{ width: 700 }}>
        <h3>
          <span className="star-color">*&nbsp;</span>Mbiemri:
        </h3>
        <Form.Item
          name="mbiemri"
          rules={[
            {
              required: true,
              message: "Ju lutem shkruaj mbiemrin",
            },
          ]}
        >
          <Input
            autoComplete="off"
            className="info-kontatki-input"
            placeholder="Mbiemri"
            style={{ width: 450 }}
            onChange={(e) => handleChangeDetajet("mbiemri", e.target.value)}
          />
        </Form.Item>
        <span className="card-input-icon">
          <IconI width={13} height={13} />
        </span>
      </div>
      <div className="input" style={{ width: 700 }}>
        <h3>
          <span className="star-color">*&nbsp;</span>Nr Telefoni:
        </h3>
        <Form.Item
          name="numriTelefonit"
          rules={[
            {
              required: true,
              message: "Ju lutem shkruaj numrin e telefonit",
            },
          ]}
        >
          <PhoneInput
            className="number-input"
            country={"al"}
            onChange={(value) => handleChangeDetajet("numriTelefonit", value)}
            autoComplete="off"
            placeholder="Nr.Tel"
            style={{ width: 450 }}
            enableLongNumbers={false}
            masks={{ al: "... ... ..." }}
          />
        </Form.Item>
        <span className="card-input-icon">
          <IconI width={13} height={13} />
        </span>
      </div>
      <div className="input" style={{ width: 700 }}>
        <h3>
          <span className="star-color">*&nbsp;</span>Fax:
        </h3>
        <Form.Item
          name="fax"
          rules={[
            {
              message: "Ju lutem shkruaj numrin Fax",
            },
          ]}
        >
          <Input
            autoComplete="off"
            className="info-kontatki-input"
            placeholder="Fax"
            style={{ width: 450 }}
            onChange={(e) => handleChangeDetajet("fax", e.target.value)}
          />
        </Form.Item>
        <span className="card-input-icon">
          <IconI width={13} height={13} />
        </span>
      </div>
      <div className="input" style={{ width: 700 }}>
        <h3>
          <span className="star-color">*&nbsp;</span>Email:
        </h3>
        <Form.Item
          name="email"
          autoComplete="off"
          rules={[
            {
              type: "email",
              required: true,
              message: "Ju lutem shkruaj emailin tuaj",
            },
          ]}
        >
          <Input
            autoComplete="off"
            className="info-kontatki-input"
            placeholder="Email"
            type="email"
            style={{ width: 450 }}
            onChange={(e) => handleChangeDetajet("email", e.target.value)}
          />
        </Form.Item>
        <span className="card-input-icon">
          <IconI width={13} height={13} />
        </span>
      </div>
    </div>
  );
}

export default InformacioneKontakti;
