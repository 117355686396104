import types from "../types/types";

const employeeRequests = (employeeRequests) => {
  return (dispatch) => {
    dispatch({
      type: types.SET_EMPLOYEE_REQUESTS,
      payload: employeeRequests,
    });
  };
};

export default employeeRequests;
