import React from "react";
import { useState, useEffect } from "react";
import "./Forgot.scss";
import {
  Modal,
  Form,
  message,
  TimePicker,
  Button,
  Popconfirm,
  Skeleton,
} from "antd";
import moment from "moment-timezone";
import { API } from "aws-amplify";
import TextArea from "antd/lib/input/TextArea";
import { getLastOnGoingShift } from "../../../helpers/apiCall";
import {
  getDisabledBeforeCustomHours,
  getDisabledBeforeCustomMinutes,
  getDisabledBeforeHours,
} from "../../../utils/disabledTimeFilters";
import { WarningIcon } from "../../notifications/notificationList/filterHeader/src";
import { Checkbox } from "antd";
import {
  requestAllPages,
  requestData,
} from "../../../../../helpers/API/RequestFactory";
import { useDispatch } from "react-redux";
import { clockings } from "../../../../../store/actions";

function Forgot({
  showForgot,
  handleForgot,
  onClockForgot,
  shift,
  userData,
  apiData,
}) {
  const [lastShift, setLastShift] = useState(null);
  const [form] = Form.useForm();
  const [isAfterMidnight, setIsAfterMidnight] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const onFinish = async (val) => {
    if (!!val.fillimi && !!val.mbarimi && val.shenimi !== "") {
      let temp = { ...val, isAfterMidnight: isAfterMidnight };
      setLoading(true);
      await onClockForgot(temp)
        .then(async (res) => {
          await requestData("clocking").then((res) => dispatch(clockings(res)));
          form.resetFields();
          handleForgot(false);
        })
        .catch((er) => console.log("err", er));
      setLoading(false);
      setIsAfterMidnight(false);
      setLastShift(null);
    }
    // console.log("Shift", shift);
  };

  const handleFail = () => {
    message.error(
      "Ndodhi nje problem ne procesimin e te dhenave. Ju lutem, konfirmoni detajet perkatese!"
    );
  };

  useEffect(async () => {
    // console.log("api data", apiData);
    if (apiData.length > 0) {
      let lsh = getLastOnGoingShift(apiData);
      setLastShift(lsh);
    }
  }, [apiData]);

  console.log("API DATA", apiData);

  useEffect(() => {
    if (lastShift !== null) {
      form.setFieldsValue({
        fillimi: moment(lastShift?.clockInDate),
      });
    }
  }, [lastShift]);

  return (
    <div>
      <Modal
        className="forgotModal"
        centered={true}
        title={"Kritike, nevojitet konfirmimi juaj!"}
        open={showForgot}
        destroyOnClose={true}
        onOk={() => handleForgot(false)}
        onCancel={() => handleForgot(false)}
        footer={null}
        width={903}
      >
        {lastShift !== null ? (
          <div className="forgot-modal-content">
            <div className="warning-forgot-intro">
              {" "}
              <span
                className="warning-title"
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  color: "#EA3943",
                }}
              >
                <span
                  style={{
                    fill: "#EA3943",
                    display: "flex",
                    alignItems: "center",
                    marginRight: "5px",
                  }}
                >
                  <WarningIcon />
                </span>{" "}
                Ju keni harruar te mbyllni turnin e fundit. Ju lutem konfirmoni
                orarin qe u larguat dhe arsyen perkatese!
              </span>
            </div>

            <br />
            <div className="forgot-form">
              <Form
                form={form}
                {...layout}
                onFinish={onFinish}
                onFinishFailed={handleFail}
              >
                {" "}
                <div className="first-row-forgot">
                  <Form.Item
                    className="start-shift-forgot"
                    name={"fillimi"}
                    label="Fillimi i turnit"
                    rules={[{ required: false }]}
                  >
                    <TimePicker
                      defaultValue={moment(lastShift?.clockInDate)}
                      value={moment(lastShift?.clockInDate)}
                      disabled={true}
                      width={180}
                      format={"HH:mm | DD/MM/YYYY"}
                    />
                  </Form.Item>
                  <Form.Item
                    className="end-shift-forgot"
                    name={"mbarimi"}
                    label="Mbarimi i turnit"
                    rules={[{ required: true }]}
                  >
                    <TimePicker
                      placeholder="Zgjidh orarin"
                      format={"HH:mm"}
                      disabledHours={() =>
                        !isAfterMidnight
                          ? getDisabledBeforeCustomHours(lastShift?.clockInDate)
                          : null
                      }
                      disabledMinutes={(val) =>
                        !isAfterMidnight
                          ? getDisabledBeforeCustomMinutes(
                              lastShift?.clockInDate,
                              val
                            )
                          : null
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    className="after-midnight-checkbox"
                    name="isAfterMidnight"
                  >
                    <Checkbox
                      // disabled
                      checked={isAfterMidnight}
                      onChange={(e) => setIsAfterMidnight(e.target.checked)}
                    >
                      Turn Pas Mesnate
                    </Checkbox>
                  </Form.Item>
                </div>
                <Form.Item
                  className="note-forgot"
                  name={"shenimi"}
                  label="Arsyeja/Shenimi"
                  rules={[{ required: true }]}
                >
                  <TextArea />
                </Form.Item>
                <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 19 }}>
                  <button className="forgot-submit-btn" htmlType="submit">
                    Konfirmo
                  </button>
                </Form.Item>
              </Form>
            </div>
          </div>
        ) : (
          <Skeleton />
        )}
      </Modal>
    </div>
  );
}

const layout = {
  labelCol: {
    span: 12,
  },
  wrapperCol: {
    span: 16,
  },
};
export default Forgot;
