import { API } from "aws-amplify";
import moment from "moment-timezone";

export const preparePrePayments = (timeframe, employeeId, prepayments) => {
	let temp;

	if (prepayments.length > 0) {
		temp = prepayments.find(
			(el) =>
				moment(el?.period[0]).format("DD/MM/YYYY") ===
					moment(timeframe?.start).format("DD/MM/YYYY") && el?.employeeId === employeeId
		);
	}

	return temp || {};
};
