import types from "../types/types";

const initialState = {
  documentations: undefined,
};

const documentationsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.SET_DOCUMENTATIONS:
      return {
        ...state,
        documentations: payload,
      };
    default:
      return state;
  }
};

export default documentationsReducer;
