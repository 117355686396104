import types from "../types/types";
const initialState = {
	clockings: undefined,
};

const clockingsReducer = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case types.SET_CLOCKINGS:
			return {
				...state,
				clockings: payload,
			};
		case types.ADD_CLOCKING:
			const temp = new Set(state.clockings);
			temp.add(payload);

			return {
				...state,
				clockings: Array.from(temp),
			};
		case types?.EDIT_CLOCKING:
			return {
				...state,
				clockings: replaceObjectInArray(state.clockings, payload),
			};
		default:
			return state;
	}
};

export default clockingsReducer;

// replace array object with a new one
const replaceObjectInArray = (array, objectToReplace) => {
	const temp = array.map((item) => {
		if (item.clockingId === objectToReplace.clockingId) {
			return objectToReplace;
		} else {
			return item;
		}
	});
	const toReturn = new Set(temp);
	return Array.from(toReturn);
};
