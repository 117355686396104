import { Steps } from "antd";
import { StepRenderer } from "./components";
import "./Stepper.scss";
import { memo } from "react";

/**
 * Customized AntD stepper.
 *
 * A customized AntD stepper that accepts all the properties of the AntD stepper.
 *
 * @param {int} currentStep  Active step's index. Should be saved in the state.
 * @param {function} setCurrentStep  Function that sets the active step.
 * @param {Array} steps  Array containing an object list of the steps. Step's object should have:
 *                        title - The title that is gonna appear on the stepper.
 *                        Comp - Component to be rendered.
 *                        disabled - Whether the step is disabled or not.
 *                        Any other key is gonna be passed as a prop to the Comp.
 * @param {String} [stepperClassName] Stepper's Container class name.
 * @param {String} [componentContainerClassName] Rendered Component's class name.
 * @returns {Node} Component of the active step.
 */

const Stepper = ({
  currentStep: current,
  setCurrentStep,
  size = "small",
  steps = [],
  stepperClassName = "",
  stepRenderer = true,
  componentContainerClassName = "",
  useKeys = false,
  statusColor,
  onChange = () => {},
  ...rest
}) => {
  const hexToRgba = (hex, alpha) => {
    const r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);
    if (alpha) {
      return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
      return "rgb(" + r + ", " + g + ", " + b + ")";
    }
  };
  const { Step } = Steps;
  return (
    <>
      <div className={`antCustomStepper ${stepperClassName}`.trim()}>
        <Steps
          {...{
            size,
            current,
            onChange: (curr) => {
              onChange(curr);
              setCurrentStep(curr);
            },
            type: "navigation",
            ...rest,
          }}
        >
          {steps?.map(
            ({ title, disabled = false, status, statusColor }, key) => {
              return (
                <Step
                  style={
                    statusColor
                      ? {
                          backgroundColor:
                            current === key
                              ? statusColor
                              : hexToRgba(statusColor, 0.4),
                          "--color":
                            current === key
                              ? statusColor
                              : hexToRgba(statusColor, 0.4),
                          "--baseColor":
                            current === key
                              ? statusColor
                              : hexToRgba(statusColor, 0.4),
                          "--processColor":
                            current === key
                              ? statusColor
                              : hexToRgba(statusColor, 0.4),
                        }
                      : {}
                  }
                  {...{ title, status, disabled, key }}
                />
              );
            }
          )}
        </Steps>
      </div>
      {stepRenderer && (
        <div className={componentContainerClassName}>
          <StepRenderer
            {...{
              current,
              steps,
              useKeys,
            }}
          />
        </div>
      )}
    </>
  );
};

export default memo(Stepper);
