import React from "react";
import {
  getDisabledAfterHours,
  getDisabledAfterMinutes,
} from "../../utils/disabledTimeFilters";
import { Input, Typography, TimePicker, message } from "antd";
import moment from "moment-timezone";
import { ClockInIcon } from "../../assets";
import Clock from "react-live-clock";
import { WitchIcon } from "../../../../assets/holidays/icons";
import { useAuth } from "../../../../authentication/authHelpers";

const { TextArea } = Input;
const { Text } = Typography;

function ClockInActive({
  time,
  editedTime,
  customTime,
  onCustomStartTime,
  setCustomTime,
  setShowConfirm,
  setShowYesterday,
  notes,
  onShiftNote,
}) {
  const auth = useAuth();
  const currEmployeePayroll = auth.employee?.employeePayrollType;
  const invertBinaryTree = (tree) => {};
  return (
    <div className="clock-in-active">
      {" "}
      <div className="time-indicator">
        {/* <h4 style={{ color: "#EA3943" }}>{moment(time).format("HH:mm")}</h4>{" "} */}
        {/* <h4 style={{ color: "#EA3943" }}>
          {" "}
          <Clock format={"HH:mm"} ticking={true} timezone={"Europe/Tirane"} />
        </h4> */}
        {/* <span className="live-icon">
            <LiveIcon />
        </span> */}
      </div>
      <div
        className="clock-in-content"
        // style={{ marginTop: editedTime !== null ? "0px" : "0px" }}
      >
        <div className="clock-in-logo-container"></div>

        <div className="actionful">
          <div className="start-shift-cont">
            <span
              style={{
                width: "160px",
                textAlign: "center",
                fontSize: "16px",
                lineHeight: "24px",
                //BCA THEME
                // color: "#c3174b",
                color: "#1D3445",
                // fontWeight: "600",
              }}
            >
              <h1>Përshëndetje!</h1>
              {/* <TimePicker
                className="timepick"
                defaultValue={time}
                disabled
                value={
                  customTime ? null : editedTime !== null ? editedTime : time
                }
                format={"HH:mm"}
                allowClear={false}
                onChange={onCustomStartTime}
                onOpenChange={(boolVal) => setCustomTime(boolVal)}
                disabledHours={getDisabledAfterHours}
                disabledMinutes={getDisabledAfterMinutes}
              /> */}
              <span
                className="start-shift-btn"
                style={{
                  width: "160px",
                }}
                onClick={() => {
                  if (currEmployeePayroll === "Page bazuar ne ore") {
                    setShowConfirm(true);
                  } else {
                    message.error(
                      "Ju nuk mundeni te filloni turnin pasi nuk keni nje page bazuar ne ore!"
                    );
                  }
                }}
              >
                Fillo Turnin
              </span>
              <div
                style={{
                  width: "160px",
                  backgroundColor: "#1D3445",
                  //BCA THEME
                  // backgroundColor: "#c3174b",
                  marginTop: "10px",
                  height: "30px",
                  borderRadius: "5px",
                  color: "white",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                Ora Tani:{" "}
                <Clock
                  format={"HH:mm"}
                  ticking={true}
                  timezone={"Europe/Tirane"}
                />
              </div>
            </span>{" "}
            <span
              className="yesterday-shift-btn"
              style={{
                textAlign: "center",
                marginLeft: "-12px",
                marginTop: "10px",
                //BCA THEME
                // color: "#c3174b",
              }}
              onClick={() => setShowYesterday(true)}
            >
              Shikoni prezencën në të kaluarën{" "}
            </span>
            {/* <span className="start-shift-btn" onClick={() => setShowConfirm(true)}> */}
            {/* <Popconfirm
title={`A jeni të sigurt që dëshironi të filloni turnin në këtë orar ${
editedTime
  ? moment(editedTime).format("HH:mm")
  : moment(time).format("HH:mm")
} ?`}
// onConfirm={onClockIn}
onConfirm={() => setShowConfirm(true)}
// onConfirm={() => setShowForgot(true)}
// onCancel={cancel}
okText="Po, fillo."
cancelText="Jo, anullo."
> */}
            {/* </Popconfirm> */}
          </div>
        </div>
      </div>
      {editedTime && (
        <div className="justify-note-wrapper" style={{ marginTop: "10px" }}>
          <TextArea
            // autoSize={false}
            value={notes?.clockInNote}
            onChange={(e) => onShiftNote(e, "clockInNote")}
            classname="justify-note"
            placeholder="Shkruaj një shënim këtu..."
            autoSize={{ minRows: 2, maxRows: 2 }}
          />
        </div>
      )}
    </div>
  );
}

export default ClockInActive;
