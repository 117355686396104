import Card from "../../../components/Card";
import ChartSummary from "../../../components/charts/Chart";
import DashboardChart from "../../../components/charts/DashboardChart";
import ClockIn from "../../../components/clockInOut/ClockIn";
import EmployeOfMonth from "../../../components/EmployeOfMonth/EmployeOfMonth";
import EmployeOfWeek from "../../../components/EmployeOfMonth/EmployeOfWeek";
import HolidayWidget from "../../../components/holidayWidget/HolidayWidget";
import RequestForm from "../../../components/requestPermission/RequestForm";
import UserRequest from "../../../components/requestPermission/UserRequest";
import WeeklyMonthly from "../../../components/summary/WeeklyMonthly";
import WeatherCard from "../../../components/weatherCard/WeatherCard";
import WorkBeforeShift from "../../../UserDashboard/OvertimeShift/WorkBeforeShift";
import UserWageHistory from "../../../UserDashboard/UserWageHistory";
import ClockingsLogs from "../../AdminCards/ClockingsLogs/ClockingsLogs";
import ClockInStats from "../../AdminCards/ClockInStats/ClockInStats";
import LateClockIns from "../../AdminCards/ClockInStats/LateClockIns";
import NoClockIn from "../../AdminCards/ClockInStats/NoClockIn";
import Employees from "../../AdminCards/Employees/Employees";
import ProblemsCard from "../../AdminCards/Problems/ProblemsCard";
import ClockOuts from "../../ClockOutStats/ClockOuts";
import ComponentCard from "../ComponentCard";

const defaultData = {

	x: 0,
	y: 0,
	h: 1,
	w: 1,
	minW: 1,
	minH: 1,
	static: false

}

export const superVisorsDashboardCards = [
	{
		i: "0",
		data: {
			name: "ClockIn",
			emri: "Veprime Turni",
			src: ComponentCard,
			content: ClockIn,
			parent: true,
			data: {},
			hidden: false,
			blur: false,
		},
		...defaultData,
		x: 0,
		y: 0
	},

	{
		i: "1",
		data: {
			name: "WeeklyMonthly",
			emri: "Orët Javore/Mujore",
			src: ComponentCard,
			content: WeeklyMonthly,
			parent: true,
			data: {},
			hidden: false,
			blur: false,
		},
		...defaultData,
		x: 1,
		y: 0

	},
	{
		i: "3",
		data: {
			name: "RequestCard",
			emri: "Kërkesë Leje",
			src: ComponentCard,
			content: UserRequest,
			parent: true,
			data: {},
			hidden: false,
			blur: false,
		},
		...defaultData,
		minW: 2,
		w: 2,
		x: 2,
		y: 0

	},
	{
		i: "4",
		data: {
			name: "Chart",
			emri: "Grafikë",
			src: ComponentCard,
			content: DashboardChart,
			parent: true,
			data: {},
			hidden: false,
			blur: false,
		}
		,
		...defaultData,
		x: 0,
		y: 1

	},
	{
		i: "5",
		data: {
			name: "EmployeesHours",
			emri: "Orët e punonjësve",
			src: ComponentCard,
			content: Employees,
			parent: false,
			data: {},
			hidden: false,
			blur: true,
		},
		...defaultData,
		x: 1,
		y: 1
	},
	{
		i: "6",
		data: {
			name: "clockInStatistics",
			emri: "Statistika për Clock In",
			src: ComponentCard,
			content: ClockInStats,
			parent: true,
			hidden: false,

		},
		...defaultData,
		x: 2,
		y: 1
	},
	{
		i: "7",
		data: {
			name: "noClockOuts",
			emri: "Punonjës që nuk kanë bërë clock out",
			src: ComponentCard,
			content: ClockOuts,
			parent: true,
			hidden: false,
		},
		...defaultData,
		minW: 1,
		w: 1,
		x: 3,
		y: 1
	},
	{
		i: "8",
		data: {
			name: "noClockIn",
			emri: "Punonjës që nuk kanë bërë clock in (sot)",
			src: ComponentCard,
			content: NoClockIn,
			parent: false,
			hidden: false,
			blur: false,
		},
		...defaultData,
		x: 0,
		y: 2
	},
	{
		i: "9",
		data: {

			name: "lateClockIns",
			emri: "Clock in me vonesë (sot)",
			src: ComponentCard,
			content: LateClockIns,
			parent: false,
			hidden: false,
			blur: false,
		},
		...defaultData,
		x: 1,
		y: 2
	},
	{
		i: "10",
		data: {
			name: "clockingsLogs",
			emri: "Historiku i turneve",
			src: ComponentCard,
			content: ClockingsLogs,
			parent: true,
			data: {},
			hidden: false,
			blur: false,
		},
		...defaultData,
		minW: 2,
		w: 2,
		x: 2,
		y: 2
	},
	{
		i: "11",
		data: {
			name: "workBeforeShift",
			emri: "Kërkesë për orë shtesë",
			src: ComponentCard,
			content: WorkBeforeShift,
			parent: true,
			data: {},
			hidden: false,
			blur: false,
		},
		...defaultData,
		minW: 2,
		w: 2,
		x: 3,
		y: 2

	},
	{
		i: "12",
		data: {
			name: "Holidays",
			emri: "Festat",
			src: ComponentCard,
			content: HolidayWidget,
			parent: true,
			data: {},

			hidden: false,
			blur: false,
		},
		...defaultData,
		minW: 2,
		w: 2,
		x: 0,
		y: 3
	},
	{
		i: "13",
		data: {
			name: "Moti",
			emri: "Moti",
			src: ComponentCard,
			content: WeatherCard,
			parent: true,
			data: {},
			hidden: false,
			blur: false,
		},
		...defaultData,
		minW: 2,
		w: 2,
		x: 2,
		y: 4


	},
	{
		i: "14",
		data: {
			name: "Punonjësi i Muajit",
			emri: "Punonjësi i Muajit",
			src: ComponentCard,
			content: EmployeOfMonth,
			parent: true,
			data: {},
			hidden: false,
			blur: false,
		},
		...defaultData,
		minW: 2,
		w: 2,
		x: 0,
		y: 4
	},
	{
		i: "15",
		data: {
			name: "Punonjësi i Javës",
			emri: "Punonjësi i Javës",
			src: ComponentCard,
			content: EmployeOfWeek,
			parent: true,
			data: {},
			hidden: false,
			blur: false,
		},
		...defaultData,
		minW: 2,
		w: 2,
		x: 0,
		y: 5
	},
	{
		i: "29",
		data: {
			name: "Problematikat",
			emri: `Problematikat`,
			src: ComponentCard,
			content: ProblemsCard,
			parent: false,
			data: {},
			hidden: false,
			blur: false,
		},
		...defaultData,
		minW: 2,
		w: 2,
		minH: 2,
		h: 2,
		x: 2,
		y: 5
	},

	// {
	// 	id: 99,
	// 	name: "wageHistory",
	// 	emri: "Statistika Pagat",
	// 	src: UserWageHistory,
	// 	content: "",
	// 	parent: false,
	// 	data: {},
	// 	hidden: false,
	// 	blur: false,
	// },
];

export const getSupervisorSaved = () => {
	const ls = JSON.parse(localStorage.getItem("draggableOrderSuperVisor"));
	if (ls !== null) {
		if (ls.length === superVisorsDashboardCards.length) {
			let temporary = [];
			ls.forEach((el, idx) => {
				let found = superVisorsDashboardCards.findIndex((l, x) => l.i === el.i);
				if (found !== -1) {
					temporary.push({
						...superVisorsDashboardCards[found],
						data: { ...superVisorsDashboardCards[found].data, hidden: el?.data?.hidden, blur: el?.data?.blur },
						x: el.x,
						y: el.y,
						h: el.h,
						w: el.w,
						minW: el.minW,
						minH: el.minH,
					});
				}
			});
			return temporary;
		}
		return superVisorsDashboardCards;
	} else if (ls === null) {
		localStorage.setItem("draggableOrderSuperVisor", JSON.stringify(superVisorsDashboardCards));
		return superVisorsDashboardCards;
	}
};
