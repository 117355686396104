import InputComponent from "../../../InputComponent/InputComponent";
import { Form, message, Modal } from "antd";
import DocumentationsCard from "./components/DocumentationsCard/DocumentationsCard";
import FormActionButtons from "../FormActionButtons/FormActionButtons";
import { useDocumentationContext } from "../../context";
import { createTemplate, updateTemplate } from "../../utils/dispatchFunctions";
import { useEffect } from "react";
import { v4 as uuid } from "uuid";
import "./NewTemplateModal.scss";

const antdModalProps = {
  closable: true,
  destroyOnClose: true,
  wrapClassName: "cardLayoutModal",
  footer: null,
  maskClosable: false,
  centered: true,
};

const MODAL_TITLE = "Model i Ri";

const NewTemplateModal = ({ visible, setVisible, editingTemplate }) => {
  const { selectedCategory = {}, curryDispatch } = useDocumentationContext();

  const [form] = Form.useForm();

  const { templatesAvailable = [], documentationsAvailable = [] } =
    selectedCategory;

  const onDiscard = () => {
    setVisible(false);
    form.resetFields();
  };

  const saveTemplate = async () => {
    const templateName = form.getFieldValue("templateName");
    const selectedDocs = documentationsAvailable
      ?.map(({ docName }) => docName)
      .filter((el) => !!form.getFieldValue(el));

    const newTemplate = !editingTemplate
      ? {
          id: uuid(),
          templateName,
          templateDocs: selectedDocs,
          createdAt: Date.now(),
        }
      : { ...editingTemplate, templateName, templateDocs: selectedDocs };

    const dispatchArgs = [selectedCategory, newTemplate];

    await curryDispatch(
      !editingTemplate
        ? createTemplate(...dispatchArgs)
        : updateTemplate(...dispatchArgs)
    );
    onDiscard();
  };

  const templateNameValidator = (_, templateName) =>
    !editingTemplate &&
    !!templateName &&
    templatesAvailable.some(
      ({ templateName: arrTempName = "" }) =>
        templateName.toLowerCase() === arrTempName.toLowerCase()
    )
      ? Promise.reject(new Error("Modeli ekziston tashmë!"))
      : Promise.resolve();

  const onSubmit = () => {
    const selectedDocs = documentationsAvailable
      ?.map(({ docName }) => docName)
      .filter((el) => !!form.getFieldValue(el));

    !selectedDocs?.length
      ? message.warning("Ju lutemi zgjidhni të paktën një dokumentacion!")
      : form.validateFields().then(saveTemplate);
  };

  useEffect(() => {
    if (!editingTemplate) return;
    const { templateDocs, templateName } = editingTemplate;
    form.setFieldsValue({
      templateName,
      ...templateDocs.reduce((acc, curr) => ({ ...acc, [curr]: true }), {}),
    });
  }, [editingTemplate]);

  return (
    <Modal
      {...{
        ...antdModalProps,
        visible,
        onCancel: onDiscard,
        className: "cardLayoutModal docConfigTemplateModal",
        title: MODAL_TITLE,
      }}
    >
      <Form {...{ form, preserve: false }}>
        <div className="modalBodyContainer">
          <div className="templateName">
            <InputComponent
              {...{
                label: "Emri Modelit",
                formItemName: "templateName",
                placeholder: "Emërtojeni këtu...",
                required: true,
                rules: [
                  {
                    validator: templateNameValidator,
                  },
                ],
              }}
            />
          </div>
          <DocumentationsCard {...{ documentationsAvailable }} />
          <FormActionButtons
            {...{
              className: "templateActionButtons",
              onSubmit,
              onDiscard,
            }}
          />
        </div>
      </Form>
    </Modal>
  );
};

export default NewTemplateModal;
