import "./CardHeader.scss";

const TITLE = "Folderat e Drive";

const CardHeader = () => {
  return (
    <div className="docFileTreeHeader">
      <span className="docFileTreeHeaderTitle">{TITLE}</span>
    </div>
  );
};

export default CardHeader;
