import React from "react";
import Cardkonfig from "../../dynamic components/Card";
import EmployOfMonthContent from "./EmployOfMonthContent";

function EmployeOfTheMonthCard() {
  return (
    <Cardkonfig
      contentChild={<EmployOfMonthContent month={true} />}
      width="100%"
      height={"calc(100% - 35px)"}
      //   title="Detajet e kompanisë/subjektit"
    />
  );
}

export default EmployeOfTheMonthCard;
