import moment from "moment-timezone";

export const getMonthStatistic = (wages, month) => {
	let result = {
		bruto: 0,
		neto: 0,
		emplCost: 0,
		totalCost: 0,
	};
	if (!!wages && !!month) {
		if (Array.isArray(wages)) {
			wages
				.filter(
					(wage) =>
						moment(wage.startPeriod).format("MMMM YYYY") === moment(month).format("MMMM YYYY")
				)
				.map((w) => {
					result.bruto += w?.bruto?.totalBruto || 0;
					result.neto += w?.taxes?.netoPay?.netoPay || 0;
					result.emplCost += w?.taxes?.netoPay?.totalEmployeeerIns || 0;
				});
			result.totalCost = result?.bruto + result?.emplCost;
			return result;
		}
		return result;
	}
	return result;
};
