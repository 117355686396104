import { useAuth } from "./authHelpers";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
// import { Navigate } from "react-router-dom";
// import { useContext } from "react";
// import { NotifContext } from "../components/dashboard/components/notifications/context/notifContext";
import { Auth } from "aws-amplify";
import { useState, useEffect, useLayoutEffect } from "react";
import LoadableComp from "../components/commonComponents/LoadableComp/LoadableComp";
// import PageNotFound from "../components/commonComponents/PageNotFound";
import { useUserSession } from "../hooks/useUserSession";
import moment from "moment-timezone";
import Swal from "sweetalert2";
function PrivateRoute({ children, currentUser, accessRoute }) {
	const [isLoading, setLoading] = useState(true);
	const auth = useAuth();
	const location = useLocation();
	const navigate = useNavigate();
	const userSession = useUserSession();

	const validateUser = async () => {
		return await Auth.currentAuthenticatedUser().then(
			(res) => {
				{
					setLoading(false);
					auth.signin(res, () => {
						// navigate("kryefaqja");
					});
				}
			},
			(error) => {
				console.log("error signing in", error);
				navigate("/login", { state: { from: location } });
				setLoading(false);
			}
		);
	};

	const logoutUser = async () => {
		let current = auth?.userAccess[0] || [];
		await userSession.close(current);
		await Auth.signOut();
		auth.signout(() => navigate("/login"));
	};

	const checkDisabled = async () => {
		if (Array.isArray(auth?.userAccess)) {
			if (auth.userAccess.length > 0) {
				let disabled = auth.userAccess[0]?.disabled;
				let forceReset = auth.userAccess[0]?.forceReset;
				let activeSessions = auth.userAccess[0]?.activeSessions;

				if (disabled) {
					await Auth.signOut();
					auth.signout(() => navigate("/login"));
					setLoading(false);
				}
				if (!!activeSessions) {
					const { logout } =
						activeSessions?.find?.(
							({ sessionId }) => sessionId === JSON.parse(window.localStorage.getItem("sessionId"))
						) || {};
					if (!!logout) {
						await logoutUser();
					}
				}

				if (forceReset) {
					navigate("/ndryshoFjalekalimin");
				}
			}
		}
	};

	useEffect(() => {
		validateUser();
		// getCustomMessage();
	}, []);

	useEffect(() => {
		checkDisabled();
	}, [auth]);

	// useEffect(() => {
	// 	if (!isLoading) getCustomMessage();
	// }, [isLoading]);

	if (isLoading) {
		return <LoadableComp loading={isLoading} />;
	}

	return <Outlet />;
}

export default PrivateRoute;
