import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import EmployeesList from "./components/EmployeesList";
import Departments from "./components/Departments";
import LoadableComp from "../../../../commonComponents/LoadableComp/LoadableComp";
import { useSelector } from "react-redux";
import { HollowDotsSpinner } from "react-epic-spinners";

const Vacations = () => {
  const [leave, setLeave] = useState([]);
  const [employeesData, setEmployeesData] = useState([]);
  const { employees } = useSelector((state) => state.employeesList);
  const { employeeRequests } = useSelector((state) => state.employeeRequests);
  useEffect(() => {
    const fetchData = async () => {
      //Merr punonjesit qe kane userSub te sakte
      const employeeData = employees?.filter?.(
        (el) =>
          !!el.userSub && el.userSub !== null && el.employeeStatus === "Aktiv"
      );
      //Merr nga pushimet vetem ato qe jane aprovuar
      const onLeave = employeeRequests;
      // .filter(
      // 	(f) => f?.requestStatus === "Aprovuar"
      // );
      setLeave(onLeave);
      setEmployeesData(employeeData);
    };
    fetchData();
  }, [employeeRequests]);

  /**
   * * @EneaXharau - Added independent loading functionality, visual loader based on validation below
   */
  return (
    <>
      {employeesData?.length >= 0 && leave?.length >= 0 ? (
        <div className="vactions-container">
          <Row>
            <Col span={6}>
              <EmployeesList leave={leave} employeesData={employeesData} />
            </Col>
            <Col span={18}>
              <div className="departments-container">
                <Departments leave={leave} employeesData={employeesData} />
              </div>
            </Col>
          </Row>
        </div>
      ) : (
        <HollowDotsSpinner
          color="#18AA73"
          size={24}
          style={{ position: "relative", top: "50%", left: "45%" }}
        />
      )}
    </>
  );
};
export default Vacations;
