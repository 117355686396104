import { Button } from "antd";
import moment from "moment-timezone";
import React from "react";
import MobileHtmlTabel from "../../../../../utils/MobileComponents/MobileHtmlTabel";

function MobileShiftGrid({ setNewShiftVisib, aggridData }) {
	return (
		<div style={{ marginRight: 10 }}>
			<div className="custom-header-div">
				{" "}
				<Button className="btn-header" onClick={() => setNewShiftVisib(true)}>
					Krijo një hyrje
				</Button>{" "}
			</div>
			<MobileHtmlTabel
				tableData={aggridData?.map?.(
					({ approved, data, fillim_turni, mbarim_turni, oret_totale }) => ({
						statusHeaderKey: approved ? "Aprovuar" : "Pa Aprovuar",
						data: moment(data).format("DD/MM/YYYY"),
						fillim_turni: moment(fillim_turni).format("HH:mm"),
						mbarim_turni: moment(mbarim_turni).format("HH:mm"),
						oret_totale,
					})
				)}
				header={["Statusi", "Data", "Fillimi i turnit", "Mbarimi i turnit", "Orët totale"]}
				isHeader={true}
				isLink={false}
			/>
		</div>
	);
}

export default MobileShiftGrid;
