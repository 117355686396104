import {
  KompaniaIcon,
  SistemiIcon,
  PolitikatIcon,
  PagatIcon,
  PerdoruesitKofigIcon,
  ProfileIcon,
} from "../../.././assets/icons";
import { EmailIcon } from "../../punonjesit/EmployeeView/Personal/User/assets/icons";
export const KonfigurimetMenuData = [
  {
    title: "Profili",
    SubmenuKey: "sub0",
    icon: ProfileIcon,
    parent: true,
    submenu: [],
    to: "kompania/profili",
  },
  {
    title: "Kompania",
    SubmenuKey: "sub1",
    icon: KompaniaIcon,
    submenu: [
      {
        key: "1",
        to: "kompania/info/rreth/komapnise",
        title: "Informacione rreth kompanisë",
      },
      {
        key: "2",
        to: "kompania/pozicionet",
        title: "Pozicionet",
      },
    ],
  },
  {
    title: "Sistemi",
    SubmenuKey: "sub2",
    icon: SistemiIcon,
    submenu: [
      {
        key: "3",
        to: "sistemi/pajisjet/orarit",
        title: "Pajisjet e orarit",
      },
      {
        key: "4",
        to: "sistemi/turnet",
        title: "Turnet",
      },
      {
        key: "10",
        to: "sistemi/dokumentacionet",
        title: "Konfigurimi i Dokumentacioneve",
      },
      {
        key: "7",
        to: "sistemi/arsyet/largimit",
        title: "Arsyet e largimeve",
      },
      {
        key: "8",
        to: "sistemi/statuset/punojesve",
        title: "Statuset e punonjesve",
      },
      {
        key: "9",
        to: "sistemi/llojet/kerkesave",
        title: "Llojet e kërkesave",
      },
      {
        key: 11,
        title: "Konfigurimi i Adresave IP",
        to: "sistemi/adresatIp",
      },
    ],
  },
  {
    title: "Politikat",
    SubmenuKey: "sub3",
    icon: PagatIcon,
    submenu: [
      {
        key: "12",
        to: "politikat/ditet/pushimit",
        title: "Politikat për ditët e pushimit",
      },
      {
        key: "13",
        to: "politikat/festave/zyrtare",
        title: "Festat zyrtare",
      },
      {
        key: "15",
        to: "politikat/oreve/jashte/orarit",
        title: "Politikat e orëve jashtë orarit",
      },
    ],
  },
  {
    title: "Pagat",
    SubmenuKey: "sub4",
    icon: PolitikatIcon,
    submenu: [
      {
        key: "17",
        to: "pagat/fitimet",
        title: "Fitimet",
      },
      {
        key: "18",
        to: "pagat/zbritje",
        title: "Zbritje",
      },
      {
        key: "19",
        to: "pagat/pagesat",
        title: "Mënyrat e pagesës",
      },
      {
        key: "20",
        to: "pagat/kalkulimi",
        title: "Kalkulimi i pagës",
      },
    ],
  },
  {
    title: "Përdoruesit",
    SubmenuKey: "sub5",
    icon: PerdoruesitKofigIcon,
    submenu: [],
    parent: true,
    to: "siguria/perdoruesit",
  },
  {
    title: "Konfigurimi i Kandidateve",
    SubmenuKey: "sub7",
    icon: PerdoruesitKofigIcon,
    subMenu: [],
    parent: true,
    to: "KonfigurimiKandidateve",
  },

  {
    title: "Preferencat",
    SubmenuKey: "sub7",
    icon: PerdoruesitKofigIcon,
    submenu: [],
    parent: true,
    to: "preferencat",
  },

  {
    title: "Email Template",
    SubmenuKey: "sub8",
    icon: EmailIcon,
    submenu: [],
    parent: true,
    to: "emailTemplate",
  },
];
