import { useState } from "react";
import { ReactComponent as TransferIcon } from "./components/TransferLabel/TransferIcon.svg";
import { TransferLabel } from "./components";
import { cloneDeep } from "lodash";
import "./CustomTransfer.scss";

export const DISABLED_TRANSFER_ACTIONS = {
  ADDITION: "add",
  REMOVAL: "remove",
};

const CustomTransfer = ({
  dataSource = [],
  label = "",
  value = [],
  disabled,
  className = "",
  onChange,
  required,
  disabledAction = "",
}) => {
  const [isItemChecked, setIsItemChecked] = useState({});

  const notTransferredItems = dataSource.filter(
    ({ key }) => !value.includes(key)
  );
  const transferredItems = value.map((key) =>
    dataSource.find(({ key: sourceKey }) => key === sourceKey)
  );

  const onAdd = (key) => {
    onChange([...value, key]);
  };

  const onRemove = (key) => {
    onChange(value.filter((valKey) => valKey !== key));
  };

  const onItemChange = (key, checked) => {
    setIsItemChecked((prev) => ({ ...prev, [key]: checked }));
  };

  const onExchange = () => {
    const checkedValues = cloneDeep(isItemChecked);

    const checkedNotTransferredItems = notTransferredItems
      .filter(({ key }) => !!checkedValues[key])
      .map(({ key }) => key);

    const checkedTransferredItems = transferredItems
      .filter(({ key }) => !!checkedValues[key])
      .map(({ key }) => key);

    onChange([
      ...value.filter((key) => !checkedTransferredItems.includes(key)),
      ...checkedNotTransferredItems,
    ]);
    setIsItemChecked({});
  };

  return (
    <div
      className={`customAntdTransfer ${className} ${
        !!disabled ? "disabledTransfer" : ""
      }`.trim()}
    >
      {!!label && (
        <div className="transferLabel">
          {!!required && <span style={{ color: "red" }}>*</span>}
          {label}
        </div>
      )}
      <div className="transfersContainer">
        <div className="source">
          <div className="itemCounter">{`${notTransferredItems.length} Item${
            notTransferredItems.length !== 1 ? "s" : ""
          }`}</div>
          <div className="listContainer">
            {notTransferredItems.map(({ key, title }) => (
              <TransferLabel
                {...{
                  key,
                  title,
                  disabled:
                    !!disabled ||
                    disabledAction === DISABLED_TRANSFER_ACTIONS.ADDITION,
                  onClick: () => onAdd(key),
                  checked: isItemChecked[key],
                  onChange: (checked) => onItemChange(key, checked),
                }}
              />
            ))}
          </div>
        </div>
        <div className="transferIcon">
          <TransferIcon onClick={() => onExchange()} />
        </div>
        <div className="transferredKeys">
          <div className="itemCounter">{`${transferredItems.length} Item${
            transferredItems.length !== 1 ? "s" : ""
          }`}</div>
          <div className="listContainer">
            {transferredItems.map(({ key, title }) => (
              <TransferLabel
                transferred
                {...{
                  key,
                  title,
                  disabled:
                    !!disabled ||
                    disabledAction === DISABLED_TRANSFER_ACTIONS.REMOVAL,
                  onClick: () => onRemove(key),
                  checked: isItemChecked[key],
                  onChange: (checked) => onItemChange(key, checked),
                }}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomTransfer;
