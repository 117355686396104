import moment from "moment-timezone";
import { htmlParser } from "../../../../utils";
import { getAddress } from "./getAddress";
export const data = [
  [
    { type: "string", id: "turni_mundshem" },
    { type: "string", id: "turni_aktual" },
    { type: "date", id: "Start" },
    { type: "date", id: "End" },
  ],
  [
    "Turni i mundshëm",
    "",
    new Date(0, 0, 0, 10, 0, 0),
    new Date(0, 0, 0, 21, 30, 0),
  ],

  [
    "Turni Aktual",
    "Turn Normal",
    new Date(0, 0, 0, 13, 30, 0),
    new Date(0, 0, 0, 21, 30, 0),
  ],
];

export const getShiftChart = (info, geoData) => {
  // console.log("info2", info);
  const handleClick = () => console.log("clicked");

  if (!!info && Array.isArray(info)) {
    let data = [
      [
        { type: "string", id: "turni_mundshem" },
        { type: "string", id: "turni_aktual" },
        { type: "string", role: "tooltip" }, //change role to 'tooltip' to display geoData on tooltip
        { type: "string", role: "style" },
        { type: "date", id: "Start" },
        { type: "date", id: "End" },
      ],
      [
        "Turni i mundshëm",
        // "Turni i mundshëm (10:00 - 21:30)",
        `Turni i mundshëm (${moment(
          info[0]?.applicableShift?.[0]?.shiftStartTime
        ).format("HH:mm")} - ${moment(
          info[0]?.applicableShift?.[0]?.shiftEndTime
        ).format("HH:mm")})`,
        "",
        "#1D3445",
        new Date(
          0,
          0,
          moment(info[0]?.clockInDate).format("d"),
          moment(info[0]?.applicableShift?.[0]?.shiftStartTime).format("HH"),
          moment(info[0]?.applicableShift?.[0]?.shiftStartTime).format("mm"),
          0
        ),
        new Date(
          0,
          0,
          moment(info[0]?.clockOutDate).format("d"),
          moment(info[0]?.applicableShift?.[0]?.shiftEndTime).format("HH"),
          moment(info[0]?.applicableShift?.[0]?.shiftEndTime).format("mm"),
          0
        ),
      ],
    ];
    if (info.length > 0) {
      const tempInterval =
        info.length > 1
          ? info[0]?.totalities?.breakTime || []
          : info[info.length - 1]?.totalities?.breakTime || [];

      if (tempInterval?.length > 1) {
        const s1 = moment(tempInterval[0], "HH:mm");
        const start = moment(info[0]?.clockInDate);
        const s2 = moment(tempInterval[1], "HH:mm");
        const end = moment(info[0]?.clockInDate);
        start.set({
          hour: s1.format("HH"),
          minute: s1.format("mm"),
          second: "00",
        });
        end.set({
          hour: s2.format("HH"),
          minute: s2.format("mm"),
          second: "00",
        });
        data.push([
          `Pushim ${tempInterval[0]} - ${tempInterval[1]}`,
          `Pushim ${tempInterval[0]} - ${tempInterval[1]}`,
          "",
          "#FCC94A",
          new Date(
            0,
            0,
            moment(start, "HH:mm").format("d"),
            moment(start, "HH:mm").format("HH"),
            moment(start, "HH:mm").format("mm"),
            0
          ),
          new Date(
            0,
            0,
            moment(end, "HH:mm").format("d"),
            moment(end, "HH:mm").format("HH"),
            moment(end, "HH:mm").format("mm"),
            0
          ),
        ]);
      }
      /// isBreakTime: true if needed to filter out break time interval in the future
      info.forEach((el) => {
        if (el?.clockingCategory !== "midnightReset") {
          data.push([
            statusTitles[el?.type],
            statusTitles[el?.type],
            //times are not as they should, i guess
            `<div class="custom-tooltip">
              <span>${statusTitles[el?.type]}: ${moment(el?.clockInDate).format(
              "HH"
            )}:${moment(el?.clockInDate).format("mm")} - ${moment(
              el?.clockOutDate
            ).format("HH")}:${moment(el?.clockOutDate).format("mm")}</span>
              <a href='${el?.clockingLogs[0]?.ipResponse.state}'>${
              el?.clockingLogs[0]?.ipResponse.state || ""
            }</a>
              <span>IP Address: ${
                el?.clockingLogs[0]?.ipResponse.IPv4 || ""
              }</span>
              </div>`,
            statusColors[el?.type],
            new Date(
              0,
              0,
              moment(el?.clockInDate).format("d"),
              moment(el?.clockInDate).format("HH"),
              moment(el?.clockInDate).format("mm"),
              0
            ),
            new Date(
              0,
              0,
              moment(el?.clockOutDate).format("d"),
              moment(el?.clockOutDate).format("HH"),
              moment(el?.clockOutDate).format("mm"),
              0
            ),
          ]);
        } else {
          data.push([
            statusTitles[el?.type],
            statusTitles[el?.type],
            `<div class="custom-tooltip">
              <span>${statusTitles[el?.type]}: ${new Date(
              0,
              0,
              moment(el?.clockInDate).subtract(1, "days").format("d"),
              moment(el?.clockInDate).subtract(1, "days").format("HH"),
              moment(el?.clockInDate).subtract(1, "days").format("mm"),
              0
            ).getTime()} - ${new Date(
              0,
              0,
              moment(el?.clockOutDate).subtract(1, "days").format("d"),
              moment(el?.clockOutDate).subtract(1, "days").format("HH"),
              moment(el?.clockOutDate).subtract(1, "days").format("mm"),
              0
            ).getTime()}</span>
              <span>Location: ${geoData[0]},${geoData[1]}</span>
              <span>IP Address: ${geoData[2]}</span>
            </div>`,
            statusColors[el?.type],
            new Date(
              0,
              0,
              moment(el?.clockInDate).subtract(1, "days").format("d"),
              moment(el?.clockInDate).subtract(1, "days").format("HH"),
              moment(el?.clockInDate).subtract(1, "days").format("mm"),
              0
            ),
            new Date(
              0,
              0,
              moment(el?.clockOutDate).subtract(1, "days").format("d"),
              moment(el?.clockOutDate).subtract(1, "days").format("HH"),
              moment(el?.clockOutDate).subtract(1, "days").format("mm"),
              0
            ),
          ]);
        }
      });
      return data;
    }
  }
};

const statusColors = {
  usual: "#1DA193",
  missing: "#EA3943",
  noInfoColor: "#F5F5F7",
  extra: "#832232",
  normalHours: "#1DA193",
  lateHours: "#57A773",
  extraHours: "#832232",
};

export const statusTitles = {
  usual: "Orë Normale",
  missing: "Bosh",
  noInfoColor: "Ska info",
  extra: "Shtesë",
  normalHours: "Para orës 19",
  extraHours: "Pas orës 22:00",
  lateHours: "Midis 19:00 - 22:00",
};
