import React from "react";
import DocumentationConfiguration from "../../../DocumentationConfiguration/DocumentationConfiguration";

function SistemiDokumentacionet() {
  return (
    <div style={{ width: "100%" }}>
      <DocumentationConfiguration />
    </div>
  );
}

export default SistemiDokumentacionet;
