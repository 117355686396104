import React, { useState } from "react";
import ReactQuill from "react-quill";
import DOMPurify from "dompurify";
import "react-quill/dist/quill.snow.css";

const SimpleQuillEditor = ({ onChange, placeholder, value }) => {
  const handleChange = (value) => {
    if (value.replace(/<(.|\n)*?>/g, "").trim().length === 0) {
      value = "";
    }

    const santitizedValue = DOMPurify.sanitize(value);
    onChange(santitizedValue);
  };

  return (
    <div className="custom-quill">
      <ReactQuill
        value={value || ""}
        theme="snow"
        placeholder={placeholder}
        className="quill-editor"
        onChange={handleChange}
        modules={{
          toolbar: false,
        }}
      />
    </div>
  );
};

export default SimpleQuillEditor;
