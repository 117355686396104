export const quoteIfString = (value, quotingSymbol = '"') => {
  return typeof value === "string"
    ? `${quotingSymbol}${value}${quotingSymbol}`
    : value;
};

export const safeQuoteIfString = (value, quoteIfStr, quotingSymbol) => {
  return quoteIfStr ? quoteIfString(value, quotingSymbol) : value;
};

export const encodedUtf8ToByteArray = (encoded) => {
  const ar = [];

  for (let i = 0; i < encoded.length; i++) {
    ar.push(encoded.charCodeAt(i));
  }
  return ar;
};

export { default as ArrayStringifier } from "./ArrayStringifier";
