import React from "react";
import { ComponentCard } from "../../../dashboard/AdminDashboard/Draggable/ComponentCard";
import UserWageHistory from "../../../dashboard/UserDashboard/UserWageHistory";
import UserActionCards from "../../../punonjesit/EmployeeView/Personal/User/UserActionCards";
import WageGrid from "./WageGrid";
import WageInfo from "./WageInfo";

function EmployeeWagesMobileView({ employeeId, employeesData }) {
  return (
    <div className="pay-mobile">
      <div className="mob-margin">
        <WageInfo showPaymentInfo={true} employeesData={employeesData} />
      </div>
      <div className="mob-margin-2">
        <UserActionCards
          contentChild={
            <ComponentCard
              blurDuration={30}
              height={425}
              width={"100%"}
              blur={true}
              content={<UserWageHistory employee={employeeId} />}
            />
          }
          headerName={"Historiku Pagat"}
        />
      </div>
      <div className="DATA-GRID-MOB">
        <WageGrid employee={employeeId} />
      </div>
    </div>
  );
}

export default EmployeeWagesMobileView;
