import { API } from "aws-amplify";
import { osName, osVersion, browserName, browserVersion, deviceType } from "react-device-detect";
// import { apiRoutes, fetchData } from "../components/SidebarPages/Fleet/utils";
import { uniqBy } from "lodash";
import { v4 as uuid } from "uuid";
import { requestData } from "../helpers/API/RequestFactory";
import { updateArrayElement } from "../helpers/utils/updateArrayElement";
import { message } from "antd";
import { useState } from "react";
//updates activeSessions key in userConfiguration
const updateSession = async (body, user) => {
	// console.log("Update Session", user);
	if (!!user?.identityId) {
		await API.put("users", `/users/${user?.identityId}`, {
			body: { activeSessions: body },
		})
			.then((success) => {
				console.log("Success Instance Token:", success);
				// message.success("U shtua sesioni");
			})
			.catch((err) => {
				// message.error("Ndodhi nje gabim ne procesimin e sesionit");
				console.error("Error instance token:", err);
			});
	}
};
// //Returns the current session ID
// export const getCurrentSessionId = async () => {
//   const currentSession = await Auth.currentSession()
//   const currentSessionId = currentSession?.accessToken?.jwtToken
//   return currentSessionId
// }
//
//gets user's current active sessions
export const getActiveSessions = (user) => {
	let whichUser = user;
	if (!!user) {
		if (!user) whichUser = user?.activeSessions;
	}
	const { activeSessions = [] } = whichUser || {};
	// console.log("Returned Active SEss", activeSessions);
	return activeSessions;
};
/**
 ** Used for closing the current session
 *! Do not use an arrow function as it does not bind "this"
 */
function sessionsFilter({ sessionId }) {
	return sessionId !== this.sessionId;
}
export const useUserSession = () => {
	const [currentSessionId, setCurrentSessionId] = useState(uuid());
	// console.log("curret", currentSessionId);
	return {
		open: async (user) =>
			await updateSession(
				uniqBy(
					[
						...getActiveSessions(user),
						{
							sessionId: currentSessionId,
							loggedIn: Date.now(),
							osName,
							osVersion,
							browserName,
							browserVersion,
							deviceType,
							ipv4: (await requestData("getIp")).split(",")[0],
						},
					],
					"sessionId"
				),
				user
			).then(() => window.localStorage.setItem("sessionId", JSON.stringify(currentSessionId))),
		close: async (user) =>
			await updateSession(
				(
					await getActiveSessions(user)
				).filter(sessionsFilter, {
					sessionId: JSON.parse(window.localStorage.getItem("sessionId")),
				}),
				user
			).then(() => window.localStorage.setItem("sessionId", null)),
		update: async (newValues, user) =>
			await updateSession(
				updateArrayElement(
					await getActiveSessions(user),
					{ sessionId: currentSessionId, ...newValues },
					"sessionId"
				),
				user
			),
	};
};
