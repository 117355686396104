import React, { useState } from "react";
import { Form, Input, Button, Card, message, Divider } from "antd";
import { CloseIcon, DeleteIcon, TickIcon } from "../../../../assets/icons";
import { CopyOutlined, InfoCircleFilled } from "@ant-design/icons";
import { PhotoshopPicker } from "react-color";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { v4 as uuidv4 } from "uuid";
import GlobalPopupKonfirm from "../../../commonComponents/GlobalPopupKonfirm";
import { CopyToClipboard } from "react-copy-to-clipboard";

function StatusiKerkesave({ fields, setFields }) {
  const [color, setColor] = useState("#00FFE5");
  const [showPicker, setShowPicker] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showCloseButton, setShowCloseButton] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);
  const [form] = Form.useForm();

  const handleSave = () => {
    //Nqs nuk zgjidhet nje ngjyre atehere statusi nuk mund te shtohet
    if (color != "#00FFE5") {
      //Merr emrin e statusit nga form dhe e shton
      //ne nje objekt bashke me id dhe ngjyren e zgjedhur
      let result = {
        id: uuidv4(),
        name: form.getFieldsValue().status,
        color: color,
      };
      setFields([...fields, result])
    }
    form.resetFields();
  };

  const removeItem = (index) => {
    if (index > -1) {
      fields.splice(index, 1);
    }
    setFields([...fields]);
    setColor("#00FFE5");
  };
  const showCopyMessage = () => {
    message.success("Ngjyra u kopjua!");
  };

  //Funksion i dnd
  function handleOnDragEnd(result) {
    if (!result.destination) return;
    const items = Array.from(fields);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    // setFields(items);
  }
  //Klikimi ne input e form shfaq pjesen e color picker
  const handleInputClick = () => {
    setShowPicker(true);
    setShowCloseButton(true);
  };
  //Klikimi ne input e form fsheh color picker dhe butonin vet
  const handleCloseClick = () => {
    setShowPicker(false);
    setShowCloseButton(false);
  };

  return (
    <div className="employee-status-comp">
      <Card
        className="employee-status-card"
        style={{ height: 438, width: "100%" }}
        title="Statuset e kërkesave"
      >
        <div className="alldivs" style={{ display: "flex" }}>
          <div
            className="left-side"
            style={{ display: "flex", flexDirection: "column" }}
          >
            {/* Form e shtimi te statuseve te reja */}
            <Form
              onFinish={() => handleSave()}
              autoComplete="off"
              form={form}
              layout="inline"
            >
              <Form.Item
                className="emp-status"
                name="status"
                rules={[{ required: true, message: "Vendosni statusin!" }]}
              >
                <Input
                  placeholder="Shto statusin e kërkesës"
                  onClick={() => handleInputClick()}
                />
              </Form.Item>
              <Form.Item
                wrapperCol={{
                  offset: 8,
                }}
              >
                <Button
                  className="add-status-button"
                  htmlType="submit"
                  disabled={disabledButton}
                  icon={
                    <TickIcon width={20} height={20} style={{ marginTop: 4 }} />
                  }
                ></Button>
              </Form.Item>
              <Form.Item
                wrapperCol={{
                  offset: 8,
                }}
              >
                {showCloseButton ? (
                  <Button
                    className="close-status-button"
                    icon={
                      <CloseIcon width={20} height={20} style={{ marginTop: 4 }} />
                    }
                    onClick={() => handleCloseClick()}
                  />
                ) : null}
              </Form.Item>
            </Form>

            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="notelist">
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className="holder"
                  >
                    {fields.map((stat, i) => {
                      return (
                        <Draggable
                          key={stat.id}
                          draggableId={stat.id}
                          index={i}
                        >
                          {(provided) => (
                            <div
                              className="name-btn-div"
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <div
                                  className="status-name"
                                  style={{ backgroundColor: stat.color }}
                                >
                                  {stat.name}
                                </div>
                                <GlobalPopupKonfirm
                                  Button={
                                    <Button
                                      className="bin-button"
                                      style={{ marginLeft: 10 }}
                                      icon={
                                        <DeleteIcon
                                          width={18}
                                          height={18}
                                          style={{ marginTop: 4 }}
                                        />
                                      }
                                    />
                                  }
                                  title={"Jeni i sigurt?"}
                                  onConfirm={() => removeItem(i)}
                                  okText={"Po"}
                                  cancelText={"Jo"}
                                />
                              </div>
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>

          {showPicker ? (
            <div className="right-side">
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Divider type="vertical" />
                <div>
                  <div>
                    <InfoCircleFilled
                      style={{
                        background: "#fff",
                        color: "#1264A3",
                        marginRight: 5,
                        marginLeft: 10,
                        fontSize: 16,
                        // marginTop: 4,
                      }}
                    />
                    Duhet të zgjidhni ngjyrën për të shtuar një status.
                  </div>
                  <div>
                    <PhotoshopPicker
                      color={color}
                      header=""
                      onChangeComplete={(c) => setColor(c.hex)}
                      onChange={() => setDisabledButton(false)}
                    />

                    <input className="hex-code" value={color} disabled />
                    <CopyToClipboard text={color}>
                      <button className="hex-code-button" onClick={showCopyMessage}>
                        <CopyOutlined />
                      </button>
                    </CopyToClipboard>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </Card>
    </div>)
}

export default StatusiKerkesave;
