import React from "react";
import { Menu } from "antd";
import { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import "./Mobile-Menu.scss";
import { useLocation } from "react-router-dom";
import { KonfigurimetMenuData } from "../../components/Konfigurimet/KonfigurimetMenu/KonfigurimetMenuData";

function MobileSetingsMenu({ data }) {
	const { SubMenu } = Menu;
	const [sData, setSData] = useState([]);
	const [k, setK] = useState("");
	const [value, setValue] = useState("");
	const loc = useLocation();

	useEffect(() => {
		KonfigurimetMenuData?.map((el) => {
			if (el?.parent === true) {
				if (`/konfigurimet/${el?.to}` === loc.pathname) {
					setK(el.SubmenuKey);
				}
			} else {
				if (!!el.submenu?.find((td) => `/konfigurimet/${td?.to}` === loc.pathname)) {
					setK(el.SubmenuKey);
				}
			}
		});
	}, [loc]);

	useEffect(() => {
		if (loc.pathname === "/konfigurimet/siguria/rolet/siguris") {
			setK("sub5");
		}
	}, [loc]);

	useEffect(() => {
		if (data?.length > 0) {
			setSData(data);
			setValue("");
		}
		// console.log("data", data);
	}, [data]);

	return (
		<div>
			{sData?.length > 0 && (
				<Menu
					className="mobile-menu"
					// onClick={handleClick}
					style={{ background: "#1D3445", border: 0 }}
					mode="vertical"
					selectedKeys={k}
					defaultSelectedKeys={k}
				>
					{KonfigurimetMenuData?.map((el) => {
						let comparator = data[0]?.accessConfig?.settingsConfig?.filter(
							(f) => f.SubmenuKey === el.SubmenuKey
						);
						let to = el?.to;
						// console.log("gjkdgd", comparator[0]);
						if (comparator?.length > 0) {
							let toCompare = comparator[0]?.submenu;
							// console.log(toCompare);
							if (!to && toCompare?.some((tcmp) => tcmp.auth === true)) {
								return (
									<SubMenu
										key={el.SubmenuKey}
										title={el.title}
										style={{
											height: 50,
											fontSize: 20,
										}}
										className="subMenu-konfig"
										icon={<el.icon />}
									>
										{el.submenu?.map((r, idx) => {
											if (
												comparator[0]?.submenu?.filter((c) => c.to === r.to && c?.auth === true)
													.length > 0
											) {
												return (
													<Menu.Item
														className="subMenu-konfig"
														style={{
															color: "#fff",
															display: "flex",
															height: 50,
															fontSize: 20,
															alignItems: "center",
															background: "#1D3445",
														}}
														key={r.key}
													>
														<Link to={r.to} key={r.key}>
															{r.title}
														</Link>
													</Menu.Item>
												);
											}
										})}
									</SubMenu>
								);
							} else {
								if (comparator[0]?.parent === false && comparator[0]?.auth === true) {
									// console.log(comparator[0]?.auth);
									return (
										<Menu.Item
											className="subMenu-konfig"
											style={{
												display: "flex",
												alignItems: "center",
												color: "#fff",
												fontSize: 20,
												background: "#1D3445",
												height: 50,
											}}
											key={el.SubmenuKey}
											danger={el.SubmenuKey}
											icon={<el.icon />}
											onTitleClick={el.title}
										>
											<Link
												style={{
													color: "#fff",
												}}
												to={el.to}
												key={el.SubmenuKey}
											>
												{el.title}
											</Link>
										</Menu.Item>
									);
								}
							}
						}
					})}
				</Menu>
			)}
		</div>
	);
}

export default MobileSetingsMenu;
