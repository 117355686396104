import { Skeleton } from "antd";
import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import ReturnImg from "../../../../utils/ReturnImg";
import "./EmployesOfMonth.scss";
import Avatar from "react-avatar";
import _ from "lodash";
import {
  CepiDjathtasPng,
  CepiMajtasPng,
  MedaljeSilverPng,
} from "../../../../assets/images";
import { useSelector } from "react-redux";

function EmployeOfWeek() {
  //state with data return object
  const [emplWeekMonth, setEmplWeekMonth] = useState(null);
  const [loading, setLoading] = useState(true);
  const { programFields } = useSelector((state) => state.programFields);

  // get data from programFields and store in state
  const getProgramFields = () => {
    let emplOfWeek = programFields?.find?.(
      (data) => data?.fieldName === "Employees of the Week & Month"
    )?.fieldOptions;
    setEmplWeekMonth(emplOfWeek);
  };

  // run when page is render
  useEffect(() => {
    if (!!programFields) {
      getProgramFields();
      setLoading(false);
    }
  }, [programFields]);

  return (
    <>
      {!loading ? (
        <div className="emp-Month-card">
          <div className="cepi-majtas">
            <img
              src={CepiMajtasPng}
              alt="Left Border"
              style={{ borderBottomLeftRadius: 10 }}
            />
          </div>
          {emplWeekMonth?.current?.name ? (
            <div className="content-emp-month">
              <div className="content-emp">
                <div className="muaj">
                  Punonjësi i Javës:{" "}
                  {moment(emplWeekMonth?.current?.start).format("DD/MMMM/YYYY")}{" "}
                  - {moment(emplWeekMonth?.current?.end).format("DD/MMMM/YYYY")}
                </div>
                <div className="emri">{emplWeekMonth?.current?.name}</div>
                <div className="message">
                  {emplWeekMonth?.current?.mesazhPublik}
                </div>
              </div>

              <div
                className="medalje-name-container"
                style={{ display: "flex" }}
              >
                <div className="medalja">
                  <img src={MedaljeSilverPng} alt="Medalje Silver" />
                </div>
                <div className="foto">
                  {
                    <ReturnImg
                      width={180}
                      height={180}
                      borderRadius={5}
                      googleDriveFileId={
                        emplWeekMonth?.current?.googleDriveFileId
                      }
                      defaultImg={
                        <div style={{ width: 180, height: 180 }}>
                          <Avatar
                            name={emplWeekMonth?.current?.name}
                            size="180"
                            round={true}
                          />
                        </div>
                      }
                    />
                  }
                </div>
              </div>
            </div>
          ) : (
            <div className="content-emp-month-noData">
              Punonjësi i Javës nuk është përzgjedhur!{" "}
            </div>
          )}
          <div className="cepi-djathtas">
            <img src={CepiDjathtasPng} alt="Right Border" />
          </div>
        </div>
      ) : (
        <div>
          <Skeleton />
        </div>
      )}
    </>
  );
}

export default EmployeOfWeek;
