import { Drawer } from "antd";
import React, { useMemo } from "react";
import { useState } from "react";
import { useLocation } from "react-router";
import {
  HamburgerIcon,
  HamburgerSetingsIcon,
  ModalXIcon,
} from "../../assets/icons";
import MobileHomeMenu from "./MobileHomeMenu";
import MobileSetingsMenu from "./MobileSetingsMenu";
import "./Mobile-Menu.scss";

function HamburgerMenu({ data }) {
  const location = useLocation();
  let path = location?.pathname;
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  useMemo(() => {
    if (path) {
      onClose();
    }
  }, [path]);

  return (
    <>
      <div className="hamburgerMenu" onClick={showDrawer}>
        {location.pathname.startsWith("/konfigurimet") === true ? (
          <HamburgerSetingsIcon />
        ) : (
          <HamburgerIcon />
        )}
      </div>

      <Drawer
        className="menu-drower"
        title="Menu"
        placement="left"
        destroyOnClose={true}
        onClose={onClose}
        open={visible}
        closeIcon={<ModalXIcon />}
        key="left"
      >
        <div>
          {location.pathname.startsWith("/konfigurimet") === true ? (
            <MobileSetingsMenu data={data} />
          ) : (
            <MobileHomeMenu data={data} />
          )}
        </div>
      </Drawer>
    </>
  );
}

export default HamburgerMenu;
