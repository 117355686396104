import { useState, useEffect } from "react";
import {
  Navigate,
  Route,
  useLocation,
  useNavigate,
  Outlet,
} from "react-router-dom";
import { Auth } from "aws-amplify";
import { useAuth } from "./authentication/authHelpers";
import LoadableComp from "./components/commonComponents/LoadableComp/LoadableComp";
import Login from "./components/Auth/Login";

function PublicRoute({ children }) {
  const [isLoading, setLoading] = useState(true);
  // const [auth, setIsAuth] = useState(null);
  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(async () => {
    await Auth.currentAuthenticatedUser()
      .then(
        (res) => {
          setLoading(false);
          navigate("/kryefaqja", { state: { from: location } });
          auth.signIn(res);
        },
        (error) => {
          setLoading(false);
          //   console.log("error signing in", error);
          //   navigate("/login", { state: { from: location } });
        }
      )
      .catch((err) =>
        console.log("An error happened with login instance", err)
      );
  }, []);

  if (isLoading) {
    return <LoadableComp loading={isLoading} />;
  }

  return <Outlet />;
}

export default PublicRoute;
