import jsPDF from "jspdf";
import { tableHeaders } from "./ActivityModal";
import moment from "moment";
import { htmlParser } from "../../../../../utils";
import { formatChanges } from "./formatChanges";

const exportPdf = async ({ filterKeylog, name }) => {
  const doc = new jsPDF({ orientation: "landscape" });

  let yCoordinate = 15;
  let xCoordinate = 130;
  doc.setFontSize(20);
  doc.text(xCoordinate, yCoordinate, name);

  xCoordinate = 10;
  filterKeylog?.forEach((keylog, index) => {
    yCoordinate += 10;
    for (const [key, value] of Object.entries(keylog)) {
      let field = tableHeaders.find((item) => item.key === key);
      doc.setFontSize(13);
      let keyText = `${!!field ? field.value : key}:`;
      doc.text(xCoordinate, yCoordinate, keyText);
      doc.setFontSize(11);

      let valueText = ` ${
        !!value
          ? Array.isArray(value)
            ? value.join(", ")
            : field.key === "changeDate"
            ? moment(value).format("DD/MM/YYYY HH:mm")
            : typeof value === "string" || value instanceof String
            ? !!htmlParser(value)?.props?.children
              ? htmlParser(value)?.props.children
              : formatChanges(value)
            : formatChanges(value)
          : ""
      }`;
      doc.text(xCoordinate + 40, yCoordinate, valueText);
      yCoordinate += 6;
    }
    if ((index + 1) % 4 === 0 && index !== filterKeylog.length - 1) {
      doc.addPage();
      yCoordinate = 15;
    }
  });
  doc.save(`${name}${" "}Keylogs.pdf`);
};

export default exportPdf;
