import types from "../types/types";

const deductions = (deductions) => {
	return (dispatch) => {
		dispatch({
			type: types.SET_DEDUCTIONS,
			payload: deductions,
		});
	};
};
export default deductions;
