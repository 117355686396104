import React, { useState, useEffect } from "react";
import { Button, Select, Form, Input, Checkbox, message } from "antd";
import { DeleteIcon, XIcon } from "../../../../assets/icons";
import { CaretDownOutlined } from "@ant-design/icons";
import { API } from "aws-amplify";
import { useFitimetContext } from ".";
import GlobalPopupKonfirm from "../../../commonComponents/GlobalPopupKonfirm";
import { useAuth } from "../../../../authentication/authHelpers";
import { v4 as uuidv4 } from "uuid";

function FitimetEditCard({ Data, id, handleHideEditView, socketHandler }) {
	const { Option } = Select;
	const [form] = Form.useForm();
	const { setTableData, setToBeEdited } = useFitimetContext();
	const { userAccess } = useAuth();

	const username = {
		userId: userAccess[0].userSub,
		employeeFirstName: userAccess[0].given_name,
		employeeLastname: userAccess[0].family_name,
	};
	//DATA TO BE update
	const [saveData, setSavedData] = useState({
		...Data,
	});
	// change the state of the data  with the new value
	const handleChange = (objField, e) => {
		let test = objField;
		setSavedData((prev) => ({ ...prev, [test]: e }));
	};



	//function to compare 2 object and get the changes
	function compareObjects(previousObject, currentObject) {
		const keys1 = Object.keys(previousObject);
		const keys2 = Object.keys(currentObject);
		let changesArray = [];

		const commonKeys = keys1.filter((key) => keys2.includes(key));
		if (commonKeys.length === 0) {
		}
		commonKeys.forEach((key) => {
			if (typeof currentObject[key] === "object") {

			} else {
				if (previousObject[key] !== currentObject[key]) {
					let changedData = {
						id: uuidv4(),
						activity: "Ndryshuar",
						author: username,
						changeDate: Date.now(),
						field: key,
						previousData: previousObject[key],
						currentData: currentObject[key],
					};
					changesArray.push(changedData);
				}
			}
		});
		if (changesArray !== 0) {
			return changesArray;
		} else {
			return false;
		}
	}

	// update function
	const updateItems = async () => {
		let { earningId, userId, ...rest } = saveData;
		let logs = compareObjects(Data, saveData)

		await API.put("earnings", `/earnings/${Data.earningId}`, {
			body: { ...rest, earningLogs: !!Data?.earningLogs ? [...Data.earningLogs, logs] : [logs] },
		}).then(() => {
			/**
			 * * @EneaXharau - Added socket emitter
			 * * .send() takes -> { request: String, body: any} -> stringified
			//  */
			// if (socketHandler.readyState === socketHandler.OPEN) {
			// 	socketHandler.send(
			// 		JSON.stringify({
			// 			request: "profit_updated",
			// 			body: "A profit was updated",
			// 		})
			// 	);
			// }

			setTableData((prev) =>
				prev.map((item) => (item.earningId === saveData.earningId ? { ...saveData, earningLogs: [...saveData.earningLogs, logs] } : item))
			);
			setToBeEdited(null);
		});
		// console.log(saveData);
		successUpdate();
	};

	// update message after updating one card
	const successUpdate = () => {
		message.success("Te dhenat u ndryshuan me sukses !", 10);
		window.localStorage.removeItem("draggableSetingsFitimet");
	};

	//delete card
	const deleteItems = async () => {
		await API.del("earnings", `/earnings/${Data.earningId}`).then(() => {
			/**
			 * * @EneaXharau - Added socket emitter
			 * * .send() takes -> { request: String, body: any} -> stringified
			 */
			// if (socketHandler.readyState === socketHandler.OPEN) {
			// 	socketHandler.send(
			// 		JSON.stringify({
			// 			request: "profit_removed",
			// 			body: "A profit was removed",
			// 		})
			// 	);
			// }

			setTableData((prev) => prev.filter((item) => item.earningId !== saveData.earningId));
			success();
		});
	};

	//show   Delete message  after deleting one card
	const success = () => {
		message.success("Te dhenat u fshin me sukses !", 10);
		window.localStorage.removeItem("draggableSetingsFitimet");
	};

	// its for the form to get initail value from server
	useEffect(() => {
		form.setFieldsValue({
			earningAmount: Data.earningAmount,
			// earningFormula: Data.earningFormula,
			earningName: Data.earningName,
			// earningRate: Data.earningRate,
			// earningType: Data.earningType,
			rateFactor: Data.rateFactor,
			excludeFromTotalHours: Data.excludeFromTotalHours,
		});
	}, [Data, form]);
	return (
		<div className="fitimet-edit-card">
			<Form name="modal-fitemt" onFinish={updateItems} form={form}>
				<div className="input-edit-festat" style={{ width: "100%" }}>
					<h3>Titulli:</h3>
					<Form.Item
						name="earningName"
						rules={[
							{
								required: true,
								message: "Ju lutem shkruaj Titullin !",
							},
						]}
					>
						<Input
							type="text"
							autoComplete="off"
							onChange={(e) => handleChange("earningName", e.target.value)}
							placeholder="Shkruaj Titullin"
							style={{ width: 222 }}
						/>
					</Form.Item>
				</div>
				{/* <div className="edit-festat-dropdown" style={{ width: "100%" }}>
          <h3 style={{ width: "100%" }}>Lloji i fitimit:</h3>
          <Form.Item
            name="earningType"
            rules={[
              {
                required: true,
                message: "Selekte nje opsion !",
              },
            ]}
          >
            <Select
              placeholder="Selekto"
              onChange={(val) => handleChange("earningType", val)}
              style={{ width: 222 }}
              bordered={false}
              dropdownClassName="festat-edit-dropdown"
              suffixIcon={<CaretDownOutlined />}
            >
              <Option value="Software Developer">Software Developer</Option>
              <Option value="React Developer">React Developer</Option>
              <Option value="Node Developer">Node Developer</Option>
            </Select>
          </Form.Item>
        </div>
        <div className="edit-festat-dropdown" style={{ width: "100%" }}>
          <h3 style={{ width: "100%" }}>Rregulli i llogaritjes:</h3>
          <Form.Item
            name="earningFormula"
            rules={[
              {
                required: true,
                message: "Selekte nje opsion !",
              },
            ]}
          >
            <Select
              placeholder="Selekto"
              onChange={(val) => handleChange("earningFormula", val)}
              style={{ width: 222 }}
              bordered={false}
              dropdownClassName="festat-edit-dropdown"
              suffixIcon={<CaretDownOutlined />}
            >
              <Option value="Software Developer">Software Developer</Option>
              <Option value="React Developer">React Developer</Option>
              <Option value="Node Developer">Node Developer</Option>
            </Select>
          </Form.Item>
        </div>
        <div className="input-edit-festat" style={{ width: "100%" }}>
          <h3>Raporti:</h3>
          <Form.Item
            name="earningRate"
            rules={[
              {
                required: true,
                message: "Ju lutem shkruaj Raportin !",
              },
            ]}
          >
            <Input
              type="number"
              onChange={(e) => handleChange("earningRate", e.target.value)}
              autoComplete="off"
              placeholder="0.00"
              style={{ width: 222 }}
            />
          </Form.Item>
        </div> */}
				<div className="input-edit-festat" style={{ width: "100%" }}>
					<h3>Rregulli:</h3>
					<Form.Item
						name="rateFactor"
						rules={[
							{
								required: true,
								message: "Ju lutem shkruaj Faktorin e raportit !",
							},
						]}
					>
						<Input
							type="number"
							onChange={(e) => handleChange("rateFactor", e.target.value)}
							autoComplete="off"
							placeholder="0.00"
							style={{ width: 222 }}
						/>
					</Form.Item>
				</div>
				<div className="input-edit-festat" style={{ width: "100%" }}>
					<h3>Vlera:</h3>
					<Form.Item
						name="earningAmount"
						rules={[
							{
								required: true,
								message: "Ju lutem shkruaj Vlerën !",
							},
						]}
					>
						<Input
							type="number"
							onChange={(e) => handleChange("earningAmount", e.target.value)}
							autoComplete="off"
							placeholder="0.00"
							style={{ width: 222 }}
						/>
					</Form.Item>
				</div>
				<Form.Item
					name="excludeFromTotalHours"
					valuePropName="checked"
				// rules={[
				//   {
				//     validator: async (_, checked) => {
				//       if (!checked) {
				//         return Promise.reject(
				//           new Error("Ju lutem Zgjidh Opsionin !")
				//         );
				//       }
				//     },
				//   },
				// ]}
				>
					<Checkbox
						onChange={(e) => handleChange("excludeFromTotalHours", e.target.checked)}
						className="edit-festat-checkbox"
						style={{ width: "100%" }}
					>
						<h3 style={{ width: 222 }}>Përjashtoje në shfaqjen e orëve totale</h3>
					</Checkbox>
				</Form.Item>
				<Form.Item>
					<div className="footer-edit-card">
						<div className="icon-goBack" onClick={() => handleHideEditView(id)}>
							<XIcon />
						</div>
						<GlobalPopupKonfirm
							Button={
								<div
									className="icon-del"
								// onClick={deleteItems}
								>
									<DeleteIcon width={18} height={18} />
								</div>
							}
							title={"Jeni të sigurt që do të fshini këtë rekord"}
							onConfirm={deleteItems}
							okText={"Po"}
							cancelText={"Jo"}
						/>
						<Button htmlType="submit" className="ruaj-foter-card">
							Ruaj Ndryshimet
						</Button>
					</div>
				</Form.Item>
			</Form>
		</div>
	);
}
export default FitimetEditCard;
