const types = {
	// User Rights
	SET_USER_RIGHTS: "SET_USER_RIGHTS",
	// Departments
	SET_DEPARTMENTS: "SET_DEPARTMENTS",
	// Program Fields
	SET_PROGRAM_FIELDS: "SET_PROGRAM_FIELDS",
	// Employees
	SET_EMPLOYEES: "SET_EMPLOYEES",
	//Add Employee
	ADD_EMPLOYEE: "ADD_EMPLOYEE",
	//EDIT Employee
	EDIT_EMPLOYEE: "EDIT_EMPLOYEE",
	//Active Employees
	GET_ACTIVE_EMPLOYEES: "GET_ACTIVE_EMPLOYEES",
	// USers
	SET_USERS: "SET_USERS",
	//Edit User
	EDIT_USER: "EDIT_USER",
	// Clockings
	SET_CLOCKINGS: "SET_CLOCKINGS",
	ADD_CLOCKING: "ADD_CLOCKING",
	EDIT_CLOCKING: "EDIT_CLOCKING",
	// Documentations
	SET_DOCUMENTATIONS: "SET_DOCUMENTATIONS",
	// Access Token
	SET_ACCESS_TOKEN: "SET_ACCESS_TOKEN",
	// Employee Requests
	SET_EMPLOYEE_REQUESTS: "SET_EMPLOYEE_REQUESTS",
	// Earnings
	SET_EARNINGS: "SET_EARNINGS",
	// Deductions
	SET_DEDUCTIONS: "SET_DEDUCTIONS",
	// Prepayments
	SET_PREPAYMENTS: "SET_PREPAYMENTS",
	// Wages
	SET_WAGES: "SET_WAGES",
	// Current Prepayments (not database)
	SET_CURRENT_PREPAYMENTS: "SET_CURRENT_PREPAYMENTS",
	// Change Problematikat
	SET_PROBLEMS: "SET_PROBLEMS",
	REMOVE_PROBLEMS: "REMOVE_PROBLEMS",
	// Change Interview Candidates,
	SET_CANDIDATES: "SET_CANDIDATES",
	REMOVE_CANDIDATES: "REMOVE_CANDIDATES",
	// Hot Credentials
	SET_HOT_CREDENTIALS: "SET_HOT_CREDENTIALS"
};
export default types;
