import "../PayStub.scss";
import React from "react";
import { isNumber } from "lodash";
import { currencyformatter } from "../../wages/utils/formatter";

function PayStubFinalTable({ columnsDef, rowData }) {
	return (
		<div className="payStub-final-table">
			<table>
				<tr>
					{/* <th>PERMBLEDHJE</th>
          <th>AKTUALISHT</th>.
		  
          <th>BRUTO</th> */}
					{columnsDef?.map((el, idx) => (
						<th style={idx !== 0 ? { textAlign: "right" } : { textAlign: "left" }}> {el}</th>
					))}
				</tr>{" "}
				{rowData?.length > 0 ? (
					rowData?.map((rd) => (
						<tr>
							{rd?.map((trd, idx) => (
								<td style={idx !== 0 ? { textAlign: "right" } : null}>
									{isNumber(trd)
										? currencyformatter.format(trd?.toFixed(2)).replace("ALL", "")
										: trd}
								</td>
							))}
						</tr>
					))
				) : (
					<strong style={{ display: "flex", justifyContent: "center", color: "red" }}>
						Nuk ka te dhena per te shfaqur
					</strong>
				)}
				{/* <tr>
          <td>Cras condimentum</td>
          <td>5.00</td>
          <td>25.00</td>
        </tr>
        <tr>
          <td>Cras condimentum</td>
          <td>5.00</td>
          <td>25.00</td>
        </tr>
        <tr>
          <td>Cras condimentum</td>
          <td>5.00</td>
          <td>25.00</td>
        </tr> */}
			</table>
		</div>
	);
}

export default PayStubFinalTable;
