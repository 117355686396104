import { Form, Modal, Tooltip, Spin } from "antd";
import DrivePermissionList from "../../../DrivePermissionList/DrivePermissionList";
import { useContext, useEffect, useState, useMemo } from "react";
import { InterviewsContext } from "../../data";
import { fileIcons } from "../../../Documentation/View/documentationViewData";
import dayjs from "dayjs";
import { openInNewTab } from "../../../Documentation/utils/openInNewTab";
import MultipleFilePrevieModal from "../../../dashboard/AdminDashboard/AdminCards/Problems/MultipleFilePreviewModal/MultipleFilePreviewModal";
import { ActionButtons } from "../../../Documentation/View/components/DocumentationModal/components";
import { ModalXIcon } from "../../../../assets/icons";
import "./PermissionsDocModal.scss";
import FilePreviewModal from "../../../Documentation/View/components/FilePreviewModal/FilePreviewModal";
import Avatar from "react-avatar";
import { apiPut } from "../../../DocumentationConfiguration/utils/api";
import { useAuth } from "../../../../authentication/authHelpers";
import { useSelector } from "react-redux";
import { driveApi } from "../../../DriveRequest";

const ACCESS_RIGHTS = ["Admin", "Supervisor"];

const PermissionsDocModal = ({
  setVisible,
  currentFile,
  endpoint,
  recordId,
  currentKeylogs,
  folderId,
}) => {
  const [form] = Form.useForm();
  const { userRole } = useAuth();
  const { accessToken } = useSelector((state) => state.accessToken);
  const driveRequest = driveApi({ accessToken });

  const [previewModal, setPreviewModal] = useState(false);
  const [loadingFileImage, setLoadingFileImage] = useState(true);
  const [fileImage, setFileImage] = useState("");
  const [keylogs, setKeylogs] = useState([]);

  const fileExtension = (currentFile?.name || "").split(".").pop();

  const getCurrentFileImage = async (id) => {
    setLoadingFileImage(true);
    await driveRequest
      .getImageSrc(id)
      .then((res) => {
        setFileImage(res?.src);
        setLoadingFileImage(false);
      })
      .catch((err) => console.log("err:", err));
  };
  const saveKeylogs = async () => {
    if (!!keylogs?.length) {
      await apiPut(endpoint, recordId, {
        keylog: [...(currentKeylogs || []), ...keylogs],
      })
        .then(() => setKeylogs([]))
        .catch((err) => {
          console.log("err:", err);
        });
    }
  };

  useEffect(() => {
    saveKeylogs();
  }, [keylogs?.length]);

  useEffect(() => {
    if (!!Object.keys(currentFile)?.length) {
      if (currentFile?.type === "photo") getCurrentFileImage(currentFile?.id);
      else {
        setFileImage("");
        setLoadingFileImage(false);
      }
    }
  }, [currentFile?.id]);

  return (
    <>
      <Modal
        {...{
          open: true,
          className: "permissionsDocModal",
          destroyOnClose: true,
          centered: true,
          maskClosable: true,
          title: "Ndrysho autorizimet në Drive",
          onCancel: () => {
            setVisible(false);
            form.resetFields();
          },
          footer: null,
        }}
        closeIcon={<ModalXIcon />}
      >
        {!!Object.keys(currentFile)?.length && (
          <div className="content">
            <div
              className="fileCard"
              key={currentFile?.id}
              data-testid="permissionsFileCard"
              onClick={() => {
                if (
                  currentFile?.type !== "photo" &&
                  currentFile?.mimeType !== "application/pdf"
                )
                  openInNewTab(currentFile?.url);
                else {
                  setPreviewModal(true);
                }
              }}
            >
              <div>
                {currentFile?.type === "photo" &&
                !!fileImage &&
                !loadingFileImage ? (
                  <Avatar src={fileImage} size="50" round={true} />
                ) : (
                  <Spin
                    spinning={!!loadingFileImage}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: 50,
                      height: 50,
                    }}
                  />
                )}
                {!loadingFileImage &&
                  !fileImage &&
                  (fileIcons({
                    type: fileExtension,
                    width: 45,
                    height: 45,
                  }) ||
                    fileIcons({ type: "image", width: 45, height: 45 }))}
              </div>
              <div className="uploadedFile">
                <div className="row">
                  <span style={{ fontWeight: 600 }}>{currentFile?.name}</span>
                  <span>
                    {dayjs(currentFile?.uploadedAt).format("DD/MM/YYYY")}
                  </span>
                </div>
                <div className="row" style={{ color: "rgba(0, 0, 0, 0.45)" }}>
                  <span>{currentFile?.uploadedBy}</span>
                  <span>
                    {dayjs(currentFile?.uploadedAt).format("hh:mm a")}
                  </span>
                </div>
              </div>
            </div>
            {!!ACCESS_RIGHTS.includes(userRole) ? (
              <DrivePermissionList
                {...{
                  form,
                  folderId,
                  setKeylogs,
                }}
              />
            ) : null}
            {!!previewModal ? (
              <FilePreviewModal
                {...{
                  visible: previewModal,
                  setVisible: setPreviewModal,
                  file: { ...currentFile, src: fileImage },
                }}
              />
            ) : null}
          </div>
        )}
      </Modal>
    </>
  );
};

export default PermissionsDocModal;
