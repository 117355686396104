export const dev = {
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://yr85kva3h4.execute-api.us-east-1.amazonaws.com/dev",
    // URL: "https://ajn2r29jbj.execute-api.us-east-1.amazonaws.com/prod",
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_FsKfaDEGb",
    APP_CLIENT_ID: "59eft9nih0k784b68ur045jf5f",
    IDENTITY_POOL_ID: "us-east-1:024d5a81-b5fd-478f-84d0-6d19a6857f91",
  },
};
const prod = {
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://ajn2r29jbj.execute-api.us-east-1.amazonaws.com/prod",
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_FsKfaDEGb",
    APP_CLIENT_ID: "59eft9nih0k784b68ur045jf5f",
    IDENTITY_POOL_ID: "us-east-1:024d5a81-b5fd-478f-84d0-6d19a6857f91",
  },
};
const config = {
  // Default to dev if not set
  ...(process.env.NODE_ENV === "production" ? prod : dev),
};

export default config;
