import { SketchPicker } from "react-color";
import useOnclickOutside from "react-cool-onclickoutside";

const ColorPicker = ({ value, onChange, setVisible }) => {
  const ref = useOnclickOutside(() => setVisible(false));

  return (
    <div {...{ ref, className: "colorPickerContainer" }}>
      <SketchPicker
        {...{
          color: value,
          onChangeComplete: (color) => onChange(color?.hex),
        }}
      />
    </div>
  );
};

export default ColorPicker;
