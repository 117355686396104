import React, { useState, useEffect } from "react";
import { Menu, message, Popconfirm } from "antd";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../../authentication/authHelpers";
import { LogoKryesore, UserSidebar } from "../../assets/icons";
import { useNavigate, Outlet } from "react-router-dom";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { SidebarData } from "../../components/Sidebar/SidebarData";

function MobileHomeMenu({ data }) {
  const [k, setK] = useState([]);
  const [sData, setSData] = useState([]);
  const { users } = useSelector((state) => state.usersList);

  const [pagatFocus, setPagatFocus] = useState(null);
  const loc = useLocation();
  const auth = useAuth();
  const navigate = useNavigate();

  const handlePassword = () => {
    Swal.fire({
      title: "Vendos kodin personal!!",
      input: "password",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      cancelButtonText: "Anulo",
      confirmButtonText: "Konfirmo",
      cancelButtonColor: "#EA3943",
      confirmButtonColor: "#1DA193",
      reverseButtons: true,
      showLoaderOnConfirm: true,
      showClass: {
        popup: "",
      },
      hideClass: {
        popup: "", // disable popup fade-out animation
      },
      // preConfirm: async (login) => {
      // 	if (login === "1234") {
      // 		navigate("/pagat");
      // 	} else {
      // 		message.error("Kodi i vendosur eshte gabim!");
      // 	}
      // },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        if (result.value === users?.pinNumber) {
          // Swal.fire({
          // 	title: `SAKTE!`,
          // });
          navigate("/pagat");
        } else {
          message.error("Kodi i vendosur eshte gabim!");
          handlePassword();
        }
      }
    });
  };

  // if refresh page to still be focused last item was clicked
  useEffect(() => {
    let idx = SidebarData.findIndex((el) => el.to.includes(loc.pathname));
    if (idx !== -1) setK([`${SidebarData[idx]?.key}`]);
  }, [loc]);

  useEffect(() => {
    if (data.length > 0) {
      setSData(data);
    }
  }, [data]);
  useEffect(() => {
    if (loc?.pathname === "/pagat" || loc?.pathname === "/pagat/llogaritja") {
      setPagatFocus("pagat");
    } else {
      setPagatFocus(null);
    }
  }, [loc]);

  return (
    <>
      {sData?.length > 0 && (
        <Menu
          mode="vertical"
          selectedKeys={k}
          defaultSelectedKeys={[k]}
          overlayClassName="mobile-menu"
          className="mobile-menu">
          {/* <h1>{sData?.email}</h1> */}
          {/* fetch the sidebar data and check if have userAccess */}
          {data[0]?.accessConfig?.sidebarConfig[0]
            ?.filter((a) => a.auth !== false)
            .map((el, idx) => {
              let Icon =
                SidebarData[SidebarData.findIndex((s) => s.to === el.to)]?.src;
              if (el?.to === "/pagat") {
                return (
                  <Popconfirm
                    openClassName="mob-tooltip"
                    className="sigurt-mobile"
                    overlayClassName="mob-tooltip"
                    placement="right"
                    title={
                      <div className="mob-tooltip-content">
                        A jeni te sigurt?
                      </div>
                    }
                    onConfirm={() => handlePassword()}
                    key={el?.id}>
                    <Menu.Item
                      className={pagatFocus === "pagat" ? "Focus-Show" : ""}
                      style={{
                        paddingLeft: 0,
                      }}
                      key={el?.id}
                      icon={<Icon fill="#1d3445" width={18} height={16} />}>
                      {el?.title}
                    </Menu.Item>
                  </Popconfirm>
                );
              }
              return (
                <Menu.Item key={el?.id} icon={<Icon fill="#1d3445" width={18} height={16} />}>
                  <Link
                    style={{ color: "#1d3445" }}
                    to={el?.to}
                    key={el?.id}
                    rel="noopener noreferrer">
                    {el?.title}
                  </Link>
                </Menu.Item>
              );
            })}
        </Menu>
      )}
    </>
  );
}

export default MobileHomeMenu;
