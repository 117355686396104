import React, { useMemo } from "react";
import { HollowDotsSpinner } from "react-epic-spinners";
import Chart from "react-google-charts";
import { Alert, message } from "antd";
import { useSelector } from "react-redux";
import { useState } from "react";
import moment from "moment";
import { getRandomColor } from "../Employees/Employees";
import {
  Bar,
  BarChart,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const shortings = {
  "IT & Software Development": { color: "#B0DAFF", shortName: "IT" },
  "Human Resources": { color: "#FFB0B0", shortName: "HR" },
  "Finance & Accounting": { color: "#B0FFB0", shortName: "FA" },
  "Sales Management": { color: "#FFB0FF", shortName: "Sales" },
  "Internal Staff": { color: "#FFB0B0", shortName: "Internal" },
  "Architecture & Engineering": { color: "#B0FFB0", shortName: "A&E" },
  Administration: { color: "#FFB0FF", shortName: "Admin" },
  "Project Monitoring & Coordinating": { color: "#B0DAFF", shortName: "PM&C" },
};
function WagesDepartments() {
  const { clockings } = useSelector((state) => state.clockings);
  const [allClockIns, setAllClockIns] = useState(null);
  const [allClockOuts, setAllClockOuts] = useState(null);
  const [lateClockIns, setLateClockIns] = useState(null);
  const { departments } = useSelector((state) => state.departments);
  const { eligibleEmployeesClockIn } = useSelector(
    (state) => state.employeesList
  );
  const [missedClockIns, setMissedClockIns] = useState([]);

  const dataSet = useMemo(() => {
    let temp = false;
    if (!!eligibleEmployeesClockIn && !!clockings && !!departments) {
      const filteredClockings = clockings?.filter(
        (c) =>
          !!c?.clockInDate &&
          !!c?.clockOutDate &&
          c?.approved === true &&
          moment(c?.clockInDate).startOf("month").valueOf() ===
            moment().startOf("month").valueOf()
      );
      temp = {};
      departments?.forEach((d) => {
        temp[d.departmentId] = {
          departmentName:
            shortings[d?.departmentName]?.shortName || d?.departmentName,
          count: 0,
          color: shortings[d.departmentName]?.color || getRandomColor(),
        };
      });
      eligibleEmployeesClockIn?.forEach((e) => {
        let depId = e?.employeeDepartmentId;
        let clockings = filteredClockings
          ?.filter((c) => c?.employeeId === e?.employeeId)
          ?.forEach((c) => {
            if (!!temp[depId]) {
              const dayEarned = c?.totalities?.totalPay || 0;
              temp[depId].count += dayEarned;
            }
          });
      });
    }

    return temp
      ? Object.values(temp)?.map((el) => {
          return { name: el?.departmentName, pagat: el?.count.toFixed(2) };
        })
      : [];
  }, [eligibleEmployeesClockIn, clockings, departments]);

  let colors = [
    "#1DA193",
    "#1DA193",
    "#FCC94A",
    "#EA3943",
    "#1DA193",
    "#1DA193",
    "#EA3943",
    "#1DA193",
  ];

  return (
    <>
      {!!clockings && !!eligibleEmployeesClockIn ? (
        <div className="clock-in-dashboard-card">
          {1 === 1 ? (
            <div
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ResponsiveContainer width="98%" height="95%">
                <BarChart
                  data={dataSet}
                  margin={{
                    top: 40,
                    right: 40,
                    left: -10,
                    bottom: 10,
                  }}
                  barSize={20}
                >
                  <XAxis
                    dataKey="name"
                    scale="point"
                    padding={{ left: 50, right: 50 }}
                  />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="pagat" fill="#8884d8" barSize={50}>
                    {dataSet.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={colors[index % 20]} />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: 55,
              }}
            >
              <Alert
                type="info"
                showIcon
                message="Nuk ka te dhena statistikore..."
              />
            </div>
          )}
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <HollowDotsSpinner color="#1DA193" size={24} />
        </div>
      )}
    </>
  );
}

export default WagesDepartments;
