import _ from "lodash";

export const groupByKey = (arr, key) => {
	if (arr.length > 0) {
		return _.groupBy(arr, key);
	}
};

export const currencyformatter = new Intl.NumberFormat("en-US", {
	style: "currency",
	currency: "ALL",
	minimumFractionDigits: 2,
	// These options are needed to round to whole numbers if needed.
	//minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
	//maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});
