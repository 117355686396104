import MondayButton from "../../../commonComponents/MondayButton/MondayButton";
import { Tick } from "../../assets/index";
import { CloseOutlined } from "@ant-design/icons";
import "./FormActionButtons.scss";

const FormActionButtons = ({
  className = "",
  onSubmit,
  discardButtonText = "Hidhni",
  submitButtonText = "Paraqisni",
  onDiscard,
}) => (
  <div className={`docFormActionButtons ${className}`.trim()}>
    <MondayButton
      className="discardButton"
      onClick={onDiscard}
      Icon={<CloseOutlined />}
    >
      {discardButtonText}
    </MondayButton>
    <MondayButton
      className="submitButton"
      onClick={onSubmit}
      Icon={
        <div>
          <Tick className="submitIcon" />
        </div>
      }
    >
      {submitButtonText}
    </MondayButton>
  </div>
);

export default FormActionButtons;
