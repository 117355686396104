import types from "../types/types";

const initialState = { userRights: undefined };
const userRightsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  // console.log("payloa", payload);

  switch (type) {
    case types.SET_USER_RIGHTS:
      return {
        ...state,
        userRights: payload,
      };
    default:
      return state;
  }
};

export default userRightsReducer;
