import React, { useEffect, useMemo, useState, useCallback } from "react";
import { Modal, Button, Select, Row, Col, message } from "antd";
// import { API } from "aws-amplify";
import { CaretDownFilled } from "@ant-design/icons";
// import { IconaMbyll } from "../../../../assets/icons";
import moment from "moment-timezone";
// import GlobalPopupKonfirm from "../../../commonComponents/GlobalPopupKonfirm";
import { useDispatch } from "react-redux";
import { API } from "aws-amplify";
import { employees as emplAct } from "../../../../store/actions";

function ListaModal({
	setDataLista,
	setListaModal,
	listaModal,
	dataLista,
	employees,
	tableData,
	setLoading,
	socketHandler,
}) {
	const [selectedEmpls, setSelectedEmpls] = useState([]);
	const [initial, setInitial] = useState([]);
	const dispatch = useDispatch();
	const { Option } = Select;

	function handleChange(value) {
		setSelectedEmpls(value);
		// console.log(value);
	}

	/**
	 * * @EneaXharau - Async function taking no parameters
	 * * it awaits for redux to fetch data from AWS database
	 * * then sets in state which also re-renders component to display new info
	 */
	const reloadEmpl = async () => {
		await API.get("employees", "/employees").then((res) => {
			dispatch(emplAct(res, "GET_ACTIVE_EMPLOYEES"));
		});
	};

	const addSingleEmplShift = async (emplId, shiftId) => {
		let current = employees.find((el) => el.employeeId === emplId);
		if (!!current) {
			let prevShifts = current?.employeeShiftId || [];
			let isPresent = prevShifts.find((c) => c === shiftId) || false;
			// console.log("case 1", [...prevShifts, shiftId], "case2:", [shiftId]);
			if (!isPresent) {
				await API.put("employees", `/employees/${emplId}`, {
					body: {
						employeeShiftId: [...prevShifts, shiftId],
					},
				}).then((res) => {
					/**
					 * * @EneaXharau - Added socket emitter
					 * * .send() takes -> { request: String, body: any} -> stringified
					 */
					// if (socketHandler.readyState === socketHandler.OPEN) {
					//   socketHandler.send(
					//     JSON.stringify({
					//       request: "shift_edited",
					//       body: "A shift was edited",
					//     })
					//   );
					// }
				});
			} else if (!!isPresent) {
				message.success("Turni është prezent për këtë punonjës!");
			}
		}
	};

	const removeShift = async (emplId, shiftId) => {
		let current = employees.find((el) => el.employeeId === emplId)?.employeeShiftId || [];
		await API.put("employees", `/employees/${emplId}`, {
			body: {
				employeeShiftId: [...current.filter((el) => el !== shiftId)],
			},
		}).then(() => {
			/**
			 * * @EneaXharau - Added socket emitter
			 * * .send() takes -> { request: String, body: any} -> stringified
			 */
			// if (socketHandler.readyState === socketHandler.OPEN)
			//   socketHandler.send(
			//     JSON.stringify({
			//       request: "shift_edited",
			//       body: "A shift was edited",
			//     })
			//   );
		});
	};

	const onReset = () => {
		setSelectedEmpls([]);
		setDataLista(null);
		setInitial([]);
		setListaModal(false);
	};

	const loading = () => {
		setLoading(true);
		setTimeout(() => {
			setLoading(false);
		}, 3000);
	};

	const onSave = async () => {
		// console.log("Data Lista", dataLista, "lM", listaModal);
		// initial
		try {
			if (selectedEmpls?.length > 0) {
				selectedEmpls.map(async (emp) => {
					let isInit = initial.find((el) => el === emp) || false;
					if (!isInit) {
						await addSingleEmplShift(emp, dataLista?.shiftId);
						// message.success("Punonjësi shtua me sukses");
					}
				});
			}
			if (initial?.length > 0) {
				initial?.map(async (i) => {
					let isSelected = selectedEmpls?.find?.((sEmp) => sEmp === i) || false;
					if (!isSelected) {
						await removeShift(i, dataLista?.shiftId);
					}
				});
			}
		} catch (err) {
			console.error("An error happened with shifts", err);
		} finally {
			await reloadEmpl();
			message.success("Veprimi u krye me sukses");
			onReset();
			loading();
			// onReset();
		}
	};

	useEffect(() => {
		if (!!dataLista && !!employees) {
			employees?.map?.((empl) => {
				const temp = empl?.employeeShiftId || [];
				if (temp?.length > 0) {
					temp?.map?.((t) => {
						if (t === dataLista?.shiftId) {
							setSelectedEmpls((prev) => [...prev, empl?.employeeId]);
							setInitial((prev) => [...prev, empl?.employeeId]);
						}
					});
				}
			});
		}
	}, [dataLista]);

	const employeMemo = useMemo(() => {
		if (selectedEmpls?.length > 0) {
			let temp = selectedEmpls?.map?.((el) => {
				let em = employees?.find?.((em) => em.employeeId === el);
				if (!!em) {
					let shifts = em.employeeShiftId || [];
					let shiftInfo = shifts?.map?.((shift) => ({
						...tableData?.find?.((td) => td.shiftId === shift),
					}));
					return {
						...em,
						shiftInfo,
					};
				}
			});
			return temp;
		}
		return [];
	}, [selectedEmpls, employees]);

	//   const employeesActiv = employees?.filter?.(
	//     (el) => el?.employeeStatus === "Aktiv"
	//   );

	/**
	 * * @EneaXharau - useEffect to listen to socket emits from backend
	 * * and run a function with a setTimeout to cancel the delay of emitting
	 * * according to its emit request.
	 * * .onmessage is a variable that needs to be a function (arrow preferably)
	 * * .onmessage takes a parameter from backend which is is a stringified object
	 */
	// useEffect(() => {
	//   socketHandler.onmessage = (msg) => {
	//     const { request, body } = JSON.parse(msg.data);
	//     switch (request) {
	//       case "shift_edited_received":
	//         reloadEmpl();
	//         break;
	//       default:
	//         break;
	//     }
	//   };
	//   return () => socketHandler.close();
	// }, []);

	return (
		<div>
			<Modal
				title="Lista e punonjësve për këtë turn"
				centered
				destroyOnClose={true}
				className="paisjet-orarit-modal"
				wrapClassName="sistemi-turnet"
				open={listaModal}
				onOk={() => onReset()}
				onCancel={() => onReset()}
				width={700}
				footer={[
					<Button className="mbyll" key="close" onClick={onReset}>
						Mbyll
					</Button>,
					<Button
						form="shift-update-modal-form"
						type="primary"
						className="ruaj"
						htmlType="submit"
						key="save"
						onClick={onSave}
					>
						Ruaj Ndryshimet
					</Button>,
				]}
			>
				<div className="header">
					<h3>Selekto një punonjës</h3>
					<Select
						mode="multiple"
						allowClear
						showArrow
						showSearch={false}
						placeholder="Please select"
						value={selectedEmpls}
						onChange={handleChange}
						suffixIcon={<CaretDownFilled style={{ fontSize: 15, color: "black" }} />}
						className="select-turn"
						bordered={false}
						style={{ width: "95%", background: "#F5F5F7", borderRadius: 5 }}
					>
						{employees?.map?.((data) => (
							<Option value={data?.employeeId} key={data?.employeeId}>
								{data?.employeeFirstName}&nbsp;{data?.employeeLastName}
							</Option>
						))}
					</Select>
				</div>
				<div className="conetnet-lista">
					{employeMemo?.map((data) => {
						return (
							<Row
								style={{
									width: "100%",
									paddingLeft: 50,
									paddingRight: 50,
									paddingBottom: 5,
								}}
							>
								<Col className="line-col" span={24}>
									<div
										className={
											data?.employeeShiftId?.length > 1 ? "name-conetent-red" : "name-conent"
										}
									>
										{data?.employeeFirstName} &nbsp;{data?.employeeLastName}
										&nbsp;
									</div>
									<div
										className={data?.employeeShiftId?.length > 1 ? "lista-line-red" : "lista-line"}
									></div>
									{data?.shiftInfo?.map((shift) => (
										<div
											className={
												data?.employeeShiftId?.length > 1 ? "time-content-red" : "time-content"
											}
										>
											{moment(shift?.shiftStartTime).format("HH:mm")} -&nbsp;
											{moment(shift?.shiftEndTime).format("HH:mm")}
											&nbsp; &nbsp;
										</div>
									))}
								</Col>
								{/* <Col className="icona-col" span={1}>
                  <GlobalPopupKonfirm
                  Button={
                    <div className="lista-icon-mbyll">
                    <Tooltip
                    placement="top"
                    overlayClassName="global-icon-tooltip"
                    title={"Fshi një rekord"}
                    >
                    <IconaMbyll
                    className="ikona-mbyll"
                    width={10}
                    height={10}
                    />
                    </Tooltip>
                    </div>
                  }
                  title={"Jeni të sigurt do fshini ktë punonjës nga ky turn !"}
                  // onConfirm={deleteItems}
                  okText={"Po"}
                  cancelText={"Jo"}
                  />
                </Col> */}
							</Row>
						);
					})}
				</div>
			</Modal>
		</div>
	);
}
export default ListaModal;
