import {
  FolderFilled,
  FolderOpenFilled,
  FolderOutlined,
} from "@ant-design/icons";
import { Tree } from "antd";
import FormCard from "../../../FormCard/FormCard";
import CardHeader from "./components/CardHeader/CardHeader";
import { openInNewTab } from "../../../utils/openInNewTab";
import "./DocumentationFileTree.scss";
import { useContext, useState } from "react";
import FilePreviewModal from "../FilePreviewModal/FilePreviewModal";
import driveApi from "../../../../DriveRequest/driveApi";
import { fileIcons } from "../../documentationViewData";
import PunonjesitContext from "../../../../punonjesit/store/PunonjesitContext";
import { useSelector } from "react-redux";

const DocumentationFileTree = ({ className = "" }) => {
  const { accessToken } = useSelector((state) => state.accessToken);
  const driveRequest = driveApi({ accessToken });

  const { documentation, employee } = useContext(PunonjesitContext);
  const { googleDriveFolderIds = {} } = employee || {};

  const { docFolderId, userFolderId } = googleDriveFolderIds;
  const recordName = `${
    employee?.employeeFirstName + " " + employee?.employeeLastName
  }`;

  const [currentFile, setCurrentFile] = useState({});
  const [previewModalVisible, setPreviewModalVisible] = useState(false);
  const [expandedNodes, setExpandedNodes] = useState([
    userFolderId,
    docFolderId,
  ]);

  // !shows file and file children in documentation file tree maps docType and folderId
  const treeData = [
    {
      title: recordName,
      key: userFolderId,
      selectable: false,
      children:
        Object.keys(googleDriveFolderIds)?.length > 0
          ? [
              {
                title: "Documentation",
                key: docFolderId,
                selectable: !!docFolderId,
                children: documentation?.reduce(
                  (acc, { docType, folderId }) =>
                    !!acc
                      ?.map(({ title: accDocType }) => accDocType)
                      ?.includes(docType)
                      ? acc
                      : [
                          ...acc,
                          {
                            title: docType,
                            key: folderId,
                            children: documentation
                              ?.filter(
                                ({ docType: arrDocType }) =>
                                  docType === arrDocType
                              )
                              ?.flatMap(({ googleDriveUploads }) =>
                                googleDriveUploads?.map(
                                  ({ name, id, ...rest }) => ({
                                    title: name,
                                    key: id,
                                    isLeaf: true,
                                    ...rest,
                                  })
                                )
                              ),
                          },
                        ],
                  []
                ),
              },
            ]
          : [],
    },
  ];

  const titleRender = (data = {}) => (
    <span
      className={!!data?.children ? "folder" : "file"}
      onClick={() => {
        const { type, mimeType, key: id, url } = data;

        type !== "photo" && mimeType !== "application/pdf"
          ? onSelect(data)
          : (async () => {
              setPreviewModalVisible(true);
              await driveRequest
                .getImageSrc(id)
                .then((image) => setCurrentFile({ ...image, mimeType, url }));
            })();
      }}
    >
      {!!data?.children?.length ? (
        expandedNodes.includes(data.key) ? (
          <FolderOpenFilled className="fileIcon" />
        ) : (
          <FolderFilled className="fileIcon" />
        )
      ) : (
        (() => {
          const { title: name } = data;
          const fileExtension = name.split(".").pop();

          return !!data?.isLeaf ? (
            <span className="fileIcon">
              {fileIcons({ type: fileExtension }) ||
                fileIcons({ type: "image" })}
            </span>
          ) : (
            <FolderOutlined className="fileIcon" />
          );
        })()
      )}
      {data.title}
    </span>
  );

  const onSelect = ({ key, children, url }) => {
    key !== recordName &&
      key !== "documentation" &&
      !!key &&
      (!!children
        ? openInNewTab(`https://drive.google.com/drive/folders/${key}`)
        : openInNewTab(url));
  };

  return (
    <>
      <FormCard
        title={<CardHeader />}
        className={`documentationFileTree ${className}`}
        childrenClassName="treeContainer"
      >
        {!!documentation?.length > 0 ? (
          <Tree
            {...{
              treeData,
              titleRender,
              showIcon: true,
              defaultExpandedKeys: [userFolderId, docFolderId],
              onExpand: (array) => setExpandedNodes(array),
            }}
          />
        ) : (
          <span className="callback-title">
            Ky punonjës nuk ka asnjë folder në Google Drive
          </span>
        )}
      </FormCard>
      {!!previewModalVisible && (
        <FilePreviewModal
          {...{
            setVisible: () => {
              setCurrentFile({});
              setPreviewModalVisible(false);
            },
            file: currentFile,
          }}
        />
      )}
    </>
  );
};

export default DocumentationFileTree;
