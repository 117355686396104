import { Button } from "antd";
import { cloneElement } from "react";
import "./MondayButton.scss";

const MondayButton = ({
  children,
  disabled = false,
  Icon,
  onClick,
  className = "",
  hasIcon = true,
  containerClassName,
  style,
  key,
  testid = "",
}) => (
  <div
    className={`mondayButtonContainer ${containerClassName}`}
    style={style}
    key={key}
    data-testid={testid}
  >
    <Button
      onClick={onClick}
      className={`mondayButtonStyle ${className}`.trim()}
      disabled={disabled}
    >
      <div className="mondayButtonText">{children}</div>
      {children && hasIcon && (
        <div
          className="mondayButtonDivider"
          style={!!disabled ? { backgroundColor: "#C8C8C8" } : {}}
        />
      )}
      {Icon && cloneElement(Icon, { className: "mondayButtonIcon" })}
    </Button>
  </div>
);

export default MondayButton;
