import React from "react";
import "./siguria_perdoruesit.scss";
import moment from "moment-timezone";

function PritTablePerdoruesit({ tableData, componentRef }) {
  return (
    <div className="Print-Punojsit" ref={componentRef}>
      <table id="table-to-perdoruesit">
        <thead>
          <tr>
            <th>Emri</th>
            <th>Mbiemri</th>
            <th>Gjinia</th>
            <th>Email</th>
            <th>Departamenti</th>
            <th>Pozicioni</th>
            {/* <th>Nr Tel</th> */}
            <th>Krijuar</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((el) => (
            <tr key={el.identityId}>
              <td>{el.given_name}</td>
              <td>{el.family_name} </td>
              <td>{el.gender} </td>
              <td>{el.email} </td>
              <td>{el.department} </td>
              <td>{el.departmentRole} </td>
              {/* <td>{JSON.stringify(el.phone_number)} </td> */}
              <td>{moment(el.createdAt).format("DD/MM/YYYY")} </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default PritTablePerdoruesit;
