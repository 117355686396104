import { useState, useEffect } from "react";
import "./AdminShiftLogs.scss";
import { Drawer, Skeleton } from "antd";
import {
  // getAllUsers,
  getLogsPerShift,
} from "../../../punonjesit/EmployeeView/Payment/EmployeeShifts/utils/apiHelpers";
// import { useAuth } from "../../../../authentication/authHelpers";
// import moment from "moment-timezone";
import ShiftLogCard from "./ShiftLogCard";
import CreatedShiftCard from "./CreatedShiftCard";
import {
  ClearIconDrawer,
  CloseIcon,
  ModalXIcon,
} from "../../../../assets/icons";
// import { ClearIcon } from "../../../dashboard/assets";
// import { ClearOutlined, DeleteFilled } from "@ant-design/icons";
import { Alert } from "antd";
import { useSelector } from "react-redux";
import { API } from "aws-amplify";
function GeneralAdminShift({
  // users,
  // shiftAdminLog,
  // setShiftAdminLog,
  shiftLogsVisibility,
  setShiftLogsVisibility,
}) {
  const [shiftLogs, setShiftLogs] = useState(null);
  const { clockings } = useSelector((state) => state.clockings);
  const { users } = useSelector((state) => state.usersList);
  const [clockingLogs, setClockingLogs] = useState([]);
  const { activeEmployees } = useSelector((state) => state.employeesList);

  const onClose = () => {
    setShiftLogsVisibility(false);
    // setShiftLogs(null);
    // setShiftAdminLog(null);
  };

  useEffect(() => {
    if (!!activeEmployees && !!users && !!clockings) {
      API.get("clockingLogs", "/clockingLogs")
        .then((res) => {
          let items = res
            .sort((a, b) => b.createdAt - a.createdAt)
            .slice(0, 500);
          items.forEach((item) => {
            const temp = users?.allUsers.Items?.find(
              (user) => user?.userSub === item?.username
            );
            const empId = clockings.find(
              (clocking) => clocking.clockingId === item.clockingId
            );
            const tempEmp = activeEmployees?.find(
              (emp) => emp?.employeeId === empId?.employeeId
            );
            // console.log("temp", temp);
            item.adminName = temp ? temp?.given_name : null;
            item.adminLastName = temp ? temp?.family_name : null;
            item.userRole = temp ? temp?.userRole : null;
            item.employee = tempEmp
              ? tempEmp?.employeeFirstName + " " + tempEmp?.employeeLastName
              : null;
          });
          setShiftLogs(items);
        })
        .catch((err) => console.log(err));
    }
  }, [activeEmployees, users, clockings, shiftLogsVisibility]);

  return (
    <>
      <Drawer
        title="Historiku i turnit"
        placement="right"
        onClose={onClose}
        open={shiftLogsVisibility}
        width={576}
        destroyOnClose={true}
        closeIcon={<ModalXIcon />}
      >
        {Array.isArray(shiftLogs) ? (
          <div className="shift-log-cards-container">
            {shiftLogs.length > 0 ? (
              shiftLogs.map((el, idx) => (
                <ShiftLogCard content={el} key={idx} />
              ))
            ) : (
              <div className="alert-no-changes">
                <Alert
                  message="Nuk ka ndryshime për këtë turn!"
                  type="info"
                  showIcon
                />
              </div>
            )}
            {/* {!!shiftAdminLog?.clockingLogs && (
							<div>
								<CreatedShiftCard content={shiftAdminLog} users={users} />
							</div>
						)} */}
          </div>
        ) : (
          <>
            {[...Array(5)].map((el) => (
              <Skeleton />
            ))}
          </>
        )}
      </Drawer>
    </>
  );
}

export default GeneralAdminShift;
