import { Form, Button, Modal, Select, DatePicker } from "antd";
import moment from "moment-timezone";
import { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { RequestChair } from "../../../../dashboard/assets";
import CancelAddEmployeeModalPopover from "../../../AddEmployeeModal/Popover";
import "./EmployeeReasonModal.scss";

const EmployeeReasonModal = ({
  employee,
  changeEmployeeStatus,
  modalVisible,
  setModalVisible,
}) => {
  const termination = modalVisible === "shkarko" ? true : false;
  const [form] = Form.useForm();
  const { Option } = Select;

  const { programFields } = useSelector((state) => state.programFields);

  const [popoverVisible, setPopoverVisible] = useState(false);

  //* gets Arsyeja e perfundimit from programFields
  const employeeReasons = useMemo(() => {
    if (!programFields?.length) return [];

    const employeeReason = programFields?.find(
      (res) => res.fieldName === "Arsyeja e perfundimit"
    );
    return employeeReason?.fieldOptions?.filter(
      (item) => item?.name !== "Logs"
    );
  }, [programFields]);

  return (
    <Modal
      title={`${
        employee?.employeeFirstName + " " + employee?.employeeLastName
      } / ${!!termination ? "Shkarko" : "Largo"} punonjësin`}
      className="reasonModal"
      open={!!modalVisible}
      onCancel={() => {
        setPopoverVisible(true);
      }}
      maskClosable={false}
      centered
      data-testid="modal-header-title"
      footer={[
        <div className="reasonModalButtons">
          <Button
            className="reasonAnuloButton"
            data-testid="anulo-btn"
            onClick={() => setPopoverVisible(true)}
          >
            Anulo
          </Button>
          <Button
            className="confirmReason"
            type="primary"
            data-testid="konfirmo-btn"
            htmlType="submit"
            onClick={() => {
              const reason = form.getFieldValue("employeeReason");
              const date =
                form.getFieldValue("employeeContractEndDate") || moment();
              changeEmployeeStatus({
                data: {
                  employeeStatus: `${!!termination ? "Shkarkuar" : "Larguar"}`,
                  ...(!!termination
                    ? { employeeReasonforTermination: reason }
                    : { employeeReasonforLeaving: reason }),
                  employeeContractEndDate: date?.valueOf(),
                },
              });
              setModalVisible(false);
              form.resetFields();
            }}
          >
            Konfirmo
          </Button>
        </div>,
      ]}
    >
      {/* <Form form={form} onFinish={changeEmployeeStatus}> */}
      <Form form={form}>
        <CancelAddEmployeeModalPopover
          title={`Ti nuk ke përfunduar, a je i sigurt që dëshiron të largohesh nga ${
            !!termination ? "shkarkimi" : "largimi"
          } i punonjësit?`}
          form={form}
          overlayClassName="reasonPopover"
          visible={popoverVisible}
          setVisible={setPopoverVisible}
          setModalVisible={setModalVisible}
          modalVisible={modalVisible}
          onClose={() => setModalVisible(false)}
        />
        <div className="reasonModalContainer">
          <div className="reasonModalComponents">
            <Form.Item
              className="terminationLabel"
              label="Arsyeja"
              data-testid="reason-form-item"
              name={"employeeReason"}
              // name={`${
              //   !!termination
              //     ? "employeeReasonforTermination"
              //     : "employeeReasonforSuspension"
              // }`}
              rules={[
                {
                  required: true,
                  message: `${
                    !!termination
                      ? "Përshkrimi i shkarkimit të punonjësit është i detyrueshëm"
                      : "Arsyeja e largimit të punonjësit është e detyrueshme!"
                  }`,
                },
              ]}
            >
              <Select
                className="inputReason"
                placeholder={`Selekto arsyen e ${
                  !!termination ? "shkarkimit" : "largimit"
                }`}
              >
                {employeeReasons?.map(({ name }) => (
                  <Option value={name} key={name}>
                    {name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              className="terminationLabel"
              label="Data e përfundimit"
              data-testid="reason-form-item"
              name="employeeContractEndDate"
              rules={[
                {
                  required: true,
                  message: `Data e ${
                    !!termination ? "shkarkimit" : "largimit"
                  } të punonjësit është e detyrushme!`,
                },
              ]}
            >
              <DatePicker
                allowClear
                format={"DD/MM/YYYY"}
                defaultValue={moment()}
                className="reasonDate"
                placeholder="Selekto datën"
              />
            </Form.Item>
          </div>
          <RequestChair className="suspensionChair" />
        </div>
      </Form>
    </Modal>
  );
};

export default EmployeeReasonModal;
