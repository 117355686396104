import axios from "axios";
import { API } from "aws-amplify";
/**
 *
 * @param {Object} headings  title of notification ex. {en:"This is headling"}
 * @param {Object} contents  title of content ex. {en:"This is content"}
 * @param {Object} url  url of redirect notification
 * @param {Object} data  data with custom values ex. {"custom_data":"some value"}
 * @param {Array} included_segments list of groups to sent default("["All"]")
 */
export const sendNotification = ({
  headings = { en: "" },
  contents = { en: "" },
  url = null,
  data = { custom_data: "" },
  included_segments = ["All"],
  key,
  value,
  filterType,
}) => {
  var notificationBody = {
    app_id:
      process.env.NODE_ENV === "production"
        ? "cd2085f7-5cf7-4a64-be0a-b53f049a34fc"
        : "9e32441c-55a6-4dcf-b0dd-d3ecca7aa921",
    headings,
    contents,
    url,
    data,
    included_segments,
    filters: [!!key && !!value ? getValueOb(filterType, key, value) : [null]],
  };
  var headers = {
    "Content-Type": "application/json; charset=utf-8",
    Authorization:
      process.env.NODE_ENV === "production"
        ? "Basic ZmI1MThiNWQtOGI2MC00NWU1LWE0MzgtZjc0MGVlMDM0NjAz"
        : "Basic N2M4YzBlZmYtYjk1MC00MzJkLWE4ZjAtOTUzOTBhYzM2MzU1",
  };

  console.log("notbody", notificationBody);
  axios.post("https://onesignal.com/api/v1/notifications", notificationBody, {
    headers,
  });
  //   axios
  //     .get(
  //       "https://onesignal.com/api/v1/notifications?app_id=1d2d8a47-fd02-42a1-a4e8-cb2b415de5f5",
  //       {
  //         headers,
  //       }
  //     )
  //     .then((res) =>
  //       console.log({
  //         res: res.data.notifications.filter(({ data }) => {
  //           return data?.ids ? data?.ids?.includes("ernest@flex.al") : false;
  //         }),
  //       })
  //     )
  //     .catch((err) => console.log({ err }));
};
export const getNotification = () => {
  var headers = {
    "Content-Type": "application/json; charset=utf-8",
    Authorization:
      process.env.NODE_ENV === "production"
        ? "Basic ZmI1MThiNWQtOGI2MC00NWU1LWE0MzgtZjc0MGVlMDM0NjAz"
        : "Basic N2M4YzBlZmYtYjk1MC00MzJkLWE4ZjAtOTUzOTBhYzM2MzU1",
  };
  process.env.NODE_ENV === "production"
    ? axios.get(
        "https://onesignal.com/api/v1/notifications?app_id=cd2085f7-5cf7-4a64-be0a-b53f049a34fc",
        {
          headers,
        }
      )
    : axios
        .get(
          "https://onesignal.com/api/v1/notifications?app_id=9e32441c-55a6-4dcf-b0dd-d3ecca7aa921",
          {
            headers,
          }
        )
        // .then((res) =>
        //   console.log({
        //     res: res.data.notifications.filter(({ data }) => {
        //       return data?.ids ? data?.ids?.includes("ernest@flex.al") : false;
        //     }),
        //   })
        // )
        .catch((err) => console.error({ err }));
};
// function send notification from user to admin
export const notificationUserToAdmin = async (saveData, users) => {
  // dispatch({ type: "ADD_NOTIF", payload: saveData });
  let test = {};
  users
    ?.filter((u) => u.userRole === "Admin" || u.userRole === "Super Admin")
    ?.forEach((el) => {
      test[el.identityId] = {
        notificationMarkedHidden: false,
        notificationMarkedDeleted: false,
        notificationMarkedRed: false,
      };
    });
  // console.log(saveData, test);
  await API.post("notifications", "/notifications", {
    body: { ...saveData, userDetails: { ...test } },
  }).then((r) => {
    let div = document.createElement("div");
    div.innerHTML = saveData.notificationBody;
    sendNotification({
      headings: { en: saveData.notificationTitle },
      contents: { en: div.innerText },
      url: "https://portal.flex.al/kryefaqja",
      key: "userRole",
      value: "Admin",
      // included_segments: ["s"]
      // data: {
      //   ids: [
      //     { user: auth.userAccess[0].email, read: false, removed: false },
      //   ],
      // },
    });
  });
};

export const notificationUserToAdminNoDbPost = async (saveData, users) => {
  // dispatch({ type: "ADD_NOTIF", payload: saveData });
  let test = {};
  users
    ?.filter((u) => u.userRole === "Admin" || u.userRole === "Super Admin")
    ?.forEach((el) => {
      test[el.identityId] = {
        notificationMarkedHidden: false,
        notificationMarkedDeleted: false,
        notificationMarkedRed: false,
      };
    });
  // console.log(saveData, test);
  // await API.post("notifications", "/notifications", {
  // 	body: { ...saveData, userDetails: { ...test } },
  // }).then((r) => {
  let div = document.createElement("div");
  div.innerHTML = saveData.notificationBody;
  sendNotification({
    headings: { en: saveData.notificationTitle },
    contents: { en: div.innerText },
    url: "https://portal.flex.al/kryefaqja",
    key: "userRole",
    value: "Admin",
    // included_segments: ["s"]
    // data: {
    //   ids: [
    //     { user: auth.userAccess[0].email, read: false, removed: false },
    //   ],
    // },
  });
  // });
};
// function send notification from admin to everyone
export const notificationAdminToEveryone = async (saveData, users) => {
  // dispatch({ type: "ADD_NOTIF", payload: saveData });
  let test = {};
  users?.forEach((el) => {
    test[el.identityId] = {
      notificationMarkedHidden: false,
      notificationMarkedDeleted: false,
      notificationMarkedRed: false,
    };
  });
  // console.log(saveData, test);
  await API.post("notifications", "/notifications", {
    body: { ...saveData, userDetails: { ...test } },
  }).then((r) => {
    let div = document.createElement("div");
    div.innerHTML = saveData.notificationBody;
    sendNotification({
      headings: { en: saveData.notificationTitle },
      contents: { en: div.innerText },
      url: "https://portal.flex.al/kryefaqja",
      // included_segments: ["s"]
      // data: {
      //   ids: [
      //     { user: auth.userAccess[0].email, read: false, removed: false },
      //   ],
      // },
    });
  });
};
export const notificationAdminToSecifikUser = async (saveData, users) => {
  // dispatch({ type: "ADD_NOTIF", payload: saveData });
  let test = {};
  users?.forEach((el) => {
    test[el.identityId] = {
      notificationMarkedHidden: false,
      notificationMarkedDeleted: false,
      notificationMarkedRed: false,
    };
  });
  await API.post("notifications", "/notifications", {
    body: { ...saveData, userDetails: { ...test } },
  }).then((r) => {
    let div = document.createElement("div");
    div.innerHTML = saveData.notificationBody;
    sendNotification({
      headings: { en: saveData.notificationTitle },
      contents: { en: div.innerText },
      url: "https://portal.flex.al/kryefaqja",
      key: "userRole",
      value: "Employee", // included_segments: ["s"]
      // data: {
      //   ids: [
      //     { user: auth.userAccess[0].email, read: false, removed: false },
      //   ],
      // },
    });
  });
};

export const notificationAdminToOnlyOneUser = async (saveData, user) => {
  // dispatch({ type: "ADD_NOTIF", payload: saveData });
  let test = {};
  test[user?.identityId] = {
    notificationMarkedHidden: false,
    notificationMarkedDeleted: false,
    notificationMarkedRed: false,
  };
  await API.post("notifications", "/notifications", {
    body: { ...saveData, userDetails: { ...test } },
  }).then((r) => {
    let div = document.createElement("div");
    div.innerHTML = saveData.notificationBody;
    sendNotification({
      headings: { en: saveData.notificationTitle },
      contents: { en: div.innerText },
      url: "https://portal.flex.al/kryefaqja",
      key: "identityId",
      value: `${user?.identityId}`, // included_segments: ["s"]
      // data: {
      //   ids: [
      //     { user: auth.userAccess[0].email, read: false, removed: false },
      //   ],
      // },
    });
  });
};
export const notificationToAllAdminAndSecifikUser = async (
  saveData,
  users,
  user,
  skipSave = false
) => {
  // dispatch({ type: "ADD_NOTIF", payload: saveData });
  let test = {};
  users?.forEach((el) => {
    test[el.identityId] = {
      notificationMarkedHidden: false,
      notificationMarkedDeleted: false,
      notificationMarkedRed: false,
    };
  });
  if (skipSave) {
    await API.post("notifications", "/notifications", {
      body: { ...saveData, userDetails: { ...test } },
    }).then((r) => {
      let div = document.createElement("div");
      div.innerHTML = saveData.notificationBody;
      sendNotification({
        headings: { en: saveData.notificationTitle },
        contents: { en: div.innerText },
        url: "https://portal.flex.al/kryefaqja",
        key: "userRole",
        value: "Admin", // included_segments: ["s"]
        // data: {
        //   ids: [
        //     { user: auth.userAccess[0].email, read: false, removed: false },
        //   ],
        // },
      });
      sendNotification({
        headings: { en: saveData.notificationTitle },
        contents: { en: div.innerText },
        url: "https://portal.flex.al/kryefaqja",
        key: "identityId",
        value: `${user?.identityId}`, // included_segments: ["s"]
        // data: {
        //   ids: [
        //     { user: auth.userAccess[0].email, read: false, removed: false },
        //   ],
        // },
      });
    });
  } else {
    let div = document.createElement("div");
    div.innerHTML = saveData.notificationBody;
    sendNotification({
      headings: { en: saveData.notificationTitle },
      contents: { en: div.innerText },
      url: "https://portal.flex.al/kryefaqja",
      key: "userRole",
      value: "Admin", // included_segments: ["s"]
      // data: {
      //   ids: [
      //     { user: auth.userAccess[0].email, read: false, removed: false },
      //   ],
      // },
    });
    sendNotification({
      headings: { en: saveData.notificationTitle },
      contents: { en: div.innerText },
      url: "https://portal.flex.al/kryefaqja",
      key: "identityId",
      value: `${user?.identityId}`, // included_segments: ["s"]
      // data: {
      //   ids: [
      //     { user: auth.userAccess[0].email, read: false, removed: false },
      //   ],
      // },
    });
  }
};

export const sendNotificationToEmployee = async (saveData, user) => {
  // dispatch({ type: "ADD_NOTIF", payload: saveData });
  console.log("user", user, "saveData", saveData);
  const test = {};

  test[user.identityId] = {
    notificationMarkedHidden: false,
    notificationMarkedDeleted: false,
    notificationMarkedRed: false,
  };
  await API.post("notifications", "/notifications", {
    body: { ...saveData, userDetails: { ...test } },
  }).then((r) => {
    let div = document.createElement("div");
    div.innerHTML = saveData.notificationBody;
    sendNotification({
      headings: { en: saveData.notificationTitle },
      contents: { en: div.innerText },
      url: "https://portal.flex.al/kryefaqja",
      key: "identityId",
      value: `${user?.identityId}`, // included_segments: ["s"]
      // data: {
      //   ids: [
      //     { user: auth.userAccess[0].email, read: false, removed: false },
      //   ],
      // },
    });
  });
};

export const notificationToDepartment = async (saveData, users) => {
  console.log("1", users);
  // dispatch({ type: "ADD_NOTIF", payload: saveData });
  let test = {};
  users?.forEach((el) => {
    test[el.identityId] = {
      notificationMarkedHidden: false,
      notificationMarkedDeleted: false,
      notificationMarkedRed: false,
    };
  });
  await API.post("notifications", "/notifications", {
    body: { ...saveData, userDetails: { ...test } },
  }).then((r) => {
    let div = document.createElement("div");
    div.innerHTML = saveData.notificationBody;
    sendNotification({
      headings: { en: saveData.notificationTitle },
      contents: { en: div.innerText },
      url: "https://portal.flex.al/kryefaqja",
      key: "identityId",
      value: users?.map?.((el) => el?.identityId), // included_segments: ["s"]
      filterType: "multiple",
      // data: {
      //   ids: [
      //     { user: auth.userAccess[0].email, read: false, removed: false },
      //   ],
      // },
    });
  });
};

export const notificationNoPostToDepartment = async (saveData, users) => {
  // dispatch({ type: "ADD_NOTIF", payload: saveData });
  let test = {};
  users?.forEach((el) => {
    sendNotification({
      headings: { en: saveData?.notificationTitle },
      contents: { en: saveData?.content },
      url: "https://portal.flex.al/kryefaqja",
      key: "identityId",
      value: el?.identityId, // included_segments: ["s"]
    });
  });
};

export const getValueOb = (filterType, key, value) => {
  if (filterType === "multiple") {
    return value?.map((el) => {
      return { field: "tag", key: key, relation: "=", value: el };
    });
  } else {
    return { field: "tag", key: key, relation: "=", value: value };
  }
};
