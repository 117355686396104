import { approvals } from "./approvals/approvals";
import { bankForwardPay } from "./payments/bankForwardPay";
import { requests } from "./requests/requests";
import { prepayments } from "./prepayments/prepayments";
import { employeesList } from "./employees/employeesList";
import { detailedTaxes } from "./payments/detailedTaxes";
import { suspensions } from "./employees/suspensions";
import { clockingTypesReports } from "./clockingTypesReports/clockingTypesReports";

export const processFactory = (type, data, filter, definitions, manualColumns, other) => {
	console.log("processFactory", other);
	switch (type) {
		case "approvals":
			return approvals(data, filter, definitions, manualColumns, other);
		case "supervisorApprovals":
			return approvals(data, filter, definitions, manualColumns);
		case "clockingTypesReports":
			return clockingTypesReports(data, filter, definitions, manualColumns, other);
		case "requests":
			return requests(data, filter, definitions, manualColumns);
		case "bankForwardPay":
			return bankForwardPay(data, filter, definitions, manualColumns);
		case "prepayments":
			return prepayments(data, filter, definitions, manualColumns);
		case "employeesList":
			return employeesList(data, filter, definitions, manualColumns);
		case "detailedTaxes":
			return detailedTaxes(data, filter, definitions, manualColumns);
		case "suspensions":
			return suspensions(data, filter, definitions, manualColumns);
		default:
			return null;
	}
};
