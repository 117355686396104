import { API } from "aws-amplify";
import moment from "moment-timezone";
import { useState, useEffect } from "react";
import "./Calculations.scss";
import { calculateEmployeeHours, getTimeFilterObj } from "./calculate";
import { CirclesToRhombusesSpinner } from "react-epic-spinners";
import { getValidShifts } from "../../helpers/apiCall";
import { useAuth } from "../../../../authentication/authHelpers";
import { calculateEmployeeChart } from "../../../ListPrezenca/timesheet/utils/calculate";
import { useSelector } from "react-redux";

const Calculations = ({ date, viewType }) => {
  const auth = useAuth();
  const [userData, setUserData] = useState([]);
  const [timeFilterObj, setTimeFilterObj] = useState({
    startTime: "",
    endTime: "",
    type: "",
  });
  const [loadingData, setLoadingData] = useState(true);
  const [timeCalculated, setTimeCalculated] = useState({
    totalHours: 0,
    extraHours: 0,
    missingHours: 0,
    lateHours: 0,
    normalHours: 0,
    extraHours: 0,
  });
  const { employeeRequests } = useSelector((state) => state.employeeRequests);
  const { clockings } = useSelector((state) => state.clockings);

  const retrieveEmployeeShift = async (employeeId) => {
    await API.get("clocking", "/clocking").then((res) => {
      let filtered = res.filter(
        (el) => el.employeeId === employeeId && el.clockOutDate !== null
      );
      let sorted = filtered.sort((a, b) => a.createdAt - b.createdAt);
      // console.log("sorted", sorted);
      setUserData(sorted);
    });
  };

  useEffect(async () => {
    if (auth.employeeId !== null && !!clockings) {
      // await retrieveEmployeeShift("testing234235253325235235234");
      let temp = clockings.filter((el) => el?.employeeId === auth?.employeeId);
      // await getValidShifts(auth.employeeId).then((result) => setUserData(result));
      setUserData(temp);
      setLoadingData(false);
    }
  }, [auth, clockings]);

  useEffect(() => {
    // console.log("User Data", userData);
    let filterObj = getTimeFilterObj(date, viewType);
    setTimeFilterObj(filterObj);
  }, [date, viewType, userData]);

  useEffect(() => {
    // let calculated = calculateEmployeeHours(timeFilterObj, userData);
    const test = calculateEmployeeChart(
      userData,
      auth.employeeId,
      {
        start: timeFilterObj.startTime,
        end: timeFilterObj.endTime,
      },
      employeeRequests
    );
    // console.log("Ledjo Test", test);
    setTimeCalculated((prev) => ({
      ...prev,
      lateHours: test.find((el) => el.key === "lateHours").value,
      normalHours: test.find((el) => el.key === "normalHours").value,
      extraHours: test.find((el) => el.key === "extraHours").value,
      totalHours: test.find((el) => el.key === "totalHours").value,
    }));
  }, [
    timeFilterObj.startTime,
    timeFilterObj.endTime,
    userData,
    employeeRequests,
  ]);

  //autumn theme
  return (
    <div className="calculations-container">
      <div className="calculation-card" style={{ backgroundColor: "#1DA193" }}>
        <span className="category">Para 19:00</span>
        <span className="amount">
          {loadingData ? (
            <CirclesToRhombusesSpinner style={{ marginTop: "15px" }} size={5} />
          ) : (
            timeCalculated?.normalHours.toFixed(2)
          )}
        </span>

        {/* <span className="amount">90.56</span> */}
      </div>
      <div className="calculation-card" style={{ backgroundColor: "#FCC94A" }}>
        <span className="category">19-22:00</span>
        {/* <span className="amount">12.34</span> */}
        <span className="amount">
          {loadingData ? (
            <CirclesToRhombusesSpinner style={{ marginTop: "15px" }} size={5} />
          ) : (
            timeCalculated?.lateHours.toFixed(2)
          )}
        </span>
      </div>
      <div className="calculation-card" style={{ backgroundColor: "#EA3943" }}>
        <span className="category">Pas 22:00</span>
        {/* <span className="amount">16.00</span> */}
        <span className="amount">
          {loadingData ? (
            <CirclesToRhombusesSpinner style={{ marginTop: "15px" }} size={5} />
          ) : (
            timeCalculated?.extraHours.toFixed(2)
          )}
        </span>
      </div>
      <div className="calculation-card" style={{ backgroundColor: "#1D3445" }}>
        <span className="category">Totali</span>
        {/* <span className="amount">86.9</span> */}
        <span className="amount">
          {loadingData ? (
            <CirclesToRhombusesSpinner style={{ marginTop: "15px" }} size={5} />
          ) : (
            timeCalculated?.totalHours.toFixed(2)
          )}
        </span>
      </div>
    </div>
  );
};

export default Calculations;
