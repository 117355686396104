import { Button, Modal } from "antd";
import React from "react";
import { InfoBluecIcon, InfoOvertime } from "../../../../assets/icons";
import { useAuth } from "../../../../authentication/authHelpers";
import "./InfoOvertimeModal.scss";
const InfoOvertimeModal = ({ infoVisible, setVisible, setInfoVisible }) => {
  const { employee } = useAuth();

  return (
    <div>
      <Modal
        className="InfoOvertimeModal"
        destroyOnClose={true}
        closable={false}
        open={infoVisible}
        centered
        footer={null}
      >
        <div className="lejeRequestOvertime">
          <InfoBluecIcon
            style={{
              height: 62,
              width: 62,
            }}
          />
          <div className="questionRequest">Kur mund të kërkosh leje?</div>
          <div className="greetRequest">
            Përshëndetje <strong>{`${employee?.employeeFirstName},`}</strong>
            <br />
            për të bërë një kërkesë për orë shtesë ju duhet që ta kërkoni të
            paktën një ditë para.
          </div>
          <div className="footerActionBtn">
            <Button
              onClick={() => setInfoVisible(false)}
              className="mbyllFaqen"
            >
              Mbyll faqen
            </Button>
            <Button
              onClick={() => {
                return setVisible(true), setInfoVisible(false);
              }}
              className="createBtn"
            >
              Krijo një kërkesë për leje
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default InfoOvertimeModal;
