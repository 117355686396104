import { API } from "aws-amplify";
import { useState, useEffect, useMemo } from "react";
import ProgressShift from "./ProgressShift";
import "./TimeSheet.scss";
import TimeSheetHeader from "./TimeSheetHeader";
import { groupByKey } from "../../../utils/loadash/methods";
import {
  filterByDay,
  filterCustomShifts,
  initializeShift,
} from "./utils/filters";
import moment from "moment-timezone";
import ChartSummary from "../../dashboard/components/charts/Chart";
import { calculateEmployeeChart, findEmployee } from "./utils/calculate";
import LoadableComp from "../../commonComponents/LoadableComp/LoadableComp";
import { Alert, BackTop, Tooltip } from "antd";
import { paySubFactory } from "../../Pagat/PaySub/employeeCalc/employeeSubFactory";
import { UnfoldIcon } from "../../../assets/icons";

function TimeSheet({ data, employees, auth, shiftsConfig }) {
  // const [filteredShifts, setFilteredShifts] = useState([]);
  const [appliedSearch, setAppliedSearch] = useState(false);
  const [employeeList, setEmployeeList] = useState([]);
  const [searchRes, setSearchRes] = useState([]);
  const [departamentFilter, setDepartamentFilter] = useState(null);
  const [departmentActive, setDepartmentActive] = useState(false);
  const [viewType, setViewType] = useState(null);
  const [searchString, setSearchString] = useState(null); // search query
  const [departmentId, setDepartmentId] = useState(null);
  const [employeeId, setEmployeeId] = useState(null);
  const [totalHours, setTotalHours] = useState(0);
  // PAGINATION
  const [pagination, setPagination] = useState({ head: 0, tail: 20 });

  const [filterInstances, setFilterInstances] = useState({
    monthInstance: moment(),
    timeType: "months",
    employeeInstance: null,
    departmentInstance: null,
    customDateInstance: null,
  });

  const filteredShifts = useMemo(() => {
    setTotalHours(0);
    const result =
      filterInstances.customDateInstance !== null
        ? filterCustomShifts(data, filterInstances.customDateInstance, "days")
        : initializeShift(
            data,
            filterInstances.monthInstance,
            filterInstances.timeType
          );
    // console.log("Result", result);

    let filtered = false;
    let filterRes;
    if (filterInstances.employeeInstance !== null) {
      filtered = true;
      filterRes = result.map((r) =>
        r.filter((el) => el.employeeId === filterInstances.employeeInstance)
      );
    } else if (filterInstances.departmentInstance !== null) {
      filtered = true;
      filterRes = result.map((r) =>
        r.filter(
          (el) =>
            el.employeeDepartmentName === filterInstances.departmentInstance
        )
      );
    } else {
      filtered = false;
      return result;
    }
    return filtered ? filterRes : result;
  }, [filterInstances]);

  const filterMonths = async (monthInstance) => {
    setTotalHours(0);
    if (monthInstance !== null) {
      setFilterInstances((prev) => ({
        ...prev,
        monthInstance: monthInstance,
        timeType: "months",
      }));
    }
    if (monthInstance === null) {
      setFilterInstances((prev) => ({
        ...prev,
        monthInstance: moment(),
        timeType: "years",
      }));
    }
  };

  const handleSelect = (val) => {
    setTotalHours(0);

    if (!!val) {
      setFilterInstances((prev) => ({
        ...prev,
        employeeInstance: val,
        departmentInstance: null,
      }));
      setDepartmentId(null);
    } else {
      setFilterInstances((prev) => ({ ...prev, employeeInstance: null }));
      setEmployeeId(null);
      setSearchRes(null);
    }
  };

  const resetPagination = () => {
    setPagination({ head: 0, tail: 20 });
  };

  const handleDepartment = (val) => {
    setTotalHours(0);
    if (!!val) {
      setFilterInstances((prev) => ({
        ...prev,
        departmentInstance: val,
        employeeInstance: null,
      }));
      setEmployeeId(null);
      setSearchRes(null);
      setDepartmentActive(true);
    } else {
      setFilterInstances((prev) => ({ ...prev, departmentInstance: null }));
      setDepartmentActive(false);
    }
  };

  const onPagination = () => {
    let tempLen = filteredShifts.filter((el) => el.length > 0).length;
    if (tempLen > pagination.tail) {
      if (tempLen - pagination.tail > 20) {
        setPagination((prev) => ({ ...prev, tail: pagination.tail + 20 }));
      } else {
        setPagination((prev) => ({ ...prev, tail: tempLen }));
      }
    }
  };

  const onCustomDate = (val) => {
    setTotalHours(0);

    if (val !== null) {
      setFilterInstances((prev) => ({
        ...prev,
        monthInstance: null,
        departmentInstance: null,
        timeType: "months",
        customDateInstance: val,
      }));
    }
    if (val === null) {
      setFilterInstances((prev) => ({
        ...prev,
        monthInstance: moment(),
        departmentInstance: null,
        customDateInstance: null,

        timeType: "months",
      }));
    }
  };

  useEffect(() => {
    if (!!employees) {
      setEmployeeList(employees);
      // console.log("Employees", employees);
    }
  }, [employees]);

  useEffect(() => {
    if (auth.userAccess.length > 0) {
      if (
        auth.userAccess[0].userRole === "Admin" ||
        auth.userAccess[0].userRole === "Super Admin" ||
        auth.userAccess[0].userRole === "Supervisor"
      ) {
        setViewType("admin");
      } else {
        setViewType("user");
        setEmployeeId(auth.employeeId);
      }
    }
  }, [auth]);

  useEffect(() => {
    if (filterInstances.employeeInstance !== null) {
      setAppliedSearch(true);
    } else if (filterInstances.employeeInstance === null) {
      setAppliedSearch(false);
    }
  }, [filterInstances.employeeInstance]);
  // console.log("Filtered", filteredShifts);

  return (
    <LoadableComp loading={viewType !== null ? false : true}>
      <div
        className="timesheet-wrapper"
        style={{ position: "relative", width: "100%" }}
      >
        <div
          className="timesheet-calculations"
          style={appliedSearch ? { width: "800px" } : { width: "1000px" }}
        >
          <div className="timesheet-header">
            <TimeSheetHeader
              {...{
                filterMonths,
                onCustomDate,
                viewType,
                handleSelect,
                handleDepartment,
                employeeList,
                departamentFilter,
                searchString,
                departmentId,
                setDepartmentId,
                employeeId,
                setEmployeeId,
              }}
            />
          </div>
          <div className="timesheet-progress-wrapper">
            {appliedSearch === false && (
              <>
                {filteredShifts.filter((el) => el.length > 0).length > 0 ? (
                  !departmentActive ? (
                    filteredShifts
                      ?.slice(pagination.head, pagination.tail)
                      .map((el) => (
                        <ProgressShift
                          employeeList={employeeList}
                          appliedSearch={appliedSearch}
                          processedShift={el}
                          viewType={viewType}
                          handleSelect={handleSelect}
                          setTotalHoursGen={setTotalHours}
                          shiftsConfig={shiftsConfig}
                        />
                      ))
                  ) : (
                    filteredShifts.map((el) => (
                      <ProgressShift
                        employeeList={employeeList}
                        appliedSearch={appliedSearch}
                        processedShift={el}
                        viewType={viewType}
                        handleSelect={handleSelect}
                        shiftsConfig={shiftsConfig}
                        setTotalHoursGen={setTotalHours}
                      />
                    ))
                  )
                ) : (
                  <Alert type="warning" description="Nuk ka te dhena" />
                )}
              </>
            )}
            {appliedSearch === true && (
              <>
                {filteredShifts.filter((el) => el.length > 0).length ? (
                  filteredShifts?.map((el) => (
                    <ProgressShift
                      employeeList={employeeList}
                      appliedSearch={appliedSearch}
                      processedShift={el}
                      viewType={viewType}
                      handleSelect={handleSelect}
                      setTotalHoursGen={setTotalHours}
                      shiftsConfig={shiftsConfig}
                    />
                  ))
                ) : (
                  <div style={{ paddingTop: 10, paddingRight: 50 }}>
                    <Alert
                      showIcon
                      type="warning"
                      description="Nuk ka te dhena"
                    />
                  </div>
                )}
              </>
            )}

            <div style={{ display: "flex", justifyContent: "center" }}>
              {" "}
              {pagination.tail <
                filteredShifts.filter((el) => el.length > 0).length &&
                !appliedSearch &&
                !departmentActive && (
                  <span onClick={onPagination} style={{ cursor: "pointer" }}>
                    <UnfoldIcon /> <BackTop />
                  </span>
                )}
            </div>
            {/* {appliedSearch === true
							? filteredShifts?.map((el) => (
									<>
										<ProgressShift
											employeeList={employeeList}
											appliedSearch={appliedSearch}
											processedShift={el}
											viewType={viewType}
										/>
									</>
							  ))
							: null} */}
          </div>
        </div>

        {/* <span>TOTAL HOURS: {totalHours}</span> */}
        {filterInstances.employeeInstance !== null ? (
          <div className="timesheet-statistics">
            <DynamicName employeeId={employeeId} employeeList={employeeList} />
            <ChartSummary
              employeeChart={employeeId}
              dataTitle="Oret"
              dataSrc={data}
            />
          </div>
        ) : null}
      </div>
    </LoadableComp>
  );
}

export default TimeSheet;

const DynamicName = ({ employeeId, employeeList }) => {
  const [name, setName] = useState("");

  useEffect(() => {
    if (employeeList.length > 0 && employeeId !== null) {
      const r = employeeList.filter((el) => el.employeeId === employeeId);
      // console.log("rrrr", r);
      setName(`${r[0]?.employeeFirstName} ${r[0]?.employeeLastName}`);
    }
  }, [employeeId, employeeList]);

  return <div className="statistic-name">{name}</div>;
};

const getEmployee = (employeeId, employeeList) => {
  if (employeeId !== null && employeeList.length > 0) {
    return employeeList.filter((el) => el.employeeId === employeeId);
  }
};
